import React, { FC, Fragment } from 'react';
import { Grid, Link, List, ListItem, makeStyles, Theme, Toolbar, Tooltip, Typography, withStyles } from '@material-ui/core';
import headerLogo from 'images/logo-full.jpg';

const useStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    backgroundColor: '#FFFFFF'
  },
  logo: {
    width: '280px'
  },
  link: {
    padding: theme.spacing(1),
    textDecoration: 'none',
    color: '#000000',
    fontSize: '12px',
    '&&:hover': {
      textDecoration: 'none',
      color: '#E8BD2F'
    },
    fontWeight: 500,
    cursor: 'pointer'
  },
  activeLink: {
    padding: theme.spacing(1),
    textDecoration: 'none',
    color: '#E8BD2F',
    fontSize: '12px',
    '&&:hover': {
      textDecoration: 'none'
    },
    fontWeight: 500,
    cursor: 'pointer'
  },
  listItem: {
    padding: theme.spacing(0),
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
}));

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: '#F5F5F9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9'
  }
}))(Tooltip);

const ResumePageHeader: FC = () => {
  const classes = useStyles();
  return (
    <Fragment>
      <Toolbar className={classes.toolbar}>
        <Grid container>
          <Grid item xs={12} sm={3} container direction='row' justify='center' alignItems='center'>
            <img src={headerLogo} alt='appLogo' className={classes.logo} />
          </Grid>
          <Grid item xs={12} sm={1} container direction='row' justify='center' alignItems='center'></Grid>
          <Grid item xs={12} sm={8} container direction='row' justify='space-between' alignItems='center' alignContent='center'>
            <Link href='https://besthelperea.sg/' className={classes.link}>
              Home / 主页
            </Link>
            <Link href='https://besthelperea.sg/our-services/' className={classes.link}>
              Our Services / 我们的服务
            </Link>
            <HtmlTooltip
              title={
                <Fragment>
                  <List>
                    <ListItem className={classes.listItem} button disableRipple>
                      <Link href='https://besthelperea.sg/jobs/' className={classes.link}>
                        Job Listing / 职位列表
                      </Link>
                    </ListItem>
                    <ListItem className={classes.listItem} button disableRipple>
                      <Link href='#' className={classes.activeLink}>
                        Upload Resume / 上传简历
                      </Link>
                    </ListItem>
                  </List>
                </Fragment>
              }
              disableFocusListener
              disableTouchListener
              interactive
              placement='bottom-start'
            >
              <Typography className={classes.activeLink}>Helper / 求职者</Typography>
            </HtmlTooltip>
            <HtmlTooltip
              title={
                <Fragment>
                  <List>
                    <ListItem className={classes.listItem} button disableRipple>
                      <Link href='https://besthelperea.sg/staff-request/' className={classes.link}>
                        Staff Request / 雇佣需求
                      </Link>
                    </ListItem>
                  </List>
                </Fragment>
              }
              disableFocusListener
              disableTouchListener
              interactive
              placement='bottom-start'
            >
              <Typography className={classes.link}>Employers / 雇主</Typography>
            </HtmlTooltip>
            <Link href='https://besthelperea.sg/blog/' className={classes.link}>
              News&Policies / 新闻与政策
            </Link>
            <Link href='https://besthelperea.sg/contact-us/' className={classes.link}>
              Contact us / 联系我们
            </Link>
          </Grid>
        </Grid>
      </Toolbar>
    </Fragment>
  );
};

export default ResumePageHeader;
