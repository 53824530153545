import React, { useState } from 'react';
import { Button, Grid, TextField, Theme, Typography, withStyles, Fab, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { orange } from '@material-ui/core/colors';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import MuiPhoneNumber from 'material-ui-phone-number';
import AddIcon from '@material-ui/icons/Add';
import { PRIMARY_COLOR } from 'constants/colors';
import numberToWords from 'number-to-words';
import clsx from 'clsx';

interface Props {
  dateOfBirth: Date;
  setDateOfBirth: React.Dispatch<React.SetStateAction<Date>>;
  placeOfBirth: string;
  setPlaceOfBirth: React.Dispatch<React.SetStateAction<string>>;
  height: number | null;
  setHeight: React.Dispatch<React.SetStateAction<number | null>>;
  weight: number | null;
  setWeight: React.Dispatch<React.SetStateAction<number | null>>;
  nationality: string;
  setNationality: React.Dispatch<React.SetStateAction<string>>;
  contactNumber: string;
  setContactNumber: React.Dispatch<React.SetStateAction<string>>;
  residentialAddress: string;
  setResidentialAddress: React.Dispatch<React.SetStateAction<string>>;
  nameOfPort: string;
  setNameOfPort: React.Dispatch<React.SetStateAction<string>>;
  religion: string;
  setReligion: React.Dispatch<React.SetStateAction<string>>;
  educationCollege: string;
  setEducationCollege: React.Dispatch<React.SetStateAction<string>>;
  numberOfSiblings: string;
  setNumberOfSiblings: React.Dispatch<React.SetStateAction<string>>;
  ageOfChildren: string;
  setAgeOfChildren: React.Dispatch<React.SetStateAction<string>>;
  isSubmitting: boolean;
  onSubmit: React.FormEventHandler;
  onCancel: React.MouseEventHandler;
}

const useStyles = makeStyles((theme: Theme) => ({
  contentGrid: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0)
  },
  cancelButton: {
    marginRight: theme.spacing(3)
  },
  controlDiv: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2)
  },
  textFieldRoot: (props: Props) => ({
    backgroundColor: '#FFFFFF',
    marginTop: theme.spacing(2)
  }),
  textFieldFont: {
    fontSize: '15px',
    height: 18,
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2.5)
  },
  autoCompleteCheckbox: {
    padding: theme.spacing(0)
  },
  checkBoxIcon: {
    fontSize: '16px'
  },
  checkBox: {
    marginLeft: theme.spacing(-2),
    '&&:hover': {
      backgroundColor: 'transparent'
    }
  },
  responsiveScale: {
    width: '100%'
  },
  gridContractNumber: {
    marginLeft: theme.spacing(-2)
  },
  textAddNew: {
    position: 'relative',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -30%)'
  },
  fabIconSmall: {
    width: '20px',
    height: theme.spacing(0),
    marginRight: theme.spacing(1)
  },
  typoCursorLink: {
    cursor: 'pointer'
  }
}));

const SaveButton = withStyles(theme => ({
  root: {
    color: '#FFFFFF',
    backgroundColor: PRIMARY_COLOR,
    '&:hover': {
      backgroundColor: orange[700]
    }
  }
}))(Button);

const ProfileForm: React.FC<Props> = props => {
  const classes = useStyles(props);

  const icon = <CheckBoxOutlineBlankIcon className={classes.checkBoxIcon} />;
  const checkedIcon = <CheckBoxIcon className={classes.checkBoxIcon} />;

  const { height, setHeight } = props;
  const { weight, setWeight } = props;
  const { nationality, setNationality } = props;
  const { contactNumber, setContactNumber } = props;
  const { residentialAddress, setResidentialAddress } = props;
  const { nameOfPort, setNameOfPort } = props;
  const { religion, setReligion } = props;
  const { educationCollege, setEducationCollege } = props;
  const { numberOfSiblings, setNumberOfSiblings } = props;
  const { ageOfChildren, setAgeOfChildren } = props;

  const { isSubmitting, onCancel, onSubmit } = props;

  return (
    <form noValidate onSubmit={onSubmit}>
      <Grid className={classes.contentGrid}>
        <Typography variant='h4' id='modal-title' color='primary'>
          EDIT PROFILE
        </Typography>
        
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              variant='outlined'
              margin='normal'
              select
              required
              fullWidth
              id='nationality'
              label='Nationality'
              value={nationality}
              onChange={event => setNationality(event.target.value)}
              autoComplete='off'
            >
              <MenuItem value='BURMESE' selected={'BURMESE' === nationality}>
                Burmese
              </MenuItem>
              <MenuItem value='FILIPINO' selected={'FILIPINO' === nationality}>
                Filipino
              </MenuItem>
              <MenuItem value='INDONESIAN' selected={'INDONESIAN' === nationality}>
                Indonesian
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin='normal'
              fullWidth
              id='phoneNumber'
              variant='outlined'
              label='Phone Number'
              value={contactNumber}
              onChange={event => setContactNumber(event.target.value)}
              autoComplete='off'
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='residentialAddress'
              label='Residential Address'
              value={residentialAddress}
              onChange={event => setResidentialAddress(event.target.value)}
              autoComplete='off'
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              type='number'
              id='height'
              label='Height (cm)'
              value={height}
              onChange={event => setHeight(event.target.value ? +event.target.value : null)}
              autoComplete='off'
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              type='number'
              id='weight'
              label='Weight (kg)'
              value={weight}
              onChange={event => setWeight(event.target.value ? +event.target.value : null)}
              autoComplete='off'
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='nameOfPort'
              label='Name of Port / Airport To Be Repatriated To'
              value={nameOfPort}
              onChange={event => setNameOfPort(event.target.value)}
              autoComplete='off'
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='religion'
              label='Religion'
              value={religion}
              onChange={event => setReligion(event.target.value)}
              autoComplete='off'
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='educationCollege'
              label='Education Level'
              value={educationCollege}
              onChange={event => setEducationCollege(event.target.value)}
              autoComplete='off'
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='numberOfSiblings'
              label='Number of Siblings'
              value={numberOfSiblings}
              onChange={event => setNumberOfSiblings(event.target.value)}
              autoComplete='off'
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {console.log('Testing', ageOfChildren)}
            <TextField
              variant='outlined'
              margin='normal'
              fullWidth
              id={`ageOfChildren`}
              label='Age of Child'
              value={ageOfChildren}
              onChange={event => setAgeOfChildren(event.target.value)}
              autoComplete='off'
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container item justify='center' xs={12} sm={12} md={12} lg={12} xl={12} className={classes.controlDiv}>
        <Button variant='contained' className={classes.cancelButton} disabled={isSubmitting} onClick={onCancel}>
          Cancel
        </Button>
        <SaveButton type='submit' variant='contained' disabled={isSubmitting}>
          Save
        </SaveButton>
      </Grid>
    </form>
  );
};

export default ProfileForm;
