import React, { FC, useContext, useState, useCallback, useEffect, Fragment } from 'react';
import axios, { CancelTokenSource } from 'axios';
import useCurrentPageTitleUpdater from 'hooks/useCurrentPageTitleUpdater';
import { Button, Container, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { CurrentPageContext } from 'contexts/CurrentPageContext';
import { CANDIDATE_BASE_URL } from 'constants/url';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import ActionSnackbar from 'components/ActionSnackbar';
import ContentSection from './components/ContentSection';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import { getCurrentRoleName } from 'selectors';
import { PaperCustom, Page } from 'components';
import SubMenu from './components/SubMenu';
import CandidatePageContents from 'typings/enum/CandidatePageContents';
import CustomizedTabs from 'components/CustomizedTabs';
import AddIcon from '@material-ui/icons/Add';
import useRouter from 'hooks/useRouter';
import DownloadIcon from '@material-ui/icons/GetApp';
import { CSVLink } from 'react-csv';
import InfiniteScroll from 'react-infinite-scroll-component';
// import InfiniteScroll from 'react-infinite-scroller';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  container: {
    '& > :nth-child(n+2)': {
      marginTop: theme.spacing(2)
    }
  },
  spacing: {
    marginLeft: theme.spacing(1)
  },
  divider: {
    marginBottom: theme.spacing(3)
  },
  pageTitleDivider: {
    backgroundColor: '#0B3469',
    width: 45,
    height: 3,
    marginTop: theme.spacing(0.5)
  },
  subMenuGrid: {
    borderRight: '1px solid #dcdcdc',
    maxWidth: theme.spacing(15)
  },
  headerSubMenuTitleContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(0)
  },
  headerPageTitleContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2)
  },
  contentContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(0),
    paddingBottom: theme.spacing(2)
  },
  gridContent: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    paddingBottom: theme.spacing(2)
  },
  buttonAddCandidate: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  exportToCsvButton: {
    textDecoration: 'none',
    marginBottom: theme.spacing(2)
  }
}));

const CandidatesPageCard: FC = () => {
  useCurrentPageTitleUpdater('Helpers (Card View)');
  const classes = useStyles();
  const { currentPageTitle } = useContext(CurrentPageContext);
  const { currentUser } = useContext(CurrentUserContext);
  const { history } = useRouter();

  const [selectedContent, setSelectedContent] = useState<CandidatePageContents>(CandidatePageContents.All);
  const [skillsQuery, setSkillsQuery] = useState<string[]>(['ALL']);
  const [experienceInSGQuery, setExperienceInSGQuery] = useState<string>('');

  const selectedRenderContent = (selectedContent: CandidatePageContents): React.MouseEventHandler => () => {
    setCurrentPage(0);
    setHasMore(true);
    setSkillsQuery(selectedContent.toUpperCase().split('+'));
    setSelectedContent(selectedContent);
  };

  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarVarient, setSnackbarVarient] = useState<'success' | 'error'>('success');
  const [messageSuccess, setMessageSuccess] = useState<string>('');
  const [messageError, setMessageError] = useState<string>('');
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(8);
  const [isSearchingCandidate, setSearchingCandidate] = useState<boolean>(false);
  const [isSearchCandidateError, setSearchCandidateError] = useState<boolean>(false);
  const [candidates, setCandidates] = useState<CandidateModel[]>([]);
  const [count, setCount] = useState<number>(0);
  const [columnFilter, setColumnFilter] = useState<ColumnFilter[]>([]);
  const [headers, setHeaders] = useState<CsvHeaderModel[]>([]);
  const [isDelete, setDelete] = useState<boolean>(false);
  const [roles, setRoles] = useState<string>('');
  const [hasMore, setHasMore] = useState<boolean>(true);

  const performActionAndRevertPage = (action: React.Dispatch<React.SetStateAction<any>>, actionParam: any) => {
    switch (actionParam) {
      case 0:
        setCurrentPage(0);
        setHasMore(true);
        setExperienceInSGQuery('ALL');
        return;
      case 1:
        setCurrentPage(0);
        setHasMore(true);
        setExperienceInSGQuery('TRANSFER');
        return;
      case 2:
        setCurrentPage(0);
        setHasMore(true);
        setExperienceInSGQuery('EX-SG');
        return;
      case 3:
        setCurrentPage(0);
        setHasMore(true);
        setExperienceInSGQuery('FRESH');
        return;
    }

    action(actionParam);
  };

  // Search Candidate whenever skill, flag, employment status filter changes
  const fetchData = useCallback(() => {
    if (!currentUser) {
      return;
    }

    const roles = currentUser ? getCurrentRoleName(currentUser) : '';
    setRoles(roles);

    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

    const getParams = () => {
      const params = new URLSearchParams();

      skillsQuery.map((x, index) => {
        if (x !== 'ALL') {
          return params.append(`sf[${index}]`, x.replace(/\s/g, ""));
        }
      });

      if (experienceInSGQuery !== 'ALL') {
        params.append('eiSG', experienceInSGQuery.toString());
      }

      if (roles == 'partner_agency') {
        params.append(`status[0]`, 'AVAILABLE');
        params.append(`status[1]`, 'RESERVED');
        params.append(`status[2]`, 'CONFIRMED');
      }

      params.append('s', (0 * 8).toString());
      params.append('l', String(8));

      return params.toString();
    };

    const searchCandidate = async () => {
      setSearchingCandidate(true);
      setSearchCandidateError(false);

      try {
        const url = `${CANDIDATE_BASE_URL}?${getParams()}`;
        const { data } = await axios.get(url, { cancelToken: cancelTokenSource.token });

        setCount(data.count);

        const candidatesData: CandidateModel[] = [...data.candidates];

        setCandidates(candidatesData);
      } catch (err) {
        setSearchCandidateError(true);
      }

      setSearchingCandidate(false);
      setDelete(false);
    };

    searchCandidate();

    return () => {
      cancelTokenSource.cancel();
    };
  }, [currentUser, columnFilter, skillsQuery, experienceInSGQuery]);

  const fetchScrollData = async () => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    const params = new URLSearchParams();

    let result: CandidateModel[] = [];
    
    try {
      skillsQuery.map((x, index) => {
        if (x !== 'ALL') {
          return params.append(`sf[${index}]`, x);
        }
      });

      if (experienceInSGQuery) {
        params.append('eiSG', experienceInSGQuery.toString());
      }

      if (roles == 'partner_agency') {
        params.append(`status[0]`, 'AVAILABLE');
        params.append(`status[1]`, 'RESERVED');
        params.append(`status[2]`, 'CONFIRMED');
      }

      params.append('s', (currentPage === 0 ? 8 : currentPage * rowsPerPage).toString());
      params.append('l', rowsPerPage.toString());

      const url = `${CANDIDATE_BASE_URL}?${params}`;
      const { data } = await axios.get(url, { cancelToken: cancelTokenSource.token });

      result = [...data.candidates];
    } catch (err) {
      setSearchCandidateError(true);
      setDelete(false);
    }

    return result;
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Load candidate data if candidate data has been deleted
  useEffect(() => {
    if (isDelete) {
      fetchData();
    }
  }, [isDelete, fetchData]);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleSetMessageSuccess = (message: string) => {
    setMessageSuccess(message);
  };

  const handleSetMessageError = (message: string) => {
    setMessageError(message);
  };

  const SelectedContent: FC<{ page: number }> = props => {
    switch (props.page) {
      case 0:
        return <div />;
      case 1:
        return <div />;
      default:
        return <div />;
    }
  };

  const handleOpenCreateCandidate = () => {
    history.push({ pathname: `/candidates-card/create/form` });
  };

  const fetchMoreData = () => {
    if (candidates.length === count) {
      setHasMore(false);
      return;
    }

    setCurrentPage(prev => {
      let result = prev + 1 + 1;
      if (prev !== 0) {
        result = prev + 1;
      }
      
      return result;
    });
    setRowsPerPage(8);

    setTimeout(async () => {

      const currentCandidates: CandidateModel[] = [...candidates];
      const newCandidates: CandidateModel[] = await fetchScrollData();

      const mergeArray = [...currentCandidates, ...newCandidates];

      if (candidates.length === count) {
        setHasMore(false);
      }

      setCandidates(mergeArray);
    }, 500);
  };

  const handleReportCandidate = () => {
    const newCandidate: CandidateModel[] = [...candidates];
    
    const candidateData: any[] = [];
    newCandidate.map(value => {
      candidateData.push({
        code: value.code,
        name: value.name,
        bioFee: value.bioFee,
        loan: value.loan,
        dateOfBirth: value.dateOfBirth,
        placeOfBirth: value.placeOfBirth,
        residential: value.residential,
        pocketMoney: value.pocketMoney,
        minimumSalary: value.minimumSalary,
        maritalStatus: value.maritalStatus,
        experienceInSG: value.experienceInSG,
        height: value.height,
        weight: value.weight,
        nationality: value.nationality,
        residentialAddress: value.residential,
        educationLevel: value.educationLevel,
        numberOfSibling: value.numberOfSiblings,
        numberOfChildren: value.numberOfChildren,
        nameOfPort: value.nameOfPort,
      })
    });
    
    return candidateData;
  };

  const renderExportButton = () => {
    if (candidates.length === 0) {
      return (
        <Button variant='outlined' color='primary' disabled={true}>
          <DownloadIcon /> EXPORT .CSV
        </Button>
      );
    } else {
      return (
        <CSVLink data={handleReportCandidate()} separator={','} filename={'candidate list.csv'} className={classes.exportToCsvButton}>
          <Button variant='outlined' color='primary'>
            <DownloadIcon /> EXPORT .CSV
          </Button>
        </CSVLink>
      );
    }
  };

  const renderContentLoading = () => {
    console.log('Testibez', count, candidates);
    if (candidates.length === count) {
      return <div></div>;
    } else {
      return (
        <Fragment>
          <Grid container spacing={1} alignItems='center' justify='center'>
            <Typography color='primary' variant='h4'>
              Loading ...
            </Typography>
          </Grid>
        </Fragment>
      );
    }
  };

  return (
    <Page title={currentPageTitle}>
      <Container maxWidth={false}>
        <Grid container direction='row'>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant='subtitle1' component='h3'>
                  {currentPageTitle}
                </Typography>
                <Typography variant='h6' component='h6' color='textSecondary'>
                  Displays the data of all helper with card view
                </Typography>
              </Grid>
              {roles !== 'partner_agency' && (
                <Grid container item xs={6} justify='flex-end'>
                  <Button variant='contained' color='primary' className={classes.buttonAddCandidate} onClick={handleOpenCreateCandidate}>
                    <AddIcon fontSize='small' />
                    Add Helper
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>

        <PaperCustom>
          <Grid item container xs={12} sm={12} md={12} lg={12} xl={12} spacing={1}>
            <Grid item className={classes.subMenuGrid}>
              <Container className={classes.headerSubMenuTitleContainer}>
                <Typography color='textSecondary' variant='h6'>
                  FILTER BY
                </Typography>
              </Container>
              <SubMenu selectedRenderContent={selectedRenderContent} subMenuActive={selectedContent} />
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container direction='column' spacing={2}>
                <Grid item xs>
                  <CustomizedTabs
                    tabs={[{ id: 0, name: 'ALL' }, { id: 1, name: 'TRANSFER' }, { id: 2, name: 'EX-SG' }, { id: 3, name: 'FRESH' }]}
                    selectedTabId={selectedTab}
                    onSelect={(tabId: number) => performActionAndRevertPage(setSelectedTab, tabId)}
                  />
                  <SelectedContent page={selectedTab} />
                </Grid>
                <Grid className={classes.gridContent}>
                  <Container className={classes.contentContainer}>
                    <Grid item xs={12} container direction='row' justify='flex-end' alignItems='center'>
                      {renderExportButton()}
                    </Grid>
                    <InfiniteScroll dataLength={candidates.length} next={fetchMoreData} hasMore={hasMore} height={750} loader={renderContentLoading()}>
                      <ContentSection
                        candidates={candidates}
                        isLoadingData={isSearchingCandidate}
                        headers={headers}
                        setDelete={setDelete}
                        setOpenSnackbar={setOpenSnackbar}
                        setSnackbarVarient={setSnackbarVarient}
                        handleSetMessageSuccess={handleSetMessageSuccess}
                        handleSetMessageError={handleSetMessageError}
                      />
                    </InfiniteScroll>
                  </Container>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <ActionSnackbar
            variant={snackbarVarient}
            message={snackbarVarient === 'success' ? messageSuccess : messageError}
            open={openSnackbar}
            handleClose={handleCloseSnackbar}
            Icon={snackbarVarient === 'success' ? CheckCircleIcon : ErrorIcon}
          />
        </PaperCustom>
      </Container>
    </Page>
  );
};

export default CandidatesPageCard;
