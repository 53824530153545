import React, { Fragment, useState } from 'react';
import {
  Button,
  Grid,
  Theme,
  Typography,
  withStyles,
  Table,
  TableBody,
  TableRow,
  TableHead,
  Tooltip,
  IconButton,
  Link,
  TextField
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { orange } from '@material-ui/core/colors';
import BodyCell from 'components/BodyCell';
import HeaderRow from 'components/HeaderRow';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import ErrorIcon from '@material-ui/icons/Error';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { PRIMARY_COLOR } from 'constants/colors';
import { StandardConfirmationDialog } from 'components';
import ActionSnackbar from 'components/ActionSnackbar';
import axios from 'axios';
import { GET_DELETE_JOB_EXPERIENCE } from 'constants/url';

interface Props {
  candidateHistorysFDW: JobExperienceModel[];
  setCandidateHistorysFDW: React.Dispatch<React.SetStateAction<JobExperienceModel[]>>;
  employer1: string;
  setEmployer1: React.Dispatch<React.SetStateAction<string>>;
  employer2: string;
  setEmployer2: React.Dispatch<React.SetStateAction<string>>;
  interview: boolean;
  setInterview: React.Dispatch<React.SetStateAction<boolean>>;
  phone: boolean;
  setPhone: React.Dispatch<React.SetStateAction<boolean>>;
  videoConference: boolean;
  setVideoConference: React.Dispatch<React.SetStateAction<boolean>>;
  person: boolean;
  setPerson: React.Dispatch<React.SetStateAction<boolean>>;
  remarks: string;
  setRemarks: React.Dispatch<React.SetStateAction<string>>;
  feedback1: string;
  setFeedback1: React.Dispatch<React.SetStateAction<string>>;
  feedback2: string;
  setFeedback2: React.Dispatch<React.SetStateAction<string>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  onAddHistory: React.FormEventHandler;
  onSubmit: React.FormEventHandler;
  onCancel: React.MouseEventHandler;
}

const useStyles = makeStyles((theme: Theme) => ({
  defaultText: {
    fontWeight: 500,
    marginRight: theme.spacing(-5)
  },
  textPasExisting: {
    position: 'relative',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)'
  },
  contentGrid: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0)
  },
  controlDiv: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2)
  },
  cancelButton: {
    marginRight: theme.spacing(3)
  },
  marginAddEmployment: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  checkBoxIcon: {
    fontSize: '16px'
  }
}));

const SaveButton = withStyles(theme => ({
  root: {
    color: '#FFFFFF',
    backgroundColor: PRIMARY_COLOR,
    '&:hover': {
      backgroundColor: orange[700]
    }
  }
}))(Button);

const CandidateHistoryForm: React.FC<Props> = props => {
  const classes = useStyles(props);

  const icon = <CheckBoxOutlineBlankIcon className={classes.checkBoxIcon} />;
  const checkedIcon = <CheckBoxIcon className={classes.checkBoxIcon} />;

  const { candidateHistorysFDW, setCandidateHistorysFDW } = props;
  const { employer1, setEmployer1 } = props;
  const { employer2, setEmployer2 } = props;
  const { interview, setInterview } = props;
  const { phone, setPhone } = props;
  const { videoConference, setVideoConference } = props;
  const { person, setPerson } = props;
  const { remarks, setRemarks } = props;
  const { feedback1, setFeedback1 } = props;
  const { feedback2, setFeedback2 } = props;

  const { isLoading, setIsLoading, onAddHistory, onCancel, onSubmit } = props;

  const [confirmationShow, setConfirmationShow] = useState<boolean>(false);
  const [messageSuccess, setMessageSuccess] = useState<string>('');
  const [snackbarVarient, setSnackbarVarient] = useState<'success' | 'error'>('success');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [messageError, setMessageError] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [selectedId, setSelectedId] = useState<number>(-1);
  const [isCancelButton, setIsCancelButton] = useState<boolean>(false);

  const handleCloseDialog = () => {
    setConfirmationShow(false);
  };

  const handleClose = () => {
    setConfirmationShow(false);
  };

  const handleSetMessageSuccess = (message: string) => {
    setMessageSuccess(message);
  };

  const handleSetMessageError = (message: string) => {
    setMessageError(message);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const actionWrapper = async (action: () => Promise<void>, actionMessage: string) => {
    setIsLoading(true);

    try {
      await action();
      handleCloseDialog();
      handleSetMessageSuccess(`Successfully ${actionMessage}`);
      setSnackbarVarient('success');
      setOpenSnackbar(true);
    } catch (err) {
      handleCloseDialog();
      handleSetMessageError(`Failed to ${actionMessage}`);
      setSnackbarVarient('error');
      setOpenSnackbar(true);
    }

    setIsLoading(false);
  };

  const deleteEmployment = async () => {
    const currentCandidateHistorysFDW = [...candidateHistorysFDW];

    await actionWrapper(async () => {
      if (!currentCandidateHistorysFDW[selectedId].new) {
        const { id } = currentCandidateHistorysFDW[selectedId];
        if (id) {
          await axios.delete(GET_DELETE_JOB_EXPERIENCE(id));
        }
      }
    }, 'delete Employment History');

    currentCandidateHistorysFDW.splice(selectedId, 1);
    setCandidateHistorysFDW(currentCandidateHistorysFDW);
  };

  const handleDeleteActionClick = (index: number) => {
    setConfirmationShow(true);
    setSelectedId(index);
    setMessage('Are you sure you want to delete this?');
  };

  return (
    <form noValidate onSubmit={onSubmit}>
      <Grid className={classes.contentGrid}>
        <Typography variant='h4' id='modal-title' color='primary'>
          EDIT EMPLOYMENT HISTORY
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant='subtitle2'>Employment History (Maximum 4)</Typography>
          </Grid>
        </Grid>
        <Table>
          <TableHead>
            <HeaderRow
              headers={[
                { label: 'Country', pL: '10px', pR: '10px', verticalAlign: 'top' },
                { label: 'Employer', pL: '10px', pR: '10px', verticalAlign: 'top' },
                { label: 'Work Duties', pL: '10px', pR: '10px', verticalAlign: 'top' },
                { label: 'Remark ', pL: '10px', pR: '10px', verticalAlign: 'top' },
                { label: 'Year', pL: '10px', pR: '10px', verticalAlign: 'top' },
                { label: 'Action', pL: '10px', pR: '10px', verticalAlign: 'top' }
              ]}
            />
          </TableHead>
          <TableBody>
            {candidateHistorysFDW.map((history, index) => (
              <TableRow key={index}>
                <BodyCell pL='0px' pR='0px' cellWidth='5%' align='left' verticalAlign='top'>{history.country}</BodyCell>
                <BodyCell pL='0px' pR='0px' cellWidth='5%' align='left' verticalAlign='top'>{history.employer}</BodyCell>
                <BodyCell pL='0px' pR='20px' cellWidth='10%' align='left' verticalAlign='top'>
                  <Typography variant='body1' style={{ whiteSpace: 'pre-line' }}>
                    {history.workDuties}
                  </Typography>
                </BodyCell>
                <BodyCell pL='0px' pR='20px' cellWidth='10%' align='left' verticalAlign='top'>
                  <Typography variant='body1' style={{ whiteSpace: 'pre-line' }}>
                    {history.remarks}
                  </Typography>
                </BodyCell>
                <BodyCell pL='0px' pR='0px' cellWidth='5%' align='left' verticalAlign='top'>
                  {history.dateFrom} - {history.dateTo}
                </BodyCell>
                <BodyCell pL='0px' pR='0px' cellWidth='5%' align='left' verticalAlign='top'>
                  <Tooltip title='Delete' placement='left'>
                    <IconButton size='small' onClick={() => handleDeleteActionClick(index)}>
                      <DeleteIcon fontSize='small' color='primary' />
                    </IconButton>
                  </Tooltip>
                </BodyCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Grid container item justify='center' xs={12} sm={12} md={12} lg={12} xl={12} className={classes.controlDiv}>
          {candidateHistorysFDW.length < 4 && (
            <Fragment>
              <Typography className={classes.defaultText} color='primary'>
                <Link href='#' onClick={onAddHistory} color='primary'>
                  Add Employment
                </Link>
              </Typography>
            </Fragment>
          )}
        </Grid>

        {/* <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant='subtitle2'>Feedback From Previous Employer in Singapore</Typography>
          </Grid>
        </Grid> */}
        {/* <Grid container>
          <Grid item xs={12}>
            <TextField
              variant='outlined'
              margin='normal'
              
              fullWidth
              id='employer1'
              label='Employer 1'
              value={employer1}
              onChange={event => setEmployer1(event.target.value)}
              autoComplete='off'
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant='outlined'
              margin='normal'
              
              fullWidth
              id='feedback1'
              label='Feedback 1'
              value={feedback1}
              onChange={event => setFeedback1(event.target.value)}
              autoComplete='off'
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant='outlined'
              margin='normal'
              
              fullWidth
              id='employer2'
              label='Employer 2'
              value={employer2}
              onChange={event => setEmployer2(event.target.value)}
              autoComplete='off'
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant='outlined'
              margin='normal'
              
              fullWidth
              id='feedback2'
              label='Feedback 2'
              value={feedback2}
              onChange={event => setFeedback2(event.target.value)}
              autoComplete='off'
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
        </Grid> */}
        {/* <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant='subtitle2'>Availability of FDW to be Interviewed by Prospective Employer</Typography>
          </Grid>
        </Grid> */}
        {/* <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  icon={icon}
                  id='interview'
                  onClick={() => setInterview(interview ? false : true)}
                  checked={interview}
                  checkedIcon={checkedIcon}
                  color='primary'
                />
              }
              label='FDW is not available for interview'
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  icon={icon}
                  id='phone'
                  onClick={() => setPhone(phone ? false : true)}
                  checked={phone}
                  checkedIcon={checkedIcon}
                  color='primary'
                />
              }
              label='FDW  can be interviewed by phone'
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  icon={icon}
                  id='videoConference'
                  onClick={() => setVideoConference(videoConference ? false : true)}
                  checked={videoConference}
                  checkedIcon={checkedIcon}
                  color='primary'
                />
              }
              label='FDW can be interviewed by video-conference'
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  icon={icon}
                  checked={person}
                  id='person'
                  onClick={() => setPerson(person ? false : true)}
                  checkedIcon={checkedIcon}
                  color='primary'
                />
              }
              label='FDW  can be interviewed in person'
            />
          </Grid>
        </Grid> */}
        {/* <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              variant='outlined'
              margin='normal'
              
              fullWidth
              multiline
              rows='4'
              id='remarks'
              label='Remarks'
              value={remarks}
              onChange={event => setRemarks(event.target.value)}
              autoComplete='off'
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
        </Grid> */}
      </Grid>

      <Grid container item justify='center' xs={12} sm={12} md={12} lg={12} xl={12} className={classes.controlDiv}>
        <Button variant='contained' className={classes.cancelButton} disabled={isLoading} onClick={onCancel}>
          Cancel
        </Button>
        <SaveButton type='submit' variant='contained' disabled={isLoading}>
          Save
        </SaveButton>
      </Grid>
      <StandardConfirmationDialog
        variant={'warning'}
        message={message}
        open={confirmationShow}
        noCancelButton={isCancelButton}
        handleClose={handleClose}
        onConfirm={() => deleteEmployment()}
      />
      <ActionSnackbar
        variant={snackbarVarient}
        message={snackbarVarient === 'success' ? messageSuccess : messageError}
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        Icon={snackbarVarient === 'success' ? CheckCircleIcon : ErrorIcon}
      />
    </form>
  );
};

export default CandidateHistoryForm;
