import React, { FC, Fragment, useContext, useEffect, useState } from 'react';
import { Button, Grid, makeStyles, Theme, Typography, ButtonBase, withStyles, IconButton } from '@material-ui/core';
import { orange } from '@material-ui/core/colors';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import axios, { CancelTokenSource } from 'axios';
import ActionSnackbar from 'components/ActionSnackbar';
import EditDocumentModal from './components/EditDocumentModal';
import { GET_DOWNLOAD_FILE_URL } from 'constants/url';
import { PRIMARY_COLOR } from 'constants/colors';
import Skeleton from 'react-loading-skeleton';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import { getCurrentRoleName } from 'selectors';
import AddIcon from '@material-ui/icons/Add';
import { dummyDocumentFile } from 'utils/dummy';
import DeleteIcon from '@material-ui/icons/Delete';
import { DOCUMENT_UPLOAD_FILE_BASE_URL, GET_DELETE_DOCUMENT_UPLOAD_FILE_BASE_URL } from 'constants/url';
import { AntSwitch, StandardConfirmationDialog } from 'components';

interface Props {
  isLoadingData: boolean;
  candidateId: number;
  setIsLoadingData: React.Dispatch<React.SetStateAction<boolean>>;
  documentUploadFiles: CandidateFileDocument[];
  setDocumentUploadFiles: React.Dispatch<React.SetStateAction<CandidateFileDocument[]>>;
  setLoadingPage: React.Dispatch<React.SetStateAction<boolean>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  headerText: {
    paddingBottom: theme.spacing(2)
  },
  buttonMargin: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2)
  },
  cursorGrid: {
    cursor: 'pointer'
  },
  inputFileStyle: {
    display: 'none',
    marginRight: theme.spacing(2)
  },
  buttonAddDocument: {
    paddingTop: theme.spacing(3)
  },
  gridAddDocument: {
    paddingTop: theme.spacing(4)
  },
  saveButton: {
    marginRight: theme.spacing(3)
  },
  gridButton: {
    paddingTop: theme.spacing(2)
  }
}));

const SaveButton = withStyles(theme => ({
  root: {
    color: '#FFFFFF',
    backgroundColor: PRIMARY_COLOR,
    '&:hover': {
      backgroundColor: orange[700]
    }
  }
}))(Button);

const CandidateDocument: FC<Props> = props => {
  const classes = useStyles();
  let cancelTokenSource: CancelTokenSource;
  const { currentUser } = useContext(CurrentUserContext);
  const { isLoadingData, candidateId, setLoadingPage, setIsLoadingData, documentUploadFiles, setDocumentUploadFiles } = props;

  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarVarient, setSnackbarVarient] = useState<'success' | 'error'>('success');
  const [openEditDocumentModal, setOpenEditDocumentModal] = useState<boolean>(false);
  const [roles, setRoles] = useState<string>('');
  const [meesage, setMessage] = useState<string>('');
  const [isCancelButton, setIsCancelButton] = useState<boolean>(false);
  const [confirmationShow, setConfirmationShow] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number>(-1);
  const [messageSuccess, setMessageSuccess] = useState<string>('');
  const [messageError, setMessageError] = useState<string>('');
  const [isProcessing, setProcessing] = useState<boolean>(false);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleCancelEditNotes = () => {
    setOpenEditDocumentModal(false);
  };

  const handleChooseFile = (event: any) => {
    let file;

    if (event.target.files[0] === undefined) {
      file = '';
    } else {
      file = event.target.files[0];
    }

    if (file) {
      if (Number((file.size / (1024 * 1024)).toFixed(2)) > 100) {
        setConfirmationShow(true);
        setMessage('File max 100MB');
        setIsCancelButton(true);
        return;
      }

      const newDocumentFiles = [...documentUploadFiles];

      const currentDocumentFile: CandidateFileDocument = dummyDocumentFile;
      newDocumentFiles.push({
        ...currentDocumentFile,
        documentFile: file.name,
        CandidateId: candidateId,
        isDeleted: false,
        new: true,
        file: file,
        size: (file.size / (1024 * 1024)).toFixed(2)
      });

      setDocumentUploadFiles(newDocumentFiles);
    }
  };

  const handleOnSubmit: React.FormEventHandler = async event => {
    event.preventDefault();
    setIsLoadingData(true);
    try {
      cancelTokenSource = axios.CancelToken.source();

      const newDocumentFile = documentUploadFiles.map(async value => {
        if (value.new) {
          value.new = false;
          const response = await axios.post(
            `${DOCUMENT_UPLOAD_FILE_BASE_URL}`,
            {
              documentfile: value.documentFile,
              CandidateId: value.CandidateId,
              allowDownloaded: value.allowDownloaded
            },
            { cancelToken: cancelTokenSource.token }
          );

          const { data } = response;

          value.documentFile = data.documentFile;

          const myHeaders = new Headers();
          const newFile: any = value.file;
          myHeaders.append('Content-Type', newFile.type);

          const config = {
            method: 'PUT',
            body: newFile,
            headers: myHeaders
          };

          await fetch(`${data.urlFile}`, config)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
        }

        if (value.isDeleted) {
          await axios.delete(GET_DELETE_DOCUMENT_UPLOAD_FILE_BASE_URL([value.id]));
        }

        return value;
      });

      const currentDoc: CandidateFileDocument[] = await Promise.all([...newDocumentFile]);

      setDocumentUploadFiles(currentDoc.filter(value => !value.isDeleted));
      setOpenSnackbar(true);
      setSnackbarVarient('success');
      handleSetMessageSuccess(`Successfully created`);
      handleCancelEditNotes();
    } catch (err) {
      console.log(err);
      setOpenSnackbar(true);
      setSnackbarVarient('error');
      handleSetMessageError(`Failed to create`);
    }
    setIsLoadingData(false);
  };

  const resetFormValues = () => {
    const currentDocumentUploadFiles = [...documentUploadFiles];

    setDocumentUploadFiles(currentDocumentUploadFiles.filter(value => !value.new));
  };

  const handleOnClose = () => {
    resetFormValues();
  };

  const handleCloseDialog = () => {
    setConfirmationShow(false);
  };

  const handleSetMessageSuccess = (message: string) => {
    setMessageSuccess(message);
  };

  const handleSetMessageError = (message: string) => {
    setMessageError(message);
  };

  const handleDeleteActionClick = (index: number) => {
    setConfirmationShow(true);
    setSelectedId(index);
    setMessage('Are you sure you want to delete this?');
  };

  const actionWrapper = async (action: () => Promise<void>, actionMessage: string) => {
    setProcessing(true);

    try {
      await action();
      handleCloseDialog();
      handleSetMessageSuccess(`Successfully ${actionMessage}`);
      setSnackbarVarient('success');
      setOpenSnackbar(true);
    } catch (err) {
      handleCloseDialog();
      handleSetMessageError(`Failed to ${actionMessage}`);
      setSnackbarVarient('error');
      setOpenSnackbar(true);
    }

    setProcessing(false);
  };

  const deleteImage = async (index: number) => {
    if (index >= 0) {
      const currentDocumentFiles = [...documentUploadFiles];

      await actionWrapper(async () => {
        if (!currentDocumentFiles[index].new) {
          await axios.delete(GET_DELETE_DOCUMENT_UPLOAD_FILE_BASE_URL([currentDocumentFiles[index].id]));
        }
      }, 'delete image');

      currentDocumentFiles.splice(index, 1);
      setDocumentUploadFiles(currentDocumentFiles);
    }
  };

  const handleDownloadFile = async (fileKey: string) => {
    setLoadingPage(true);
    const { data } = await axios.get(GET_DOWNLOAD_FILE_URL(fileKey));

    const config = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/*'
      }
    };

    await fetch(`${data}`, config)
      .then(response => response.blob())
      .then(blob => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${fileKey}`);
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => console.log('error', error));

    setLoadingPage(false);
  };

  const handleClose = () => {
    setConfirmationShow(false);
  };

  const handleAllowDownloaded = (index: number) => {
    const newDocumentFiles = [...documentUploadFiles];
    newDocumentFiles[index].allowDownloaded = newDocumentFiles[index].allowDownloaded ? false : true;

    setDocumentUploadFiles(newDocumentFiles);
  };

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    const roles = currentUser ? getCurrentRoleName(currentUser) : '';
    setRoles(roles);
  }, [currentUser]);

  return (
    <Grid>
      <Grid item xs>
        <Typography className={classes.headerText} variant='h4' color='primary'>
          Candidate Documents
        </Typography>
        {roles !== 'partner_agency' && (
          <Typography className={classes.headerText} variant='h5' color='textSecondary'>
            You can upload up to 5 documents
          </Typography>
        )}
      </Grid>
      {isLoadingData ? (
        <Skeleton width={90} />
      ) : (
        documentUploadFiles.map((value, index) => (
          <Grid container direction='row' spacing={3} key={index}>
            <Grid item xs={4}>
              <Typography variant='h6'>
                {String(value.documentFile).substring(0, 36)}
                {String(value.documentFile).length > 36 &&
                  '...' +
                    String(value.documentFile)
                      .split('.')
                      .pop()}
              </Typography>
            </Grid>

            <Grid item xs={5}>
              <Grid container direction='row' spacing={2} alignItems='center'>
                {roles !== 'partner_agency' && (
                  <Fragment>
                    <Grid item xs={1} alignItems='center'>
                      <AntSwitch color='primary' checked={value.allowDownloaded} onClick={() => handleAllowDownloaded(index)} />
                    </Grid>
                    <Grid item xs={4} alignItems='center'>
                      <Typography> {value.allowDownloaded ? 'Downloadable' : 'Non-Downloadable'}</Typography>
                    </Grid>
                  </Fragment>
                )}
                <Grid
                  item
                  xs={4}
                  alignItems='center'
                  className={classes.cursorGrid}
                  alignContent='center'
                  onClick={() => handleDownloadFile(value.documentFile)}
                >
                  {value.allowDownloaded && (
                    <Typography variant='h6' color='primary'>
                      Download Document
                    </Typography>
                  )}
                </Grid>
                {roles !== 'partner_agency' && (
                  <Grid item xs={2} alignItems='center' alignContent='center'>
                    <IconButton size='small' onClick={() => handleDeleteActionClick(index)}>
                      <DeleteIcon color='primary' />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        ))
      )}
      {roles !== 'partner_agency' && documentUploadFiles.length < 5 && (
        <Grid container className={classes.gridAddDocument}>
          <input
            accept='application/pdf,application/vnd.ms-word,video/mp4,video/quicktime'
            className={classes.inputFileStyle}
            id={`outlined-button-file`}
            type='file'
            onChange={event => handleChooseFile(event)}
          />
          <label htmlFor={`outlined-button-file`}>
            <ButtonBase focusRipple key={`Upload`} component='span' disableRipple>
              <Grid container item xs={12}>
                <AddIcon fontSize='small' color='primary' />
                <Typography variant='h5' color='primary'>
                  Add Document
                </Typography>
              </Grid>
            </ButtonBase>
          </label>
        </Grid>
      )}
      {documentUploadFiles.filter(value => value.new).length > 0 && (
        <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.gridButton}>
          <SaveButton type='submit' className={classes.saveButton} variant='contained' disabled={isLoadingData} onClick={handleOnSubmit}>
            Save
          </SaveButton>
          <Button variant='contained' disabled={isLoadingData} onClick={handleOnClose}>
            Cancel
          </Button>
        </Grid>
      )}

      <StandardConfirmationDialog
        variant={'warning'}
        message={meesage}
        open={confirmationShow}
        noCancelButton={isCancelButton}
        handleClose={handleClose}
        onConfirm={() => selectedId >= 0 && deleteImage(selectedId)}
      />
      <ActionSnackbar
        variant={snackbarVarient}
        message={snackbarVarient === 'success' ? messageSuccess : messageError}
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        Icon={snackbarVarient === 'success' ? CheckCircleIcon : ErrorIcon}
      />
      <EditDocumentModal
        open={openEditDocumentModal}
        candidateId={candidateId}
        documentUploadFiles={documentUploadFiles}
        setDocumentUploadFiles={setDocumentUploadFiles}
        handleCancel={handleCancelEditNotes}
        setOpenSnackbar={setOpenSnackbar}
        setSnackbarVarient={setSnackbarVarient}
      />
    </Grid>
  );
};

export default CandidateDocument;
