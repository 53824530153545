import { Grid } from '@material-ui/core';
import React, { FC } from 'react';
import MedicalHistoryForm from './components/MedicalHistoryForm';

interface Props {
  allergies: string;
  setAllergies: React.Dispatch<React.SetStateAction<string>>;
  dietaryRestrictions: string;
  setDietaryrestrictions: React.Dispatch<React.SetStateAction<string>>;
  mentalIllness: boolean;
  setMentalIllness: React.Dispatch<React.SetStateAction<boolean>>;
  tuberculosis: boolean;
  setTuberculosis: React.Dispatch<React.SetStateAction<boolean>>;
  physicalDisabilities: string;
  setPhysicaldisabilities: React.Dispatch<React.SetStateAction<string>>;
  heartDisease: boolean;
  setHeartdisease: React.Dispatch<React.SetStateAction<boolean>>;
  othersPastExisting: string;
  setOtherspastexisting: React.Dispatch<React.SetStateAction<string>>;
  othersFood: string;
  setOthersfood: React.Dispatch<React.SetStateAction<string>>;
  malaria: boolean;
  setMalaria: React.Dispatch<React.SetStateAction<boolean>>;
  operations: boolean;
  setOperations: React.Dispatch<React.SetStateAction<boolean>>;
  epilepsy: boolean;
  setEpilepsy: React.Dispatch<React.SetStateAction<boolean>>;
  asthma: boolean;
  setAsthma: React.Dispatch<React.SetStateAction<boolean>>;
  diabetes: boolean;
  setDiabetes: React.Dispatch<React.SetStateAction<boolean>>;
  hypertension: boolean;
  setHypertension: React.Dispatch<React.SetStateAction<boolean>>;
  pork: boolean;
  setPork: React.Dispatch<React.SetStateAction<boolean>>;
  beef: boolean;
  setBeef: React.Dispatch<React.SetStateAction<boolean>>;
  preferenceForRestDay: string | null;
  setPreferenceforrestday: React.Dispatch<React.SetStateAction<string | null>>;
  remarks: string;
  setRemarks: React.Dispatch<React.SetStateAction<string>>;
  isSubmitting: boolean;
}

const CandidateMedicalHistory: FC<Props> = props => {
  const { allergies, setAllergies } = props;
  const { dietaryRestrictions, setDietaryrestrictions } = props;
  const { mentalIllness, setMentalIllness } = props;
  const { tuberculosis, setTuberculosis } = props;
  const { physicalDisabilities, setPhysicaldisabilities } = props;
  const { heartDisease, setHeartdisease } = props;
  const { othersPastExisting, setOtherspastexisting } = props;
  const { othersFood, setOthersfood } = props;
  const { malaria, setMalaria } = props;
  const { operations, setOperations } = props;
  const { epilepsy, setEpilepsy } = props;
  const { asthma, setAsthma } = props;
  const { diabetes, setDiabetes } = props;
  const { hypertension, setHypertension } = props;
  const { pork, setPork } = props;
  const { beef, setBeef } = props;
  const { preferenceForRestDay, setPreferenceforrestday } = props;
  const { remarks, setRemarks } = props;

  const { isSubmitting } = props;

  return (
    <Grid>
      <MedicalHistoryForm
        allergies={allergies}
        setAllergies={setAllergies}
        dietaryRestrictions={dietaryRestrictions}
        setDietaryrestrictions={setDietaryrestrictions}
        mentalIllness={mentalIllness}
        setMentalIllness={setMentalIllness}
        tuberculosis={tuberculosis}
        setTuberculosis={setTuberculosis}
        physicalDisabilities={physicalDisabilities}
        setPhysicaldisabilities={setPhysicaldisabilities}
        heartDisease={heartDisease}
        setHeartdisease={setHeartdisease}
        othersPastExisting={othersPastExisting}
        setOtherspastexisting={setOtherspastexisting}
        othersFood={othersFood}
        setOthersfood={setOthersfood}
        malaria={malaria}
        setMalaria={setMalaria}
        operations={operations}
        setOperations={setOperations}
        epilepsy={epilepsy}
        setEpilepsy={setEpilepsy}
        asthma={asthma}
        setAsthma={setAsthma}
        diabetes={diabetes}
        setDiabetes={setDiabetes}
        hypertension={hypertension}
        setHypertension={setHypertension}
        pork={pork}
        setPork={setPork}
        beef={beef}
        setBeef={setBeef}
        preferenceForRestDay={preferenceForRestDay}
        setPreferenceforrestday={setPreferenceforrestday}
        remarks={remarks}
        setRemarks={setRemarks}
        isSubmitting={isSubmitting}
      />
    </Grid>
  );
};

export default CandidateMedicalHistory;
