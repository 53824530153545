import { Button, Container, Grid, makeStyles, Theme, Typography, withStyles } from '@material-ui/core';
import { Page, PaperCustom, StandardConfirmationDialog } from 'components';
import useCurrentPageTitleUpdater from 'hooks/useCurrentPageTitleUpdater';
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { CurrentPageContext } from 'contexts/CurrentPageContext';
import { PRIMARY_COLOR } from 'constants/colors';
import { orange } from '@material-ui/core/colors';
import AddSignatureModal from './components/AddSignatureModal';
import { dummyAgeofChildren, dummyCandidate, dummyExtendFieldDocument } from 'utils/dummy';
import ActionSnackbar from 'components/ActionSnackbar';
import axios, { CancelTokenSource } from 'axios';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import DocumentForm from './components/DocumentForm';
import { CANDIDATE_BASE_URL, DOCUMENT_BASE_URL } from 'constants/url';
import useRouter from 'hooks/useRouter';
import numberToWords from 'number-to-words';

const useStyles = makeStyles((theme: Theme) => ({
  defaultText: {
    fontWeight: 500,
    marginRight: theme.spacing(-5)
  }
}));

const CreateDocument: FC = () => {
  const { history } = useRouter();
  useCurrentPageTitleUpdater('Job Order Form');
  let cancelTokenSource: CancelTokenSource;
  const { currentPageTitle } = useContext(CurrentPageContext);

  const [openAddSignatureModal, setOpenAddSignatureModal] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarVarient, setSnackbarVarient] = useState<'success' | 'error'>('success');

  const [candidateId, setCandidateId] = useState<number>(0);
  const [code, setCode] = useState<string>('');
  const [codeError, setCodeError] = useState<string>('');
  const [nameOfEmployer, setNameOfEmployer] = useState<string>('');
  const [nameOfEmployerError, setNameOfEmployerError] = useState<string>('');
  const [nameofFDW, setNameofFDW] = useState<string>('');
  const [nameofFDWError, setNameofFDWError] = useState<string>('');
  const [race, setRace] = useState<string>('');
  const [raceError, setError] = useState<string>('');
  const [salary, setSalary] = useState<number | null>(null);
  const [salaryError, setSalaryError] = useState<string>('');
  const [daysOff, setDaysOff] = useState<string>('');
  const [daysOffError, setDaysOffError] = useState<string>('');
  const [probation, setProbation] = useState<string>('');
  const [probationError, setProbationError] = useState<string>('');
  const [typeOfHouse, setTypeOfHouse] = useState<string>('');
  const [typeOfHouseError, setTypeOfHouseError] = useState<string>('');
  const [numberOfBedroom, setNumberOfBedroom] = useState<number | null>(null);
  const [numberOfToilet, setNumberOfToilet] = useState<number | null>(null);
  const [numberOfFamilyMembers, setNumberOfFamilyMembers] = useState<number | null>(null);
  const [numberOfBabies, setNumberOfBabies] = useState<number | null>(null);
  const [ageOfFirstChildBelowTwelve, setAgeOfFirstChildBelowTwelve] = useState<string>('');
  const [ageOfFirstChildBelowFive, setAgeOfFirstChildBelowFive] = useState<string>('');
  const [fetchChildren, setFetchChildren] = useState<boolean>(false);
  const [waitDownstairs, setWaitDownstairs] = useState<boolean>(false);
  const [busOnly, setBusOnly] = useState<boolean>(false);
  const [lookAfterElderly, setLookAfterElderly] = useState<boolean>(false);
  const [healthy, setHealthy] = useState<boolean>(false);
  const [assistanceNeeded, setAssistanceNeeded] = useState<boolean>(false);
  const [bedriddenPatient, setBedriddenPatient] = useState<boolean>(false);
  const [generalHouseWork, setGeneralHouseWork] = useState<boolean>(false);
  const [ironingOfClothes, setIroningOfClothes] = useState<boolean>(false);
  const [cooking, setCooking] = useState<boolean>(false);
  const [clothesWashingBy, setClothesWashingBy] = useState<ExtendFieldDocumentModel>(dummyExtendFieldDocument);
  const [carWashing, setCarWashing] = useState<boolean>(false);
  const [takeCareOfPet, setTakeCareOfPet] = useState<ExtendFieldDocumentModel>(dummyExtendFieldDocument);
  const [gardening, setGardening] = useState<ExtendFieldDocumentModel>(dummyExtendFieldDocument);
  const [remarks, setRemarks] = useState<string>('');
  const [signature, setSignature] = useState<string>('');
  const [signatureError, setSignatureError] = useState<string>('');
  const [helperSignature, setHelperSignature] = useState<string>('');
  const [helperSignatureError, setHelperSignatureError] = useState<string>('');
  const [employerSignature, setEmployerSignature] = useState<string>('');
  const [employerSignatureError, setEmployerSignatureError] = useState<string>('');
  const [queryString, setQueryString] = useState<string>('');

  const [candidates, setCandidates] = useState<CandidateModel[]>([]);
  const [candidate, setCandidate] = useState<CandidateModel>(dummyCandidate);

  const [message, setMessage] = useState<string>('');
  const [titleMessage, setTitleMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const handleCancelAddSignature = () => {
    setOpenAddSignatureModal(false);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const clearFormErrors = () => {
    setCodeError('');
    setNameOfEmployerError('');
    setNameofFDWError('');
    setError('');
    setSalaryError('');
    setDaysOffError('');
    setProbationError('');
    setTypeOfHouseError('');
    setSignatureError('');
    setHelperSignatureError('');
    setEmployerSignatureError('');
  };

  const validateForm = () => {
    let ret = true;
    clearFormErrors();

    if (!code || !code.trim()) {
      setCodeError('Please enter Code');
      ret = false;
    }

    if (!nameOfEmployer || !nameOfEmployer.trim()) {
      setNameOfEmployerError('Please enter Name Of employer');
      ret = false;
    }

    if (!nameofFDW || !nameofFDW.trim()) {
      setNameofFDWError('Please enter Name of FDW');
      ret = false;
    }

    if (!race || !race.trim()) {
      setError("Please enter Employer's Race");
      ret = false;
    }

    if (!salary || salary === 0) {
      setSalaryError('Please enter Salary');
      ret = false;
    }

    if (!daysOff || !daysOff.trim()) {
      setDaysOffError('Please enter Day(s) Off');
      ret = false;
    }

    if (!probation || !probation.trim()) {
      setProbationError('Please enter Probation');
      ret = false;
    }

    if (!typeOfHouse || !typeOfHouse.trim()) {
      setTypeOfHouseError('Please enter Type of House');
      ret = false;
    }

    if (!signature || !signature.trim()) {
      setSignatureError('Please enter Signature');
      ret = false;
    }

    if (!helperSignature || !helperSignature.trim()) {
      setHelperSignatureError("Please enter Helper's name");
      ret = false;
    }

    if (!employerSignature || !employerSignature.trim()) {
      setEmployerSignatureError("Please enter Employer's Name");
      ret = false;
    }

    return ret;
  };

  const resetFormValues = useCallback(() => {
    setCandidateId(0);
    setCode('');
    setNameOfEmployer('');
    setNameofFDW('');
    setRace('');
    setSalary(0);
    setDaysOff('');
    setProbation('');
    setTypeOfHouse('');
    setNumberOfBedroom(0);
    setNumberOfToilet(0);
    setNumberOfFamilyMembers(0);
    setNumberOfBabies(0);
    setAgeOfFirstChildBelowTwelve('');
    setAgeOfFirstChildBelowFive('');
    setFetchChildren(false);
    setWaitDownstairs(false);
    setBusOnly(false);
    setLookAfterElderly(false);
    setHealthy(false);
    setAssistanceNeeded(false);
    setBedriddenPatient(false);
    setGeneralHouseWork(false);
    setIroningOfClothes(false);
    setCooking(false);
    setClothesWashingBy(dummyExtendFieldDocument);
    setCarWashing(false);
    setTakeCareOfPet(dummyExtendFieldDocument);
    setGardening(dummyExtendFieldDocument);
    setRemarks('');
    setSignature('');
    setHelperSignature('');
    setEmployerSignature('');
    setCandidate(dummyCandidate);
  }, []);

  const redirectToHome = () => {
    history.push('/candidates-document');
  };

  const handleOnClose = () => {
    resetFormValues();
    redirectToHome();
    setOpenDialog(false);
  };

  const fetchData = useCallback(() => {
    setLoading(true);

    cancelTokenSource = axios.CancelToken.source();

    const getParams = () => {
      const params = new URLSearchParams();

      params.append('qf', queryString);

      return params.toString();
    };

    const loadData = async () => {
      try {
        const url = `${CANDIDATE_BASE_URL}?${getParams()}`;
        const { data } = await axios.get(url, { cancelToken: cancelTokenSource.token });
        setCandidates(data.candidates);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };

    loadData();
  }, [queryString]);

  useEffect(() => {
    fetchData();

    setMessage('You will no longer be able to make changes later');
    setTitleMessage("Are you sure to Save and Export the document?");
    setClothesWashingBy({...clothesWashingBy, name: 'Clothes Washing by', hand: true });
    setTakeCareOfPet({...takeCareOfPet, name: 'Take Care of Pet(s)', hand: true });
    setGardening({...takeCareOfPet, name: 'Gardening', hand: true });
  }, [fetchData]);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleShowConfirmation: React.FormEventHandler = async event => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }
    setOpenDialog(true);
  };

  const handleOnSubmit: React.FormEventHandler = async event => {
    event.preventDefault();

    setLoading(true);
    try {
      cancelTokenSource = axios.CancelToken.source();

      const currentAgeOfFirstChildBelowTwelve: AgeofChildrenModel[] = [];
      const currentAgeOfFirstChildBelowFive: AgeofChildrenModel[] = [];
      
      await axios.post(
        `${DOCUMENT_BASE_URL}`,
        {
          code,
          candidateId,
          nameOfEmployer,
          nameofFDW,
          race,
          salary,
          daysOff,
          probation,
          typeOfHouse,
          numberOfBedroom,
          numberOfToilet,
          numberOfFamilyMembers,
          numberOfBabies,
          ageOfFirstChildBelowTwelve,
          ageOfFirstChildBelowFive,
          fetchChildren,
          waitDownstairs,
          busOnly,
          lookAfterElderly,
          healthy,
          assistanceNeeded,
          bedriddenPatient,
          generalHouseWork,
          ironingOfClothes,
          cooking,
          clothesWashingBy,
          carWashing,
          takeCareOfPet,
          gardening,
          remarks,
          signature,
          helperSignature,
          employerSignature
        },
        { cancelToken: cancelTokenSource.token, responseType: 'blob' }
      ).then(response => {
        const file = new Blob([response.data && response.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);

        const newwindow = window.open(fileURL, 'name', 'height=700,width=750');
        if (newwindow && window.focus) {
          newwindow.focus();
        }
      });

      setLoading(false);
      setOpenSnackbar(true);
      setSnackbarVarient('success');
      handleOnClose();

    } catch (err) {
      console.log(err);
      setOpenSnackbar(true);
      setSnackbarVarient('error');
      setLoading(false);
    }
  };

  return (
    <Page title={currentPageTitle}>
      <Container>
        <Grid container direction='row' spacing={1}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant='subtitle1' component='h3'>
              {currentPageTitle}
            </Typography>
          </Grid>
        </Grid>
        <PaperCustom>
          <DocumentForm
            candidates={candidates}
            candidate={candidate}
            setCandidate={setCandidate}
            setOpenAddSignatureModal={setOpenAddSignatureModal}
            candidateId={candidateId}
            setCandidateId={setCandidateId}
            code={code}
            setCode={setCode}
            codeError={codeError}
            nameOfEmployer={nameOfEmployer}
            setNameOfEmployer={setNameOfEmployer}
            nameOfEmployerError={nameOfEmployerError}
            nameofFDW={nameofFDW}
            setNameofFDW={setNameofFDW}
            nameofFDWError={nameofFDWError}
            race={race}
            setRace={setRace}
            raceError={raceError}
            salary={salary}
            setSalary={setSalary}
            salaryError={salaryError}
            daysOff={daysOff}
            setDaysOff={setDaysOff}
            daysOffError={daysOffError}
            probation={probation}
            setProbation={setProbation}
            probationError={probationError}
            typeOfHouse={typeOfHouse}
            setTypeOfHouse={setTypeOfHouse}
            typeOfHouseError={typeOfHouseError}
            numberOfBedroom={numberOfBedroom}
            setNumberOfBedroom={setNumberOfBedroom}
            numberOfToilet={numberOfToilet}
            setNumberOfToilet={setNumberOfToilet}
            numberOfFamilyMembers={numberOfFamilyMembers}
            setNumberOfFamilyMembers={setNumberOfFamilyMembers}
            numberOfBabies={numberOfBabies}
            setNumberOfBabies={setNumberOfBabies}
            ageOfFirstChildBelowTwelve={ageOfFirstChildBelowTwelve}
            setAgeOfFirstChildBelowTwelve={setAgeOfFirstChildBelowTwelve}
            ageOfFirstChildBelowFive={ageOfFirstChildBelowFive}
            setAgeOfFirstChildBelowFive={setAgeOfFirstChildBelowFive}
            fetchChildren={fetchChildren}
            setFetchChildren={setFetchChildren}
            waitDownstairs={waitDownstairs}
            setWaitDownstairs={setWaitDownstairs}
            busOnly={busOnly}
            setBusOnly={setBusOnly}
            lookAfterElderly={lookAfterElderly}
            setLookAfterElderly={setLookAfterElderly}
            healthy={healthy}
            setHealthy={setHealthy}
            assistanceNeeded={assistanceNeeded}
            setAssistanceNeeded={setAssistanceNeeded}
            bedriddenPatient={bedriddenPatient}
            setBedriddenPatient={setBedriddenPatient}
            generalHouseWork={generalHouseWork}
            setGeneralHouseWork={setGeneralHouseWork}
            ironingOfClothes={ironingOfClothes}
            setIroningOfClothes={setIroningOfClothes}
            cooking={cooking}
            setCooking={setCooking}
            clothesWashingBy={clothesWashingBy}
            setClothesWashingBy={setClothesWashingBy}
            carWashing={carWashing}
            setCarWashing={setCarWashing}
            takeCareOfPet={takeCareOfPet}
            setTakeCareOfPet={setTakeCareOfPet}
            gardening={gardening}
            setGardening={setGardening}
            remarks={remarks}
            setRemarks={setRemarks}
            signature={signature}
            setSignature={setSignature}
            signatureError={signatureError}
            helperSignature={helperSignature}
            setHelperSignature={setHelperSignature}
            helperSignatureError={helperSignatureError}
            employerSignature={employerSignature}
            setEmployerSignature={setEmployerSignature}
            employerSignatureError={employerSignatureError}
            setQueryString={setQueryString}
            isSubmitting={isLoading}
            onSubmit={handleShowConfirmation}
            onCancel={handleOnClose}
          />
          <AddSignatureModal open={openAddSignatureModal} setOpenAddSignatureModal={setOpenAddSignatureModal} signature={signature} setSignature={setSignature} handleCancel={handleCancelAddSignature} />
        </PaperCustom>
      </Container>
      <StandardConfirmationDialog
        variant={'warning'}
        titleMessage={titleMessage}
        message={message}
        open={openDialog}
        handleClose={handleCloseDialog}
        onConfirm={handleOnSubmit}
        isLoading={isLoading}
      />
      <ActionSnackbar
        variant={snackbarVarient}
        message={snackbarVarient === 'success' ? 'Create is successful' : 'Create failed'}
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        Icon={snackbarVarient === 'success' ? CheckCircleIcon : ErrorIcon}
      />
    </Page>
  );
};

export default CreateDocument;
