import useCurrentPageTitleUpdater from 'hooks/useCurrentPageTitleUpdater';
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { CurrentPageContext } from 'contexts/CurrentPageContext';
import { Button, Container, Grid, makeStyles, Step, StepLabel, Stepper, Theme, Typography, withStyles } from '@material-ui/core';
import { Page, PaperCustom } from 'components';
import CandidateProfile from './components/CandidateProfile';
import { dummyFeedbackEmployers, dummyAgeofChildren, dummySelect, dummySubSkillFDW } from 'utils/dummy';
import { orange } from '@material-ui/core/colors';
import axios, { CancelTokenSource } from 'axios';
import { RESUME_BASE_URL, CANDIDATE_BASE_URL } from 'constants/url';
import CandidateMedicalHistory from './components/CandidateMedicalHistory';
import CandidateSkill from './components/CandidateSkill';
import CandidateHistoryFDW from './components/CandidateHistoryFDW';
import CandidateUploadDocument from './components/CandidateUploadDocument';
import ActionSnackbar from 'components/ActionSnackbar';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  cancelButton: {
    marginRight: theme.spacing(3)
  },
  nextButton: {
    color: '#FFFFFF',
    marginRight: theme.spacing(3)
  },
  backButton: {
    marginRight: theme.spacing(3)
  }
}));

const NextButtonDisabled = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(orange[500])
  }
}))(Button);

const NextButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText('#5d98b4')
  }
}))(Button);

const CreateCandidatePage: FC = () => {
  const classes = useStyles();
  useCurrentPageTitleUpdater('Helper Form');
  const historyBack = useHistory()
  const { currentPageTitle } = useContext(CurrentPageContext);

  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setLoading] = useState<boolean>(false);
  
  const [snackbarVarient, setSnackbarVarient] = useState<'success' | 'error'>('success');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  // Form Candidate Profile
  const [uploadPhoto, setUploadPhoto] = useState<any>('');
  const [uploadPhotoError, setUploadPhotoError] = useState<string>('');
  const [linkVideo, setLinkVideo] = useState<string>('');
  const [fullName, setFullName] = useState<string>('');
  const [fullNameError, setFullNameError] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [codeError, setCodeError] = useState<string>('');
  const [passportStatus, setPassportStatus] = useState<string>('');
  const [passportStatusError, setPassportStatusError] = useState<string>('');
  const [bioFee, setBioFee] = useState<number | null>(null);
  const [bioFeeError, setBioFeeError] = useState<string>('');
  const [loan, setLoan] = useState<number | null>(null);
  const [loanError, setLoanError] = useState<string>('');
  const [pocketMoney, setPocketMoney] = useState<number | null>(null);
  const [pocketMoneyError, setPocketMoneyError] = useState<string>('');
  const [minimumSalary, setMinimumSalary] = useState<number | null>(null);
  const [minimumSalaryError, setMinimumSalaryError] = useState<string>('');
  const [maritalStatus, setMaritalStatus] = useState<string>('');
  const [maritalStatusError, setMaritalStatusError] = useState<string>('');
  const [experienceSG, setExperinceSG] = useState<string>('');
  const [skills, setSkills] = useState<SkillsModel[]>([]);
  const [skillsError, setSkillsError] = useState<string>('');
  const [remarks, setRemarks] = useState<string>('');
  const [dob, setDOB] = useState<Date | null>(null);
  const [dobError, setDOBError] = useState<string>('');
  const [placeOfBirth, setPlaceofBirth] = useState<string>('');
  const [placeOfBirthError, setPlaceofBirthError] = useState<string>('');
  const [height, setHeight] = useState<number | null>(null);
  const [heightError, setHeightError] = useState<string>('');
  const [weight, setWeight] = useState<number | null>(null);
  const [weightError, setWeightError] = useState<string>('');
  const [nationality, setNationality] = useState<string>('');
  const [nationalityError, setNationalityError] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [phoneNumberError, setPhoneNumberError] = useState<string>('');
  const [residentialAddress, setResidentialAddress] = useState<string>('');
  const [residentialAddressError, setResidentialAddressError] = useState<string>('');
  const [nameOfPort, setNameOfPort] = useState<string>('');
  const [nameOfPortError, setNameOfPortError] = useState<string>('');
  const [religion, setReligion] = useState<string>('');
  const [religionError, setReligionError] = useState<string>('');
  const [educationCollege, setEducationCollege] = useState<string>('');
  const [educationCollegeError, setEducationCollegeError] = useState<string>('');
  const [numberOfSiblings, setNumberOfSiblings] = useState<string>('');
  const [numberOfSiblingsError, setNumberOfSiblingsError] = useState<string>('');
  const [addAgeOfChildren, setAddAgeOfChildren] = useState<string>('');
  const [addAgeOfChildrensError, setAddAgeOfChildrensError] = useState<string>('');

  const [maritalStatuses, setMaritalStatuses] = useState<Select[]>([dummySelect]);
  const [experinceInSGStatuses, setExperinceInSGStatuses] = useState<Select[]>([dummySelect]);
  const [skillsStatuses, setSkillStatuses] = useState<Select[]>([dummySelect]);

  // Candidate Medical History
  const [allergies, setAllergies] = useState<string>('');
  const [dietaryRestrictions, setDietaryrestrictions] = useState<string>('');
  const [mentalIllness, setMentalIllness] = useState<boolean>(false);
  const [tuberculosis, setTuberculosis] = useState<boolean>(false);
  const [physicalDisabilities, setPhysicaldisabilities] = useState<string>('');
  const [heartDisease, setHeartdisease] = useState<boolean>(false);
  const [othersPastExisting, setOtherspastexisting] = useState<string>('');
  const [othersFood, setOthersfood] = useState<string>('');
  const [malaria, setMalaria] = useState<boolean>(false);
  const [operations, setOperations] = useState<boolean>(false);
  const [epilepsy, setEpilepsy] = useState<boolean>(false);
  const [asthma, setAsthma] = useState<boolean>(false);
  const [diabetes, setDiabetes] = useState<boolean>(false);
  const [hypertension, setHypertension] = useState<boolean>(false);
  const [pork, setPork] = useState<boolean>(true);
  const [beef, setBeef] = useState<boolean>(true);
  const [preferenceForRestDay, setPreferenceforrestday] = useState<string | null>(null);
  const [medicalHistoryRemarks, setMedicalHistoryRemarks] = useState<string>('');

  // Candidate Skill
  const [namesSINGEA, setNamesSINGEA] = useState<string[]>([]);
  const [areasWorksSINGEA, setAreasWorksSINGEA] = useState<string[]>([]);
  const [subSkillsFDWIdSINGEA, setSubSkillsFDWIdSINGEA] = useState<SubSkillsModel[]>([]);

  const [namesTCEA, setNamesTCEA] = useState<string[]>([]);
  const [areasWorksTCEA, setAreasWorksTCEA] = useState<string[]>([]);
  const [subSkillsFDWIdTCEA, setSubSkillsFDWIdTCEA] = useState<SubSkillsModel[]>([]);

  // Candidate History FDW
  const [jobExperiences, setJobExperiences] = useState<JobExperienceModel[]>([]);
  const [feedbackEmployer, setFeedbackEmployer] = useState<FeedbackEmployerModel>(dummyFeedbackEmployers);

  // Candidate Document
  const [documentFiles, setDocumentFiles] = useState<CandidateFileDocument[]>([]);

  const getSteps = () => {
    return ['Profile', 'Medical History', 'Skills', 'Employment History'];
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const steps = getSteps();

  const clearFormErrors = () => {
    setCodeError('');
    setBioFeeError('');
    setPassportStatusError('');
    setLoanError('');
    setPocketMoneyError('');
    setMinimumSalaryError('');
    setMaritalStatusError('');
    setFullNameError('');
    setDOBError('');
    setHeightError('');
    setWeightError('');
    setPhoneNumberError('');
    setNationalityError('');
    setNameOfPortError('');
    setReligionError('');
    setEducationCollegeError('');
    setPlaceofBirthError('');
    setResidentialAddressError('');
    setNumberOfSiblingsError('');
    setSkillsError('');
    setUploadPhotoError('');
    setAddAgeOfChildrensError('');
  };

  const validateForm = () => {
    clearFormErrors();
    let ret = true;

    if (activeStep === 0) {
      if (!code || !code.trim()) {
        setCodeError('Please enter ID');
        ret = false;
      }

      if (!bioFee || bioFee == 0) {
        setBioFeeError('Please enter Bio Fee');
        ret = false;
      }

      if (!loan || loan === 0) {
        setLoanError('Please enter Loan');
        ret = false;
      }

      if (!pocketMoney || pocketMoney == 0) {
        setPocketMoneyError('Please enter Pocket Money');
        ret = false;
      }

      if (!minimumSalary || minimumSalary === 0) {
        setMinimumSalaryError('Please enter Minimum Salary');
        ret = false;
      }

      if (!maritalStatus || !maritalStatus.trim()) {
        setMaritalStatusError('Please enter Minimum Salary');
        ret = false;
      }

      if (!fullName || !fullName.trim()) {
        setFullNameError('Please enter Full name');
        ret = false;
      }

      if (!dob) {
        setDOBError('Please enter Date of Birth');
        ret = false;
      }

      if (!height || height === 0) {
        setHeightError('Please enter Height');
        ret = false;
      }

      if (!weight || weight === 0) {
        setWeightError('Please enter Weight');
        ret = false;
      }

      // if (addAgeOfChildrens.length === 1) {
      //   setAddAgeOfChildrensError('Please added one Age of Children');
      //   ret = false;
      // }

      if (!placeOfBirth || !placeOfBirth.trim()) {
        setPlaceofBirthError('Please enter Place of Birth');
        ret = false;
      }

      if (!residentialAddress || !residentialAddress.trim()) {
        setResidentialAddressError('Please enter Residential Address')
      }

      if (!phoneNumber || !phoneNumber.trim()) {
        setPhoneNumberError('Please enter Phone Number');
        ret = false;
      }

      if (skills.length === 0) {
        setSkillsError('Please select minimum one Skill');
        ret = false;
      }

      // if (!uploadPhoto) {
      //   setUploadPhotoError('Please select photo');
      //   ret = false;
      // }

      // if (!numberOfSiblings || !numberOfSiblings.trim()) {
      //   setNumberOfSiblingsError('Please enter Number of Siblings');
      //   ret = false;
      // }

      if (!nationality || !nationality.trim()) {
        setNationalityError('Please enter Nationality');
        ret = false;
      }

      if (!nameOfPort || !nameOfPort.trim()) {
        setNameOfPortError('Please enter Name of Port');
        ret = false;
      }

      if (!religion || !religion.trim()) {
        setReligionError('Please enter Religion');
        ret = false;
      }

      if (!educationCollege || !educationCollege.trim()) {
        setEducationCollegeError('Please enter Education Level');
        ret = false;
      }
    } else if (activeStep === 1) {
    } else {
      //   setActiveStep(prevActiveStep => prevActiveStep + 1);
    }

    return ret;
  };

  const handleNext = () => {
    if (!validateForm()) {
        return;
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const resetFormValues = () => {
    setActiveStep(0);
    
    setUploadPhoto('');
    setLinkVideo('');
    setFullName('');
    setFullNameError('');
    setCode('');
    setCodeError('');
    setPassportStatus('');
    setPassportStatusError('');
    setBioFee(0);
    setBioFeeError('');
    setLoan(0);
    setLoanError('');
    setPocketMoney(0);
    setPocketMoneyError('');
    setMinimumSalary(0);
    setMinimumSalaryError('');
    setMaritalStatus('');
    setMaritalStatusError('');
    setExperinceSG('');
    setSkills([]);
    setRemarks('');
    setDOB(null);
    setDOBError('');
    setPlaceofBirth('');
    setPlaceofBirthError('')
    setHeight(0);
    setHeightError('');
    setWeight(0);
    setWeightError('');
    setNationality('');
    setNationalityError('');
    setPhoneNumber('');
    setResidentialAddress('');
    setNameOfPort('');
    setNameOfPortError('');
    setReligion('');
    setReligionError('');
    setEducationCollege('');
    setEducationCollegeError('');
    setNumberOfSiblings('');
    setAddAgeOfChildren('');
    setAllergies('');
    setDietaryrestrictions('');
    setMentalIllness(false);
    setTuberculosis(false);
    setPhysicaldisabilities('');
    setHeartdisease(false);
    setOtherspastexisting('');
    setOthersfood('');
    setMalaria(false);
    setOperations(false);
    setEpilepsy(false);
    setAsthma(false);
    setDiabetes(false);
    setHypertension(false);
    setPork(false);
    setBeef(false);
    setPreferenceforrestday('-');
    setMedicalHistoryRemarks('');
    setNamesSINGEA([]);
    setAreasWorksSINGEA([]);
    setSubSkillsFDWIdSINGEA([dummySubSkillFDW]);
    setNamesTCEA([]);
    setAreasWorksTCEA([]);
    setSubSkillsFDWIdTCEA([dummySubSkillFDW]);
    setJobExperiences([]);
    setFeedbackEmployer(dummyFeedbackEmployers);
    setDocumentFiles([]);
    loadSkill();
  };

  const loadSkill = () => {
    const nameInterview = [
      'Care of infants / children',
      'Care of Elderly',
      'Care of disabled',
      'General Housework',
      'Cooking',
      'Language Abilities',
      'Other Skills'
    ];

    [0, 1, 2, 3, 4, 5, 6].map(index => {
      const newNamesSINGEA = namesSINGEA;
      const newAreasWorksSINGEA = areasWorksSINGEA;
      const newSubSkillsFDWIdSINGEA = subSkillsFDWIdSINGEA;
      const currentSubSkill = {
        willingness: true,
        experience: true,
        assessmentObservation: '',
        languangeAbilities: '',
        otherSkills: '',
        new: true
      };

      newNamesSINGEA.push(nameInterview[index]);
      newAreasWorksSINGEA.push('SINGEA');
      newSubSkillsFDWIdSINGEA.push(currentSubSkill);

      setNamesSINGEA(newNamesSINGEA);
      setAreasWorksSINGEA(newAreasWorksSINGEA);
      setSubSkillsFDWIdSINGEA(newSubSkillsFDWIdSINGEA);
    });

    [0, 1, 2, 3, 4, 5, 6].map(index => {
      const newNamesTCEA = namesTCEA;
      const newAreasWorksTCEA = areasWorksTCEA;
      const newSubSkillsFDWIdTCEA = subSkillsFDWIdTCEA;
      const currentSubSkill = {
        willingness: true,
        experience: true,
        assessmentObservation: '',
        languangeAbilities: '',
        otherSkills: '',
        new: true
      };

      newNamesTCEA.push(nameInterview[index]);
      newAreasWorksTCEA.push('TCEA');
      newSubSkillsFDWIdTCEA.push(currentSubSkill);

      setNamesTCEA(newNamesTCEA);
      setAreasWorksTCEA(newAreasWorksTCEA);
      setSubSkillsFDWIdTCEA(newSubSkillsFDWIdTCEA);
    });
  };

  useEffect(() => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

    const loadProperties = async () => {
      setLoading(true);

      try {
        loadSkill();
        
        const url = `${RESUME_BASE_URL}`;
        const { data } = await axios.get(url, { cancelToken: cancelTokenSource.token });

        //Set Marital Status master
        let maritalStatusData: Select[] = [];

        //Set Experince In SG master
        let experinceInSGStatusData: Select[] = [];

        //Set skill master
        let skillStatusData: Select[] = [];

        data.maritalStatus.map((value: string, index: number) => {
          return maritalStatusData.push({ name: value, id: index });
        });

        data.experinceInSGStatus.map((value: string, index: number) => {
          return experinceInSGStatusData.push({ name: value, id: index });
        });

        data.skills.map((value: string, index: number) => {
          return skillStatusData.push({ name: value, id: index });
        });

        setMaritalStatuses(maritalStatusData);
        setExperinceInSGStatuses(experinceInSGStatusData);
        setSkillStatuses(skillStatusData);

        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };

    

    loadProperties();

    return () => {
      cancelTokenSource.cancel();
    };
  }, []);

  const getStepContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return (
          <CandidateProfile
            linkVideo={linkVideo}
            setLinkVideo={setLinkVideo}
            fullName={fullName}
            setFullName={setFullName}
            fullNameError={fullNameError}
            code={code}
            setCode={setCode}
            codeError={codeError}
            passportStatus={passportStatus}
            setPassportStatus={setPassportStatus}
            passportStatusError={passportStatusError}
            bioFee={bioFee}
            setBioFee={setBioFee}
            bioFeeError={bioFeeError}
            loan={loan}
            setLoan={setLoan}
            loanError={loanError}
            pocketMoney={pocketMoney}
            setPocketMoney={setPocketMoney}
            pocketMoneyError={pocketMoneyError}
            minimumSalary={minimumSalary}
            setMinimumSalary={setMinimumSalary}
            minimumSalaryError={minimumSalaryError}
            maritalStatus={maritalStatus}
            setMaritalStatus={setMaritalStatus}
            maritalStatusError={maritalStatusError}
            uploadPhoto={uploadPhoto}
            setUploadPhoto={setUploadPhoto}
            uploadPhotoError={uploadPhotoError}
            experienceSG={experienceSG}
            setExperinceSG={setExperinceSG}
            skills={skills}
            setSkills={setSkills}
            skillsError={skillsError}
            remarks={remarks}
            setRemarks={setRemarks}
            skillsStatuses={skillsStatuses}
            experinceInSGStatuses={experinceInSGStatuses}
            dob={dob}
            setDOB={setDOB}
            dobError={dobError}
            placeOfBirth={placeOfBirth}
            setPlaceofBirth={setPlaceofBirth}
            placeOfBirthError={placeOfBirthError}
            height={height}
            setHeight={setHeight}
            heightError={heightError}
            weight={weight}
            setWeight={setWeight}
            weightError={weightError}
            nationality={nationality}
            setNationality={setNationality}
            nationalityError={nationalityError}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            phoneNumberError={phoneNumberError}
            residentialAddress={residentialAddress}
            setResidentialAddress={setResidentialAddress}
            residentialAddressError={residentialAddressError}
            nameOfPort={nameOfPort}
            setNameOfPort={setNameOfPort}
            nameOfPortError={nameOfPortError}
            religion={religion}
            setReligion={setReligion}
            religionError={religionError}
            educationCollege={educationCollege}
            setEducationCollege={setEducationCollege}
            educationCollegeError={educationCollegeError}
            numberOfSiblings={numberOfSiblings}
            setNumberOfSiblings={setNumberOfSiblings}
            numberOfSiblingsError={numberOfSiblingsError}
            maritalStatuses={maritalStatuses}
            addAgeOfChildren={addAgeOfChildren}
            setAddAgeOfChildren={setAddAgeOfChildren}
            addAgeOfChildrensError={addAgeOfChildrensError}
            isSubmitting={isLoading}
          />
        );
      case 1:
        return (
          <CandidateMedicalHistory
            allergies={allergies}
            setAllergies={setAllergies}
            dietaryRestrictions={dietaryRestrictions}
            setDietaryrestrictions={setDietaryrestrictions}
            mentalIllness={mentalIllness}
            setMentalIllness={setMentalIllness}
            tuberculosis={tuberculosis}
            setTuberculosis={setTuberculosis}
            physicalDisabilities={physicalDisabilities}
            setPhysicaldisabilities={setPhysicaldisabilities}
            heartDisease={heartDisease}
            setHeartdisease={setHeartdisease}
            othersPastExisting={othersPastExisting}
            setOtherspastexisting={setOtherspastexisting}
            othersFood={othersFood}
            setOthersfood={setOthersfood}
            malaria={malaria}
            setMalaria={setMalaria}
            operations={operations}
            setOperations={setOperations}
            epilepsy={epilepsy}
            setEpilepsy={setEpilepsy}
            asthma={asthma}
            setAsthma={setAsthma}
            diabetes={diabetes}
            setDiabetes={setDiabetes}
            hypertension={hypertension}
            setHypertension={setHypertension}
            pork={pork}
            setPork={setPork}
            beef={beef}
            setBeef={setBeef}
            preferenceForRestDay={preferenceForRestDay}
            setPreferenceforrestday={setPreferenceforrestday}
            remarks={medicalHistoryRemarks}
            setRemarks={setMedicalHistoryRemarks}
            isSubmitting={isLoading}
          />
        );
      case 2:
        return (
          <CandidateSkill
            namesSINGEA={namesSINGEA}
            setNamesSINGEA={setNamesSINGEA}
            areasWorksSINGEA={areasWorksSINGEA}
            setAreasWorksSINGEA={setAreasWorksSINGEA}
            subSkillsFDWIdSINGEA={subSkillsFDWIdSINGEA}
            setSubSkillsFDWIdSINGEA={setSubSkillsFDWIdSINGEA}
            namesTCEA={namesTCEA}
            setNamesTCEA={setNamesTCEA}
            areasWorksTCEA={areasWorksTCEA}
            setAreasWorksTCEA={setAreasWorksTCEA}
            subSkillsFDWIdTCEA={subSkillsFDWIdTCEA}
            setSubSkillsFDWIdTCEA={setSubSkillsFDWIdTCEA}
            isSubmitting={isLoading}
          />
        );
      case 3:
        return (
          <CandidateHistoryFDW
            jobExperiences={jobExperiences}
            setJobExperiences={setJobExperiences}
            feedbackEmployer={feedbackEmployer}
            setFeedbackEmployer={setFeedbackEmployer}
          />
        );
      case 4:
        return <CandidateUploadDocument documentFiles={documentFiles} setDocumentFiles={setDocumentFiles} isSubmitting={isLoading} />;
      default:
        return <div />;
    }
  };

  const handleOnSubmit: React.FormEventHandler = async event => {
    event.preventDefault();

    setLoading(true);

    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    try {
      const photo: any = uploadPhoto;
      const newSubSkillsFDWIdSINGEA = subSkillsFDWIdSINGEA.filter(value => value.new === true);
      const newSubSkillsFDWIdTCEA = subSkillsFDWIdTCEA.filter(value => value.new === true);
      
      const response = await axios.post(
        CANDIDATE_BASE_URL,
        {
          // Profile
          code,
          name: fullName,
          dateOfBirth: dob,
          placeOfBirth,
          height,
          weight,
          nationality,
          residential: residentialAddress,
          nameOfPort,
          contactNumberInHomeCountry: phoneNumber,
          religion,
          educationLevel: educationCollege,
          numberOfSiblings,
          maritalStatus,
          status: 'AVAILABLE',
          numberOfChildren: addAgeOfChildren ? String(addAgeOfChildren).split(',').length : 0,
          passportStatus,
          bioFee,
          minimumSalary,
          restDay: '',
          candidateNotes: '',
          photo: photo.name,
          skills,
          experienceInSG: experienceSG,
          remarks: remarks,
          linkYoutube: linkVideo,
          pocketMoney,
          loan,
          ageOfChildrens: addAgeOfChildren,
          // Medical History
          allergies,
          dietaryRestrictions,
          mentalIllness,
          tuberculosis,
          physicalDisabilities,
          heartDisease,
          othersPastExisting,
          othersFood,
          malaria,
          operations,
          epilepsy,
          asthma,
          diabetes,
          hypertension,
          pork,
          beef,
          preferenceForRestDay,
          medicalHistoryRemarks,
          // Employment History
          feedbackEmployer,
          jobExperiences,
          // Candidate Skill FDW
          namesSINGEA,
          areasWorksSINGEA,
          subSkillsFDWIdSINGEA: newSubSkillsFDWIdSINGEA,
          namesTCEA,
          areasWorksTCEA,
          subSkillsFDWIdTCEA: newSubSkillsFDWIdTCEA,
          // Candidate Document
          documentFiles,
        },
        { cancelToken: cancelTokenSource.token }
      );

      const { data } = response;

      if (photo) {
        const myHeaders = new Headers();

        myHeaders.append('Content-Type', photo.type);

        const config = {
          method: 'PUT',
          body: photo,
          headers: myHeaders,
        };

        await fetch(`${data.photo}`, config)
          .then(response => response.text())
          .then(result => console.log(result))
          .catch(error => console.log('error', error));
      }

      if (documentFiles) {
        const resultDocuments: CandidateFileDocument[] =  data.DocumentUploadFiles;
        resultDocuments.map(async (value, index) => {
          const fileData: any = documentFiles[index].file;

          const myHeaders = new Headers();

          myHeaders.append('Content-Type', fileData.type);

          const config = {
            method: 'PUT',
            body: fileData,
            headers: myHeaders,
          };

          await fetch(`${value.urlFile}`, config)
          .then(response => response.text())
          .then(result => console.log(result))
          .catch(error => console.log('error', error));
        })
      }

      setOpenSnackbar(true);
      setSnackbarVarient('success');
      setLoading(false);
      resetFormValues();
    } catch (err) {
      setSnackbarVarient('error');
      setOpenSnackbar(true);
      setLoading(false);
    }
  };

  const handleCancel = () => {
    historyBack.goBack();
  };

  return (
    <Page title={currentPageTitle}>
      <Container>
        <Grid container direction='row' spacing={1}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant='subtitle1' component='h3'>
              {currentPageTitle}
            </Typography>
          </Grid>
        </Grid>
        <PaperCustom>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Grid>
            <Typography>{getStepContent(activeStep)}</Typography>
          </Grid>
          {activeStep !== 0 ? (
            <Button variant='contained' className={classes.backButton} onClick={handleBack} disabled={isLoading}>
              Back
            </Button>
          ) : (
            <Button variant='contained' className={classes.cancelButton} disabled={isLoading} onClick={handleCancel}>
              Cancel
            </Button>
          )}
          {activeStep === steps.length - 1 ? (
            <NextButtonDisabled variant='contained' color='primary' className={classes.nextButton} onClick={handleOnSubmit} disabled={isLoading}>
              Confirm
            </NextButtonDisabled>
          ) : (
            <NextButton variant='contained' color='primary' className={classes.nextButton} onClick={handleNext} disabled={isLoading}>
              Next
            </NextButton>
          )}
        </PaperCustom>
        <ActionSnackbar
          variant={snackbarVarient}
          message={snackbarVarient === 'success' ? 'Create is successfully' : 'Create failed'}
          open={openSnackbar}
          handleClose={handleCloseSnackbar}
          Icon={snackbarVarient === 'success' ? CheckCircleIcon : ErrorIcon}
        />
      </Container>
    </Page>
  );
};

export default CreateCandidatePage;
