import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Dialog, DialogContent, DialogTitle, IconButton, Theme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import CandidateForm from './components/CandidateForm';
import axios, { CancelTokenSource } from 'axios';
import { GET_EDIT_CANDIDATE_URL, RESUME_BASE_URL } from 'constants/url';

interface Props {
  open: boolean;
  candidate: CandidateModel;
  setCandidate: React.Dispatch<React.SetStateAction<CandidateModel>>;
  handleCancel(): void;
  setOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarVarient: React.Dispatch<React.SetStateAction<'success' | 'error'>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  contentGrid: {
    borderRadius: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#0B3469',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0)
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  saveButton: {
    color: '#FFFFFF'
  },
  cancelButton: {
    marginRight: theme.spacing(3)
  },
  controlDiv: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2)
  }
}));

const ChangeStatusModal: React.FC<Props> = props => {
  const classes = useStyles();

  let cancelTokenSource: CancelTokenSource;
  const {
    open,
    candidate,
    setCandidate,
    handleCancel,
    setOpenSnackbar,
    setSnackbarVarient
  } = props;

  const [isLoading, setLoading] = useState<boolean>(false);

  const [candidateId, setCandidateId] = useState<number>(0);
  const [status, setStatus] = useState<string>('');
  const [candidateStatuses, setCandidateStatuses] = useState<Select[]>([]);

  // This is to ensure that the form vale and erors are reset/cleared when user canceled the editing
  const resetFormValues = useCallback(() => {

    const { id, status } = candidate;

    setCandidateId(id);
    setStatus(status);

  }, [candidate]);

  useEffect(() => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

    const loadProperties = async () => {
      setLoading(true);

      try {
        const url = `${RESUME_BASE_URL}`;
        const { data } = await axios.get(url, { cancelToken: cancelTokenSource.token });

        //Set Marital Status master
        let candidateStatusData: Select[] = [];

        data.candidateStatus.map((value: string, index: number) => {
          return candidateStatusData.push({ name: value, id: index });
        });

        setCandidateStatuses(candidateStatusData);
      } catch (err) {
        console.log(err);
      }

      setLoading(false);
    };

    loadProperties();

    return () => {
      cancelTokenSource.cancel();
    };
  }, []);

  useEffect(() => {
    resetFormValues();
  }, [resetFormValues]);

  const handleOnClose = () => {
    resetFormValues();
    handleCancel();
  };

  const handleOnSubmit: React.FormEventHandler = async event => {
    event.preventDefault();
    setLoading(true);
    try {
      cancelTokenSource = axios.CancelToken.source();
      const { data } = await axios.put(
        `${GET_EDIT_CANDIDATE_URL(candidateId)}`,
        {
          status,
        },
        { cancelToken: cancelTokenSource.token }
      );

      const candidateData: CandidateModel = data;

      setCandidate(candidateData);
      setOpenSnackbar(true);
      setLoading(false);
      setSnackbarVarient('success');
      handleCancel();

      return () => {
        cancelTokenSource.cancel();
      };
    } catch (err) {
      console.log(err);
      setOpenSnackbar(true);
      setSnackbarVarient('error');
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} scroll='body' fullWidth={true} maxWidth='sm'>
      <DialogTitle>
        <IconButton size='small' className={classes.closeButton} onClick={handleOnClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <CandidateForm
          candidateStatuses={candidateStatuses}
          status={status}
          setStatus={setStatus}
          isSubmitting={isLoading}
          onSubmit={handleOnSubmit}
          onCancel={handleOnClose}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ChangeStatusModal;
