export const dummyMedicalHistory: MedicalHistoryModel = {
  id: 0,
  allergies: '',
  mentalIllness: false,
  epilepsy: false,
  asthma: false,
  diabetes: false,
  hypertension: false,
  tuberculosis: false,
  heartDisease: false,
  malaria: false,
  operations: false,
  physicalDisabilities: '',
  dietaryRestrictions: '',
  othersPastExisting: '',
  pork: false,
  beef: false,
  preferenceForRestDay: '',
  othersFood: '',
  remarks: ''
};

export const dummyAgeofChildren: AgeofChildrenModel = {
  id: 0,
  age: '-',
  name: '',
  edit: false,
  new: false
};

export const dummyCandidate: CandidateModel = {
  id: 0,
  name: '',
  code: '',
  dateOfBirth: new Date(),
  linkYoutube: '',
  passportStatus: '-',
  pocketMoney: 0,
  loan: 0,
  bioFee: 0,
  minimumSalary: 0,
  maritalStatus: '',
  contactNumberInHomeCountry: '',
  placeOfBirth: '',
  religion: '',
  height: 0,
  weight: 0,
  status: '',
  educationLevel: '',
  experienceInSG: '',
  numberOfSiblings: '',
  nationality: '',
  numberOfChildren: 0,
  residential: '',
  nameOfPort: '',
  Skills: [],
  ageOfChildren: '',
  photo: '',
  remarks: '',
  MedicalHistory: dummyMedicalHistory,
  createdAt: new Date(),
  updatedAt: new Date()
};

export const dummyFeedbackEmployers: FeedbackEmployerModel = {
  id: 0,
  employer1: '',
  employer2: '',
  feedback1: '',
  feedback2: '',
  interview: false,
  phone: false,
  videoConference: false,
  person: false,
  remarks: ''
};

export const dummySelect: Select = {
  id: 0,
  name: ''
};

export const dummyJobExperience: JobExperienceModel = {
  id: 0,
  dateFrom: '',
  dateTo: '',
  country: '',
  CandidateId: 0,
  employer: '',
  workDuties: '',
  remarks: '',
  createdAt: new Date(),
  updatedAt: new Date()
};

export const dummySubSkillFDW: SubSkillsModel = {
  id: 0,
  willingness: true,
  experience: true,
  assessmentObservation: '',
  languangeAbilities: '',
  otherSkills: '',
  new: false
};

export const dummySkillFDW: SkillsFDWModel = {
  id: 0,
  name: '',
  areasWork: '',
  SubSkillFDW: dummySubSkillFDW
};

export const dummySkill: SkillsModel = {
  id: 0,
  name: ''
};

export const dummyYoutube: YoutubeModel = {
  input: '',
  filename: '',
  downloadUrl: '',
  format: 'mp4',
  suggestions: [],
  downloads: [],
  currentVideoInfo: null,
  focus: false,
  isDark: false
};

const dummyUser: UserDetailsModel = {
  id: 0,
  loginName: '',
  displayName: '',
  email: '',
  role: '',
  description: '',
  active: false
};

export const dummyExtendFieldDocument: ExtendFieldDocumentModel = {
  id: 0,
  name: '',
  hand: false,
  machine: false,
  both: false
};

export const dummyDocumentFile: CandidateFileDocument = {
  id: 0,
  CandidateId: 0,
  documentFile: '',
  allowDownloaded: true,
  isDeleted: false,
  file: '',
  size: '',
  new: true
};

export const dummyDocument: DocumentModel = {
  id: 0,
  Candidate: dummyCandidate,
  User: dummyUser,
  ExtendFieldDocument: [dummyExtendFieldDocument],
  CandidateId: 0,
  UserId: 0,
  nameOfEmployer: '',
  nameOfFDW: '',
  race: '',
  salary: 0,
  dayOff: 0,
  probation: 0,
  typeOfHouse: '',
  numberOfBedroom: 0,
  numberOfToilet: 0,
  numberOfFamilyMember: 0,
  numberOfBabies: 0,
  remark: '',
  helperName: '',
  employeName: '',
  waitDownStairs: false,
  busOnly: false,
  fetchChildrenToBackFromSchool: false,
  bedriddenPatient: false,
  generalHouseWork: false,
  ironingOfClothes: false,
  cooking: false,
  carWashing: false,
  healthy: false,
  assistanceNeeded: false,
  lookAfterElderly: false,
  ageOfFirstChildBelowTwelve: '',
  ageOfFirstChildBelowFive: '',
  signature: '',
};

export const dummyChildrenOfEmployer: ChildrenOfEmployerModel = {
  id: 0,
  age: 0
};
