import { Container, Grid, Typography } from '@material-ui/core';
import { Page, PaperCustom } from 'components';
import React, { FC } from 'react';

const FAQPage: FC = () => {
  return (
    <Page title='FAQ Page'>
      <Container>
        <Grid container direction='row' spacing={1}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant='subtitle1' component='h3'>
              FAQ Page
            </Typography>
            <Typography variant='h6' component='h6' color='textSecondary'>
              Displays the data of all FAQ
            </Typography>
          </Grid>
        </Grid>
        <PaperCustom>
          <Grid container spacing={2}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Container>
                    
                </Container>
              </Grid>
            </Grid>
          </Grid>
        </PaperCustom>
      </Container>
    </Page>
  );
};

export default FAQPage;
