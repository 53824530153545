import { Button, Container, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { Page, PaperCustom } from 'components';
import ActionSnackbar from 'components/ActionSnackbar';
import { CurrentPageContext } from 'contexts/CurrentPageContext';
import useCurrentPageTitleUpdater from 'hooks/useCurrentPageTitleUpdater';
import React, { FC, Fragment, useCallback, useContext, useEffect, useState } from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import ContentSection from './ContentSection';
import axios, { CancelTokenSource } from 'axios';
import { DOCUMENT_BASE_URL } from 'constants/url';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import { getCurrentRoleName } from 'selectors';
import useDebounce from 'hooks/useDebounce';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  container: {
    '& > :nth-child(n+2)': {
      marginTop: theme.spacing(2)
    }
  }
}));

const CandidateDocumentPage: FC = () => {
  useCurrentPageTitleUpdater('Job Order Page');
  const { currentUser } = useContext(CurrentUserContext);
  const { currentPageTitle } = useContext(CurrentPageContext);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(20);
  const [orderColumn, setOrderColumn] = useState<string>('');
  const [orderBy, setOrderBy] = useState<'asc' | 'desc'>('asc');
  const [isSearchingDocument, setSearchingDocument] = useState<boolean>(false);
  const [documents, setDocuments] = useState<DocumentModel[]>([]);
  const [count, setCount] = useState<number>(0);

  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarVarient, setSnackbarVarient] = useState<'success' | 'error'>('success');
  const [messageSuccess, setMessageSuccess] = useState<string>('');
  const [messageError, setMessageError] = useState<string>('');
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [roles, setRoles] = useState<string>('');

  const [partnerCode, setPartnerCode] = useState<string>('');
  const [agencyName, setAgencyName] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [dateTime, setDateTime] = useState<string>('');

  const partnerCodeQuery = useDebounce(partnerCode, 500);
  const agencyNameQuery = useDebounce(agencyName, 500);
  const nameQuery = useDebounce(name, 500);
  const dateTimeQuery = useDebounce(dateTime, 500);

  const fetchData = useCallback(() => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

    const getParams = () => {
      const params = new URLSearchParams();
      const searchData: SearchByColumnModel[] = [];

      if (orderBy && orderColumn) {
        params.append('orderBy', orderBy);
        params.append('orderColumn', orderColumn);
      }

      if (partnerCodeQuery) {
        searchData.push({ name: '"Candidate".code', value: partnerCodeQuery });
      }

      if (agencyNameQuery) {
        searchData.push({ name: '"User"."displayName"', value: agencyNameQuery });
      }

      if (nameQuery) {
        searchData.push({ name: '"Candidate".name', value: nameQuery });
      }

      if (dateTimeQuery) {
        searchData.push({ name: '"Candidate".createdAt', value: dateTimeQuery });
      }

      params.append('columnName[]', JSON.stringify(searchData));

      params.append('s', (0 * 20).toString());
      params.append('l', String(20));

      return params.toString();
    };

    const searchDocument = async () => {
      setSearchingDocument(true);

      try {
        const url = `${DOCUMENT_BASE_URL}?${getParams()}`;
        const { data } = await axios.get(url, { cancelToken: cancelTokenSource.token });

        setCount(data.count);

        const documentsData: DocumentModel[] = [...data.documents];
        
        setDocuments(documentsData);
        setSearchingDocument(false);
      } catch (err) {
        setSearchingDocument(true);
      }
    };

    searchDocument();
    return () => {
      cancelTokenSource.cancel();
    };
  }, [agencyNameQuery, dateTimeQuery, nameQuery, orderBy, orderColumn, partnerCodeQuery]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    const roles = currentUser ? getCurrentRoleName(currentUser) : '';
    setRoles(roles);
  }, [currentUser]);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleSetMessageSuccess = (message: string) => {
    setMessageSuccess(message);
  };

  const handleSetMessageError = (message: string) => {
    setMessageError(message);
  };

  const fetchScrollData = async () => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    const params = new URLSearchParams();

    if (orderBy && orderColumn) {
      params.append('orderBy', orderBy);
      params.append('orderColumn', orderColumn);
    }

    params.append('s', (currentPage === 0 ? 20 : currentPage * rowsPerPage).toString());
    params.append('l', rowsPerPage.toString());

    let result: DocumentModel[] = [];
    try {
      const url = `${DOCUMENT_BASE_URL}?${params.toString()}`;
      const { data } = await axios.get(url, { cancelToken: cancelTokenSource.token });

      setCount(data.count);

      const documentsData: DocumentModel[] = [...data.documents];
      result = documentsData;
    } catch (err) {
      console.log(err);
    }

    return result;
  };

  const fetchMoreData = () => {
    if (documents.length === count) {
      setHasMore(false);
      return;
    }

    setCurrentPage(prev => {
      let result = prev + 1 + 1;
      if (prev !== 0) {
        result = prev + 1;
      }
      
      return result;
    });
    setRowsPerPage(20);

    setTimeout(async () => {
      const currentDocuments: DocumentModel[] = [...documents];
      const newDocuments: DocumentModel[] = await fetchScrollData();

      const mergeArray = [...currentDocuments, ...newDocuments];
      
      if (mergeArray.length === count) {
        setHasMore(false);
      }

      setDocuments(mergeArray);
    }, 500);
  };

  const renderContentLoading = () => {
    if (documents.length === count) {
      return <div></div>;
    } else {
      return (
        <Fragment>
          <Grid container spacing={1} alignItems='center' justify='center'>
            <Typography color='primary' variant='h4'>
              Loading ...
            </Typography>
          </Grid>
        </Fragment>
      );
    }
  };

  return (
    <Page title={currentPageTitle}>
      <Container maxWidth={false}>
        <Grid container direction='row' spacing={1}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant='subtitle1' component='h3'>
                  {currentPageTitle}
                </Typography>
                <Typography variant='h6' component='h6' color='textSecondary'>
                  Displays the data of all Documents
                </Typography>
              </Grid>
              <Grid container item xs={6} justify='flex-end'></Grid>
            </Grid>
          </Grid>
        </Grid>
        <PaperCustom>
          <Grid container spacing={2}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Container>
                  <InfiniteScroll
                    dataLength={documents.length}
                    next={fetchMoreData}
                    hasMore={hasMore}
                    height={750}
                    loader={roles !== 'partner_agency' && renderContentLoading()}
                  >
                    <ContentSection
                      documents={documents}
                      isLoadingData={isSearchingDocument}
                      setOrderColumn={setOrderColumn}
                      orderBy={orderBy}
                      setOrderBy={setOrderBy}
                      partnerCode={partnerCode}
                      setPartnerCode={setPartnerCode}
                      agencyName={agencyName}
                      setAgencyName={setAgencyName}
                      name={name}
                      setName={setName}
                      dateTime={dateTime}
                      setDateTime={setDateTime}
                      setOpenSnackbar={setOpenSnackbar}
                      setSnackbarVarient={setSnackbarVarient}
                      handleSetMessageSuccess={handleSetMessageSuccess}
                      handleSetMessageError={handleSetMessageError}
                    />
                  </InfiniteScroll>
                </Container>
              </Grid>
            </Grid>
          </Grid>
          <ActionSnackbar
            variant={snackbarVarient}
            message={snackbarVarient === 'success' ? messageSuccess : messageError}
            open={openSnackbar}
            handleClose={handleCloseSnackbar}
            Icon={snackbarVarient === 'success' ? CheckCircleIcon : ErrorIcon}
          />
        </PaperCustom>
      </Container>
    </Page>
  );
};

export default CandidateDocumentPage;
