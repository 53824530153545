import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, makeStyles, Theme, Typography, withStyles } from '@material-ui/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import CanvasDraw from 'react-canvas-draw';
import { PRIMARY_COLOR } from 'constants/colors';
import { orange } from '@material-ui/core/colors';

interface Props {
  open: boolean;
  setOpenAddSignatureModal: React.Dispatch<React.SetStateAction<boolean>>;
  signature: string;
  setSignature: React.Dispatch<React.SetStateAction<string>>;
  handleCancel(): void;
}

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  contentGrid: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(2),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0)
  },
  canvasDraw: {
    border: '1px dashed grey',
    boxShadow: '0 13px 27px -5px rgba(50, 50, 93, 0.25),    0 8px 16px -8px rgba(0, 0, 0, 0.3)'
  },
  cancelButton: {
    marginRight: theme.spacing(3)
  },
  controlDiv: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2)
  }
}));

const SaveButton = withStyles(theme => ({
  root: {
    color: '#FFFFFF',
    backgroundColor: PRIMARY_COLOR,
    '&:hover': {
      backgroundColor: orange[700]
    }
  }
}))(Button);

const AddSignatureModal: React.FC<Props> = props => {
  const classes = useStyles();
  const { open, setOpenAddSignatureModal, signature, setSignature, handleCancel } = props;

  const canvasRef = useRef(null);

  const handleOnClose = () => {
    handleCancel();
  };

  return (
    <Dialog open={open} scroll='body' fullWidth={true} maxWidth='md'>
      <DialogTitle>
        <IconButton size='small' className={classes.closeButton} onClick={handleOnClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid className={classes.contentGrid}>
          <Typography variant='h4' id='modal-title' color='primary'>
            Draw your signature here
          </Typography>
        </Grid>
        <Grid container spacing={1}>
          <CanvasDraw
            ref={canvasRef}
            hideGrid
            canvasWidth='100%'
            brushRadius={2}
            className={classes.canvasDraw}
          />
        </Grid>
        <Grid container item justify='flex-end' xs={12} sm={12} md={12} lg={12} xl={12} className={classes.controlDiv}>
          <Button variant='contained' className={classes.cancelButton} onClick={handleOnClose}>
            Cancel
          </Button>
          <SaveButton type='submit' variant='contained' onClick={() => {
              const imageCanvas: any = canvasRef.current!;
              setSignature(imageCanvas.canvasContainer.children[1].toDataURL());
              setOpenAddSignatureModal(false);
          }}>
            Confirm
          </SaveButton>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default AddSignatureModal;
