import React, { FC, useState, useEffect, useContext } from 'react';
import { Avatar, Button, Divider, Grid, makeStyles, Theme, Typography } from '@material-ui/core';

import axios, { CancelTokenSource } from 'axios';
import NumberFormat from 'react-number-format';
import Skeleton from 'react-loading-skeleton';
import {
  PRIMARY_COLOR,
  CANDIDATE_STATUS_RESERVED_BACKROUND_COLOR,
  CANDIDATE_STATUS_RESERVED_COLOR,
  CANDIDATE_STATUS_CONFIRMED_BACKROUND_COLOR,
  CANDIDATE_STATUS_CONFIRMED_COLOR,
  CANDIDATE_STATUS_AVAILABLE_COLOR,
  CANDIDATE_STATUS_AVAILABLE_BACKROUND_COLOR,
  CANDIDATE_STATUS_TAKEN_COLOR,
  CANDIDATE_STATUS_TAKEN_BACKROUND_COLOR,
  CANDIDATE_STATUS_INACTIVE_COLOR,
  CANDIDATE_STATUS_INACTIVE_BACKROUND_COLOR
} from 'constants/colors';
import YouTube from 'react-youtube';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import ActionSnackbar from 'components/ActionSnackbar';
import EditCandidateModal from './components/EditCandidateModal';
import ChangeStatusModal from './components/ChangeStatusModal';
import { Options } from 'react-youtube';
import { isYtUrl } from 'utils/helpers';
import { downloadMedia } from 'utils';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import { getCurrentRoleName } from 'selectors';
import clsx from 'clsx';
import { GET_DOWNLOAD_PICTURE_URL } from 'constants/url';
import videoNotAvailable from 'images/video_not_available.png';
import { format } from 'date-fns';

interface Props {
  candidate: CandidateModel;
  setCandidate: React.Dispatch<React.SetStateAction<CandidateModel>>;
  isLoadingData: boolean;
  setIsLoadingData: React.Dispatch<React.SetStateAction<boolean>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: theme.spacing(2)
  },
  gridName: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  contentTypography: {
    fontWeight: 500,
    marginRight: theme.spacing(-3)
  },
  skills: {
    border: `1px solid ${PRIMARY_COLOR}`,
    borderRadius: theme.spacing(3),
    color: PRIMARY_COLOR,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.2),
    paddingBottom: theme.spacing(0.2),
    marginLeft: theme.spacing(1)
  },
  lineHeight: {
    lineHeight: 2
  },
  statusCandidate: (props: CandidateModel) => {
    let color = '';
    let backgroundColor = '';

    const { status } = props;

    switch (status) {
      case 'RESERVED':
        color = CANDIDATE_STATUS_RESERVED_COLOR;
        backgroundColor = CANDIDATE_STATUS_RESERVED_BACKROUND_COLOR;
        break;
      case 'CONFIRMED':
        color = CANDIDATE_STATUS_CONFIRMED_COLOR;
        backgroundColor = CANDIDATE_STATUS_CONFIRMED_BACKROUND_COLOR;
        break;
      case 'AVAILABLE':
        color = CANDIDATE_STATUS_AVAILABLE_COLOR;
        backgroundColor = CANDIDATE_STATUS_AVAILABLE_BACKROUND_COLOR;
        break;
      case 'TAKEN':
        color = CANDIDATE_STATUS_TAKEN_COLOR;
        backgroundColor = CANDIDATE_STATUS_TAKEN_BACKROUND_COLOR;
        break;
      case 'INACTIVE':
        color = CANDIDATE_STATUS_INACTIVE_COLOR;
        backgroundColor = CANDIDATE_STATUS_INACTIVE_BACKROUND_COLOR;
        break;
    }

    return {
      fontWeight: 500,
      fontSize: '12px',
      border: `1px solid ${color}`,
      borderRadius: theme.spacing(3),
      backgroundColor: backgroundColor,
      color: color,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    };
  },
  avatar: {
    backgroundColor: '#0B3469',
    width: '135px',
    height: '135px'
  },
  gridHeader: {
    display: 'flex'
  },
  contentValue: {
    lineHeight: 2,
    color: '#0B3469',
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(0.2)
  },
  gridButton: {
    marginTop: theme.spacing(4)
  },
  divider: {
    marginBottom: theme.spacing(2)
  },
  dividerVertical: {
    height: theme.spacing(12),
    marginLeft: theme.spacing(2.5)
  },
  buttonMargin: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2)
  }
}));

const HeaderContent: FC<Props> = props => {
  const { candidate, isLoadingData, setCandidate, setIsLoadingData } = props;
  const classes = useStyles(candidate);
  const { currentUser } = useContext(CurrentUserContext);

  const {
    name,
    code,
    photo,
    dateOfBirth,
    remarks,
    experienceInSG,
    placeOfBirth,
    Skills,
    linkYoutube,
    passportStatus,
    pocketMoney,
    status,
    loan,
    bioFee,
    minimumSalary,
    maritalStatus
  } = candidate!;

  const splitYT = String(linkYoutube).split('v=');
  let newLinkYoutube = '';

  if (splitYT[1]) {
    newLinkYoutube = splitYT[1];
  } else {
    const splitYTWithoutWatch = String(linkYoutube).replace('https://youtu.be/', '');
    
    if (splitYTWithoutWatch) {
      newLinkYoutube = splitYTWithoutWatch;
    }
  }

  const [isLoadingDownload, setIsLoadingDownload] = useState<boolean>(false);
  const [downloads, setDownloads] = useState<any[]>(['']);
  const [age, setAge] = useState<number>(0);
  const [photoUrl, setPhotoUrl] = useState<string>('');

  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarVarient, setSnackbarVarient] = useState<'success' | 'error'>('success');
  const [openEditProfileModal, setOpenEditProfileModal] = useState<boolean>(false);
  const [openChangeStatusModal, setOpenChangeStatusModal] = useState<boolean>(false);
  const [roles, setRoles] = useState<string>('');
  const [isYT, setIsYT] = useState<boolean>(false);

  const BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const opts: Options = {
    height: '296',
    width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
      origin: BASE_URL
    }
  };

  useEffect(() => {
    const birthDate = new Date(dateOfBirth);
    const difference = Date.now() - birthDate.getTime();
    const age = new Date(difference);

    setAge(Math.abs(age.getUTCFullYear() - 1970));

    if (candidate.photoUrl) {
      setPhotoUrl(candidate.photoUrl);
    }

    if (isYtUrl(linkYoutube)) {
      setIsYT(true);
    }
  }, [candidate, dateOfBirth, linkYoutube]);

  const renderAvatar = () => {
    if (!photo) {
      if (isLoadingData) {
        return <Avatar aria-label='candidateInitial' />;
      } else {
        return <Avatar className={classes.avatar} aria-label='candidateInitial' src={photoUrl} />;
      }
    } else {
      if (isLoadingData) {
        return <Avatar className={classes.avatar} aria-label='candidateInitial' />;
      } else {
        return <Avatar className={classes.avatar} alt={name} src={photoUrl} />;
      }
    }
  };

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    const roles = currentUser ? getCurrentRoleName(currentUser) : '';
    setRoles(roles);
  }, [currentUser]);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleCancelEditProfile = () => {
    setOpenEditProfileModal(false);
    setOpenChangeStatusModal(false);
  };

  const handleDownloadVideo = async (videoId: string) => {
    if (isYtUrl(videoId)) {
      downloadMedia(videoId);
    }
  };

  const handleDownloadPhoto = async () => {
    const { data } = await axios.get(GET_DOWNLOAD_PICTURE_URL(photo));

    const config = {
      method: 'GET',
      headers: {
        'Content-Type': 'image/*'
      }
    };

    await fetch(`${data}`, config)
      .then(response => {
        return response.blob().then(blob => {
          return {
            contentType: response.headers.get('Content-Type'),
            raw: blob
          };
        });
      })
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob.raw]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `photo-profile.${String(blob.contentType).split('/')[1]}`);
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => console.log('error', error));
  };

  return (
    <Grid>
      <Grid container spacing={3} direction='row' justify='center' alignItems='stretch'>
        <Grid item xs={6} container spacing={2}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  {renderAvatar()}
                </Grid>
                <Grid item xs={12} className={classes.gridButton}>
                  <Button variant='outlined' color='primary' onClick={handleDownloadPhoto}>
                    Download
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={8}>
                      <Typography variant='h4'>{isLoadingData ? <Skeleton width={90} /> : `${name}`}</Typography>
                    </Grid>
                    <Grid container justify='flex-end'>
                      <Grid item xs={4}>
                        <Typography align='right' color='primary'>
                          <span
                            className={clsx({
                              [classes.statusCandidate]: status
                            })}
                          >
                            {status}
                          </span>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Typography variant='h6' color='textSecondary'>
                    {isLoadingData ? <Skeleton width={90} /> : `ID: ${code} | Age: ${age}`}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='h6' color='textSecondary'>
                    {isLoadingData ? <Skeleton width={90} /> : `${remarks}`}
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.contentValue}>
                  {isLoadingData ? (
                    <Skeleton width={298} />
                  ) : (
                    Skills &&
                    Skills.map((skill, index) => {
                      return (
                        <Typography variant='body1' align='right' display='inline' color='primary' className={classes.lineHeight} key={index}>
                          <span className={classes.skills}>{skill.name} </span>
                        </Typography>
                      );
                    })
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider className={classes.divider} />
            <Grid container spacing={4}>
              <Grid item xs={4}>
                <Typography className={classes.contentTypography}>Passport status:</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.contentTypography} color='textSecondary'>
                  {isLoadingData ? <Skeleton width={130} /> : (passportStatus ? passportStatus : '-')}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.contentTypography}>Pocket Money:</Typography>
              </Grid>
              <Grid item xs={2}>
                {isLoadingData ? (
                  <Skeleton width={130} />
                ) : (
                  <NumberFormat
                    value={pocketMoney ? pocketMoney : '-'}
                    displayType={'text'}
                    thousandSeparator={true}
                    color='textSecondary'
                    prefix={' $'}
                    className={classes.contentTypography}
                  />
                )}
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={4}>
                <Typography className={classes.contentTypography}>Bio fee:</Typography>
              </Grid>
              <Grid item xs={3}>
                {isLoadingData ? (
                  <Skeleton width={130} />
                ) : (
                  <NumberFormat
                    value={bioFee ? bioFee : '-'}
                    displayType={'text'}
                    color='textSecondary'
                    thousandSeparator={true}
                    prefix={' $'}
                    className={classes.contentTypography}
                  />
                )}
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.contentTypography}>Minimum salary:</Typography>
              </Grid>
              <Grid item xs={2}>
                {isLoadingData ? (
                  <Skeleton width={130} />
                ) : (
                  <NumberFormat
                    value={minimumSalary ? minimumSalary : '-'}
                    displayType={'text'}
                    color='textSecondary'
                    thousandSeparator={true}
                    prefix={' $'}
                    className={classes.contentTypography}
                  />
                )}
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={4}>
                <Typography className={classes.contentTypography}>Loan:</Typography>
              </Grid>
              <Grid item xs={3}>
                {isLoadingData ? (
                  <Skeleton width={130} />
                ) : (
                  <NumberFormat
                    value={loan ? loan : '-'}
                    displayType={'text'}
                    color='textSecondary'
                    thousandSeparator={true}
                    prefix={' $'}
                    className={classes.contentTypography}
                  />
                )}
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.contentTypography}>Marital status:</Typography>
              </Grid>
              <Grid item xs={2}>
                {isLoadingData ? (
                  <Skeleton width={130} />
                ) : (
                  <Typography className={classes.contentTypography} color='textSecondary'>
                    {maritalStatus ? maritalStatus : '-'}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={4}>
                <Typography className={classes.contentTypography}>Date of Birth:</Typography>
              </Grid>
              <Grid item xs={3}>
                {isLoadingData ? (
                  <Skeleton width={130} />
                ) : (
                  <Typography className={classes.contentTypography} color='textSecondary'>
                    {dateOfBirth ? format(new Date(dateOfBirth), 'yyyy-MM-dd') : '-'}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.contentTypography}>Experience in Sg:</Typography>
              </Grid>
              <Grid item xs={2}>
                {isLoadingData ? (
                  <Skeleton width={130} />
                ) : (
                  <Typography className={classes.contentTypography} color='textSecondary'>
                    {experienceInSG ? experienceInSG : '-'}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={4}>
                <Typography className={classes.contentTypography}>Place of Birth:</Typography>
              </Grid>
              <Grid item xs={2}>
                {isLoadingData ? (
                  <Skeleton width={130} />
                ) : (
                  <Typography className={classes.contentTypography} color='textSecondary'>
                    {placeOfBirth ? placeOfBirth : '-'}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid item xs={12}>
            {isYT ? (
              <YouTube videoId={newLinkYoutube} opts={opts} />
            ) : (
              <img src={videoNotAvailable} height='296px' width='100%' />
            )}
          </Grid>
          {isYT && (
            <Grid item xs={12}>
              {/* <Button
                variant='outlined'
                color='primary'
                fullWidth
                disabled={isLoadingDownload}
                onClick={() => {
                  handleDownloadVideo(linkYoutube);
                }}
              >
                Download Video
              </Button> */}
            </Grid>
          )}
        </Grid>
      </Grid>
      {roles !== 'partner_agency' && (
        <Grid item xs={4}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                variant='contained'
                color='primary'
                fullWidth
                disabled={isLoadingDownload}
                className={classes.buttonMargin}
                onClick={() => {
                  setOpenChangeStatusModal(true);
                }}
              >
                Change Status
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant='outlined'
                color='primary'
                fullWidth
                disabled={isLoadingDownload}
                className={classes.buttonMargin}
                onClick={() => {
                  setOpenEditProfileModal(true);
                }}
              >
                Edit Biodata
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}

      <ActionSnackbar
        variant={snackbarVarient}
        message={snackbarVarient === 'success' ? 'Update is successful' : 'Update failed'}
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        Icon={snackbarVarient === 'success' ? CheckCircleIcon : ErrorIcon}
      />
      <ChangeStatusModal
        open={openChangeStatusModal}
        candidate={candidate}
        setCandidate={setCandidate}
        handleCancel={handleCancelEditProfile}
        setOpenSnackbar={setOpenSnackbar}
        setSnackbarVarient={setSnackbarVarient}
      />
      <EditCandidateModal
        open={openEditProfileModal}
        candidate={candidate}
        setCandidate={setCandidate}
        handleCancel={handleCancelEditProfile}
        setOpenSnackbar={setOpenSnackbar}
        setSnackbarVarient={setSnackbarVarient}
      />
    </Grid>
  );
};

export default HeaderContent;
