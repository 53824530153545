import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Dialog, DialogContent, DialogTitle, IconButton, Theme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import CandidateForm from './components/CandidateForm';
import axios, { CancelTokenSource } from 'axios';
import { GET_EDIT_CANDIDATE_URL, RESUME_BASE_URL } from 'constants/url';
import { dummySelect } from 'utils/dummy';

interface Props {
  open: boolean;
  candidate: CandidateModel;
  setCandidate: React.Dispatch<React.SetStateAction<CandidateModel>>;
  handleCancel(): void;
  setOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarVarient: React.Dispatch<React.SetStateAction<'success' | 'error'>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  contentGrid: {
    borderRadius: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#0B3469',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0)
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  saveButton: {
    color: '#FFFFFF'
  },
  cancelButton: {
    marginRight: theme.spacing(3)
  },
  controlDiv: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2)
  }
}));

const EditCandidateModal: React.FC<Props> = props => {
  const classes = useStyles();

  let cancelTokenSource: CancelTokenSource;
  const {
    open,
    candidate,
    handleCancel,
    setOpenSnackbar,
    setSnackbarVarient,
    setCandidate
  } = props;

  const [isLoading, setLoading] = useState<boolean>(false);

  const [dob, setDOB] = useState<Date | null>(null);
  const [candidateId, setCandidateId] = useState<number>(0);
  const [uploadPhoto, setUploadPhoto] = useState<any>('');
  const [linkVideo, setLinkVideo] = useState<string>('');
  const [fullName, setFullName] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [passportStatus, setPassportStatus] = useState<string>('');
  const [bioFee, setBioFee] = useState<number>(0);
  const [loan, setLoan] = useState<number>(0);
  const [pocketMoney, setPocketMoney] = useState<number>(0);
  const [minimumSalary, setMinimumSalary] = useState<number>(0);
  const [maritalStatus, setMaritalStatus] = useState<string>('');
  const [experienceSG, setExperinceSG] = useState<string>('');
  const [skills, setSkills] = useState<SkillsModel[]>([]);
  const [remarks, setRemarks] = useState<string>('');
  const [fileName, setFileName] = useState<string>('');
  const [placeOfBirth, setPlaceOfBirth] = useState<string>('-');

  const [maritalStatuses, setMaritalStatuses] = useState<Select[]>([dummySelect]);
  const [experinceInSGStatuses, setExperinceInSGStatuses] = useState<Select[]>([dummySelect]);
  const [skillsStatuses, setSkillStatuses] = useState<Select[]>([dummySelect]);

  // This is to ensure that the form vale and erors are reset/cleared when user canceled the editing
  const resetFormValues = useCallback(() => {
    if (!candidate.id) {
      return;
    };

    const {
      id,
      linkYoutube,
      name,
      code,
      passportStatus,
      bioFee,
      loan,
      pocketMoney,
      minimumSalary,
      maritalStatus,
      experienceInSG,
      Skills,
      remarks,
      photo,
      dateOfBirth,
      placeOfBirth,
    } = candidate;

    setDOB(dateOfBirth);
    setCandidateId(id);
    setLinkVideo(linkYoutube);
    setFullName(name);
    setCode(code);
    setPassportStatus(passportStatus);
    setBioFee(bioFee);
    setLoan(loan);
    setPocketMoney(pocketMoney);
    setMinimumSalary(minimumSalary);
    setMaritalStatus(maritalStatus);
    setUploadPhoto(uploadPhoto);
    setExperinceSG(experienceInSG);
    setSkills(Skills);
    setUploadPhoto(photo);
    setFileName(photo);

    if (placeOfBirth) {
      setPlaceOfBirth(placeOfBirth);
    }

    if (remarks) {
      setRemarks(remarks);
    }
    
  }, [candidate]);

  useEffect(() => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

    const loadProperties = async () => {
      setLoading(true);

      try {
        const url = `${RESUME_BASE_URL}`;
        const { data } = await axios.get(url, { cancelToken: cancelTokenSource.token });

        //Set Marital Status master
        let maritalStatusData: Select[] = [];

        //Set Experince In SG master
        let experinceInSGStatusData: Select[] = [];

        //Set skill master
        let skillStatusData: Select[] = [];

        data.maritalStatus.map((value: string, index: number) => {
          return maritalStatusData.push({ name: value, id: index });
        });

        data.experinceInSGStatus.map((value: string, index: number) => {
          return experinceInSGStatusData.push({ name: value, id: index });
        });

        data.skills.map((value: string, index: number) => {
          return skillStatusData.push({ name: value, id: index });
        });

        setMaritalStatuses(maritalStatusData);
        setExperinceInSGStatuses(experinceInSGStatusData);
        setSkillStatuses(skillStatusData)
      } catch (err) {
        console.log(err);
      }

      setLoading(false);
    };

    loadProperties();

    return () => {
      cancelTokenSource.cancel();
    };
  }, []);

  useEffect(() => {
    resetFormValues();
  }, [resetFormValues]);

  const handleOnClose = () => {
    handleCancel();
  };

  const handleOnSubmit: React.FormEventHandler = async event => {
    event.preventDefault();
    setLoading(true);
    try {

      cancelTokenSource = axios.CancelToken.source();
      const newSkills = skills.map(value => {
        value.CandidateId = candidateId;

        return value;
      })

      let newFileName = fileName;

      if (uploadPhoto && uploadPhoto.name) {
        newFileName = uploadPhoto.name;
      }
      
      const { data } = await axios.put(
        `${GET_EDIT_CANDIDATE_URL(candidateId)}`,
        {
          name: fullName,
          linkYoutube: linkVideo,
          photo: newFileName,
          code,
          dateOfBirth: dob,
          passportStatus,
          bioFee,
          loan,
          pocketMoney,
          minimumSalary,
          maritalStatus,
          experienceInSG: experienceSG,
          skills: newSkills,
          placeOfBirth,
          remarks
        },
        { cancelToken: cancelTokenSource.token }
      );

      const candidateData: CandidateModel = data;
      
      if (uploadPhoto && typeof uploadPhoto === 'object') {
        const myHeaders = new Headers();

        myHeaders.append('Content-Type', uploadPhoto.type);

        const config = {
          method: 'PUT',
          body: uploadPhoto,
          headers: myHeaders,
        };
        
        await fetch(`${candidateData.uploadPhotoUrl}`, config)
          .then(response => response.text())
          .then(result => console.log(result))
          .catch(error => console.log('error', error));

          candidateData.photoUrl = URL.createObjectURL(uploadPhoto);
      }

      setCandidate(candidateData);
      setOpenSnackbar(true);
      setSnackbarVarient('success');
      handleOnClose();
      setLoading(false);
      
      return () => {
        cancelTokenSource.cancel();
      };
    } catch (err) {
      console.log(err);
      setOpenSnackbar(true);
      setSnackbarVarient('error');
    }
    setLoading(false);
  };

  return (
    <Dialog open={open} scroll='body' fullWidth={true} maxWidth='md'>
      <DialogTitle>
        <IconButton size='small' className={classes.closeButton} onClick={handleOnClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <CandidateForm
          dob={dob}
          setDOB={setDOB}
          linkVideo={linkVideo}
          setLinkVideo={setLinkVideo}
          fullName={fullName}
          setFullName={setFullName}
          code={code}
          setCode={setCode}
          passportStatus={passportStatus}
          setPassportStatus={setPassportStatus}
          bioFee={bioFee}
          setBioFee={setBioFee}
          loan={loan}
          setLoan={setLoan}
          pocketMoney={pocketMoney}
          setPocketMoney={setPocketMoney}
          minimumSalary={minimumSalary}
          setMinimumSalary={setMinimumSalary}
          maritalStatus={maritalStatus}
          setMaritalStatus={setMaritalStatus}
          uploadPhoto={uploadPhoto}
          setUploadPhoto={setUploadPhoto}
          experienceSG={experienceSG}
          setExperinceSG={setExperinceSG}
          skills={skills}
          setSkills={setSkills}
          remarks={remarks}
          setRemarks={setRemarks}
          skillsStatuses={skillsStatuses}
          experinceInSGStatuses={experinceInSGStatuses}
          maritalStatuses={maritalStatuses}
          placeOfBirth={placeOfBirth}
          setPlaceOfBirth={setPlaceOfBirth}
          isSubmitting={isLoading}
          onSubmit={handleOnSubmit}
          onCancel={handleOnClose}
        />
      </DialogContent>
    </Dialog>
  );
};

export default EditCandidateModal;
