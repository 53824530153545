import React, { FC, useContext, useEffect, useState } from 'react';
import { Button, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import MedicalHistoryModal from './components/MedicalHistoryModal';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import { green, red } from '@material-ui/core/colors';
import ActionSnackbar from 'components/ActionSnackbar';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import MuiCollapse from '@material-ui/core/Collapse';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import { getCurrentRoleName } from 'selectors';

interface Props {
  medicalHistory: MedicalHistoryModel;
  isLoadingData: boolean;
  setIsLoadingData: React.Dispatch<React.SetStateAction<boolean>>;
  setMedicalHistory: React.Dispatch<React.SetStateAction<MedicalHistoryModel>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  contentTypography: {
    fontWeight: 500,
    marginRight: theme.spacing(-3)
  },
  headerText: {
    paddingBottom: theme.spacing(2)
  },
  doneIcon: {
    color: green[500]
  },
  closeIcon: {
    color: red[500]
  },
  titleGrid: {
    paddingBottom: theme.spacing(2)
  },
  buttonMargin: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2)
  },
  cursorGrid: {
    cursor: 'pointer'
  }
}));

const GridItemXs12 = (props: any) => <Grid item xs={12} {...props} />;

const Collapse = (props: any) => {
  const classes = useCollapseStyles();
  return (
    <MuiCollapse
      component={GridItemXs12}
      classes={{
        ...classes,
        hidden: classes.hidden,
        root: classes.root
      }}
      {...props}
    >
      {/* This spacing has to match with the one in the container
            outside the collapse */}
      {props.children}
    </MuiCollapse>
  );
};

const useCollapseStyles = makeStyles({
  root: {
    // width: '100%'
  },
  hidden: {
    // The grid item's padding normally balances with the negative padding
    // of the container outside the Collapse.
    // But when the collapse hides its content, the padding of the item
    // is still taking up space, creating an unwanted space.
    // The 'hidden' style rule is only applied when the collapse finishes
    // hiding its content
    padding: '0 !important'
  }
});

const CandidateMedicalHistory: FC<Props> = props => {
  const classes = useStyles();
  const { medicalHistory, setMedicalHistory } = props;
  const { currentUser } = useContext(CurrentUserContext);

  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarVarient, setSnackbarVarient] = useState<'success' | 'error'>('success');
  const [openEditMedicalHistory, setOpenEditMedicalHistory] = useState<boolean>(false);
  const [collapse, setCollapse] = useState<boolean>(true);
  const [roles, setRoles] = useState<string>('');
  const [allergies, setAllergies] = useState<string>('-');
  const [dietaryRestrictions, setDietaryrestrictions] = useState<string>('-');
  const [mentalIllness, setMentalillness] = useState<boolean>(false);
  const [tuberculosis, setTuberculosis] = useState<boolean>(false);
  const [physicalDisabilities, setPhysicaldisabilities] = useState<string>('-');
  const [heartDisease, setHeartdisease] = useState<boolean>(false);
  const [othersPastExisting, setOtherspastexisting] = useState<string>('-');
  const [othersFood, setOthersfood] = useState<string>('-');
  const [malaria, setMalaria] = useState<boolean>(false);
  const [operations, setOperations] = useState<boolean>(false);
  const [epilepsy, setEpilepsy] = useState<boolean>(false);
  const [asthma, setAsthma] = useState<boolean>(false);
  const [diabetes, setDiabetes] = useState<boolean>(false);
  const [hypertension, setHypertension] = useState<boolean>(false);
  const [pork, setPork] = useState<boolean>(false);
  const [beef, setBeef] = useState<boolean>(false);
  const [preferenceForRestDay, setPreferenceforrestday] = useState<number | string>('-');
  const [otherRemarks, setOtherRemarks] = useState<string>('-');

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleCancelMedicalHistory = () => {
    setOpenEditMedicalHistory(false);
  };

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    if (medicalHistory) {
      const {
        allergies,
        dietaryRestrictions,
        mentalIllness,
        tuberculosis,
        physicalDisabilities,
        heartDisease,
        othersPastExisting,
        othersFood,
        malaria,
        operations,
        epilepsy,
        asthma,
        diabetes,
        hypertension,
        pork,
        beef,
        preferenceForRestDay,
        remarks
      } = medicalHistory!;

      if (allergies) {
        setAllergies(allergies);
      }

      setDietaryrestrictions(dietaryRestrictions);
      setMentalillness(mentalIllness);
      setTuberculosis(tuberculosis);

      if (remarks) {
        setOtherRemarks(remarks);
      }

      if (physicalDisabilities) {
        setPhysicaldisabilities(physicalDisabilities);
      }

      setHeartdisease(heartDisease);

      if (othersPastExisting) {
        setOtherspastexisting(othersPastExisting);
      }

      setOthersfood(othersFood);
      setMalaria(malaria);
      setOperations(operations);
      setEpilepsy(epilepsy);
      setAsthma(asthma);
      setDiabetes(diabetes);
      setHypertension(hypertension);
      setPork(pork);
      setBeef(beef);

      if (preferenceForRestDay) {
        setPreferenceforrestday(preferenceForRestDay);
      }
    }

    const roles = currentUser ? getCurrentRoleName(currentUser) : '';
    setRoles(roles);
  }, [currentUser, medicalHistory]);

  return (
    <Grid>
      <Grid item xs={12} className={classes.cursorGrid} onClick={() => setCollapse(prev => !prev)}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography className={classes.headerText} variant='h4' color='primary'>
              Medical History / Dietary Restrictions
            </Typography>
          </Grid>
          <Grid item xs={6} container direction='row' justify='flex-end' alignItems='center'>
            {!collapse ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
          </Grid>
        </Grid>
      </Grid>

      <Collapse in={!collapse}>
        <Grid container spacing={2} className={classes.titleGrid}>
          <Grid item xs={6}>
            <Typography className={classes.contentTypography}>Allergies</Typography>
            <Typography className={classes.contentTypography} color='textSecondary'>
              {allergies}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.contentTypography}>Dietary restrictions</Typography>
            <Typography className={classes.contentTypography} color='textSecondary'>
              {dietaryRestrictions}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.titleGrid}>
          <Grid item xs={6}>
            <Typography className={classes.contentTypography}>Past and existing illness</Typography>
            <Grid container spacing={4}>
              <Grid item xs={3}>
                <Typography className={classes.contentTypography} color='textSecondary'>
                  Mental Illness
                </Typography>
              </Grid>
              <Grid item xs={3}>
                {mentalIllness ? <DoneIcon className={classes.doneIcon} /> : <CloseIcon className={classes.closeIcon} />}
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.contentTypography} color='textSecondary'>
                  Tuberculosis
                </Typography>
              </Grid>
              <Grid item xs={3}>
                {tuberculosis ? <DoneIcon className={classes.doneIcon} /> : <CloseIcon className={classes.closeIcon} />}
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={3}>
                <Typography className={classes.contentTypography} color='textSecondary'>
                  Epilepsy
                </Typography>
              </Grid>
              <Grid item xs={3}>
                {epilepsy ? <DoneIcon className={classes.doneIcon} /> : <CloseIcon className={classes.closeIcon} />}
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.contentTypography} color='textSecondary'>
                  Heart Disease
                </Typography>
              </Grid>
              <Grid item xs={3}>
                {heartDisease ? <DoneIcon className={classes.doneIcon} /> : <CloseIcon className={classes.closeIcon} />}
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={3}>
                <Typography className={classes.contentTypography} color='textSecondary'>
                  Asthma
                </Typography>
              </Grid>
              <Grid item xs={3}>
                {asthma ? <DoneIcon className={classes.doneIcon} /> : <CloseIcon className={classes.closeIcon} />}
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.contentTypography} color='textSecondary'>
                  Malaria
                </Typography>
              </Grid>
              <Grid item xs={3}>
                {malaria ? <DoneIcon className={classes.doneIcon} /> : <CloseIcon className={classes.closeIcon} />}
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={3}>
                <Typography className={classes.contentTypography} color='textSecondary'>
                  Diabetes
                </Typography>
              </Grid>
              <Grid item xs={3}>
                {diabetes ? <DoneIcon className={classes.doneIcon} /> : <CloseIcon className={classes.closeIcon} />}
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.contentTypography} color='textSecondary'>
                  Operations
                </Typography>
              </Grid>
              <Grid item xs={3}>
                {operations ? <DoneIcon className={classes.doneIcon} /> : <CloseIcon className={classes.closeIcon} />}
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={3}>
                <Typography className={classes.contentTypography} color='textSecondary'>
                  Hypertension
                </Typography>
              </Grid>
              <Grid item xs={3}>
                {hypertension ? <DoneIcon className={classes.doneIcon} /> : <CloseIcon className={classes.closeIcon} />}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6} className={classes.titleGrid}>
            <Typography className={classes.contentTypography}>Food handling preferences</Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography className={classes.contentTypography} color='textSecondary'>
                  Pork
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {pork ? <DoneIcon className={classes.doneIcon} /> : <CloseIcon className={classes.closeIcon} />}
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography className={classes.contentTypography} color='textSecondary'>
                  Beef
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {beef ? <DoneIcon className={classes.doneIcon} /> : <CloseIcon className={classes.closeIcon} />}
              </Grid>
            </Grid>
            <Grid container spacing={2} className={classes.titleGrid}>
              <Grid item xs={6}>
                <Typography className={classes.contentTypography} color='textSecondary'>
                  Others
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {othersFood ? othersFood : '-'}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={1} className={classes.titleGrid}>
          <Grid item xs={12}>
            <Typography className={classes.contentTypography}>Others</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.contentTypography} color='textSecondary'>
              {othersPastExisting}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1} className={classes.titleGrid}>
          <Grid item xs={6}>
            <Typography className={classes.contentTypography}>Physical disabilities</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.contentTypography} color='textSecondary'>
              {physicalDisabilities}
            </Typography>
          </Grid>
        </Grid>
        {roles !== 'partner_agency' && (
          <Grid item xs={4}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button
                  variant='contained'
                  color='primary'
                  fullWidth
                  className={classes.buttonMargin}
                  onClick={() => {
                    setOpenEditMedicalHistory(true);
                  }}
                >
                  Edit
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Collapse>
      <ActionSnackbar
        variant={snackbarVarient}
        message={snackbarVarient === 'success' ? 'Update is successful' : 'Update failed'}
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        Icon={snackbarVarient === 'success' ? CheckCircleIcon : ErrorIcon}
      />
      <MedicalHistoryModal
        open={openEditMedicalHistory}
        medicalHistory={medicalHistory}
        setMedicalHistory={setMedicalHistory}
        handleCancel={handleCancelMedicalHistory}
        setOpenSnackbar={setOpenSnackbar}
        setSnackbarVarient={setSnackbarVarient}
      />
    </Grid>
  );
};

export default CandidateMedicalHistory;
