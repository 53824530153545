import React, { Fragment, useEffect } from 'react';
import {
  Grid,
  TextField,
  Theme,
  Typography,
  withStyles,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { orange } from '@material-ui/core/colors';
import clsx from 'clsx';
import { PRIMARY_COLOR } from 'constants/colors';

interface Props {
  namesSINGEA: string[];
  setNamesSINGEA: React.Dispatch<React.SetStateAction<string[]>>;
  areasWorksSINGEA: string[];
  setAreasWorksSINGEA: React.Dispatch<React.SetStateAction<string[]>>;
  subSkillsFDWIdSINGEA: SubSkillsModel[];
  setSubSkillsFDWIdSINGEA: React.Dispatch<React.SetStateAction<SubSkillsModel[]>>;
  namesTCEA: string[];
  setNamesTCEA: React.Dispatch<React.SetStateAction<string[]>>;
  areasWorksTCEA: string[];
  setAreasWorksTCEA: React.Dispatch<React.SetStateAction<string[]>>;
  subSkillsFDWIdTCEA: SubSkillsModel[];
  setSubSkillsFDWIdTCEA: React.Dispatch<React.SetStateAction<SubSkillsModel[]>>;
  isSubmitting: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  contentGrid: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0)
  },
  cancelButton: {
    marginRight: theme.spacing(3)
  },
  controlDiv: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2)
  },
  textInterview: {
    position: 'relative',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, 30%)',
    paddingBottom: theme.spacing(3)
  },
  controlTableCellOne: {
    width: 200
  },
  controlTableCellTwo: {
    width: 200
  },
  controlTableCellThree: {
    width: 200
  },
  controlTableCellFour: {
    width: 200
  },
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto'
  },
  table: {
    minWidth: 700
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default
    }
  }
}));

const TextFieldSkill = withStyles(() => ({
  root: {
    marginBottom: '8.9px'
  }
}))(TextField);

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const SkillForm: React.FC<Props> = props => {
  const classes = useStyles(props);

  const { namesSINGEA, setNamesSINGEA } = props;
  const { areasWorksSINGEA, setAreasWorksSINGEA } = props;
  const { subSkillsFDWIdSINGEA, setSubSkillsFDWIdSINGEA } = props;

  const { namesTCEA, setNamesTCEA } = props;
  const { areasWorksTCEA, setAreasWorksTCEA } = props;
  const { subSkillsFDWIdTCEA, setSubSkillsFDWIdTCEA } = props;

  const { isSubmitting } = props;

  const includeTextfield = ['Language Abilities', 'Other Skills'];

  const handleAssesmentSINGEA = (index: number, value: string) => {
    const newSubSkill = [...subSkillsFDWIdSINGEA];
    newSubSkill[index].assessmentObservation = value;
    setSubSkillsFDWIdSINGEA(newSubSkill);
  };

  const handleTextLangOtherSINGEA = (index: number, value: string, condition: string) => {
    const newSubSkill = [...subSkillsFDWIdSINGEA];

    if (condition !== 'Other Skills') {
      newSubSkill[index].languangeAbilities = value;
    } else {
      newSubSkill[index].otherSkills = value;
    }

    setSubSkillsFDWIdSINGEA(newSubSkill);
  };

  const handleAssesmentTCEA = (index: number, value: string) => {
    const newSubSkill = [...subSkillsFDWIdTCEA];
    newSubSkill[index].assessmentObservation = value;
    setSubSkillsFDWIdTCEA(newSubSkill);
  };

  const handleTextLangOtherTCEA = (index: number, value: string, condition: string) => {
    const newSubSkill = [...subSkillsFDWIdTCEA];

    if (condition !== 'Other Skills') {
      newSubSkill[index].languangeAbilities = value;
    } else {
      newSubSkill[index].otherSkills = value;
    }

    setSubSkillsFDWIdTCEA(newSubSkill);
  };

  return (
    <form noValidate>
      <Grid className={classes.contentGrid}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box m={4} />
            <Typography variant='subtitle2'>Interview by Singapore EA</Typography>
          </Grid>
        </Grid>
        <Table>
          <TableBody>
            {namesSINGEA.map((value, index) => (
              <TableRow className={classes.row} key={index}>
                <CustomTableCell className={classes.controlTableCellOne}>
                  <Typography variant='body1' className={clsx({ [classes.textInterview]: !includeTextfield.includes(value) })}>
                    {value}
                  </Typography>
                  {includeTextfield.includes(value) && (
                    <TextField
                      variant='outlined'
                      margin='normal'
                      id={`${value}SINGEA-${index}`}
                      label={value}
                      value={value !== 'Other Skills' ? subSkillsFDWIdSINGEA[index].languangeAbilities : subSkillsFDWIdSINGEA[index].otherSkills}
                      autoComplete='off'
                      onChange={event => handleTextLangOtherSINGEA(index, event.target.value, value)}
                    />
                  )}
                </CustomTableCell>
                <CustomTableCell className={classes.controlTableCellTwo}>
                  <RadioGroup
                    row
                    value={subSkillsFDWIdSINGEA[index].willingness}
                    onChange={event =>
                      setSubSkillsFDWIdSINGEA(() => {
                        const newSubSkill = [...subSkillsFDWIdSINGEA];
                        newSubSkill[index].willingness = event.target.value === 'true' ? true : false;

                        return newSubSkill;
                      })
                    }
                  >
                    <FormControlLabel
                      value={true}
                      checked={subSkillsFDWIdSINGEA[index].willingness}
                      control={<Radio color='primary' />}
                      label='Yes'
                      labelPlacement='end'
                    />
                    <FormControlLabel
                      value={false}
                      checked={!subSkillsFDWIdSINGEA[index].willingness}
                      control={<Radio color='primary' />}
                      label='No'
                      labelPlacement='end'
                    />
                  </RadioGroup>
                </CustomTableCell>
                <CustomTableCell className={classes.controlTableCellThree}>
                  <Fragment>
                    <RadioGroup
                      value={subSkillsFDWIdSINGEA[index].experience}
                      onChange={event =>
                        setSubSkillsFDWIdSINGEA((prevState: SubSkillsModel[]) => {
                          const newSubSkill = [...subSkillsFDWIdSINGEA];
                          newSubSkill[index].experience = event.target.value === 'true' ? true : false;

                          return newSubSkill;
                        })
                      }
                      row
                    >
                      <FormControlLabel
                        value={true}
                        checked={subSkillsFDWIdSINGEA[index].experience}
                        control={<Radio color='primary' />}
                        label='Yes'
                        labelPlacement='end'
                      />
                      <FormControlLabel
                        value={false}
                        checked={!subSkillsFDWIdSINGEA[index].experience}
                        control={<Radio color='primary' />}
                        label='No'
                        labelPlacement='end'
                      />
                    </RadioGroup>
                  </Fragment>
                </CustomTableCell>
                <CustomTableCell className={classes.controlTableCellFour}>
                  <Fragment>
                    <TextFieldSkill
                      variant='outlined'
                      margin='normal'
                      multiline
                      rows={2}
                      id={`assessmentObservationSINGEA-${index}`}
                      label={`Assessment / Observation`}
                      value={subSkillsFDWIdSINGEA[index].assessmentObservation}
                      autoComplete='off'
                      onChange={event => handleAssesmentSINGEA(index, event.target.value)}
                    />
                  </Fragment>
                </CustomTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box m={4} />
            <Typography variant='subtitle2'>Interview by Training Centre EA</Typography>
          </Grid>
        </Grid>
        <Table>
          <TableBody>
            {namesTCEA.map((value, index) => (
              <TableRow className={classes.row} key={index}>
                <CustomTableCell className={classes.controlTableCellOne}>
                  <Typography variant='body1' className={clsx({ [classes.textInterview]: !includeTextfield.includes(value) })}>
                    {value}
                  </Typography>
                  {includeTextfield.includes(value) && (
                    <TextField
                      variant='outlined'
                      margin='normal'
                      id={`${value}TCEA-${index}`}
                      label={value}
                      value={value !== 'Other Skills' ? subSkillsFDWIdTCEA[index].languangeAbilities : subSkillsFDWIdTCEA[index].otherSkills}
                      autoComplete='off'
                      onChange={event => handleTextLangOtherTCEA(index, event.target.value, value)}
                    />
                  )}
                </CustomTableCell>
                <CustomTableCell className={classes.controlTableCellTwo}>
                  <RadioGroup
                    row
                    value={subSkillsFDWIdTCEA[index].willingness}
                    onChange={event =>
                      setSubSkillsFDWIdTCEA((prevState: SubSkillsModel[]) => {
                        const newSubSkill = [...subSkillsFDWIdTCEA];
                        newSubSkill[index].willingness = event.target.value === 'true' ? true : false;

                        return newSubSkill;
                      })
                    }
                  >
                    <FormControlLabel
                      value={true}
                      checked={subSkillsFDWIdTCEA[index].willingness}
                      control={<Radio color='primary' />}
                      label='Yes'
                      labelPlacement='end'
                    />
                    <FormControlLabel
                      value={false}
                      checked={!subSkillsFDWIdTCEA[index].willingness}
                      control={<Radio color='primary' />}
                      label='No'
                      labelPlacement='end'
                    />
                  </RadioGroup>
                </CustomTableCell>
                <CustomTableCell className={classes.controlTableCellThree}>
                  <Fragment>
                    <RadioGroup
                      row
                      value={subSkillsFDWIdTCEA[index].experience}
                      onChange={event =>
                        setSubSkillsFDWIdTCEA((prevState: SubSkillsModel[]) => {
                          const newSubSkill = [...subSkillsFDWIdTCEA];
                          newSubSkill[index].experience = event.target.value === 'true' ? true : false;

                          return newSubSkill;
                        })
                      }
                    >
                      <FormControlLabel
                        value={true}
                        checked={subSkillsFDWIdTCEA[index].experience}
                        control={<Radio color='primary' />}
                        label='Yes'
                        labelPlacement='end'
                      />
                      <FormControlLabel
                        value={false}
                        checked={!subSkillsFDWIdTCEA[index].experience}
                        control={<Radio color='primary' />}
                        label='No'
                        labelPlacement='end'
                      />
                    </RadioGroup>
                  </Fragment>
                </CustomTableCell>
                <CustomTableCell className={classes.controlTableCellFour}>
                  <Fragment>
                    <TextFieldSkill
                      variant='outlined'
                      margin='normal'
                      multiline
                      rows={2}
                      id={`assessmentObservationTCEA-${index}`}
                      label={`Assessment / Observation`}
                      value={subSkillsFDWIdTCEA[index].assessmentObservation}
                      autoComplete='off'
                      onChange={event => handleAssesmentTCEA(index, event.target.value)}
                    />
                  </Fragment>
                </CustomTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </form>
  );
};

export default SkillForm;
