import React, { FC, Fragment, useEffect, useState } from 'react';
import {
  TableRow,
  makeStyles,
  Theme,
  Avatar,
  Typography,
  IconButton,
  Tooltip,
  CircularProgress,
  Switch,
  FormControlLabel,
  withStyles,
  MenuItem,
  Menu,
  Modal,
  Fade,
  Backdrop
} from '@material-ui/core';
import {
  CANDIDATE_STATUS_RESERVED_COLOR,
  CANDIDATE_STATUS_CONFIRMED_COLOR,
  CANDIDATE_STATUS_AVAILABLE_COLOR,
  CANDIDATE_STATUS_TAKEN_COLOR,
  CANDIDATE_STATUS_INACTIVE_COLOR,
  CANDIDATE_STATUS_AVAILABLE_BACKROUND_COLOR,
  CANDIDATE_STATUS_CONFIRMED_BACKROUND_COLOR,
  CANDIDATE_STATUS_TAKEN_BACKROUND_COLOR,
  CANDIDATE_STATUS_INACTIVE_BACKROUND_COLOR,
  CANDIDATE_STATUS_RESERVED_BACKROUND_COLOR
} from 'constants/colors';
import { green } from '@material-ui/core/colors';
import axios from 'axios';

import { GET_DEACTIVATE_USER_URL, GET_ACTIVATE_USER_URL, GET_EXPORT_PDF_URL } from 'constants/url';
import BodyCell from 'components/BodyCell';
import Skeleton from 'react-loading-skeleton';
import { ucWords } from 'utils';
import NumberFormat from 'react-number-format';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { downloadMedia } from 'utils';
import clsx from 'clsx';
import imageLoader from 'images/imageLoader.gif';

interface Props {
  isLoadingData: boolean;
  candidate: CandidateModel;
}

const GreenSwitch = withStyles({
  switchBase: {
    '&$checked': {
      color: green[500]
    },
    '&$checked + $track': {
      backgroundColor: green[500]
    }
  },
  checked: {},
  track: {}
})(Switch);

const useStyles = makeStyles((theme: Theme) => ({
  tableRow: {
    height: 64
  },
  tableCellInner: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    display: 'inline-flex',
    fontSize: '14px',
    fontWeight: 500,
    height: '36px',
    width: '36px'
  },
  nameTextCell: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  circle: {
    height: theme.spacing(3),
    width: theme.spacing(3),
    borderRadius: '50%',
    display: 'inline-block',
    backgroundColor: green[500],
    marginRight: theme.spacing(1),
    content: "''"
  },
  wrapper: {
    position: 'relative'
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    zIndex: 1,
    top: -4,
    left: -4
  },
  newIcon: {
    color: green[500]
  },
  actionIcon: {
    fontSize: 20
  },
  menuAvatar: {
    backgroundColor: '#ffffff',
    display: 'inline-flex',
    fontSize: '14px',
    fontWeight: 500,
    height: '36px',
    width: '36px',
    color: '#707070'
  },
  menuList: {
    minHeight: 30
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  loader: {
    width: '50%',
    marginLeft: theme.spacing(-1.5)
  },
  statusCandidate: (props: Props) => {
    let color = '';
    let backgroundColor = '';

    const { candidate } = props;
    const { status } = candidate;

    switch (status) {
      case 'RESERVED':
        color = CANDIDATE_STATUS_RESERVED_COLOR;
        backgroundColor = CANDIDATE_STATUS_RESERVED_BACKROUND_COLOR;
        break;
      case 'CONFIRMED':
        color = CANDIDATE_STATUS_CONFIRMED_COLOR;
        backgroundColor = CANDIDATE_STATUS_CONFIRMED_BACKROUND_COLOR;
        break;
      case 'AVAILABLE':
        color = CANDIDATE_STATUS_AVAILABLE_COLOR;
        backgroundColor = CANDIDATE_STATUS_AVAILABLE_BACKROUND_COLOR;
        break;
      case 'TAKEN':
        color = CANDIDATE_STATUS_TAKEN_COLOR;
        backgroundColor = CANDIDATE_STATUS_TAKEN_BACKROUND_COLOR;
        break;
      case 'INACTIVE':
        color = CANDIDATE_STATUS_INACTIVE_COLOR;
        backgroundColor = CANDIDATE_STATUS_INACTIVE_BACKROUND_COLOR;
        break;
    }

    return {
      fontWeight: 500,
      fontSize: '12px',
      border: `1px solid ${color}`,
      borderRadius: theme.spacing(3),
      backgroundColor: backgroundColor,
      color: color,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    };
  }
}));

const BodyRow: FC<Props> = props => {
  const classes = useStyles(props);
  const { isLoadingData, candidate } = props;
  const { id } = candidate;

  const [isProcessing, setProcessing] = useState<boolean>(false);
  const [code, setCode] = useState<string>('-');
  const [name, setName] = useState<string>('-');
  const [age, setAge] = useState<string>('-');
  const [maritalStatus, setMaritalStatus] = useState<string>('-');
  const [experince, setExperience] = useState<string>('-');
  const [passportStatus, setPassportStatus] = useState<string>('-');
  const [bioFee, setBioFee] = useState<string>('-');
  const [loan, setLoan] = useState<string>('-');
  const [pocketMoney, setPocketMoney] = useState<string>('-');
  const [minSalary, setMinSalary] = useState<string>('-');
  const [remarks, setRemark] = useState<string>('-');
  const [experienceInSG, setExperienceInSG] = useState<string>('-');
  const [preferenceForRestDay, setPreferenceForRestDay] = useState<string>('-');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isLoadingPage, setLoadingPage] = useState<boolean>(false);

  useEffect(() => {
    if (candidate.code) {
      setCode(candidate.code);
    }

    if (candidate.name) {
      setName(candidate.name);
    }

    if (candidate.age) {
      setAge(String(candidate.age));
    }

    if (candidate.MedicalHistory.preferenceForRestDay) {
      setPreferenceForRestDay(candidate.MedicalHistory.preferenceForRestDay);
    }

    if (candidate.maritalStatus) {
      setMaritalStatus(ucWords(candidate.maritalStatus));
    }

    if (candidate.status) {
      setExperience(candidate.status);
    }

    if (candidate.passportStatus) {
      setPassportStatus(candidate.passportStatus);
    }

    if (candidate.bioFee) {
      setBioFee(String(candidate.bioFee));
    }

    if (candidate.loan) {
      setLoan(String(candidate.loan));
    }

    if (candidate.pocketMoney) {
      setPocketMoney(String(candidate.pocketMoney));
    }

    if (candidate.pocketMoney) {
      setMinSalary(String(candidate.minimumSalary));
    }

    if (candidate.remarks) {
      setRemark(candidate.remarks);
    }

    if (candidate.experienceInSG) {
      setExperienceInSG(candidate.experienceInSG);
    }
  }, [
    candidate.age,
    candidate.bioFee,
    candidate.code,
    candidate.loan,
    candidate.maritalStatus,
    candidate.minimumSalary,
    candidate.name,
    candidate.passportStatus,
    candidate.pocketMoney,
    candidate.remarks,
    candidate.status,
    candidate.experienceInSG
  ]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const actionWrapper = async (action: () => Promise<void>) => {
    setProcessing(true);

    try {
      await action();
      // setOpenSnackbar(true);
      // handleSetMessageSuccess(active ? 'Successfully deactived user' : 'Successfully actived user');
      // setSnackbarVarient('success');
    } catch (err) {
      // setOpenSnackbar(true);
      // handleSetMessageError('Failed to operation');
      // setSnackbarVarient('error');
    }

    setProcessing(false);
  };

  const handleViewClient = (event: React.MouseEvent<HTMLLIElement>) => {
    setAnchorEl(event.currentTarget);
    window.open(`/candidates-list/${candidate.id}`, '_blank');
  };

  const handleExport = async (event: React.MouseEvent<HTMLLIElement>) => {
    setAnchorEl(event.currentTarget);
    setLoadingPage(true);
    const response = await axios({
      url: GET_EXPORT_PDF_URL(candidate.id),
      method: 'GET',
      responseType: 'blob'
    });
    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
    const newwindow = window.open(url, 'name', 'height=700,width=750');
    if (newwindow && window.focus) {
      newwindow.focus();
    }

    setLoadingPage(false);
  };

  const handleDownloadVideo = async (event: React.MouseEvent<HTMLLIElement>) => {
    setAnchorEl(event.currentTarget);
    setLoadingPage(true);
    await downloadMedia(candidate.linkYoutube);

    setLoadingPage(false);
  };

  return (
    <Fragment>
    <TableRow className={classes.tableRow}>
      <BodyCell cellWidth='5%' isComponent={true}>
        {isLoadingData ? <Skeleton width={100} height={25} /> : code}
      </BodyCell>
      <BodyCell cellWidth='5%' isComponent={true}>
        {isLoadingData ? <Skeleton width={100} height={25} /> : name}
      </BodyCell>
      <BodyCell cellWidth='5%' isComponent={true}>
        {isLoadingData ? <Skeleton width={100} height={25} /> : age}
      </BodyCell>
      <BodyCell cellWidth='5%' isComponent={true}>
        {isLoadingData ? <Skeleton width={100} height={25} /> : preferenceForRestDay}
      </BodyCell>
      <BodyCell cellWidth='5%' isComponent={true}>
        {isLoadingData ? <Skeleton width={100} height={25} /> : maritalStatus}
      </BodyCell>
      <BodyCell cellWidth='20%' isComponent={true}>
        {isLoadingData ? (
          <Skeleton width={100} height={25} />
        ) : (
          <span
            className={clsx({
              [classes.statusCandidate]: experince
            })}
          >
            {candidate.status}
          </span>
        )}
      </BodyCell>
      <BodyCell cellWidth='20%' isComponent={true}>
        {isLoadingData ? <Skeleton width={100} height={25} /> : passportStatus}
      </BodyCell>
      <BodyCell cellWidth='20%' isComponent={true}>
        {isLoadingData ? <Skeleton width={100} height={25} /> : experienceInSG}
      </BodyCell>
      <BodyCell cellWidth='20%' isComponent={true}>
        {isLoadingData ? (
          <Skeleton width={100} height={25} />
        ) : (
          <NumberFormat value={bioFee} displayType={'text'} thousandSeparator={true} color='textSecondary' prefix={' $'} />
        )}
      </BodyCell>
      <BodyCell cellWidth='20%' isComponent={true}>
        {isLoadingData ? (
          <Skeleton width={100} height={25} />
        ) : (
          <NumberFormat value={loan} displayType={'text'} thousandSeparator={true} color='textSecondary' prefix={' $'} />
        )}
      </BodyCell>
      <BodyCell cellWidth='20%' isComponent={true}>
        {isLoadingData ? (
          <Skeleton width={100} height={25} />
        ) : (
          <NumberFormat value={pocketMoney} displayType={'text'} thousandSeparator={true} color='textSecondary' prefix={' $'} />
        )}
      </BodyCell>
      <BodyCell cellWidth='20%' isComponent={true}>
        {isLoadingData ? (
          <Skeleton width={100} height={25} />
        ) : (
          <NumberFormat value={minSalary} displayType={'text'} thousandSeparator={true} color='textSecondary' prefix={' $'} />
        )}
      </BodyCell>
      <BodyCell cellWidth='20%' isComponent={true}>
        {isLoadingData ? (
          <Skeleton width={100} height={25} />
        ) : (
          <NumberFormat value={remarks} displayType={'text'} thousandSeparator={true} color='textSecondary' prefix={' $'} />
        )}
      </BodyCell>
      <BodyCell cellWidth='20%' isComponent={true}>
        {isLoadingData ? (
          <Skeleton width={100} height={25} />
        ) : (
          <Fragment>
            <IconButton size='small' color='inherit' onClick={handleClick}>
              <Avatar className={classes.menuAvatar}>
                <MoreVertIcon />
              </Avatar>
            </IconButton>
            <Menu
              id='list-menu'
              anchorEl={anchorEl}
              keepMounted
              elevation={1}
              getContentAnchorEl={null}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
            >
              <MenuItem className={classes.menuList} onClick={handleViewClient}>
                View Details
              </MenuItem>
              <MenuItem className={classes.menuList} onClick={handleExport}>
                Export as PDF
              </MenuItem>
              {/* <MenuItem className={classes.menuList} onClick={handleDownloadVideo}>
                Download Video
              </MenuItem> */}
            </Menu>
          </Fragment>
        )}
      </BodyCell>

    </TableRow>
    <Modal
        aria-labelledby='spring-modal-title'
        aria-describedby='spring-modal-description'
        className={classes.modal}
        open={isLoadingPage}
        disableBackdropClick
        disableAutoFocus
        disableEnforceFocus
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={isLoadingPage}>
          <img src={imageLoader} alt='imageLoader' className={classes.loader} />
        </Fade>
      </Modal>
    </Fragment>
  );
};

export default BodyRow;
