import React from 'react';
import { Button, Grid, TextField, Theme, Typography, withStyles, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { orange } from '@material-ui/core/colors';
import { PRIMARY_COLOR } from 'constants/colors';

interface Props {
  allergies: string;
  setAllergies: React.Dispatch<React.SetStateAction<string>>;
  dietaryRestrictions: string;
  setDietaryrestrictions: React.Dispatch<React.SetStateAction<string>>;
  mentalIllness: boolean;
  setMentalIllness: React.Dispatch<React.SetStateAction<boolean>>;
  tuberculosis: boolean;
  setTuberculosis: React.Dispatch<React.SetStateAction<boolean>>;
  physicalDisabilities: string;
  setPhysicaldisabilities: React.Dispatch<React.SetStateAction<string>>;
  heartDisease: boolean;
  setHeartdisease: React.Dispatch<React.SetStateAction<boolean>>;
  othersPastExisting: string;
  setOtherspastexisting: React.Dispatch<React.SetStateAction<string>>;
  othersFood: string;
  setOthersfood: React.Dispatch<React.SetStateAction<string>>;
  malaria: boolean;
  setMalaria: React.Dispatch<React.SetStateAction<boolean>>;
  operations: boolean;
  setOperations: React.Dispatch<React.SetStateAction<boolean>>;
  epilepsy: boolean;
  setEpilepsy: React.Dispatch<React.SetStateAction<boolean>>;
  asthma: boolean;
  setAsthma: React.Dispatch<React.SetStateAction<boolean>>;
  diabetes: boolean;
  setDiabetes: React.Dispatch<React.SetStateAction<boolean>>;
  hypertension: boolean;
  setHypertension: React.Dispatch<React.SetStateAction<boolean>>;
  pork: boolean;
  setPork: React.Dispatch<React.SetStateAction<boolean>>;
  beef: boolean;
  setBeef: React.Dispatch<React.SetStateAction<boolean>>;
  preferenceForRestDay: string | null;
  setPreferenceforrestday: React.Dispatch<React.SetStateAction<string | null>>;
  remarks: string;
  setRemarks: React.Dispatch<React.SetStateAction<string>>;
  isSubmitting: boolean;
  onSubmit: React.FormEventHandler;
  onCancel: React.MouseEventHandler;
}

const useStyles = makeStyles((theme: Theme) => ({
  textPasExisting: {
    position: 'relative',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)'
  },
  contentGrid: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0)
  },
  controlDiv: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2)
  },
  cancelButton: {
    marginRight: theme.spacing(3)
  }
}));

const SaveButton = withStyles(theme => ({
  root: {
    color: '#FFFFFF',
    backgroundColor: PRIMARY_COLOR,
    '&:hover': {
      backgroundColor: orange[700]
    }
  }
}))(Button);

const MedicalHistoryForm: React.FC<Props> = props => {
  const classes = useStyles(props);

  const { allergies, setAllergies } = props;
  const { dietaryRestrictions, setDietaryrestrictions } = props;
  const { mentalIllness, setMentalIllness } = props;
  const { tuberculosis, setTuberculosis } = props;
  const { physicalDisabilities, setPhysicaldisabilities } = props;
  const { heartDisease, setHeartdisease } = props;
  const { othersPastExisting, setOtherspastexisting } = props;
  const { othersFood, setOthersfood } = props;
  const { malaria, setMalaria } = props;
  const { operations, setOperations } = props;
  const { epilepsy, setEpilepsy } = props;
  const { asthma, setAsthma } = props;
  const { diabetes, setDiabetes } = props;
  const { hypertension, setHypertension } = props;
  const { pork, setPork } = props;
  const { beef, setBeef } = props;
  const { preferenceForRestDay, setPreferenceforrestday } = props;
  const { remarks, setRemarks } = props;

  const { isSubmitting, onCancel, onSubmit } = props;

  return (
    <form noValidate onSubmit={onSubmit}>
      <Grid className={classes.contentGrid}>
        <Typography variant='h4' id='modal-title' color='primary'>
          EDIT MEDICAL HISTORY
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              variant='outlined'
              margin='normal'
              fullWidth
              id='allergies'
              label='Allergies'
              value={allergies}
              onChange={event => setAllergies(event.target.value)}
              autoComplete='off'
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant='subtitle2'>Past And Existing Illness</Typography>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={3}>
              <Typography variant='body1' className={classes.textPasExisting}>
                Mental Illness
              </Typography>
            </Grid>
            <RadioGroup
              aria-label='mentalIllness'
              name='Mental Illness'
              value={mentalIllness}
              onChange={event =>
                setMentalIllness(prev => {
                  const newMentalIllness = event.target.value === 'true' ? true : false;
                  return newMentalIllness;
                })
              }
              row
            >
              <FormControlLabel value={true} checked={mentalIllness} control={<Radio color='primary' />} label='Yes' labelPlacement='end' />
              <FormControlLabel value={false} checked={!mentalIllness} control={<Radio color='primary' />} label='No' labelPlacement='end' />
            </RadioGroup>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={3}>
              <Typography variant='body1' className={classes.textPasExisting}>
                Epilepsy
              </Typography>
            </Grid>
            <RadioGroup
              aria-label='epilepsy'
              name='Epilepsy'
              value={epilepsy}
              onChange={event =>
                setEpilepsy(prev => {
                  const newEpilepsy = event.target.value === 'true' ? true : false;
                  return newEpilepsy;
                })
              }
              row
            >
              <FormControlLabel value={true} checked={epilepsy} control={<Radio color='primary' />} label='Yes' labelPlacement='end' />
              <FormControlLabel value={false} checked={!epilepsy} control={<Radio color='primary' />} label='No' labelPlacement='end' />
            </RadioGroup>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={3}>
              <Typography variant='body1' className={classes.textPasExisting}>
              Asthma
              </Typography>
            </Grid>
            <RadioGroup
              aria-label='asthma'
              name='Asthma'
              value={asthma}
              onChange={event =>
                setAsthma(prev => {
                  const newAsthma = event.target.value === 'true' ? true : false;
                  return newAsthma;
                })
              }
              row
            >
              <FormControlLabel value={true} checked={asthma} control={<Radio color='primary' />} label='Yes' labelPlacement='end' />
              <FormControlLabel value={false} checked={!asthma} control={<Radio color='primary' />} label='No' labelPlacement='end' />
            </RadioGroup>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={3}>
              <Typography variant='body1' className={classes.textPasExisting}>
                Diabetes
              </Typography>
            </Grid>
            <RadioGroup
              aria-label='diabetes'
              name='Diabetes'
              value={diabetes}
              onChange={event =>
                setDiabetes(prev => {
                  const newDiabetes = event.target.value === 'true' ? true : false;
                  return newDiabetes;
                })
              }
              row
            >
              <FormControlLabel value={true} checked={diabetes} control={<Radio color='primary' />} label='Yes' labelPlacement='end' />
              <FormControlLabel value={false} checked={!diabetes} control={<Radio color='primary' />} label='No' labelPlacement='end' />
            </RadioGroup>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={3}>
              <Typography variant='body1' className={classes.textPasExisting}>
                Hypertension
              </Typography>
            </Grid>
            <RadioGroup
              aria-label='hypertension'
              name='Hypertension'
              value={hypertension}
              onChange={event =>
                setHypertension(prev => {
                  const newHypertension = event.target.value === 'true' ? true : false;
                  return newHypertension;
                })
              }
              row
            >
              <FormControlLabel value={true} checked={hypertension} control={<Radio color='primary' />} label='Yes' labelPlacement='end' />
              <FormControlLabel value={false} checked={!hypertension} control={<Radio color='primary' />} label='No' labelPlacement='end' />
            </RadioGroup>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={3}>
              <Typography variant='body1' className={classes.textPasExisting}>
                Tuberculosis
              </Typography>
            </Grid>
            <RadioGroup
              aria-label='tuberculosis'
              name='Tuberculosis'
              value={tuberculosis}
              onChange={event =>
                setTuberculosis(prev => {
                  const newTuberculosis = event.target.value === 'true' ? true : false;
                  return newTuberculosis;
                })
              }
              row
            >
              <FormControlLabel value={true} checked={tuberculosis} control={<Radio color='primary' />} label='Yes' labelPlacement='end' />
              <FormControlLabel value={false} checked={!tuberculosis} control={<Radio color='primary' />} label='No' labelPlacement='end' />
            </RadioGroup>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={3}>
              <Typography variant='body1' className={classes.textPasExisting}>
                Heart Disease
              </Typography>
            </Grid>
            <RadioGroup
              aria-label='heartDisease'
              name='Heart Disease'
              value={heartDisease}
              onChange={event =>
                setHeartdisease(prev => {
                  const newHeartDisease = event.target.value === 'true' ? true : false;
                  return newHeartDisease;
                })
              }
              row
            >
              <FormControlLabel value={true} checked={heartDisease} control={<Radio color='primary' />} label='Yes' labelPlacement='end' />
              <FormControlLabel value={false} checked={!heartDisease} control={<Radio color='primary' />} label='No' labelPlacement='end' />
            </RadioGroup>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={3}>
              <Typography variant='body1' className={classes.textPasExisting}>
                Malaria
              </Typography>
            </Grid>
            <RadioGroup
              aria-label='malaria'
              name='Malaria'
              value={malaria}
              onChange={event =>
                setMalaria(prev => {
                  const newMalaria = event.target.value === 'true' ? true : false;
                  return newMalaria;
                })
              }
              row
            >
              <FormControlLabel value={true} checked={malaria} control={<Radio color='primary' />} label='Yes' labelPlacement='end' />
              <FormControlLabel value={false} checked={!malaria} control={<Radio color='primary' />} label='No' labelPlacement='end' />
            </RadioGroup>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={3}>
              <Typography variant='body1' className={classes.textPasExisting}>
                Operations
              </Typography>
            </Grid>
            <RadioGroup
              aria-label='operations'
              name='operations'
              value={operations}
              onChange={event =>
                setOperations(prev => {
                  const newOperations = event.target.value === 'true' ? true : false;
                  return newOperations;
                })
              }
              row
            >
              <FormControlLabel value={true} checked={operations} control={<Radio color='primary' />} label='Yes' labelPlacement='end' />
              <FormControlLabel value={false} checked={!operations} control={<Radio color='primary' />} label='No' labelPlacement='end' />
            </RadioGroup>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              variant='outlined'
              margin='normal'
              fullWidth
              id='others'
              label='Others'
              value={othersPastExisting}
              onChange={event => setOtherspastexisting(event.target.value)}
              autoComplete='off'
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              variant='outlined'
              margin='normal'
              fullWidth
              id='physicalDisabilities'
              label='Physical Disabilties'
              value={physicalDisabilities}
              onChange={event => setPhysicaldisabilities(event.target.value)}
              autoComplete='off'
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant='outlined'
              margin='normal'
              fullWidth
              id='dietaryRestrictions'
              label='Dietary Restrictions'
              value={dietaryRestrictions}
              onChange={event => setDietaryrestrictions(event.target.value)}
              autoComplete='off'
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant='subtitle2'>Food Handling Preferences</Typography>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={3}>
              <Typography variant='body1' className={classes.textPasExisting}>
                Pork
              </Typography>
            </Grid>
            <RadioGroup
              aria-label='pork'
              name='pork'
              value={pork}
              onChange={event =>
                setPork(prev => {
                  const newPork = event.target.value === 'true' ? true : false;
                  return newPork;
                })
              }
              row
            >
              <FormControlLabel value={true} checked={pork} control={<Radio color='primary' />} label='Yes' labelPlacement='end' />
              <FormControlLabel value={false} checked={!pork} control={<Radio color='primary' />} label='No' labelPlacement='end' />
            </RadioGroup>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={3}>
              <Typography variant='body1' className={classes.textPasExisting}>
                Beef
              </Typography>
            </Grid>
            <RadioGroup
              aria-label='beef'
              name='beef'
              value={beef}
              onChange={event =>
                setBeef(prev => {
                  const newBeef = event.target.value === 'true' ? true : false;
                  return newBeef;
                })
              }
              row
            >
              <FormControlLabel value={true} checked={beef} control={<Radio color='primary' />} label='Yes' labelPlacement='end' />
              <FormControlLabel value={false} checked={!beef} control={<Radio color='primary' />} label='No' labelPlacement='end' />
            </RadioGroup>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              variant='outlined'
              margin='normal'
              fullWidth
              id='others'
              label='Others'
              value={othersFood}
              onChange={event => setOthersfood(event.target.value)}
              autoComplete='off'
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              variant='outlined'
              margin='normal'
              fullWidth
              id='preferenceForRestDay'
              label='Preference for Rest Day per month'
              value={preferenceForRestDay}
              onChange={event => setPreferenceforrestday(event.target.value)}
              autoComplete='off'
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              variant='outlined'
              margin='normal'
              fullWidth
              id='remarks'
              label='Remark'
              value={remarks}
              onChange={event => setRemarks(event.target.value)}
              autoComplete='off'
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container item justify='center' xs={12} sm={12} md={12} lg={12} xl={12} className={classes.controlDiv}>
        <Button variant='contained' className={classes.cancelButton} disabled={isSubmitting} onClick={onCancel}>
          Cancel
        </Button>
        <SaveButton type='submit' variant='contained' disabled={isSubmitting}>
          Save
        </SaveButton>
      </Grid>
    </form>
  );
};

export default MedicalHistoryForm;
