import React, { FC, Fragment, ComponentType } from 'react';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import { ListItem, ListItemIcon, ListItemText, Typography, Tooltip, Theme, Badge, withStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import List from '@material-ui/core/List';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import { BLACK_3, PRIMARY_COLOR } from 'constants/colors';
import IconMdi from '@mdi/react';
import useRouter from 'hooks/useRouter';

interface Props {
  Icon?: ComponentType<SvgIconProps>;
  path: string;
  iconMdi?: string;
  label: string;
  child?: boolean;
  childData?: ChildDrawerMenuModel[];
  useNotification?: boolean;
  totalNotification?: number;
  handleDrawerClose(): void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  colorDrawer: {
    fontSize: '20px'
  },
  textDrawer: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '19px',
    color: BLACK_3
  },
  nested: {
    paddingLeft: theme.spacing(8)
  }
}));

const MuiListItem = withStyles({
  root: {
    '&$selected': {
      backgroundColor: PRIMARY_COLOR,
      color: 'white',
      '& .MuiListItemIcon-root': {
        color: 'white'
      }
    },
    '&$selected:hover': {
      backgroundColor: 'purple',
      color: 'white',
      '& .MuiListItemIcon-root': {
        color: 'white'
      }
    },
    '&:hover': {
      backgroundColor: PRIMARY_COLOR,
      color: 'white',
      '& .MuiListItemIcon-root': {
        color: 'white'
      },
      '& .MuiTypography-root': {
        color: 'white'
      }
    }
  },
  selected: {}
})(ListItem);

const DrawerItem: FC<Props> = props => {
  const { history } = useRouter();
  const classes = useStyles();

  const { Icon, path, label, iconMdi, child, childData, handleDrawerClose, useNotification, totalNotification } = props;

  const [open, setOpen] = React.useState(false);

  const onClickHandler = (pathChild?: string): React.MouseEventHandler => async () => {
    if (path === '#') {
      setOpen(!open);
      if (pathChild) {
        setOpen(true);
        history.push(pathChild);
        handleDrawerClose();
      }
    } else {
      history.push(path);
      handleDrawerClose();
    }
  };

  return (
    <List component='nav'>
      <MuiListItem button onClick={onClickHandler()}>
        <ListItemIcon>
          {Icon ? (
            <Fragment>
              <Badge invisible={useNotification} badgeContent={totalNotification} color='error'>
                <Icon className={classes.colorDrawer} />
              </Badge>
            </Fragment>
          ) : (
            <Fragment>
              <IconMdi path={iconMdi ? iconMdi : ''} size={1} />
            </Fragment>
          )}
        </ListItemIcon>
        <Typography className={classes.textDrawer}>{label}</Typography>
        {child && (open ? <ExpandLess color='action' /> : <ExpandMore color='action' />)}
      </MuiListItem>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          {childData &&
            childData.map((value, index) => (
              <MuiListItem key={index} button className={classes.nested} onClick={onClickHandler(value.path)}>
                <Typography className={classes.textDrawer}>{value.name}</Typography>
              </MuiListItem>
            ))}
        </List>
      </Collapse>
    </List>
  );
};

export default DrawerItem;
