import React, { FC, useContext, useEffect, useState } from 'react';
import { Button, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { dummySkillFDW } from 'utils/dummy';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import { green, red } from '@material-ui/core/colors';
import ActionSnackbar from 'components/ActionSnackbar';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import EditSkillModal from './components/EditSkillModal';
import MuiCollapse from '@material-ui/core/Collapse';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import { getCurrentRoleName } from 'selectors';

interface Props {
  candidateId: number;
  setCandidateSkillsFDW: React.Dispatch<React.SetStateAction<SkillsFDWModel[]>>;
  candidateSkillsFDW: SkillsFDWModel[];
  isLoadingData: boolean;
  setIsLoadingData: React.Dispatch<React.SetStateAction<boolean>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  defaultText: {
    fontWeight: 500,
    marginRight: theme.spacing(-3)
  },
  contentSkill: {
    fontWeight: 500,
    marginRight: theme.spacing(-3),
    marginLeft: theme.spacing(4)
  },
  contentSkillAssObser: {
    fontWeight: 500,
    marginRight: theme.spacing(-3),
    marginLeft: theme.spacing(4),
    whiteSpace: 'pre-line'
  },
  headerText: {
    paddingBottom: theme.spacing(2)
  },
  titleGrid: {
    paddingBottom: theme.spacing(2)
  },
  fiberIcon: {
    marginTop: theme.spacing(0.4)
  },
  doneIcon: {
    color: green[500]
  },
  closeIcon: {
    color: red[500]
  },
  buttonMargin: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2)
  },
  cursorGrid: {
    cursor: 'pointer'
  },
  noneText: {
    fontSize: '1.5rem',
    marginLeft: theme.spacing(5)
  }
}));

const GridItemXs12 = (props: any) => <Grid item xs={12} {...props} />;

const Collapse = (props: any) => {
  const classes = useCollapseStyles();
  return (
    <MuiCollapse
      component={GridItemXs12}
      classes={{
        ...classes,
        hidden: classes.hidden,
        root: classes.root
      }}
      {...props}
    >
      {/* This spacing has to match with the one in the container
            outside the collapse */}
      {props.children}
    </MuiCollapse>
  );
};

const useCollapseStyles = makeStyles({
  root: {
    // width: '100%'
  },
  hidden: {
    // The grid item's padding normally balances with the negative padding
    // of the container outside the Collapse.
    // But when the collapse hides its content, the padding of the item
    // is still taking up space, creating an unwanted space.
    // The 'hidden' style rule is only applied when the collapse finishes
    // hiding its content
    padding: '0 !important'
  }
});

const CandidateSkill: FC<Props> = props => {
  const classes = useStyles();
  const { currentUser } = useContext(CurrentUserContext);
  const { candidateId, candidateSkillsFDW, setCandidateSkillsFDW } = props;

  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarVarient, setSnackbarVarient] = useState<'success' | 'error'>('success');
  const [openEditSkillModal, setOpenEditSkillModal] = useState<boolean>(false);
  const [singea, setSingea] = useState<SkillsFDWModel[]>([dummySkillFDW]);
  const [tcea, setTcea] = useState<SkillsFDWModel[]>([dummySkillFDW]);
  const [collapse, setCollapse] = useState<boolean>(true);
  const [roles, setRoles] = useState<string>('');

  const includeTextfield = ['Language Abilities', 'Other Skills'];

  useEffect(() => {
    const singeaData = candidateSkillsFDW && candidateSkillsFDW!.filter(value => value.areasWork === 'SINGEA');
    const tceaData = candidateSkillsFDW && candidateSkillsFDW!.filter(value => value.areasWork === 'TCEA');

    if (singeaData.length) {
      setSingea(singeaData);
    }

    if (tceaData.length) {
      setTcea(tceaData);
    }
  }, [candidateSkillsFDW]);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleCancelSkill = () => {
    setOpenEditSkillModal(false);
  };

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    const roles = currentUser ? getCurrentRoleName(currentUser) : '';
    setRoles(roles);
  }, [currentUser]);

  return (
    <Grid>
      <Grid item xs={12} className={classes.cursorGrid} onClick={() => setCollapse(prev => !prev)}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography className={classes.headerText} variant='h4' color='primary'>
              Skills of FDW
            </Typography>
          </Grid>
          <Grid item xs={6} container direction='row' justify='flex-end' alignItems='center'>
            {!collapse ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
          </Grid>
        </Grid>
      </Grid>

      <Collapse in={!collapse}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Grid item xs className={classes.titleGrid}>
              <Grid item xs={6}>
                <Typography className={classes.defaultText} color='primary'>
                  Areas of work (interview by Singapore EA)
                </Typography>
              </Grid>
            </Grid>
            {singea.map((skill, index) => (
              <Grid key={index}>
                <Grid container spacing={2} className={classes.titleGrid}>
                  <Grid item>
                    <FiberManualRecordIcon fontSize='inherit' className={classes.fiberIcon} />
                  </Grid>
                  <Grid item xs={5}>
                    <Typography className={classes.defaultText}>
                      {includeTextfield.includes(skill.name)
                        ? `${skill.name} (Specify: ${
                            skill.name === 'Language Abilities' ? skill.SubSkillFDW.languangeAbilities : skill.SubSkillFDW.otherSkills
                          })`
                        : skill.name}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography className={classes.contentSkill} color='textSecondary'>
                      Willingness
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className={classes.contentSkill} color='textSecondary'>
                      {skill.SubSkillFDW.willingness ? <DoneIcon className={classes.doneIcon} /> : <CloseIcon className={classes.closeIcon} />}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography className={classes.contentSkill} color='textSecondary'>
                      Experience
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className={classes.contentSkill} color='textSecondary'>
                      {skill.SubSkillFDW.experience ? <DoneIcon className={classes.doneIcon} /> : <CloseIcon className={classes.closeIcon} />}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography className={classes.contentSkill} color='textSecondary'>
                      Assessment / Observation
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    {skill.SubSkillFDW.assessmentObservation ? (
                      <Typography className={classes.contentSkillAssObser} color='textSecondary'>
                        {skill.SubSkillFDW.assessmentObservation}
                      </Typography>
                    ) : (
                      <div className={classes.noneText}>-</div>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid item xs={6}>
            <Grid item xs className={classes.titleGrid}>
              <Grid item xs={12}>
                <Typography className={classes.defaultText} color='primary'>
                  Areas of work (interview by Training Centre EA)
                </Typography>
              </Grid>
            </Grid>
            {tcea.map((skill, index) => (
              <Grid key={index}>
                <Grid container spacing={2} className={classes.titleGrid}>
                  <Grid item>
                    <FiberManualRecordIcon fontSize='inherit' className={classes.fiberIcon} />
                  </Grid>
                  <Grid item xs={5}>
                    <Typography className={classes.defaultText}>
                      {includeTextfield.includes(skill.name)
                        ? `${skill.name} (Specify: ${
                            skill.name === 'Language Abilities' ? skill.SubSkillFDW.languangeAbilities : skill.SubSkillFDW.otherSkills
                          })`
                        : skill.name}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography className={classes.contentSkill} color='textSecondary'>
                      Willingness
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className={classes.contentSkill} color='textSecondary'>
                      {skill.SubSkillFDW.willingness ? <DoneIcon className={classes.doneIcon} /> : <CloseIcon className={classes.closeIcon} />}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography className={classes.contentSkill} color='textSecondary'>
                      Experience
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className={classes.contentSkill} color='textSecondary'>
                      {skill.SubSkillFDW.experience ? <DoneIcon className={classes.doneIcon} /> : <CloseIcon className={classes.closeIcon} />}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography className={classes.contentSkill} color='textSecondary'>
                      Assessment / Observation
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    {skill.SubSkillFDW.assessmentObservation ? (
                      <Typography className={classes.contentSkillAssObser} color='textSecondary'>
                        {skill.SubSkillFDW.assessmentObservation}
                      </Typography>
                    ) : (
                      <div className={classes.noneText}>-</div>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
        {roles !== 'partner_agency' && (
          <Grid item xs={4}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button
                  variant='contained'
                  color='primary'
                  fullWidth
                  className={classes.buttonMargin}
                  onClick={() => {
                    setOpenEditSkillModal(true);
                  }}
                >
                  Edit
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Collapse>
      <ActionSnackbar
        variant={snackbarVarient}
        message={snackbarVarient === 'success' ? 'Update is successful' : 'Update failed'}
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        Icon={snackbarVarient === 'success' ? CheckCircleIcon : ErrorIcon}
      />
      <EditSkillModal
        open={openEditSkillModal}
        candidateId={candidateId}
        candidateSkillsFDW={candidateSkillsFDW}
        setCandidateSkillsFDW={setCandidateSkillsFDW}
        handleCancel={handleCancelSkill}
        setOpenSnackbar={setOpenSnackbar}
        setSnackbarVarient={setSnackbarVarient}
      />
    </Grid>
  );
};

export default CandidateSkill;
