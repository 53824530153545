import {
  Box,
  Button,
  Checkbox,
  Fab,
  FormControlLabel,
  Grid,
  Link,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Theme,
  Typography,
  withStyles
} from '@material-ui/core';
import { NumberFormatCustom } from 'components';
import { orange } from '@material-ui/core/colors';
import { PRIMARY_COLOR } from 'constants/colors';
import React, { useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Autocomplete } from '@material-ui/lab';
import numberToWords from 'number-to-words';
import clsx from 'clsx';

interface Props {
  candidates: CandidateModel[];
  candidate?: CandidateModel;
  setCandidate?: React.Dispatch<React.SetStateAction<CandidateModel>>;
  setOpenAddSignatureModal: React.Dispatch<React.SetStateAction<boolean>>;
  candidateId: number;
  setCandidateId: React.Dispatch<React.SetStateAction<number>>;
  code: string;
  setCode: React.Dispatch<React.SetStateAction<string>>;
  codeError: string;
  nameOfEmployer: string;
  setNameOfEmployer: React.Dispatch<React.SetStateAction<string>>;
  nameOfEmployerError: string;
  nameofFDW: string;
  setNameofFDW: React.Dispatch<React.SetStateAction<string>>;
  nameofFDWError: string;
  race: string;
  setRace: React.Dispatch<React.SetStateAction<string>>;
  raceError: string;
  salary: number | null;
  setSalary: React.Dispatch<React.SetStateAction<number | null>>;
  salaryError: string;
  daysOff: string;
  setDaysOff: React.Dispatch<React.SetStateAction<string>>;
  daysOffError: string;
  probation: string;
  setProbation: React.Dispatch<React.SetStateAction<string>>;
  probationError: string;
  typeOfHouse: string;
  setTypeOfHouse: React.Dispatch<React.SetStateAction<string>>;
  typeOfHouseError: string;
  numberOfBedroom: number | null;
  setNumberOfBedroom: React.Dispatch<React.SetStateAction<number | null>>;
  numberOfToilet: number | null;
  setNumberOfToilet: React.Dispatch<React.SetStateAction<number | null>>;
  numberOfFamilyMembers: number | null;
  setNumberOfFamilyMembers: React.Dispatch<React.SetStateAction<number | null>>;
  numberOfBabies: number | null;
  setNumberOfBabies: React.Dispatch<React.SetStateAction<number | null>>;
  ageOfFirstChildBelowTwelve: string;
  setAgeOfFirstChildBelowTwelve: React.Dispatch<React.SetStateAction<string>>;
  ageOfFirstChildBelowFive: string;
  setAgeOfFirstChildBelowFive: React.Dispatch<React.SetStateAction<string>>;
  fetchChildren: boolean;
  setFetchChildren: React.Dispatch<React.SetStateAction<boolean>>;
  waitDownstairs: boolean;
  setWaitDownstairs: React.Dispatch<React.SetStateAction<boolean>>;
  busOnly: boolean;
  setBusOnly: React.Dispatch<React.SetStateAction<boolean>>;
  lookAfterElderly: boolean;
  setLookAfterElderly: React.Dispatch<React.SetStateAction<boolean>>;
  healthy: boolean;
  setHealthy: React.Dispatch<React.SetStateAction<boolean>>;
  assistanceNeeded: boolean;
  setAssistanceNeeded: React.Dispatch<React.SetStateAction<boolean>>;
  bedriddenPatient: boolean;
  setBedriddenPatient: React.Dispatch<React.SetStateAction<boolean>>;
  generalHouseWork: boolean;
  setGeneralHouseWork: React.Dispatch<React.SetStateAction<boolean>>;
  ironingOfClothes: boolean;
  setIroningOfClothes: React.Dispatch<React.SetStateAction<boolean>>;
  cooking: boolean;
  setCooking: React.Dispatch<React.SetStateAction<boolean>>;
  clothesWashingBy: ExtendFieldDocumentModel;
  setClothesWashingBy: React.Dispatch<React.SetStateAction<ExtendFieldDocumentModel>>;
  carWashing: boolean;
  setCarWashing: React.Dispatch<React.SetStateAction<boolean>>;
  takeCareOfPet: ExtendFieldDocumentModel;
  setTakeCareOfPet: React.Dispatch<React.SetStateAction<ExtendFieldDocumentModel>>;
  gardening: ExtendFieldDocumentModel;
  setGardening: React.Dispatch<React.SetStateAction<ExtendFieldDocumentModel>>;
  remarks: string;
  setRemarks: React.Dispatch<React.SetStateAction<string>>;
  signature: string;
  setSignature: React.Dispatch<React.SetStateAction<string>>;
  signatureError: string;
  helperSignature: string;
  setHelperSignature: React.Dispatch<React.SetStateAction<string>>;
  helperSignatureError: string;
  employerSignature: string;
  setEmployerSignature: React.Dispatch<React.SetStateAction<string>>;
  employerSignatureError: string;
  setQueryString: React.Dispatch<React.SetStateAction<string>>;
  isSubmitting: boolean;
  onSubmit: React.FormEventHandler;
  onCancel: React.MouseEventHandler;
}

const useStyles = makeStyles((theme: Theme) => ({
  defaultText: {
    fontWeight: 500,
    marginRight: theme.spacing(-5)
  },
  checkBoxIcon: {
    fontSize: '16px'
  },
  textVerticalCenter: {
    position: 'relative',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)'
  },
  dottedBorderBox: {
    width: '246px',
    height: '115px',
    border: '1px dashed grey',
    borderRadius: 10
  },
  dottedBorderBoxError: {
    width: '246px',
    height: '115px',
    border: '1px dashed red',
    borderRadius: 10
  },
  controlDiv: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2)
  },
  cancelButton: {
    marginRight: theme.spacing(3)
  },
  marginAddNew: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  signatureImage: {
    width: '246px'
  },
  textAddNew: {
    position: 'relative',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -30%)'
  },
  typoCursorLink: {
    cursor: 'pointer'
  },
}));

const SaveButton = withStyles(theme => ({
  root: {
    color: '#FFFFFF',
    backgroundColor: PRIMARY_COLOR,
    '&:hover': {
      backgroundColor: orange[700]
    }
  }
}))(Button);

const DocumentForm: React.FC<Props> = props => {
  const classes = useStyles();

  const { candidateId, setCandidateId } = props;
  const { nameOfEmployer, setNameOfEmployer } = props;
  const { nameofFDW, setNameofFDW } = props;
  const { race, setRace } = props;
  const { salary, setSalary } = props;
  const { daysOff, setDaysOff } = props;
  const { probation, setProbation } = props;
  const { typeOfHouse, setTypeOfHouse } = props;
  const { numberOfBedroom, setNumberOfBedroom } = props;
  const { numberOfToilet, setNumberOfToilet } = props;
  const { numberOfFamilyMembers, setNumberOfFamilyMembers } = props;
  const { numberOfBabies, setNumberOfBabies } = props;
  const { ageOfFirstChildBelowTwelve, setAgeOfFirstChildBelowTwelve } = props;
  const { ageOfFirstChildBelowFive, setAgeOfFirstChildBelowFive } = props;
  const { fetchChildren, setFetchChildren } = props;
  const { waitDownstairs, setWaitDownstairs } = props;
  const { busOnly, setBusOnly } = props;
  const { lookAfterElderly, setLookAfterElderly } = props;
  const { healthy, setHealthy } = props;
  const { assistanceNeeded, setAssistanceNeeded } = props;
  const { bedriddenPatient, setBedriddenPatient } = props;
  const { generalHouseWork, setGeneralHouseWork } = props;
  const { ironingOfClothes, setIroningOfClothes } = props;
  const { cooking, setCooking } = props;
  const { clothesWashingBy, setClothesWashingBy } = props;
  const { carWashing, setCarWashing } = props;
  const { takeCareOfPet, setTakeCareOfPet } = props;
  const { gardening, setGardening } = props;
  const { remarks, setRemarks } = props;
  const { signature, setSignature } = props;
  const { helperSignature, setHelperSignature } = props;
  const { employerSignature, setEmployerSignature } = props;
  const { code, setCode } = props;
  const { signatureError } = props;
  const {
    codeError,
    nameOfEmployerError,
    helperSignatureError,
    employerSignatureError,
    nameofFDWError,
    raceError,
    salaryError,
    daysOffError,
    probationError,
    typeOfHouseError
  } = props;
  const { candidate, setCandidate } = props;
  const { setOpenAddSignatureModal, candidates, setQueryString, isSubmitting, onCancel, onSubmit } = props;

  const [clothesWashingByTemp, setClothesWashingByTemp] = useState<string>('hand');
  const [takeCareOfPetTemp, setTakeCareOfPetTemp] = useState<string>('hand');
  const [gardeningTemp, setGardeningTemp] = useState<string>('hand');

  const handleSearchCandidate = (event: any) => {
    setQueryString(event.target.value);
  };

  const handleChangeClothesWashingBy = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newClothesWashingBy: ExtendFieldDocumentModel = clothesWashingBy;

    newClothesWashingBy.name = 'Clothes Washing by';
    setClothesWashingByTemp(event.target.value);

    switch (event.target.value) {
      case 'hand':
        newClothesWashingBy.hand = true;
        newClothesWashingBy.machine = false;
        newClothesWashingBy.both = false;
        setClothesWashingBy({ ...newClothesWashingBy });

        return;
      case 'machine':
        newClothesWashingBy.hand = false;
        newClothesWashingBy.machine = true;
        newClothesWashingBy.both = false;
        setClothesWashingBy({ ...newClothesWashingBy });

        return;
      case 'both':
        newClothesWashingBy.hand = true;
        newClothesWashingBy.machine = true;
        newClothesWashingBy.both = true;
        setClothesWashingBy({ ...newClothesWashingBy });

        return;
    }
  };

  const handleChangeTakeCareOfPet = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newTakeCareOfPet: ExtendFieldDocumentModel = takeCareOfPet;

    newTakeCareOfPet.name = 'Take Care of Pet(s)';
    setTakeCareOfPetTemp(event.target.value);

    switch (event.target.value) {
      case 'hand':
        newTakeCareOfPet.hand = true;
        newTakeCareOfPet.machine = false;
        newTakeCareOfPet.both = false;

        setTakeCareOfPet({ ...newTakeCareOfPet });

        return;
      case 'machine':
        newTakeCareOfPet.hand = false;
        newTakeCareOfPet.machine = true;
        newTakeCareOfPet.both = false;

        setTakeCareOfPet({ ...newTakeCareOfPet });

        return;
      case 'both':
        newTakeCareOfPet.hand = true;
        newTakeCareOfPet.machine = true;
        newTakeCareOfPet.both = true;

        setTakeCareOfPet({ ...newTakeCareOfPet });

        return;
    }
  };

  const handleChangeGardening = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newGardening: ExtendFieldDocumentModel = takeCareOfPet;

    newGardening.name = 'Gardening';
    setGardeningTemp(event.target.value);

    switch (event.target.value) {
      case 'hand':
        newGardening.hand = true;
        newGardening.machine = false;
        newGardening.both = false;

        setGardening({ ...newGardening });

        return;
      case 'machine':
        newGardening.hand = false;
        newGardening.machine = true;
        newGardening.both = false;

        setGardening({ ...newGardening });

        return;
      case 'both':
        newGardening.hand = true;
        newGardening.machine = true;
        newGardening.both = true;
        setGardening({ ...newGardening });

        return;
    }
  };

  const icon = <CheckBoxOutlineBlankIcon className={classes.checkBoxIcon} />;
  const checkedIcon = <CheckBoxIcon className={classes.checkBoxIcon} />;

  const handleCandidate = (candidate: CandidateModel) => {
    if (candidate) {
      const { id, code, name } = candidate;

      setCode(code);
      setCandidateId(id);
      setNameofFDW(name);
    }
  };

  return (
    <form noValidate onSubmit={onSubmit}>
      <Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Autocomplete
              freeSolo
              id='codeCandidate'
              options={[...candidates]}
              value={candidate}
              autoHighlight
              getOptionLabel={option => option.code}
              onChange={(event: any, value: any) => handleCandidate(value)}
              renderOption={option => <React.Fragment>{option.code}</React.Fragment>}
              renderInput={params => (
                <TextField
                  {...params}
                  variant='outlined'
                  margin='normal'
                  required
                  fullWidth
                  label='Helper Code'
                  error={codeError !== ''}
                  helperText={codeError}
                  onChange={(event: any) => handleSearchCandidate(event)}
                  placeholder='Helper Code'
                  inputProps={{
                    ...params.inputProps
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='nameOfEmployer'
              label='Name of Employer'
              error={nameOfEmployerError !== ''}
              helperText={nameOfEmployerError}
              value={nameOfEmployer}
              onChange={event => setNameOfEmployer(event.target.value)}
              autoComplete='off'
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              disabled
              id='nameofFDW'
              label='Name of FDW'
              error={nameofFDWError !== ''}
              helperText={nameofFDWError}
              value={nameofFDW}
              onChange={event => setNameofFDW(event.target.value)}
              autoComplete='off'
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              select
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='race'
              label='Employer’s Race'
              error={raceError !== ''}
              helperText={raceError}
              value={race}
              onChange={event => setRace(event.target.value)}
              autoComplete='off'
            >
              <MenuItem value='CHINESE'>Chinese</MenuItem>
              <MenuItem value='INDIAN'>Indian</MenuItem>
              <MenuItem value='Malay'>Malay</MenuItem>
              <MenuItem value='OTHER'>Other</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='salary'
              label='Salary'
              value={salary}
              onChange={event => setSalary(event.target.value ? +event.target.value : null)}
              error={salaryError !== ''}
              helperText={salaryError}
              autoComplete='off'
              InputProps={{
                inputComponent: NumberFormatCustom as any,
                inputProps: {
                  thousandSeparator: true,
                  prefix: '$'
                }
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='daysOff'
              label='Day(s) Off'
              error={daysOffError !== ''}
              helperText={daysOffError}
              value={daysOff}
              onChange={event => setDaysOff(event.target.value)}
              autoComplete='off'
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='probation'
              label='Probation'
              error={probationError !== ''}
              helperText={probationError}
              value={probation}
              onChange={event => setProbation(event.target.value)}
              autoComplete='off'
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='typeOfHouse'
              label='Type of House'
              error={typeOfHouseError !== ''}
              helperText={typeOfHouseError}
              value={typeOfHouse}
              onChange={event => setTypeOfHouse(event.target.value)}
              autoComplete='off'
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant='outlined'
              margin='normal'
              fullWidth
              id='numberOfBedroom'
              label='Number of Bedroom'
              type='number'
              // error={titleError !== ''}
              // helperText={titleError}
              value={numberOfBedroom}
              onChange={event => setNumberOfBedroom(event.target.value ? +event.target.value : null)}
              autoComplete='off'
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant='outlined'
              margin='normal'
              fullWidth
              id='numberOfToilet'
              label='Number of Toilet'
              type='number'
              // error={titleError !== ''}
              // helperText={titleError}
              value={numberOfToilet}
              onChange={event => setNumberOfToilet(event.target.value ? +event.target.value : null)}
              autoComplete='off'
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant='outlined'
              margin='normal'
              fullWidth
              id='numberOfFamilyMembers'
              label='Number of Family Members'
              type='number'
              // error={titleError !== ''}
              // helperText={titleError}
              value={numberOfFamilyMembers}
              onChange={event => setNumberOfFamilyMembers(event.target.value ? +event.target.value : null)}
              autoComplete='off'
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant='outlined'
              margin='normal'
              fullWidth
              id='numberOfBabies'
              label='Number of Babies'
              type='number'
              // error={titleError !== ''}
              // helperText={titleError}
              value={numberOfBabies}
              onChange={event => setNumberOfBabies(event.target.value ? +event.target.value : null)}
              autoComplete='off'
            />
          </Grid>
          <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    id={`ageOfFirstChildBelowTwelve`}
                    label='Children Below 12'
                    value={ageOfFirstChildBelowTwelve}
                    onChange={event => setAgeOfFirstChildBelowTwelve(event.target.value)}
                    autoComplete='off'
                  />
                </Grid>
                <Grid item xs={6}>
                <TextField
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    id={`ageOfFirstChildBelowFive`}
                    label='Children Below 5'
                    value={ageOfFirstChildBelowFive}
                    onChange={event => setAgeOfFirstChildBelowFive(event.target.value)}
                    autoComplete='off'
                  />
                </Grid>
              </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography className={classes.textVerticalCenter}>Fetch Children To & Back From School</Typography>
              </Grid>
              <Grid item xs={6}>
                <RadioGroup
                  aria-label='fetchChildren'
                  name='Fetch Children'
                  value={fetchChildren}
                  onChange={event =>
                    setFetchChildren(() => {
                      const newfectChildren = event.target.value === 'true' ? true : false;
                      return newfectChildren;
                    })
                  }
                  row
                >
                  <FormControlLabel value={true} control={<Radio color='primary' />} label='Yes' labelPlacement='end' />
                  <FormControlLabel value={false} control={<Radio color='primary' />} label='No' labelPlacement='end' />
                </RadioGroup>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  icon={icon}
                  id='waitDownstairs'
                  onClick={() => setWaitDownstairs(waitDownstairs ? false : true)}
                  checked={waitDownstairs}
                  checkedIcon={checkedIcon}
                  color='primary'
                />
              }
              label='Wait Downstairs'
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  icon={icon}
                  id='busOnly'
                  onClick={() => setBusOnly(busOnly ? false : true)}
                  checked={busOnly}
                  checkedIcon={checkedIcon}
                  color='primary'
                />
              }
              label='Bus Only'
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography className={classes.textVerticalCenter}>Look After Elderly</Typography>
              </Grid>
              <Grid item xs={6}>
                <RadioGroup
                  aria-label='lookAfterElderly'
                  name='Look After Elderly'
                  value={lookAfterElderly}
                  onChange={event =>
                    setLookAfterElderly(prev => {
                      const newLookAfterElderly = event.target.value === 'true' ? true : false;
                      return newLookAfterElderly;
                    })
                  }
                  row
                >
                  <FormControlLabel value={true} control={<Radio color='primary' />} label='Yes' labelPlacement='end' />
                  <FormControlLabel value={false} control={<Radio color='primary' />} label='No' labelPlacement='end' />
                </RadioGroup>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  icon={icon}
                  id='healthy'
                  onClick={() => setHealthy(healthy ? false : true)}
                  checked={healthy}
                  checkedIcon={checkedIcon}
                  color='primary'
                />
              }
              label='Healthy'
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  icon={icon}
                  id='assitanceNeeded'
                  onClick={() => setAssistanceNeeded(assistanceNeeded ? false : true)}
                  checked={assistanceNeeded}
                  checkedIcon={checkedIcon}
                  color='primary'
                />
              }
              label='Assitance Needed'
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography className={classes.textVerticalCenter}>Bedridden Patient</Typography>
              </Grid>
              <Grid item xs={6}>
                <RadioGroup
                  aria-label='bedriddenPatient'
                  name='Bedridden Patient'
                  value={bedriddenPatient}
                  onChange={event =>
                    setBedriddenPatient(() => {
                      const newBedriddenPatient = event.target.value === 'true' ? true : false;
                      return newBedriddenPatient;
                    })
                  }
                  row
                >
                  <FormControlLabel value={true} control={<Radio color='primary' />} label='Yes' labelPlacement='end' />
                  <FormControlLabel value={false} control={<Radio color='primary' />} label='No' labelPlacement='end' />
                </RadioGroup>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography className={classes.textVerticalCenter}>General House Work</Typography>
              </Grid>
              <Grid item xs={6}>
                <RadioGroup
                  aria-label='generalHouseWork'
                  name='General House Work'
                  value={generalHouseWork}
                  onChange={event =>
                    setGeneralHouseWork(() => {
                      const newGeneralHouseWork = event.target.value === 'true' ? true : false;
                      return newGeneralHouseWork;
                    })
                  }
                  row
                >
                  <FormControlLabel value={true} control={<Radio color='primary' />} label='Yes' labelPlacement='end' />
                  <FormControlLabel value={false} control={<Radio color='primary' />} label='No' labelPlacement='end' />
                </RadioGroup>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography className={classes.textVerticalCenter}>Ironing of Clothes</Typography>
              </Grid>
              <Grid item xs={6}>
                <RadioGroup
                  aria-label='ironingOfClothes'
                  name='Ironing of Clothes'
                  value={ironingOfClothes}
                  onChange={event =>
                    setIroningOfClothes(() => {
                      const newIroningOfClothes = event.target.value === 'true' ? true : false;
                      return newIroningOfClothes;
                    })
                  }
                  row
                >
                  <FormControlLabel value={true} control={<Radio color='primary' />} label='Yes' labelPlacement='end' />
                  <FormControlLabel value={false} control={<Radio color='primary' />} label='No' labelPlacement='end' />
                </RadioGroup>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography className={classes.textVerticalCenter}>Cooking</Typography>
              </Grid>
              <Grid item xs={6}>
                <RadioGroup
                  aria-label='cooking'
                  name='Cooking'
                  value={cooking}
                  onChange={event =>
                    setCooking(() => {
                      const newCooking = event.target.value === 'true' ? true : false;
                      return newCooking;
                    })
                  }
                  row
                >
                  <FormControlLabel value={true} control={<Radio color='primary' />} label='Yes' labelPlacement='end' />
                  <FormControlLabel value={false} control={<Radio color='primary' />} label='No' labelPlacement='end' />
                </RadioGroup>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography className={classes.textVerticalCenter}>Clothes Washing by</Typography>
              </Grid>
              <Grid item xs={6}>
                <RadioGroup
                  aria-label='clothesWashingBy'
                  name='Clothes Washing by'
                  value={clothesWashingByTemp}
                  onChange={handleChangeClothesWashingBy}
                  row
                >
                  <FormControlLabel value='hand' control={<Radio color='primary' />} label='Hands' labelPlacement='end' />
                  <FormControlLabel value='machine' control={<Radio color='primary' />} label='Machine' labelPlacement='end' />
                  <FormControlLabel value='both' control={<Radio color='primary' />} label='Both' labelPlacement='end' />
                </RadioGroup>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography className={classes.textVerticalCenter}>Car Washing</Typography>
              </Grid>
              <Grid item xs={6}>
                <RadioGroup
                  aria-label='carWashing'
                  name='Car Washing'
                  value={carWashing}
                  onChange={event =>
                    setCarWashing(() => {
                      const newCarWashing = event.target.value === 'true' ? true : false;
                      return newCarWashing;
                    })
                  }
                  row
                >
                  <FormControlLabel value={true} control={<Radio color='primary' />} label='Yes' labelPlacement='end' />
                  <FormControlLabel value={false} control={<Radio color='primary' />} label='No' labelPlacement='end' />
                </RadioGroup>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography className={classes.textVerticalCenter}>Take Care of Pet(s)</Typography>
              </Grid>
              <Grid item xs={6}>
                <RadioGroup aria-label='takeCareOfPet' name='Take Care of Pet(s)' value={takeCareOfPetTemp} onChange={handleChangeTakeCareOfPet} row>
                  <FormControlLabel value='hand' control={<Radio color='primary' />} label='Yes' labelPlacement='end' />
                  <FormControlLabel value='machine' control={<Radio color='primary' />} label='No' labelPlacement='end' />
                </RadioGroup>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography className={classes.textVerticalCenter}>Gardening</Typography>
              </Grid>
              <Grid item xs={6}>
                <RadioGroup aria-label='gardening' name='Gardening' value={gardeningTemp} onChange={handleChangeGardening} row>
                  <FormControlLabel value='hand' control={<Radio color='primary' />} label='Yes ' labelPlacement='end' />
                  <FormControlLabel value='machine' control={<Radio color='primary' />} label='No' labelPlacement='end' />
                </RadioGroup>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant='outlined'
              margin='normal'
              fullWidth
              multiline
              rows='3'
              id='remarks'
              label='Remarks'
              value={remarks}
              onChange={event => setRemarks(event.target.value)}
              autoComplete='off'
            />
          </Grid>
          <Grid item xs={6}>
            <Box className={classes.dottedBorderBox}></Box>
          </Grid>
          <Grid item xs={6}>
            <Box className={clsx({ [classes.dottedBorderBox]: !signatureError, [classes.dottedBorderBoxError]: signatureError })}>
              {signature && <img src={signature} alt='Signature' className={classes.signatureImage} />}
            </Box>
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.controlDiv}>
              <Typography className={classes.defaultText} color='primary'>
                <Link href='#' color='primary' onClick={() => setOpenAddSignatureModal(true)}>
                  Add Signature
                </Link>
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.textVerticalCenter}>Helper’s Signature</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.textVerticalCenter}>Employer’s Signature</Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='helperSignature'
              label='Helper’s Name'
              error={helperSignatureError !== ''}
              helperText={helperSignatureError}
              value={helperSignature}
              onChange={event => setHelperSignature(event.target.value)}
              autoComplete='off'
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='employerSignature'
              label='Employer’s Name'
              error={employerSignatureError !== ''}
              helperText={employerSignatureError}
              value={employerSignature}
              onChange={event => setEmployerSignature(event.target.value)}
              autoComplete='off'
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container item justify='flex-end' xs={12} sm={12} md={12} lg={12} xl={12} className={classes.controlDiv}>
        <Button variant='contained' className={classes.cancelButton} onClick={onCancel}>
          Cancel
        </Button>
        <SaveButton type='submit' variant='contained' disabled={isSubmitting}>
          save data & export PDF
        </SaveButton>
      </Grid>
    </form>
  );
};

export default DocumentForm;
