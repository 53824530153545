import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Dialog, DialogContent, DialogTitle, IconButton, Theme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CandidateHistoryForm from './CandidateHistoryForm';
import EmploymentHistoryOverseasModal from './EmploymentHistoryOverseasModal';
import axios, { CancelTokenSource } from 'axios';
import { JOB_EXPERIENCE_BASE_URL, GET_EDIT_FEEDBACK_EMPLOYER_BASE_URL } from 'constants/url';
import { format } from 'date-fns';

interface Props {
  open: boolean;
  candidateId: number;
  candidateHistorysFDW: JobExperienceModel[];
  feedbackEmployer: FeedbackEmployerModel;
  setFeedbackEmployer: React.Dispatch<React.SetStateAction<FeedbackEmployerModel>>;
  setCandidateHistorysFDW: React.Dispatch<React.SetStateAction<JobExperienceModel[]>>;
  handleCancel(): void;
  setOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarVarient: React.Dispatch<React.SetStateAction<'success' | 'error'>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  contentGrid: {
    borderRadius: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#0B3469',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0)
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  saveButton: {
    color: '#FFFFFF'
  },
  cancelButton: {
    marginRight: theme.spacing(3)
  },
  controlDiv: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2)
  }
}));

const EditCandidateHistoryModal: React.FC<Props> = props => {
  const classes = useStyles();

  let cancelTokenSource: CancelTokenSource;
  const {
    open,
    candidateId,
    candidateHistorysFDW,
    feedbackEmployer,
    setFeedbackEmployer,
    handleCancel,
    setOpenSnackbar,
    setSnackbarVarient,
    setCandidateHistorysFDW
  } = props;

  const [isLoading, setLoading] = useState<boolean>(false);

  const [dateFrom, setDatefrom] = useState<string>('');
  const [dateTo, setDateto] = useState<string>('');
  const [country, setCountry] = useState<string>('');
  const [employer, setEmployer] = useState<string>('');
  const [workDuties, setWorkduties] = useState<string>('');
  const [remarksOverseas, setRemarksOverseas] = useState<string>('');

  const [candiateId, setCandidateId] = useState<number>(0);
  const [fEmployerId, setFEmployerId] = useState<number>(0);
  const [employer1, setEmployer1] = useState<string>('');
  const [employer2, setEmployer2] = useState<string>('');
  const [interview, setInterview] = useState<boolean>(false);
  const [phone, setPhone] = useState<boolean>(false);
  const [videoConference, setVideoConference] = useState<boolean>(false);
  const [person, setPerson] = useState<boolean>(false);
  const [remarks, setRemarks] = useState<string>('');
  const [feedback1, setFeedback1] = useState<string>('');
  const [feedback2, setFeedback2] = useState<string>('');

  const [openAddEmploymentOverseasModal, setOpenEditEmploymentOverseasModal] = useState<boolean>(false);

  // This is to ensure that the form vale and erors are reset/cleared when user canceled the editing
  const resetFormValues = useCallback(() => {
    setDatefrom(format(new Date(), 'yyyy'));
    setDateto(format(new Date(), 'yyyy'));
    setCountry('');
    setEmployer('');
    setWorkduties('');
    setRemarksOverseas('');

    
    if (feedbackEmployer) {
      const { id, employer1, employer2, interview, phone, videoConference, person, remarks, feedback1, feedback2 } = feedbackEmployer;

      setFEmployerId(id);
      setEmployer1(employer1);
      setEmployer2(employer2);
      setInterview(interview);
      setPhone(phone);
      setVideoConference(videoConference);
      setPerson(person);
      setRemarks(remarks);
      setFeedback1(feedback1);
      setFeedback2(feedback2);
    }
    
  }, [candidateHistorysFDW, feedbackEmployer]);

  useEffect(() => {
    resetFormValues();
  }, [resetFormValues]);

  const handleOnClose = () => {
    resetFormValues();
    handleCancel();
    const currentCandidateHistorysFDW = [...candidateHistorysFDW];
    setCandidateHistorysFDW(currentCandidateHistorysFDW.filter(value => !value.new));
  };

  const handleOnCancel = () => {
    resetFormValues();
    setOpenEditEmploymentOverseasModal(false);
  };

  const handleOnAdd = () => {
    const currentCandidateHistorysFDW = [...candidateHistorysFDW];

    const newDateFrom = dateFrom ? dateFrom : '';
    const newDateTo = dateTo ? dateTo : '';

    currentCandidateHistorysFDW.push({
      id: 0,
      dateFrom: newDateFrom,
      dateTo: newDateTo,
      country,
      employer,
      CandidateId: candidateId,
      workDuties,
      remarks: remarksOverseas,
      new: true,
    });

    setCandidateHistorysFDW(currentCandidateHistorysFDW);
    setOpenEditEmploymentOverseasModal(false);
  };

  const handleOnAddHistory = () => {
    if (candidateHistorysFDW.length >= 4) {
      return;
    }

    setOpenEditEmploymentOverseasModal(true);
  };

  const handleOnSubmit: React.FormEventHandler = async event => {
    event.preventDefault();
    setLoading(true);

    try {
      cancelTokenSource = axios.CancelToken.source();

      const currentCandidateHistorysFDW = [...candidateHistorysFDW];

      currentCandidateHistorysFDW.map(async fdw => {
        if (fdw.id === 0) {
          try {
            await axios.post(
              `${JOB_EXPERIENCE_BASE_URL}`,
              {
                dateFrom: format(new Date(fdw.dateFrom), 'yyyy'),
                dateTo: format(new Date(fdw.dateTo), 'yyyy'),
                country: fdw.country,
                employer: fdw.employer,
                CandidateId: fdw.CandidateId,
                workDuties: fdw.workDuties,
                remarks: fdw.remarks
              },
              { cancelToken: cancelTokenSource.token }
            );
          } catch (err) {
            console.log(err);
            setOpenSnackbar(true);
            setSnackbarVarient('error');
          }
        }
      });

      await axios.put(
        `${GET_EDIT_FEEDBACK_EMPLOYER_BASE_URL(fEmployerId)}`,
        {
          employer1,
          employer2,
          feedback1,
          feedback2,
          interview,
          phone,
          videoConference,
          person,
          remarks
        },
        { cancelToken: cancelTokenSource.token }
      );

      setFeedbackEmployer({ ...feedbackEmployer, employer1, employer2, feedback1, feedback2, interview, phone, videoConference, person, remarks });
      setOpenSnackbar(true);
      setSnackbarVarient('success');
      handleCancel();
    } catch (err) {
      console.log(err);
      setOpenSnackbar(true);
      setSnackbarVarient('error');
    }
    setLoading(false);
  };

  return (
    <Dialog open={open} scroll='body' fullWidth={true} maxWidth='md'>
      <DialogTitle>
        <IconButton size='small' className={classes.closeButton} onClick={handleOnClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <CandidateHistoryForm
          candidateHistorysFDW={candidateHistorysFDW}
          setCandidateHistorysFDW={setCandidateHistorysFDW}
          employer1={employer1}
          setEmployer1={setEmployer1}
          employer2={employer2}
          setEmployer2={setEmployer2}
          interview={interview}
          setInterview={setInterview}
          phone={phone}
          setPhone={setPhone}
          videoConference={videoConference}
          setVideoConference={setVideoConference}
          person={person}
          setPerson={setPerson}
          remarks={remarks}
          setRemarks={setRemarks}
          feedback1={feedback1}
          setFeedback1={setFeedback1}
          feedback2={feedback2}
          setFeedback2={setFeedback2}
          isLoading={isLoading}
          setIsLoading={setLoading}
          onAddHistory={handleOnAddHistory}
          onSubmit={handleOnSubmit}
          onCancel={handleOnClose}
        />
        <EmploymentHistoryOverseasModal
          open={openAddEmploymentOverseasModal}
          dateFrom={dateFrom}
          setDatefrom={setDatefrom}
          dateTo={dateTo}
          setDateto={setDateto}
          country={country}
          setCountry={setCountry}
          employer={employer}
          setEmployer={setEmployer}
          workDuties={workDuties}
          setWorkduties={setWorkduties}
          remarks={remarksOverseas}
          setRemarks={setRemarksOverseas}
          isSubmitting={isLoading}
          onAdd={handleOnAdd}
          onCancel={handleOnCancel}
        />
      </DialogContent>
    </Dialog>
  );
};

export default EditCandidateHistoryModal;
