import { ButtonBase, Grid, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { FC, Fragment, useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { dummyDocumentFile } from 'utils/dummy';
import AntSwitch from 'components/AntSwitch';
import { StandardConfirmationDialog } from 'components/AppDialog';

interface Props {
  documentFiles: CandidateFileDocument[];
  setDocumentFiles: React.Dispatch<React.SetStateAction<CandidateFileDocument[]>>;
  isSubmitting: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  inputFileStyle: {
    display: 'none',
    marginRight: theme.spacing(2)
  },
  buttonAddDocument: {
    padding: theme.spacing(3)
  },
  paper: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: theme.palette.text.secondary
  },
  gridHeaderDocument: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(3)
  }
}));

const CandidateUploadDocument: FC<Props> = props => {
  const classes = useStyles(props);
  const { documentFiles, setDocumentFiles, isSubmitting } = props;

  const [confirmationShow, setConfirmationShow] = useState<boolean>(false);

  const handleClose = () => {
    setConfirmationShow(false);
  };

  const handleAllowDownloaded = (index: number) => {

    const newDocumentFiles = [...documentFiles];
    newDocumentFiles[index].allowDownloaded = newDocumentFiles[index].allowDownloaded ? false : true;

    setDocumentFiles(newDocumentFiles);
  };

  const handleChooseFile = (event: any) => {
    let file;

    if (event.target.files[0] === undefined) {
      file = '';
    } else {
      file = event.target.files[0];
    }

    if (Number((file.size / (1024 * 1024)).toFixed(2)) > 100) {
      setConfirmationShow(true);
      return;
    }

    const newDocumentFiles = [...documentFiles];

    const currentDocumentFile: CandidateFileDocument = dummyDocumentFile;
    newDocumentFiles.push({ ...currentDocumentFile, documentFile: file.name, file: file, size: (file.size / (1024 * 1024)).toFixed(2) });

    setDocumentFiles(newDocumentFiles);
  };

  const handleDeleteFile = (index: number) => {
    const currentDocumentFiles = [...documentFiles];

    currentDocumentFiles.splice(index, 1);
    setDocumentFiles(currentDocumentFiles);
  };

  return (
    <Grid>
      <Grid item xs={12} className={classes.gridHeaderDocument}>
        <Typography variant='h3'>Helper Document</Typography>
      </Grid>

      {documentFiles.map((value, index) => (
        <Grid container direction='row' spacing={3}>
          <Grid item xs={4}>
            <Typography variant='h6'>{value.documentFile}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography color='textSecondary'>{value.size} MB</Typography>
          </Grid>
          <Grid item xs={2}>
            <AntSwitch color='primary' checked={value.allowDownloaded} onClick={() => handleAllowDownloaded(index)} disabled={isSubmitting} />
          </Grid>
          <Grid item xs={2}>
            <IconButton size='small' onClick={() => handleDeleteFile(index)} disabled={isSubmitting}>
              <DeleteIcon color='primary' />
            </IconButton>
          </Grid>
        </Grid>
      ))}

      <Grid container spacing={3} direction='row' justify='center' alignItems='stretch'>
        <input
          accept='application/pdf,application/vnd.ms-word,video/mp4,video/quicktime'
          className={classes.inputFileStyle}
          id={`outlined-button-file`}
          type='file'
          onChange={event => handleChooseFile(event)}
        />
        <label htmlFor={`outlined-button-file`}>
          <ButtonBase focusRipple key={`Upload`} component='span' disableRipple>
            <div className={classes.buttonAddDocument}>
              <Grid container item xs={12} justify='center'>
                <AddIcon fontSize='small' color='primary' />
                <Typography variant='body1' color='primary'>
                  Add Document
                </Typography>
              </Grid>
            </div>
          </ButtonBase>
        </label>
      </Grid>
      <StandardConfirmationDialog
        variant={'warning'}
        message='File max 100MB'
        open={confirmationShow}
        noCancelButton={true}
        handleClose={handleClose}
      />
    </Grid>
  );
};

export default CandidateUploadDocument;
