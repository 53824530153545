import React, { FC, Fragment, useContext, useEffect, useState } from 'react';
import Divider from '@material-ui/core/Divider';
import { Avatar, Button, Grid, makeStyles, Table, TableBody, TableHead, TableRow, Theme, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import ActionSnackbar from 'components/ActionSnackbar';
import EditCandidateHistoryModal from '../CandidateHistoryFDW/components/EditCandidateHistoryModal';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import MuiCollapse from '@material-ui/core/Collapse';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import { getCurrentRoleName } from 'selectors';
import { HeaderRow } from 'components/Table';
import BodyCell from 'components/BodyCell';

interface Props {
  jobExperiences: JobExperienceModel[];
  feedbackEmployer: FeedbackEmployerModel;
  isLoadingData: boolean;
  candidateId: number;
  setJobExperiences: React.Dispatch<React.SetStateAction<JobExperienceModel[]>>;
  setFeedbackEmployer: React.Dispatch<React.SetStateAction<FeedbackEmployerModel>>;
  setIsLoadingData: React.Dispatch<React.SetStateAction<boolean>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  headerText: {
    paddingBottom: theme.spacing(2)
  },
  titleGrid: {
    paddingBottom: theme.spacing(2)
  },
  defaultText: {
    fontWeight: 500,
    marginRight: theme.spacing(-3)
  },
  companyNameText: {
    fontWeight: 500,
    marginRight: theme.spacing(-3)
  },
  remarkText: {
    fontWeight: 400,
    marginRight: theme.spacing(-3)
  },
  avatar: {
    backgroundColor: theme.palette.primary.main
  },
  dividerVertical: {
    height: theme.spacing(12),
    marginLeft: theme.spacing(2.5)
  },
  gridContentLeft: {
    marginLeft: theme.spacing(-5)
  },
  gridContentRight: {
    marginBottom: theme.spacing(2)
  },
  buttonMargin: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2)
  },
  cursorGrid: {
    cursor: 'pointer'
  }
}));

const GridItemXs12 = (props: any) => <Grid item xs={12} {...props} />;

const Collapse = (props: any) => {
  const classes = useCollapseStyles();
  return (
    <MuiCollapse
      component={GridItemXs12}
      classes={{
        ...classes,
        hidden: classes.hidden,
        root: classes.root
      }}
      {...props}
    >
      {/* This spacing has to match with the one in the container
            outside the collapse */}
      {props.children}
    </MuiCollapse>
  );
};

const useCollapseStyles = makeStyles({
  root: {
    // width: '100%'
  },
  hidden: {
    // The grid item's padding normally balances with the negative padding
    // of the container outside the Collapse.
    // But when the collapse hides its content, the padding of the item
    // is still taking up space, creating an unwanted space.
    // The 'hidden' style rule is only applied when the collapse finishes
    // hiding its content
    padding: '0 !important'
  }
});

const CandidateHistoryFDW: FC<Props> = props => {
  const classes = useStyles();
  const { currentUser } = useContext(CurrentUserContext);
  const { jobExperiences, candidateId, setFeedbackEmployer, setJobExperiences, feedbackEmployer } = props;

  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarVarient, setSnackbarVarient] = useState<'success' | 'error'>('success');

  const [employer1, setEmployer1] = useState<string>('');
  const [employer2, setEmployer2] = useState<string>('');

  const [feedback1, setFeedback1] = useState<string>('');
  const [feedback2, setFeedback2] = useState<string>('');

  const [interview, setInterview] = useState<boolean>(false);
  const [phone, setPhone] = useState<boolean>(false);
  const [videoConference, setVideoConference] = useState<boolean>(false);
  const [person, setPerson] = useState<boolean>(false);
  const [remarks, setRemarks] = useState<string>('');
  const [openEditEmploymentHistoryModal, setOpenEditEmploymentHistoryModal] = useState<boolean>(false);
  const [collapse, setCollapse] = useState<boolean>(true);
  const [roles, setRoles] = useState<string>('');

  useEffect(() => {
    if (!feedbackEmployer) {
      return;
    }

    const { employer1, employer2, feedback1, feedback2, interview, phone, videoConference, person, remarks } = feedbackEmployer;

    setEmployer1(employer1);
    setEmployer2(employer2);
    setFeedback1(feedback1);
    setFeedback2(feedback2);
    setInterview(interview);
    setPhone(phone);
    setVideoConference(videoConference);
    setPerson(person);
    setRemarks(remarks);
  }, [feedbackEmployer]);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleCancelHistory = () => {
    setOpenEditEmploymentHistoryModal(false);
  };

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    const roles = currentUser ? getCurrentRoleName(currentUser) : '';
    setRoles(roles);
  }, [currentUser]);

  return (
    <Grid>
      <Grid item xs={12} className={classes.cursorGrid} onClick={() => setCollapse(prev => !prev)}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography className={classes.headerText} variant='h4' color='primary'>
              Employment History of the FDW
            </Typography>
          </Grid>
          <Grid item xs={6} container direction='row' justify='flex-end' alignItems='center'>
            {!collapse ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
          </Grid>
        </Grid>
      </Grid>

      <Collapse in={!collapse}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid item xs className={classes.titleGrid}>
              <Grid item xs={6}>
                <Typography className={classes.defaultText} color='primary'>
                  Employment history
                </Typography>
              </Grid>
            </Grid>
            <Table>
              <TableHead>
                <HeaderRow
                  headers={[
                    { label: 'From', verticalAlign: 'middle', align: 'left' },
                    { label: 'To', pL: '10px', pR: '10px', verticalAlign: 'middle', align: 'left' },
                    { label: 'Country', pL: '10px', pR: '10px', verticalAlign: 'middle', align: 'left' },
                    { label: 'Employer ', pL: '10px', pR: '10px', verticalAlign: 'middle', align: 'left' },
                    { label: 'Work Duties', pL: '10px', pR: '10px', verticalAlign: 'middle', align: 'left' },
                    { label: 'Remarks', pL: '10px', pR: '10px', verticalAlign: 'middle', align: 'left' }
                  ]}
                />
              </TableHead>
              <TableBody>
                {jobExperiences.length ? (
                  jobExperiences.map((jobEx, index) => (
                    <TableRow key={index}>
                      <BodyCell pL='0px' pR='0px' cellWidth='2%' align='left' verticalAlign='top'>
                        {jobEx.dateFrom}
                      </BodyCell>
                      <BodyCell pL='0px' pR='0px' cellWidth='2%' align='left' verticalAlign='top'>
                        {jobEx.dateTo}
                      </BodyCell>
                      <BodyCell pL='0px' pR='0px' cellWidth='5%' align='left' verticalAlign='top'>
                        {jobEx.country}
                      </BodyCell>
                      <BodyCell pL='0px' pR='px' cellWidth='5%' align='left' verticalAlign='top'>{jobEx.employer}</BodyCell>
                      <BodyCell pL='0px' pR='20px' cellWidth='10%' align='left' verticalAlign='top'>
                        <Typography variant='body1' style={{ whiteSpace: 'pre-line' }}>
                          {jobEx.workDuties}
                        </Typography>
                      </BodyCell>
                      <BodyCell pL='0px' pR='0px' cellWidth='10%' align='left' verticalAlign='top'>
                        <Typography variant='body1' style={{ whiteSpace: 'pre-line' }}>
                          {jobEx.remarks}
                        </Typography>
                      </BodyCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <BodyCell colSpan={6} align='left'>
                      <Typography variant='body1'>No Content</Typography>
                    </BodyCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Grid>
          <Grid item xs={4}>
            {/* <Grid item xs className={classes.titleGrid}>
              <Grid item xs={12}>
                <Typography className={classes.defaultText} color='primary'>
                  Feedback from previous employers in Singapore
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={12} className={classes.gridContentRight}>
              <Typography className={classes.defaultText}>{employer1}</Typography>
              <Typography className={classes.defaultText} color='textSecondary'>
                {feedback1 ? feedback1 : '-'}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.gridContentRight}>
              <Typography className={classes.defaultText}>{employer2}</Typography>
              <Typography className={classes.defaultText} color='textSecondary'>
                {feedback2 ? feedback2 : '-'}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.gridContentRight}>
              <Grid item>
                <Typography className={classes.defaultText}>Availability of FDW to be interviewed by Prospective Employer</Typography>
              </Grid>
              <Grid item>
                {interview && (
                  <Typography className={classes.defaultText} color='textSecondary'>
                    FDW is not available for interview
                  </Typography>
                )}
                {phone && (
                  <Typography className={classes.defaultText} color='textSecondary'>
                    FDW can be interviewed by phone
                  </Typography>
                )}
                {videoConference && (
                  <Typography className={classes.defaultText} color='textSecondary'>
                    FDW can be interviewed by video-conference
                  </Typography>
                )}
                {person && (
                  <Typography className={classes.defaultText} color='textSecondary'>
                    FDW can be interviewed in person
                  </Typography>
                )}
              </Grid>
            </Grid> */}
            {/* <Grid item xs={12} className={classes.gridContentRight}>
              <Grid item>
                <Typography className={classes.defaultText} color='primary'>Remarks</Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.defaultText} color='textSecondary'>
                  {remarks ? remarks : '-'}
                </Typography>
              </Grid>
            </Grid> */}
          </Grid>
        </Grid>
        {roles !== 'partner_agency' && (
          <Grid item xs={4}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button
                  variant='contained'
                  color='primary'
                  fullWidth
                  className={classes.buttonMargin}
                  onClick={() => {
                    setOpenEditEmploymentHistoryModal(true);
                  }}
                >
                  Edit
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Collapse>
      <ActionSnackbar
        variant={snackbarVarient}
        message={snackbarVarient === 'success' ? 'Update is successful' : 'Update failed'}
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        Icon={snackbarVarient === 'success' ? CheckCircleIcon : ErrorIcon}
      />
      <EditCandidateHistoryModal
        open={openEditEmploymentHistoryModal}
        candidateId={candidateId}
        candidateHistorysFDW={jobExperiences}
        setCandidateHistorysFDW={setJobExperiences}
        feedbackEmployer={feedbackEmployer}
        setFeedbackEmployer={setFeedbackEmployer}
        handleCancel={handleCancelHistory}
        setOpenSnackbar={setOpenSnackbar}
        setSnackbarVarient={setSnackbarVarient}
      />
    </Grid>
  );
};

export default CandidateHistoryFDW;
