import React, { createRef, Fragment, useEffect, useState } from 'react';
import { Checkbox, Button, Grid, TextField, Theme, Typography, withStyles, MenuItem, FormControlLabel, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { orange } from '@material-ui/core/colors';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { PRIMARY_COLOR } from 'constants/colors';
import { ucWords } from 'utils';
import NumberFormatCustom from 'components/NumberFormatCustom';
import { format } from 'date-fns';

interface Props {
  dob: Date | null;
  setDOB: React.Dispatch<React.SetStateAction<Date | null>>;
  linkVideo: string;
  setLinkVideo: React.Dispatch<React.SetStateAction<string>>;
  fullName: string;
  setFullName: React.Dispatch<React.SetStateAction<string>>;
  code: string;
  setCode: React.Dispatch<React.SetStateAction<string>>;
  passportStatus: string;
  setPassportStatus: React.Dispatch<React.SetStateAction<string>>;
  bioFee: number;
  setBioFee: React.Dispatch<React.SetStateAction<number>>;
  loan: number;
  setLoan: React.Dispatch<React.SetStateAction<number>>;
  pocketMoney: number;
  setPocketMoney: React.Dispatch<React.SetStateAction<number>>;
  minimumSalary: number;
  setMinimumSalary: React.Dispatch<React.SetStateAction<number>>;
  maritalStatus: string;
  setMaritalStatus: React.Dispatch<React.SetStateAction<string>>;
  uploadPhoto: any;
  setUploadPhoto: React.Dispatch<React.SetStateAction<any>>;
  experienceSG: string;
  setExperinceSG: React.Dispatch<React.SetStateAction<string>>;
  skills: SkillsModel[];
  setSkills: React.Dispatch<React.SetStateAction<SkillsModel[]>>;
  remarks: string;
  setRemarks: React.Dispatch<React.SetStateAction<string>>;
  placeOfBirth: string;
  setPlaceOfBirth: React.Dispatch<React.SetStateAction<string>>;

  skillsStatuses: Select[];
  experinceInSGStatuses: Select[];
  maritalStatuses: Select[];
  isSubmitting: boolean;
  onSubmit: React.FormEventHandler;
  onCancel: React.MouseEventHandler;
}

const useStyles = makeStyles((theme: Theme) => ({
  gridClass: {
    marginBottom: theme.spacing(2)
  },
  inputFileStyle: {
    display: 'none',
    marginRight: theme.spacing(2)
  },
  contentGrid: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0)
  },
  cancelButton: {
    marginRight: theme.spacing(3)
  },
  controlDiv: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2)
  },
  textFieldRoot: (props: Props) => ({
    backgroundColor: '#FFFFFF',
    marginTop: theme.spacing(2)
  }),
  textFieldFont: {
    fontSize: '15px',
    height: 18,
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2.5)
  },
  autoCompleteCheckbox: {
    padding: theme.spacing(0)
  },
  checkBoxIcon: {
    fontSize: '16px'
  },
  checkBox: {
    marginLeft: theme.spacing(-2),
    '&&:hover': {
      backgroundColor: 'transparent'
    }
  },
  responsiveScale: {
    width: '100%'
  }
}));

const SaveButton = withStyles(theme => ({
  root: {
    color: '#FFFFFF',
    backgroundColor: PRIMARY_COLOR,
    '&:hover': {
      backgroundColor: orange[700]
    }
  }
}))(Button);

const ButtonUpload = withStyles(theme => ({
  root: {
    height: theme.spacing(3)
  }
}))(Button);

const CandidateForm: React.FC<Props> = props => {
  const classes = useStyles(props);

  const icon = <CheckBoxOutlineBlankIcon className={classes.checkBoxIcon} />;
  const checkedIcon = <CheckBoxIcon className={classes.checkBoxIcon} />;

  const fileRef = createRef<HTMLInputElement>();

  const { dob, setDOB } = props;
  const { linkVideo, setLinkVideo } = props;
  const { fullName, setFullName } = props;
  const { code, setCode } = props;
  const { passportStatus, setPassportStatus } = props;
  const { bioFee, setBioFee } = props;
  const { loan, setLoan } = props;
  const { pocketMoney, setPocketMoney } = props;
  const { minimumSalary, setMinimumSalary } = props;
  const { maritalStatus, setMaritalStatus } = props;
  const { experienceSG, setExperinceSG } = props;
  const { uploadPhoto, setUploadPhoto } = props;
  const { skills, setSkills } = props;
  const { remarks, setRemarks } = props;
  const { placeOfBirth, setPlaceOfBirth } = props;

  const { maritalStatuses, experinceInSGStatuses, skillsStatuses, isSubmitting, onCancel, onSubmit } = props;

  const handleIndividualCheck = (name: string) => {
    const newChecked = [...skills];
    // count element in object selected filter for check already exist or not
    const countElement = newChecked.filter(newCheckedValue => newCheckedValue.name === name).length;
    if (countElement === 0) {
      newChecked.push({ id: 0, name: name });
    } else {
      // check index of element and remove object by index
      const checkedFilterIndex = newChecked.findIndex(newCheckedValue => newCheckedValue.name === name);
      newChecked.splice(checkedFilterIndex, 1);
    }
    setSkills(newChecked);
  };

  const handleChooseFile = (event: any) => {
    let file;

    if (event.target.files[0] === undefined) {
      file = '';
    } else {
      file = event.target.files[0];
    }

    setUploadPhoto(file);
  };

  const handleOpenFile = () => {
    const fileElem = fileRef.current;
    if (fileElem) {
      fileElem.click();
    }
  };

  return (
    <form noValidate onSubmit={onSubmit}>
      <Grid className={classes.contentGrid}>
        <Typography variant='h4' id='modal-title' color='primary'>
          EDIT BIODATA
        </Typography>
        <Grid container spacing={2} className={classes.gridClass}>
          <Grid item xs={6}>
            <input
              accept='image/*'
              className={classes.inputFileStyle}
              id={`outlined-button-file`}
              type='file'
              ref={fileRef}
              onChange={event => handleChooseFile(event)}
            />
            <InputLabel htmlFor={`outlined-button-file`}>
              <TextField
                variant='outlined'
                margin='normal'
                required
                fullWidth
                id='uploadPhoto'
                label='Upload your photo'
                value={uploadPhoto && typeof uploadPhoto === 'object' ? uploadPhoto.name : uploadPhoto}
                InputLabelProps={{ shrink: true }}
                autoComplete='off'
                onClick={() => handleOpenFile()}
                InputProps={{
                  endAdornment: (
                    <ButtonUpload variant='contained' color='primary'>
                      Upload
                    </ButtonUpload>
                  )
                }}
              />
            </InputLabel>
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant='outlined'
              margin='normal'
              fullWidth
              id='video'
              label='Link Video'
              value={linkVideo}
              onChange={event => setLinkVideo(event.target.value)}
              autoComplete='off'
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='name'
              label='Full Name'
              value={fullName}
              onChange={event => setFullName(event.target.value)}
              autoComplete='off'
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              type='date'
              id='dateOfBirth'
              label='Date of Birth'
              value={dob && format(new Date(dob), 'yyyy-MM-dd')}
              onChange={event => setDOB(new Date(event.target.value))}
              autoComplete='off'
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='name'
              label='Place of Birth'
              value={placeOfBirth}
              onChange={event => setPlaceOfBirth(event.target.value)}
              autoComplete='off'
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='id'
              label='ID'
              value={code}
              onChange={event => setCode(event.target.value)}
              autoComplete='off'
            />
          </Grid>
          <Grid item xs={6}>
            <div className={classes.responsiveScale}>
              <TextField
                select
                variant='outlined'
                margin='normal'
                fullWidth
                id='passportStatus'
                label='Passport Status'
                value={passportStatus}
                onChange={event => setPassportStatus(event.target.value)}
                autoComplete='off'
              >
                <MenuItem value='READY' selected={'READY' === passportStatus}>
                  Ready
                </MenuItem>
                <MenuItem value='PROCESSING' selected={'PROCESSING' === passportStatus}>
                  Processing
                </MenuItem>
                <MenuItem value='NOT READY' selected={'NOT READY' === passportStatus}>
                  Not Ready
                </MenuItem>
              </TextField>
            </div>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  variant='outlined'
                  margin='normal'
                  required
                  fullWidth
                  id='bioFee'
                  label='Bio Fee'
                  value={bioFee}
                  onChange={event => setBioFee(+event.target.value)}
                  autoComplete='off'
                  InputProps={{
                    inputComponent: NumberFormatCustom as any,
                    inputProps: {
                      thousandSeparator: true,
                      prefix: '$'
                    }
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant='outlined'
                  margin='normal'
                  required
                  fullWidth
                  id='loan'
                  label='Loan'
                  value={loan}
                  onChange={event => setLoan(+event.target.value)}
                  autoComplete='off'
                  InputProps={{
                    inputComponent: NumberFormatCustom as any,
                    inputProps: {
                      thousandSeparator: true,
                      prefix: '$'
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  variant='outlined'
                  margin='normal'
                  required
                  fullWidth
                  id='pocketMoney'
                  label='Pocket Money'
                  value={pocketMoney}
                  onChange={event => setPocketMoney(+event.target.value)}
                  autoComplete='off'
                  InputProps={{
                    inputComponent: NumberFormatCustom as any,
                    inputProps: {
                      thousandSeparator: true,
                      prefix: '$'
                    }
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant='outlined'
                  margin='normal'
                  required
                  fullWidth
                  id='minimumSalary'
                  label='Minimum Salary'
                  value={minimumSalary}
                  onChange={event => setMinimumSalary(+event.target.value)}
                  autoComplete='off'
                  InputProps={{
                    inputComponent: NumberFormatCustom as any,
                    inputProps: {
                      thousandSeparator: true,
                      prefix: '$'
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.responsiveScale}>
              <TextField
                select
                margin='normal'
                fullWidth
                id='maritalStatus'
                label='Marital Status'
                value={maritalStatus}
                onChange={event => setMaritalStatus(event.target.value)}
                variant='outlined'
                autoComplete='off'
              >
                {maritalStatuses.map(marital => (
                  <MenuItem key={marital.id} value={marital.name} selected={marital.name === maritalStatus}>
                    {ucWords(marital.name)}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.responsiveScale}>
              <TextField
                select
                margin='normal'
                fullWidth
                id='experienceSG*'
                label='Experience in SG*'
                value={experienceSG}
                onChange={event => setExperinceSG(event.target.value)}
                variant='outlined'
                autoComplete='off'
              >
                {experinceInSGStatuses.map(ex => (
                  <MenuItem key={ex.id} value={ex.name} selected={ex.name === experienceSG}>
                    {ucWords(ex.name)}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </Grid>
          <Grid item xs={6}>
            <Grid item xs={6}>
              <Typography variant='subtitle2'>Skills*</Typography>
            </Grid>
            <Grid container spacing={2}>
              {skillsStatuses.map((skill, index) => (
                <Grid item xs={3} key={index}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={icon}
                        checked={skills.filter(value => value.name === skill.name).length > 0}
                        checkedIcon={checkedIcon}
                        color='primary'
                        onClick={() => handleIndividualCheck(skill.name)}
                      />
                    }
                    label={ucWords(skill.name)}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant='outlined'
              fullWidth
              multiline
              rows='3'
              id='remarks'
              label='Remarks'
              value={remarks}
              autoComplete='off'
              onChange={event => setRemarks(event.target.value)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container item justify='center' xs={12} sm={12} md={12} lg={12} xl={12} className={classes.controlDiv}>
        <Button variant='contained' className={classes.cancelButton} disabled={isSubmitting} onClick={onCancel}>
          Cancel
        </Button>
        <SaveButton type='submit' variant='contained' disabled={isSubmitting}>
          Save
        </SaveButton>
      </Grid>
    </form>
  );
};

export default CandidateForm;
