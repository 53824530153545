import React, { FC, Fragment, useContext, useEffect, useState } from 'react';

import { List, makeStyles, Theme, Typography } from '@material-ui/core';
import { mdiCubeOutline, mdiViewGridOutline } from '@mdi/js';
import HelpIcon from '@material-ui/icons/Help';
import ReorderIcon from '@material-ui/icons/Reorder';
import { WHITE, GRAY_3 } from 'constants/colors';
import DrawerItem from './DrawerItem';
import SettingsIcon from '@material-ui/icons/Settings';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import { getCurrentRoleName } from 'selectors';

interface Props {
  currentUserData: CurrentUser | undefined;
  handleDrawerClose(): void;
  useNotification?: boolean;
  totalNotification?: number;
  openDrawer: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  textIcon: {
    color: WHITE,
    fontSize: 50,
    fontWeight: 'bold'
  },
  textDrawer: {
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    fontFamily: '"Roboto", sans-serif',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '16px',
    color: GRAY_3
  },
  textHide: {
    display: 'none'
  }
}));

const DrawerList: FC<Props> = props => {
  const classes = useStyles();
  const { currentUser } = useContext(CurrentUserContext);
  const { handleDrawerClose, openDrawer } = props;
  const [roles, setRoles] = useState<string>('');

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    const roles = currentUser ? getCurrentRoleName(currentUser) : '';
    setRoles(roles);
  }, [currentUser]);

  return (
    <List className={classes.textIcon}>
      <DrawerItem handleDrawerClose={handleDrawerClose} iconMdi={mdiViewGridOutline} path='/candidates-card' label='Helper Card' child={false} />
      <DrawerItem handleDrawerClose={handleDrawerClose} Icon={ReorderIcon} path='/candidates-list' label='Helper List' child={false} />
      <DrawerItem handleDrawerClose={handleDrawerClose} iconMdi={mdiCubeOutline} path='/candidates-document' label='Job Order' child={false} />
      {roles !== 'partner_agency' && (
        <Fragment>
          <Typography className={openDrawer ? classes.textDrawer : classes.textHide}>PREFERENCES</Typography>
          <DrawerItem handleDrawerClose={handleDrawerClose} Icon={SettingsIcon} path='/settings' label='Settings' child={false}></DrawerItem>
        </Fragment>
      )}
      {/* <DrawerItem handleDrawerClose={handleDrawerClose} Icon={HelpIcon} path='/faq' label='FAQ' child={false} /> */}
    </List>
  );
};

export default DrawerList;
