import React, { createRef, Fragment, useState } from 'react';
import {
  Checkbox,
  Button,
  Grid,
  TextField,
  Theme,
  Typography,
  withStyles,
  MenuItem,
  FormControlLabel,
  InputLabel,
  InputAdornment
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { ucWords } from 'utils';
import NumberFormatCustom from 'components/NumberFormatCustom';
import { format } from 'date-fns';
import clsx from 'clsx';

interface Props {
  linkVideo: string;
  setLinkVideo: React.Dispatch<React.SetStateAction<string>>;
  fullName: string;
  setFullName: React.Dispatch<React.SetStateAction<string>>;
  fullNameError: string;
  code: string;
  setCode: React.Dispatch<React.SetStateAction<string>>;
  codeError: string;
  passportStatus: string;
  setPassportStatus: React.Dispatch<React.SetStateAction<string>>;
  passportStatusError: string;
  bioFee: number | null;
  setBioFee: React.Dispatch<React.SetStateAction<number | null>>;
  bioFeeError: string;
  loan: number | null;
  setLoan: React.Dispatch<React.SetStateAction<number | null>>;
  loanError: string;
  pocketMoney: number | null;
  setPocketMoney: React.Dispatch<React.SetStateAction<number | null>>;
  pocketMoneyError: string;
  minimumSalary: number | null;
  setMinimumSalary: React.Dispatch<React.SetStateAction<number | null>>;
  minimumSalaryError: string;
  maritalStatus: string;
  setMaritalStatus: React.Dispatch<React.SetStateAction<string>>;
  maritalStatusError: string;
  uploadPhoto: any;
  setUploadPhoto: React.Dispatch<React.SetStateAction<any>>;
  uploadPhotoError: string;
  experienceSG: string;
  setExperinceSG: React.Dispatch<React.SetStateAction<string>>;
  skills: SkillsModel[];
  setSkills: React.Dispatch<React.SetStateAction<SkillsModel[]>>;
  skillsError: string;
  remarks: string;
  setRemarks: React.Dispatch<React.SetStateAction<string>>;
  dob: Date | null;
  setDOB: React.Dispatch<React.SetStateAction<Date | null>>;
  dobError: string;
  placeOfBirth: string;
  setPlaceofBirth: React.Dispatch<React.SetStateAction<string>>;
  placeOfBirthError: string;
  height: number | null;
  setHeight: React.Dispatch<React.SetStateAction<number | null>>;
  heightError: string;
  weight: number | null;
  setWeight: React.Dispatch<React.SetStateAction<number | null>>;
  weightError: string;
  nationality: string;
  setNationality: React.Dispatch<React.SetStateAction<string>>;
  nationalityError: string;
  phoneNumber: string;
  setPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
  phoneNumberError: string;
  residentialAddress: string;
  setResidentialAddress: React.Dispatch<React.SetStateAction<string>>;
  residentialAddressError: string;
  nameOfPort: string;
  setNameOfPort: React.Dispatch<React.SetStateAction<string>>;
  nameOfPortError: string;
  religion: string;
  setReligion: React.Dispatch<React.SetStateAction<string>>;
  religionError: string;
  educationCollege: string;
  setEducationCollege: React.Dispatch<React.SetStateAction<string>>;
  educationCollegeError: string;
  numberOfSiblings: string;
  setNumberOfSiblings: React.Dispatch<React.SetStateAction<string>>;
  numberOfSiblingsError: string;
  addAgeOfChildren: string;
  setAddAgeOfChildren: React.Dispatch<React.SetStateAction<string>>;
  addAgeOfChildrensError: string;
  skillsStatuses: Select[];
  experinceInSGStatuses: Select[];
  maritalStatuses: Select[];
  isSubmitting: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  gridClass: {
    marginBottom: theme.spacing(2)
  },
  contentGrid: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0)
  },
  cancelButton: {
    marginRight: theme.spacing(3)
  },
  controlDiv: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2)
  },
  textFieldRoot: (props: Props) => ({
    backgroundColor: '#FFFFFF',
    marginTop: theme.spacing(2)
  }),
  textAddNew: {
    position: 'relative',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -30%)'
  },
  textFieldFont: {
    fontSize: '15px',
    height: 18,
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2.5)
  },
  autoCompleteCheckbox: {
    padding: theme.spacing(0)
  },
  checkBoxIcon: {
    fontSize: '16px'
  },
  checkBox: {
    marginLeft: theme.spacing(-2),
    '&&:hover': {
      backgroundColor: 'transparent'
    }
  },
  responsiveScale: {
    width: '100%'
  },
  inputFileStyle: {
    display: 'none',
    marginRight: theme.spacing(2)
  },
  typoCursorLink: {
    cursor: 'pointer'
  },
  contentErrorGrid: {
    borderRadius: 4,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#fc0320'
  }
}));

const ButtonUpload = withStyles(theme => ({
  root: {
    height: theme.spacing(3)
  }
}))(Button);

const CandidateForm: React.FC<Props> = props => {
  const classes = useStyles(props);

  const icon = <CheckBoxOutlineBlankIcon className={classes.checkBoxIcon} />;
  const checkedIcon = <CheckBoxIcon className={classes.checkBoxIcon} />;

  const { uploadPhoto, setUploadPhoto, uploadPhotoError } = props;
  const { linkVideo, setLinkVideo } = props;
  const { fullName, setFullName, fullNameError } = props;
  const { code, setCode, codeError } = props;
  const { passportStatus, setPassportStatus, passportStatusError } = props;
  const { bioFee, setBioFee, bioFeeError } = props;
  const { loan, setLoan, loanError } = props;
  const { pocketMoney, setPocketMoney, pocketMoneyError } = props;
  const { minimumSalary, setMinimumSalary, minimumSalaryError } = props;
  const { maritalStatus, setMaritalStatus, maritalStatusError } = props;
  const { experienceSG, setExperinceSG } = props;
  const { skills, setSkills, skillsError } = props;
  const { remarks, setRemarks } = props;

  const { dob, setDOB, dobError } = props;
  const { placeOfBirth, setPlaceofBirth, placeOfBirthError } = props;
  const { height, setHeight, heightError } = props;
  const { weight, setWeight, weightError } = props;
  const { nationality, setNationality, nationalityError } = props;
  const { phoneNumber, setPhoneNumber, phoneNumberError } = props;
  const { residentialAddress, setResidentialAddress, residentialAddressError } = props;
  const { nameOfPort, setNameOfPort, nameOfPortError } = props;
  const { religion, setReligion, religionError } = props;
  const { educationCollege, setEducationCollege, educationCollegeError } = props;
  const { numberOfSiblings, setNumberOfSiblings, numberOfSiblingsError } = props;
  const { addAgeOfChildren, setAddAgeOfChildren, addAgeOfChildrensError } = props;

  const { maritalStatuses, experinceInSGStatuses, skillsStatuses } = props;

  const fileRef = createRef<HTMLInputElement>();

  const handleIndividualCheck = (name: string) => {
    const newChecked = [...skills];
    // count element in object selected filter for check already exist or not
    const countElement = newChecked.filter(newCheckedValue => newCheckedValue.name === name).length;
    if (countElement === 0) {
      newChecked.push({ id: 0, name: name });
    } else {
      // check index of element and remove object by index
      const checkedFilterIndex = newChecked.findIndex(newCheckedValue => newCheckedValue.name === name);
      newChecked.splice(checkedFilterIndex, 1);
    }
    setSkills(newChecked);
  };

  const handleChooseFile = (event: any) => {
    let file;

    if (event.target.files[0] === undefined) {
      file = '';
    } else {
      file = event.target.files[0];
    }

    setUploadPhoto(file);
  };

  const handleOpenFile = () => {
    const fileElem = fileRef.current;
    if (fileElem) {
      fileElem.click();
    }
  };

  return (
    <form noValidate>
      <Grid className={classes.contentGrid}>
        <Grid container spacing={2} className={classes.gridClass}>
          <Grid item xs={6}>
            <input
              accept='image/*'
              className={classes.inputFileStyle}
              id={`outlined-button-file`}
              type='file'
              ref={fileRef}
              onChange={event => handleChooseFile(event)}
            />
            <InputLabel htmlFor={`outlined-button-file`}>
              <TextField
                variant='outlined'
                margin='normal'
                fullWidth
                id=''
                label='Upload your photo'
                error={uploadPhotoError !== ''}
                helperText={uploadPhotoError}
                value={uploadPhoto && typeof uploadPhoto === 'object' ? uploadPhoto.name : uploadPhoto}
                autoComplete='off'
                InputLabelProps={{ shrink: true }}
                onClick={() => handleOpenFile()}
                InputProps={{
                  endAdornment: (
                    <ButtonUpload variant='contained' color='primary'>
                      Upload
                    </ButtonUpload>
                  )
                }}
              />
            </InputLabel>
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant='outlined'
              margin='normal'
              fullWidth
              id='video'
              label='Link Video'
              value={linkVideo}
              onChange={event => setLinkVideo(event.target.value)}
              autoComplete='off'
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='id'
              label='ID'
              error={codeError !== ''}
              helperText={codeError}
              value={code}
              onChange={event => setCode(event.target.value)}
              autoComplete='off'
            />
          </Grid>
          <Grid item xs={6}>
            <div className={classes.responsiveScale}>
              <TextField
                select
                variant='outlined'
                margin='normal'
                fullWidth
                id='passportStatus'
                label='Passport Status'
                error={passportStatusError !== ''}
                helperText={passportStatusError}
                value={passportStatus}
                onChange={event => setPassportStatus(event.target.value)}
                autoComplete='off'
              >
                <MenuItem value='READY' selected={'READY' === passportStatus}>
                  Ready
                </MenuItem>
                <MenuItem value='PROCESSING' selected={'PROCESSING' === passportStatus}>
                  Processing
                </MenuItem>
                <MenuItem value='NOT READY' selected={'NOT READY' === passportStatus}>
                  Not Ready
                </MenuItem>
              </TextField>
            </div>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  variant='outlined'
                  margin='normal'
                  required
                  fullWidth
                  id='bioFee'
                  label='Bio Fee'
                  value={bioFee}
                  error={bioFeeError !== ''}
                  helperText={bioFeeError}
                  onChange={event => setBioFee(event.target.value ? +event.target.value : null)}
                  autoComplete='off'
                  InputProps={{
                    inputComponent: NumberFormatCustom as any,
                    inputProps: {
                      thousandSeparator: true,
                      prefix: '$'
                    }
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant='outlined'
                  margin='normal'
                  required
                  fullWidth
                  id='loan'
                  label='Loan'
                  value={loan}
                  error={loanError !== ''}
                  helperText={loanError}
                  onChange={event => setLoan(event.target.value ? +event.target.value : null)}
                  autoComplete='off'
                  InputProps={{
                    inputComponent: NumberFormatCustom as any,
                    inputProps: {
                      thousandSeparator: true,
                      prefix: '$'
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  variant='outlined'
                  margin='normal'
                  required
                  fullWidth
                  id='loan'
                  label='Pocket Money'
                  value={pocketMoney}
                  error={pocketMoneyError !== ''}
                  helperText={pocketMoneyError}
                  onChange={event => setPocketMoney(event.target.value ? +event.target.value : null)}
                  autoComplete='off'
                  InputProps={{
                    inputComponent: NumberFormatCustom as any,
                    inputProps: {
                      thousandSeparator: true,
                      prefix: '$'
                    }
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant='outlined'
                  margin='normal'
                  required
                  fullWidth
                  id='loan'
                  label='Minimum Salary'
                  error={minimumSalaryError !== ''}
                  helperText={minimumSalaryError}
                  value={minimumSalary}
                  onChange={event => setMinimumSalary(event.target.value ? +event.target.value : null)}
                  autoComplete='off'
                  InputProps={{
                    inputComponent: NumberFormatCustom as any,
                    inputProps: {
                      thousandSeparator: true,
                      prefix: '$'
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.responsiveScale}>
              <TextField
                select
                margin='normal'
                fullWidth
                id='maritalStatus'
                label='Marital Status'
                error={maritalStatusError !== ''}
                helperText={maritalStatusError}
                value={maritalStatus}
                onChange={event => setMaritalStatus(event.target.value)}
                variant='outlined'
                autoComplete='off'
              >
                {maritalStatuses.map(marital => (
                  <MenuItem key={marital.id} value={marital.name} selected={marital.name === maritalStatus}>
                    {ucWords(marital.name)}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.responsiveScale}>
              <TextField
                select
                margin='normal'
                fullWidth
                id='experienceSG'
                label='Experience in SG'
                value={experienceSG}
                onChange={event => setExperinceSG(event.target.value)}
                variant='outlined'
                autoComplete='off'
              >
                {experinceInSGStatuses.map(ex => (
                  <MenuItem key={ex.id} value={ex.name} selected={ex.name === experienceSG}>
                    {ucWords(ex.name)}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </Grid>
          <Grid item xs={6}>
            <Grid item xs={6}>
              <Typography variant='subtitle2'>Skills*</Typography>
            </Grid>
            <Grid container spacing={2} className={clsx({ [classes.contentErrorGrid]: skillsError !== '' })}>
              {skillsStatuses.map((skill, index) => (
                <Grid item xs={3} key={index}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={icon}
                        checked={skills.filter(value => value.name === skill.name).length > 0}
                        checkedIcon={checkedIcon}
                        color='primary'
                        onClick={() => handleIndividualCheck(skill.name)}
                      />
                    }
                    label={ucWords(skill.name)}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant='outlined'
              fullWidth
              multiline
              rows='3'
              id='remarks'
              label='Remarks'
              value={remarks}
              autoComplete='off'
              onChange={event => setRemarks(event.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='name'
              label='Full Name'
              error={fullNameError !== ''}
              helperText={fullNameError}
              value={fullName}
              onChange={event => setFullName(event.target.value)}
              autoComplete='off'
            />
          </Grid>

          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  variant='outlined'
                  margin='normal'
                  required
                  fullWidth
                  type='date'
                  id='dateOfBirth'
                  label='Date of Birth'
                  error={dobError !== ''}
                  helperText={dobError}
                  value={dob && format(new Date(dob), 'yyyy-MM-dd')}
                  onChange={event => setDOB(new Date(event.target.value))}
                  autoComplete='off'
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant='outlined'
                  margin='normal'
                  required
                  fullWidth
                  id='placeOfBirth'
                  label='Place of Birth'
                  value={placeOfBirth}
                  error={placeOfBirthError !== ''}
                  helperText={placeOfBirthError}
                  onChange={event => setPlaceofBirth(event.target.value)}
                  autoComplete='off'
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  variant='outlined'
                  margin='normal'
                  required
                  fullWidth
                  type='number'
                  id='height'
                  label='Height'
                  error={heightError !== ''}
                  helperText={heightError}
                  value={height}
                  onChange={event => setHeight(event.target.value ? +event.target.value : null)}
                  InputProps={{
                    endAdornment: <InputAdornment position='start'>Cm</InputAdornment>
                  }}
                  autoComplete='off'
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant='outlined'
                  margin='normal'
                  required
                  fullWidth
                  type='number'
                  id='weight'
                  label='Weight'
                  error={weightError !== ''}
                  helperText={weightError}
                  value={weight}
                  onChange={event => setWeight(event.target.value ? +event.target.value : null)}
                  InputProps={{
                    endAdornment: <InputAdornment position='start'>Kg</InputAdornment>
                  }}
                  autoComplete='off'
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant='outlined'
              margin='normal'
              select
              required
              fullWidth
              id='nationality'
              label='Nationality'
              error={nationalityError !== ''}
              helperText={nationalityError}
              value={nationality}
              onChange={event => setNationality(event.target.value)}
              autoComplete='off'
            >
              <MenuItem value='BURMESE' selected={'BURMESE' === nationality}>
                Burmese
              </MenuItem>
              <MenuItem value='FILIPINO' selected={'FILIPINO' === nationality}>
                Filipino
              </MenuItem>
              <MenuItem value='INDONESIAN' selected={'INDONESIAN' === nationality}>
                Indonesian
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin='normal'
              fullWidth
              required
              id='phoneNumber'
              variant='outlined'
              label='Phone Number'
              value={phoneNumber}
              error={phoneNumberError !== ''}
              helperText={phoneNumberError}
              onChange={event => setPhoneNumber(event.target.value)}
              autoComplete='off'
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='residentialAddress'
              label='Residential Address'
              value={residentialAddress}
              onChange={event => setResidentialAddress(event.target.value)}
              autoComplete='off'
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='nameOfPort'
              label='Name of Port / Airport To Be Repatriated To'
              error={nameOfPortError !== ''}
              helperText={nameOfPortError}
              value={nameOfPort}
              onChange={event => setNameOfPort(event.target.value)}
              autoComplete='off'
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              select
              fullWidth
              id='religion'
              label='Religion'
              error={religionError !== ''}
              helperText={religionError}
              value={religion}
              onChange={event => setReligion(event.target.value)}
              autoComplete='off'
            >
              <MenuItem value='BUDDHIST' selected={'BUDDHIST' === religion}>
                Buddhist
              </MenuItem>
              <MenuItem value='CHRISTIAN' selected={'CHRISTIAN' === religion}>
                Christian
              </MenuItem>
              <MenuItem value='CATHOLIC' selected={'CATHOLIC' === religion}>
                Catholic
              </MenuItem>
              <MenuItem value='HINDU' selected={'HINDU' === religion}>
                Hindu
              </MenuItem>
              <MenuItem value='MUSLIM' selected={'MUSLIM' === religion}>
                Muslim
              </MenuItem>
              <MenuItem value='OTHER' selected={'OTHER' === religion}>
                Other
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='educationCollege'
              label='Education Level'
              error={educationCollegeError !== ''}
              helperText={educationCollegeError}
              value={educationCollege}
              onChange={event => setEducationCollege(event.target.value)}
              autoComplete='off'
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant='outlined'
              margin='normal'
              fullWidth
              id='numberOfSiblings'
              label='Number of Siblings'
              value={numberOfSiblings}
              error={numberOfSiblingsError !== ''}
              helperText={numberOfSiblingsError}
              onChange={event => setNumberOfSiblings(event.target.value)}
              autoComplete='off'
            />
          </Grid>
          {/* {addAgeOfChildrens.map((value, index) => ( */}
            <Fragment>
              <Grid item xs={6}>
                <TextField
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  error={addAgeOfChildrensError !== ''}
                  helperText={addAgeOfChildrensError} 
                  id={`ageOfChildren`}
                  label='Age of Child'
                  value={addAgeOfChildren}
                  onChange={event => setAddAgeOfChildren(event.target.value)}
                  autoComplete='off'
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              {/* <Grid item xs={10}>
                <Grid item className={classes.textAddNew}>
                  {index !== 0 ? (
                    <Grid container spacing={2} alignItems='center'>
                      <Grid item>
                        {!value.edit ? (
                          <Typography
                            color='primary'
                            className={clsx({ [classes.typoCursorLink]: !addAgeOfChildrens.filter(child => child.edit === true).length })}
                            onClick={() => {
                              if (!addAgeOfChildrens.filter(child => child.edit === true).length) {
                                handleEditAge(index);
                              }
                            }}
                          >
                            Edit
                          </Typography>
                        ) : (
                          <Typography color='primary' className={classes.typoCursorLink} onClick={() => handleSaveAge(index)}>
                            Save
                          </Typography>
                        )}
                      </Grid>
                      <Grid item alignItems='center'>
                        <Typography color='textSecondary'>|</Typography>
                      </Grid>
                      <Grid item>
                        {!value.edit ? (
                          <Typography
                            color='textSecondary'
                            className={clsx({ [classes.typoCursorLink]: !addAgeOfChildrens.filter(child => child.edit === true).length })}
                            onClick={() => {
                              if (!addAgeOfChildrens.filter(child => child.edit === true).length) {
                                handleDeleteAge(index);
                              }
                            }}
                          >
                            Delete
                          </Typography>
                        ) : (
                          <Typography color='textSecondary' className={classes.typoCursorLink} onClick={() => handleCancelAge(index)}>
                            Cancel
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  ) : (
                    <Fab size='small' color='primary' aria-label='add' onClick={() => handleAddAgeofChild(index)}>
                      <AddIcon fontSize='inherit' />
                    </Fab>
                  )}
                </Grid>
              </Grid> */}
            </Fragment>
          {/* ))} */}
        </Grid>
      </Grid>
    </form>
  );
};

export default CandidateForm;
