import React, { Fragment, useEffect } from 'react';
import { Checkbox, Button, Divider, Grid, TextField, Theme, Typography, withStyles, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { orange } from '@material-ui/core/colors';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { PRIMARY_COLOR } from 'constants/colors';
import { ucWords } from 'utils';
import NumberFormatCustom from 'components/NumberFormatCustom';

interface Props {
  candidateStatuses: Select[];
  status: string;
  setStatus: React.Dispatch<React.SetStateAction<string>>;

  isSubmitting: boolean;
  onSubmit: React.FormEventHandler;
  onCancel: React.MouseEventHandler;
}

const useStyles = makeStyles((theme: Theme) => ({
  gridClass: {
    marginBottom: theme.spacing(2)
  },
  contentGrid: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0)
  },
  responsiveScale: {
    width: '100%'
  },
  cancelButton: {
    marginRight: theme.spacing(3)
  },
  controlDiv: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2)
  },
}));

const SaveButton = withStyles(theme => ({
  root: {
    color: '#FFFFFF',
    backgroundColor: PRIMARY_COLOR,
    '&:hover': {
      backgroundColor: orange[700]
    }
  }
}))(Button);

const CandidateForm: React.FC<Props> = props => {
  const classes = useStyles(props);

  const { candidateStatuses, isSubmitting, onCancel, onSubmit } = props;

  const { status, setStatus } = props;

  return (
    <form noValidate onSubmit={onSubmit}>
      <Grid className={classes.contentGrid}>
        <Typography variant='h4' id='modal-title' color='primary'>
          Change Status
        </Typography>
        <Grid container spacing={1} className={classes.gridClass}>
          <div className={classes.responsiveScale}>
            <TextField
              select
              margin='normal'
              fullWidth
              id='status'
              label='Status'
              value={status}
              onChange={event => setStatus(event.target.value)}
              variant='outlined'
              autoComplete='off'
            >
              {candidateStatuses.map(candidateStatus => (
                <MenuItem key={candidateStatus.id} value={candidateStatus.name} selected={candidateStatus.name === status}>
                  {ucWords(candidateStatus.name)}
                </MenuItem>
              ))}
            </TextField>
          </div>
        </Grid>
      </Grid>
      <Grid container item justify='center' xs={12} sm={12} md={12} lg={12} xl={12} className={classes.controlDiv}>
        <Button variant='contained' className={classes.cancelButton} disabled={isSubmitting} onClick={onCancel}>
          Cancel
        </Button>
        <SaveButton type='submit' variant='contained' disabled={isSubmitting}>
          Save
        </SaveButton>
      </Grid>
    </form>
  );
};

export default CandidateForm;
