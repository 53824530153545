import axios, { CancelTokenSource } from "axios";
import { YOUTUBE_DOWNLOADER_METAINFO_URL } from "constants/url";
import { dummyYoutube } from "./dummy";
import { getDownloadUrl } from "./helpers";

export const isValidEmail = (email: string): boolean => {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const ucWords = (str: string) => {
  return (str.toLowerCase() + '').replace(/^(.)|\s+(.)/g, function($1) {
    return $1.toUpperCase();
  });
};

export const downloadMedia = async (videoId?: string) => {
  const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

  const { input, format } = dummyYoutube;
  const videoUrl = videoId || input;

  if (!videoUrl) {
    return;
  }
  
  try {
    const url = `${YOUTUBE_DOWNLOADER_METAINFO_URL}?url=${videoUrl}`;
    const { data } = await axios.get(url, { cancelToken: cancelTokenSource.token });

    const downloadUrl = getDownloadUrl(videoUrl, format);

    const videoInfo = {
      title: data.data.videoDetails.title,
      videoId: data.data.videoDetails.videoId
    };

    if (!!videoUrl) {
      await axios
        .get(downloadUrl, {
          headers: {
            t: localStorage.getItem('token')
          },
          responseType: 'blob'
        })
        .then(response => {
          if (response && response.data) {
            const file = new Blob([response.data && response.data]);
            const fileURL = URL.createObjectURL(file);
            const link = document.createElement('a');
            link.href = fileURL;
            link.setAttribute('download', `${videoInfo.title}.mp4`);

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();
          }
        });
    }
  } catch (err) {
    console.log(err);
  }
};
