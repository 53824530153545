export const PRIMARY_COLOR = '#FE753E';
export const BACKGROUND_DRAWER_PRIMARY_COLOR = '#FFF3EE';
export const CANDIDATE_STATUS_COLOR = '#FFEEE8';
export const CANDIDATE_STATUS_RESERVED_COLOR = '#FE753E';
export const CANDIDATE_STATUS_RESERVED_BACKROUND_COLOR = '#faf3f0';
export const CANDIDATE_STATUS_CONFIRMED_COLOR = '#27AE60';
export const CANDIDATE_STATUS_CONFIRMED_BACKROUND_COLOR = '#bff5d6';
export const CANDIDATE_STATUS_AVAILABLE_COLOR = '#2D9CDB';
export const CANDIDATE_STATUS_AVAILABLE_BACKROUND_COLOR = '#D0E9F9';
export const CANDIDATE_STATUS_TAKEN_COLOR = '#efb400';
export const CANDIDATE_STATUS_TAKEN_BACKROUND_COLOR = '#fdf4d9';
export const CANDIDATE_STATUS_INACTIVE_COLOR = '#828282';
export const CANDIDATE_STATUS_INACTIVE_BACKROUND_COLOR = '#e3e1e1';
export const WHITE = '#FFFFFF';
export const BLACK_PRIMARY = '#223B4E';
export const WHITE_2 = '#F2F2F2';
export const BLUE_PRIMARY = '#02A9EA';
export const BLUE_SECONDARY = '#0088BD';
export const BLACK = '#333333';
export const BLACK_2 = '#2E384D';
export const BLACK_3 = '#222B45';
export const GREY = '#E0E0E0';
export const GRAY_3 = '#828282';
export const GREY_6 = '#F2F2F2';
export const GREEN = '#27ae60';
export const BACKGROUND = '#F7F9FC';
export const GRADIENT_DRAWER_COLOR = 'linear-gradient(#0088BD 30%, #02A9EA 70%)';
export const ORANGE = '#F2994A';
export const RED = '#EB5757';
export const WHITESEARCH='#F7F9FC';
export const PENDING_COLOR = '#F2C94C';
export const CONFIRMATION_COLOR = '#27AE60';
export const YELLOW = '#FFF200';
