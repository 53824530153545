import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, MenuItem, TextField, Theme, Typography, withStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { format } from 'date-fns';
import { orange } from '@material-ui/core/colors';
import { PRIMARY_COLOR } from 'constants/colors';
import DateFnsUtils from '@date-io/date-fns';

interface Props {
  open: boolean;
  dateFrom: string;
  setDatefrom: React.Dispatch<React.SetStateAction<string>>;
  dateTo: string;
  setDateto: React.Dispatch<React.SetStateAction<string>>;
  country: string;
  setCountry: React.Dispatch<React.SetStateAction<string>>;
  employer: string;
  setEmployer: React.Dispatch<React.SetStateAction<string>>;
  workDuties: string;
  setWorkduties: React.Dispatch<React.SetStateAction<string>>;
  remarks: string;
  setRemarks: React.Dispatch<React.SetStateAction<string>>;
  isSubmitting: boolean;
  onCancel(): void;
  onAdd(): void;
}

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  contentGrid: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0)
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  saveButton: {
    color: '#FFFFFF'
  },
  cancelButton: {
    marginRight: theme.spacing(3)
  },
  controlDiv: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2)
  },
  textFieldFont: {
    fontSize: '12px',
    height: 18
  }
}));

const SaveButton = withStyles(theme => ({
  root: {
    color: '#FFFFFF',
    backgroundColor: PRIMARY_COLOR,
    '&:hover': {
      backgroundColor: orange[700]
    }
  }
}))(Button);

const EmploymentHistoryOverseasModal: React.FC<Props> = props => {
  const classes = useStyles();

  const { open, onCancel, isSubmitting, onAdd } = props;

  const { dateFrom, setDatefrom } = props;
  const { dateTo, setDateto } = props;
  const { country, setCountry } = props;
  const { employer, setEmployer } = props;
  const { workDuties, setWorkduties } = props;
  const { remarks, setRemarks } = props;

  const handleOnClose = () => {
    onCancel();
  };

  const handleDateFromChange = (date: Date | null) => {
    if (date) {
      setDatefrom(format(date, 'yyyy'));
    }
  };

  const handleDateToChange = (date: Date | null) => {
    if (date) {
      setDateto(format(date, 'yyyy'));
    }
  };

  return (
    <Dialog open={open} scroll='body' fullWidth={true} maxWidth='md'>
      <DialogTitle>
        <IconButton size='small' className={classes.closeButton} onClick={handleOnClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid className={classes.contentGrid}>
          <Typography variant='h4' id='modal-title' color='primary'>
            Employment History Overseas
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    fullWidth
                    autoOk
                    allowKeyboardControl
                    margin='normal'
                    id='dateFrom'
                    value={!dateFrom ? new Date() : dateFrom}
                    variant='inline'
                    inputVariant='outlined'
                    format='yyyy'
                    views={['year']}
                    onChange={handleDateFromChange}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid container item justify='center' alignItems='center' xs={1}>
                <Typography variant='h4'>-</Typography>
              </Grid>
              <Grid item xs={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    fullWidth
                    autoOk
                    allowKeyboardControl
                    margin='normal'
                    id='dateTo'
                    value={!dateTo ? new Date() : dateTo}
                    variant='inline'
                    inputVariant='outlined'
                    format='yyyy'
                    views={['year']}
                    onChange={handleDateToChange}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='country'
                  label='Country'
                  value={country}
                  onChange={event => setCountry(event.target.value)}
                  autoComplete='off'
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='employer'
                  label='Employer'
                  value={employer}
                  onChange={event => setEmployer(event.target.value)}
                  autoComplete='off'
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              variant='outlined'
              margin='normal'
              fullWidth
              multiline
              rows='4'
              id='workDuties'
              label='Work Duties'
              value={workDuties}
              onChange={event => setWorkduties(event.target.value)}
              autoComplete='off'
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant='outlined'
              margin='normal'
              fullWidth
              multiline
              rows='4'
              id='remarks'
              label='Remarks'
              value={remarks}
              onChange={event => setRemarks(event.target.value)}
              autoComplete='off'
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
        </Grid>

        <Grid container item justify='flex-end' xs={12} sm={12} md={12} lg={12} xl={12} className={classes.controlDiv}>
          <Button variant='contained' className={classes.cancelButton} onClick={onCancel} disabled={isSubmitting}>
            Cancel
          </Button>
          <SaveButton type='submit' variant='contained' disabled={isSubmitting} onClick={onAdd}>
            Add
          </SaveButton>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default EmploymentHistoryOverseasModal;
