import React, { FC, useContext, useEffect, useState } from 'react';
import { Button, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { Skeleton } from '@material-ui/lab';
import { Fragment } from 'react';
import ActionSnackbar from 'components/ActionSnackbar';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import EditProfileModal from './components/EditProfileModal';
import MuiCollapse from '@material-ui/core/Collapse';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import { getCurrentRoleName } from 'selectors';
import numberToWords from 'number-to-words';
import { ucWords } from 'utils';

interface Props {
  candidates: CandidateModel;
  setCandidates: React.Dispatch<React.SetStateAction<CandidateModel>>;
  isLoadingData: boolean;
  setIsLoadingData: React.Dispatch<React.SetStateAction<boolean>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  contentTypography: {
    fontWeight: 500,
    marginRight: theme.spacing(-3)
  },
  headerText: {
    paddingBottom: theme.spacing(2)
  },
  buttonMargin: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2)
  },
  cursorGrid: {
    cursor: 'pointer'
  }
}));

const GridItemXs12 = (props: any) => <Grid item xs={12} {...props} />;

const Collapse = (props: any) => {
  const classes = useCollapseStyles();
  return (
    <MuiCollapse
      component={GridItemXs12}
      classes={{
        ...classes,
        hidden: classes.hidden,
        root: classes.root
      }}
      {...props}
      items={props}
    >
      {/* This spacing has to match with the one in the container
            outside the collapse */}
      {props.children}
    </MuiCollapse>
  );
};

const useCollapseStyles = makeStyles({
  root: {
    // width: '100%'
  },
  hidden: {
    // The grid item's padding normally balances with the negative padding
    // of the container outside the Collapse.
    // But when the collapse hides its content, the padding of the item
    // is still taking up space, creating an unwanted space.
    // The 'hidden' style rule is only applied when the collapse finishes
    // hiding its content
    padding: '0 !important'
  }
});

const CandidateProfile: FC<Props> = props => {
  const classes = useStyles();
  const { currentUser } = useContext(CurrentUserContext);
  const { candidates, isLoadingData, setCandidates, setIsLoadingData } = props;

  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarVarient, setSnackbarVarient] = useState<'success' | 'error'>('success');
  const [openEditProfileModal, setOpenEditProfileModal] = useState<boolean>(false);
  const [collapse, setCollapse] = useState<boolean>(true);
  const [roles, setRoles] = useState<string>('');

  const {
    passportStatus,
    contactNumberInHomeCountry,
    placeOfBirth,
    religion,
    height,
    numberOfSiblings,
    weight,
    educationLevel,
    nationality,
    ageOfChildren,
    residential,
    nameOfPort
  } = candidates!;

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleCancelEditProfile = () => {
    setOpenEditProfileModal(false);
  };

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    const roles = currentUser ? getCurrentRoleName(currentUser) : '';
    setRoles(roles);
  }, [currentUser]);

  return (
    <Grid item xs={12}>
      <Grid item xs={12} className={classes.cursorGrid} onClick={() => setCollapse(prev => !prev)}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography className={classes.headerText} variant='h4' color='primary'>
              Profile
            </Typography>
          </Grid>
          <Grid item xs={6} container direction='row' justify='flex-end' alignItems='center'>
            {!collapse ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
          </Grid>
        </Grid>
      </Grid>

      <Collapse in={!collapse}>
        <Grid container spacing={4}>
          <Grid item xs={2}>
            <Typography className={classes.contentTypography}>Height:</Typography>
          </Grid>
          <Grid item xs={2}>
            {isLoadingData ? (
              <Skeleton width={90} />
            ) : (
              <Typography className={classes.contentTypography} color='textSecondary'>
                {height ? height : '-'}cm
              </Typography>
            )}
          </Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={2}>
            <Typography className={classes.contentTypography}>Education level:</Typography>
          </Grid>
          <Grid item xs={2}>
            {isLoadingData ? (
              <Skeleton width={90} />
            ) : (
              <Typography className={classes.contentTypography} color='textSecondary'>
                {educationLevel ? educationLevel : '-'}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={2}>
            <Typography className={classes.contentTypography}>Weight:</Typography>
          </Grid>
          <Grid item xs={2}>
            {isLoadingData ? (
              <Skeleton width={90} />
            ) : (
              <Typography className={classes.contentTypography} color='textSecondary'>
                {weight ? weight : '-'}kg
              </Typography>
            )}
          </Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={2}>
            <Typography className={classes.contentTypography}>Number of siblings:</Typography>
          </Grid>
          <Grid item xs={2}>
            {isLoadingData ? (
              <Skeleton width={90} />
            ) : (
              <Typography className={classes.contentTypography} color='textSecondary'>
                {numberOfSiblings ? numberOfSiblings : '-'}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={2}>
            <Typography className={classes.contentTypography}>Nationality:</Typography>
          </Grid>
          <Grid item xs={2}>
            {isLoadingData ? (
              <Skeleton width={90} />
            ) : (
              <Typography className={classes.contentTypography} color='textSecondary'>
                {nationality ? nationality : '-'}
              </Typography>
            )}
          </Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={2}>
            <Typography className={classes.contentTypography}>Number of children:</Typography>
          </Grid>
          <Grid item xs={2}>
            {isLoadingData ? (
              <Skeleton width={90} />
            ) : (
              <Typography className={classes.contentTypography} color='textSecondary'>
                {ageOfChildren ? String(ageOfChildren).split(',').length : 0}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={2}>
            <Typography className={classes.contentTypography}>Residential address:</Typography>
          </Grid>
          <Grid item xs={2}>
            {isLoadingData ? (
              <Skeleton width={90} />
            ) : (
              <Typography className={classes.contentTypography} color='textSecondary'>
                {residential ? residential : '-'}
              </Typography>
            )}
          </Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={2}>
            <Typography className={classes.contentTypography}>Name of port/airport:</Typography>
          </Grid>
          <Grid item xs={2}>
            {isLoadingData ? (
              <Skeleton width={90} />
            ) : (
              <Typography className={classes.contentTypography} color='textSecondary'>
                {nameOfPort ? nameOfPort : '-'}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={2}></Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={2}>
            <Typography className={classes.contentTypography}>Age of children:</Typography>
          </Grid>
          <Grid item xs={2}>
            {isLoadingData ? (
              <Skeleton width={90} />
            ) : (
              <Typography className={classes.contentTypography} color='textSecondary'>
                {ageOfChildren ? ageOfChildren : '-'}
              </Typography>
            )}
          </Grid>
        </Grid>

        {roles !== 'partner_agency' && (
          <Grid item xs={4}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button
                  variant='contained'
                  color='primary'
                  fullWidth
                  className={classes.buttonMargin}
                  onClick={() => {
                    setOpenEditProfileModal(true);
                  }}
                >
                  Edit
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Collapse>

      <ActionSnackbar
        variant={snackbarVarient}
        message={snackbarVarient === 'success' ? 'Update is successful' : 'Update failed'}
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        Icon={snackbarVarient === 'success' ? CheckCircleIcon : ErrorIcon}
      />
      <EditProfileModal
        open={openEditProfileModal}
        candidates={candidates}
        setCandidates={setCandidates}
        handleCancel={handleCancelEditProfile}
        setOpenSnackbar={setOpenSnackbar}
        setSnackbarVarient={setSnackbarVarient}
      />
    </Grid>
  );
};

export default CandidateProfile;
