import React, { FC, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import {
  CANDIDATE_STATUS_RESERVED_COLOR,
  CANDIDATE_STATUS_CONFIRMED_COLOR,
  CANDIDATE_STATUS_AVAILABLE_COLOR,
  CANDIDATE_STATUS_TAKEN_COLOR,
  CANDIDATE_STATUS_INACTIVE_COLOR,
  CANDIDATE_STATUS_AVAILABLE_BACKROUND_COLOR,
  CANDIDATE_STATUS_CONFIRMED_BACKROUND_COLOR,
  CANDIDATE_STATUS_TAKEN_BACKROUND_COLOR,
  CANDIDATE_STATUS_INACTIVE_BACKROUND_COLOR,
  CANDIDATE_STATUS_RESERVED_BACKROUND_COLOR
} from 'constants/colors';
import { green, orange } from '@material-ui/core/colors';
import { Button, Card, CardContent, CardActions, Grid, makeStyles, Theme, Typography, CardMedia, withStyles } from '@material-ui/core';
import clsx from 'clsx';

interface Props {
  isLoadingData: boolean;
  candidate: CandidateModel;
  index: number;
  setSelectedId: React.Dispatch<React.SetStateAction<number | undefined>>;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  cardHeader: {
    padding: theme.spacing(2)
  },
  customTypography: {
    marginTop: theme.spacing(0.5),
    textAlign: 'right'
  },
  labelPadding: {
    marginRight: theme.spacing(1)
  },
  tagUserIsGood: {
    backgroundColor: green[500],
    borderRadius: 3,
    color: '#FFFFFF',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.2),
    paddingBottom: theme.spacing(0.2)
  },
  tagUserIsBad: {
    backgroundColor: orange[500],
    borderRadius: 3,
    color: '#FFFFFF',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.2),
    paddingBottom: theme.spacing(0.2)
  },
  tagUserIsNotFlagYet: {
    border: '1px solid #C6C6C6',
    borderRadius: 3,
    color: '#C6C6C6',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.2),
    paddingBottom: theme.spacing(0.2)
  },
  contentTypography: {
    fontWeight: 500
  },
  nullImage: {
    textAlign: 'center'
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  skills: {
    border: '1px solid #0B3469',
    borderRadius: 3,
    color: '#0B3469',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.2),
    paddingBottom: theme.spacing(0.2),
    marginLeft: theme.spacing(1)
  },
  status: {
    backgroundColor: '#7A8DA5',
    borderRadius: 3,
    color: '#FFFFFF',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.2),
    paddingBottom: theme.spacing(0.2),
    marginLeft: theme.spacing(1)
  },
  lineHeight: {
    lineHeight: 2
  },
  qualificationLineHeight: {
    lineHeight: 1.7
  },
  avatar: {
    backgroundColor: '#0B3469'
  },
  media: {
    margin: '10px 20px 0',
    width: '80%',
    height: '204px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '4px',
    position: 'static',
    objectPosition: '-20% 0',
    objectFit: 'cover',
    justifyContent: 'center',
  },
  cardCandidates: {
    boxShadow: '0px 5px 12px rgba(0, 0, 0, 0.08)'
  },
  content: {
    flex: '1 0 auto'
  },
  statusCandidate: (props: Props) => {
    let color = '';
    let backgroundColor = '';

    const { candidate } = props;
    const { status } = candidate;

    switch (status) {
      case 'RESERVED':
        color = CANDIDATE_STATUS_RESERVED_COLOR;
        backgroundColor = CANDIDATE_STATUS_RESERVED_BACKROUND_COLOR;
        break;
      case 'CONFIRMED':
        color = CANDIDATE_STATUS_CONFIRMED_COLOR;
        backgroundColor = CANDIDATE_STATUS_CONFIRMED_BACKROUND_COLOR;
        break;
      case 'AVAILABLE':
        color = CANDIDATE_STATUS_AVAILABLE_COLOR;
        backgroundColor = CANDIDATE_STATUS_AVAILABLE_BACKROUND_COLOR;
        break;
      case 'TAKEN':
        color = CANDIDATE_STATUS_TAKEN_COLOR;
        backgroundColor = CANDIDATE_STATUS_TAKEN_BACKROUND_COLOR;
        break;
      case 'INACTIVE':
        color = CANDIDATE_STATUS_INACTIVE_COLOR;
        backgroundColor = CANDIDATE_STATUS_INACTIVE_BACKROUND_COLOR;
        break;
    }

    return {
      fontWeight: 500,
      fontSize: '12px',
      border: `1px solid ${color}`,
      borderRadius: theme.spacing(3),
      backgroundColor: backgroundColor,
      color: color,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    };
  }
}));

const GridCustomContent = withStyles({
  root: {
    padding: '10px'
  }
})(Grid);

const Content: FC<Props> = props => {
  const classes = useStyles(props);
  const { isLoadingData, candidate, index } = props;
  const [age, setAge] = useState<number>(0);
  const [photoUrl, setPhotoUrl] = useState<string>('');

  const newName = candidate.name;
  const matches = newName.match(/\b(\w)/g);
  const newInitialName = matches ? matches!.join('').toUpperCase() : String(matches).toUpperCase();

  const handleViewDetailClick = (candidateId: number) => {
    window.open(`/candidates-card/${candidateId}`, '_blank');
  };

  let initialName: any = [];
  if (candidate.name) {
    const splitedNames = candidate.name.split(' ');
    splitedNames.map((splitedName, index) => {
      if (index === 0 || index === splitedNames.length - 1) {
        if (splitedName[0]) {
          initialName.push(splitedName[0].toUpperCase());
        } else {
          initialName.push(splitedNames[index - 1][0] ? splitedNames[index - 1][0].toUpperCase() : '');
        }
      }
      return initialName;
    });
  }

  useEffect(() => {
    const birthDate = new Date(candidate.dateOfBirth);
    const difference = Date.now() - birthDate.getTime();
    const age = new Date(difference);

    setAge(Math.abs(age.getUTCFullYear() - 1970));

    if (candidate.photoUrl) {
      setPhotoUrl(candidate.photoUrl);
    }
  }, [candidate.photo, candidate.dateOfBirth, candidate.photoUrl]);

  return (
    <GridCustomContent xs={12} sm={3} key={index}>
      <Card className={classes.cardCandidates}>
        {isLoadingData ? (
          <Skeleton width='80%' height='204px' />
        ) : photoUrl ? (
          <img className={classes.media} src={photoUrl} title={candidate.name} />
        ) : (
          <CardMedia className={classes.media} title={candidate.name}>
            <Typography variant="h3" className={classes.nullImage}>{newInitialName}</Typography>
          </CardMedia>
        )}
        <CardContent className={classes.content}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant='h5' align='center'>
                <span className={classes.contentTypography}>{isLoadingData ? <Skeleton width={60} /> : candidate.name ? candidate.name : '-'}</span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h6' align='center' color='textSecondary'>
                {isLoadingData ? (
                  <Skeleton width={60} />
                ) : (
                  <span className={classes.contentTypography}>{!candidate.code ? '' : `ID: ${candidate.code} | Age: ${age}`}</span>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h6' align='center' color='textSecondary'>
                <span
                  className={clsx({
                    [classes.statusCandidate]: candidate.status
                  })}
                >
                  {candidate.status}
                </span>
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button fullWidth color='primary' disabled={isLoadingData ? true : false} onClick={() => handleViewDetailClick(candidate.id)}>
            {isLoadingData ? <Skeleton width={100} /> : 'View More'}
          </Button>
        </CardActions>
      </Card>
    </GridCustomContent>
  );
};

export default Content;
