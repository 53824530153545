import React, { FC, Fragment, useState, useEffect, useContext } from 'react';
import { TableCellHead, TableCustom } from 'components/Table';
import axios from 'axios';
import { GET_DELETE_CANDIDATES } from 'constants/url';
import { dummyDocument } from 'utils/dummy';
import { StandardConfirmationDialog } from 'components/AppDialog';
import { Button, Grid, makeStyles, Paper, Table, TableBody, TableHead, TableRow, TextField, Theme, Typography } from '@material-ui/core';
import HeaderRow from 'components/HeaderRow';
import BodyRow from './components/BodyRow';
import BodyCell from 'components/BodyCell';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import { getCurrentRoleName } from 'selectors';
import AddIcon from '@material-ui/icons/Add';

interface Props {
  isLoadingData: boolean;
  documents: DocumentModel[];
  setOrderColumn: React.Dispatch<React.SetStateAction<string>>;
  orderBy: 'asc' | 'desc';
  setOrderBy: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>;
  partnerCode: string;
  setPartnerCode: React.Dispatch<React.SetStateAction<string>>;
  agencyName: string;
  setAgencyName: React.Dispatch<React.SetStateAction<string>>;
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  dateTime: string;
  setDateTime: React.Dispatch<React.SetStateAction<string>>;
  setOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarVarient: React.Dispatch<React.SetStateAction<'success' | 'error'>>;
  handleSetMessageSuccess: (message: string) => void;
  handleSetMessageError: (message: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: theme.palette.text.secondary
  },
  exportToCsvButton: {
    textDecoration: 'none'
  },
  buttonAddDocument: {
    padding: theme.spacing(3)
  }
}));

const ContentSection: FC<Props> = props => {
  const classes = useStyles();
  const { currentUser } = useContext(CurrentUserContext);
  const {
    isLoadingData,
    documents,
    setOrderColumn,
    orderBy,
    setOrderBy,
    setOpenSnackbar,
    setSnackbarVarient,
    handleSetMessageSuccess,
    handleSetMessageError
  } = props;

  const { partnerCode, setPartnerCode } = props;
  const { agencyName, setAgencyName } = props;
  const { name, setName } = props;
  const { dateTime, setDateTime } = props;

  // The below logic introduces a 500ms delay for showing the skeleton
  const [showSkeleton, setShowSkeleton] = useState<boolean>(false);

  const [message, setMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number>();
  const [roles, setRoles] = useState<string>('');

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (isLoadingData) {
      timeout = setTimeout(() => {
        setShowSkeleton(true);
      }, 1500);
    }

    setShowSkeleton(false);

    return () => {
      clearTimeout(timeout);
    };
  }, [isLoadingData]);

  useEffect(() => {
    if (!currentUser) {
      return;
    }
    
    const roles = currentUser ? getCurrentRoleName(currentUser) : '';
    setRoles(roles);
  }, [currentUser]);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const actionWrapper = async (action: () => Promise<void>) => {
    try {
      await action();
      handleCloseDialog();

      handleSetMessageSuccess(`Successfully delete a document`);
      setSnackbarVarient('success');
      setOpenSnackbar(true);
    } catch (err) {
      handleCloseDialog();
      handleSetMessageError(`Failed to delete a document`);
      setSnackbarVarient('error');
      setOpenSnackbar(true);
    }
  };

  const deleteContract = async (selectedId: number) => {
    await actionWrapper(async () => {
      await axios.delete(GET_DELETE_CANDIDATES(selectedId));
    });
  };

  const handleRequestSort = (value: string, event: React.MouseEvent<unknown>) => {
    setOrderColumn(value);
    setOrderBy!(orderBy === 'desc' ? 'asc' : 'desc');
  };

  const handleCreateClick = () => {
    window.open(`/candidates-document/create`, '_blank');
  };

  const renderContent = () => {
    return (
      <Paper className={classes.paper}>
        {roles === 'partner_agency' ? (
          <div className={classes.buttonAddDocument}>
            <Grid container item xs={12} justify='center'>
              <Button variant='contained' color='primary' onClick={() => handleCreateClick()}>
                <AddIcon fontSize='small' />
                Job Order Page
              </Button>
            </Grid>
          </div>
        ) : (
          <Table>
            <TableHead>
              <HeaderRow
                onRequestSort={handleRequestSort}
                headers={[
                  { label: 'CANDIDATE CODE', verticalAlign: 'middle', bB: true },
                  { label: 'AGENCY NAME', verticalAlign: 'middle', bB: true, isSort: true, orderBy: orderBy, value: 'name' },
                  { label: 'FDW NAME', verticalAlign: 'middle', bB: true, isSort: true, orderBy: orderBy, value: 'nameOfFDW' },
                  { label: 'DATE / TIME', verticalAlign: 'middle', bB: true, isSort: true, orderBy: orderBy, value: 'dateName' },
                  { label: 'ACTION', verticalAlign: 'middle', bB: true }
                ]}
              />
              <TableRow>
                <TableCellHead variant='head'>
                  <TextField id='partnerCode' variant='outlined' value={partnerCode} onChange={event => setPartnerCode(event.target.value)}  />
                </TableCellHead>
                <TableCellHead variant='head'>
                  <TextField id='agencyName' variant='outlined' value={agencyName} onChange={event => setAgencyName(event.target.value)} />
                </TableCellHead>
                <TableCellHead variant='head'>
                  <TextField id='fdwName' variant='outlined' value={name} onChange={event => setName(event.target.value)} />
                </TableCellHead>
                <TableCellHead variant='head'>
                  <TextField id='dateName' variant='outlined' value={dateTime} onChange={event => setDateTime(event.target.value)} />
                </TableCellHead>
              </TableRow>
            </TableHead>

            <TableBody>
              {showSkeleton ? (
                [1, 2, 3, 4, 5].map(index => <BodyRow key={index} document={dummyDocument} isLoadingData={isLoadingData} />)
              ) : (
                <Fragment>
                  {documents.length ? (
                    documents.map((document, index) => <BodyRow key={index} document={document} isLoadingData={isLoadingData} />)
                  ) : (
                    <TableRow>
                      <BodyCell colSpan={5} align='center'>
                        <Typography variant='body1'>No Content</Typography>
                      </BodyCell>
                    </TableRow>
                  )}
                </Fragment>
              )}
            </TableBody>
          </Table>
        )}
      </Paper>
    );
  };

  return (
    <Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12} container direction='row' justify='flex-end' alignItems='center'>
          {/* {renderExportButton()} */}
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {renderContent()}
        </Grid>
      </Grid>
      <StandardConfirmationDialog
        variant={'warning'}
        message={message}
        open={openDialog}
        handleClose={handleCloseDialog}
        onConfirm={() => selectedId && deleteContract(selectedId)}
      />
    </Fragment>
  );
};

export default ContentSection;
