import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Dialog, DialogContent, DialogTitle, IconButton, Theme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ProfileForm from './ProfileForm';
import axios, { CancelTokenSource } from 'axios';
import { GET_EDIT_CANDIDATE_URL, RESUME_BASE_URL } from 'constants/url';

interface Props {
  open: boolean;
  candidates: CandidateModel;
  setCandidates: React.Dispatch<React.SetStateAction<CandidateModel>>;
  handleCancel(): void;
  setOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarVarient: React.Dispatch<React.SetStateAction<'success' | 'error'>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  contentGrid: {
    borderRadius: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#0B3469',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0)
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  saveButton: {
    color: '#FFFFFF'
  },
  cancelButton: {
    marginRight: theme.spacing(3)
  },
  controlDiv: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2)
  }
}));

const EditProfileModal: React.FC<Props> = props => {
  const classes = useStyles();

  let cancelTokenSource: CancelTokenSource;
  const {
    open,
    candidates,
    handleCancel,
    setOpenSnackbar,
    setSnackbarVarient,
    setCandidates
  } = props;

  const [isLoading, setLoading] = useState<boolean>(false);

  const [candidateId, setCandidateId] = useState<number>(0);
  const [dateOfBirth, setDateOfBirth] = useState<Date>(new Date);
  const [placeOfBirth, setPlaceOfBirth] = useState<string>('');
  const [height, setHeight] = useState<number | null>(null);
  const [weight, setWeight] = useState<number | null>(null);
  const [nationality, setNationality] = useState<string>('');
  const [contactNumber, setContactNumber] = useState<string>('');
  const [residentialAddress, setResidentialAddress] = useState<string>('');
  const [nameOfPort, setNameOfPort] = useState<string>('');
  const [religion, setReligion] = useState<string>('');
  const [educationCollege, setEducationCollege] = useState<string>('');
  const [numberOfSiblings, setNumberOfSiblings] = useState<string>('');
  const [ageOfChildren, setAgeOfChildren] = useState<string>('');

  // This is to ensure that the form vale and erors are reset/cleared when user canceled the editing
  const resetFormValues = useCallback(() => {

    const {
      id,
      dateOfBirth,
      placeOfBirth,
      height,
      weight,
      nationality,
      contactNumberInHomeCountry,
      residential,
      nameOfPort,
      religion,
      educationLevel,
      numberOfSiblings,
      ageOfChildren
    } = candidates!;
    
    setCandidateId(id);
    setDateOfBirth(dateOfBirth);
    setPlaceOfBirth(placeOfBirth);
    setHeight(height);
    setWeight(weight);
    setNationality(nationality);
    setContactNumber(contactNumberInHomeCountry);
    setResidentialAddress(residential);
    setNameOfPort(nameOfPort);
    setReligion(religion);
    setEducationCollege(educationLevel);
    setNumberOfSiblings(numberOfSiblings);
    setAgeOfChildren(ageOfChildren);
  }, [candidates]);

  useEffect(() => {
    resetFormValues();
  }, [resetFormValues]);

  const handleOnClose = () => {
    resetFormValues();
    handleCancel();
  };

  const handleOnSubmit: React.FormEventHandler = async event => {
    event.preventDefault();
    setLoading(true);
    try {
      cancelTokenSource = axios.CancelToken.source();
      
      const { data } = await axios.put(
        `${GET_EDIT_CANDIDATE_URL(candidateId)}`,
        {
          dateOfBirth,
          placeOfBirth,
          height,
          weight,
          nationality,
          contactNumberInHomeCountry: contactNumber,
          residential: residentialAddress,
          nameOfPort,
          religion,
          educationLevel: educationCollege,
          numberOfSiblings,
          ageOfChildren
        },
        { cancelToken: cancelTokenSource.token }
      );

      setCandidates(data);
      setOpenSnackbar(true);
      setSnackbarVarient('success');
      handleCancel();
    } catch (err) {
      console.log(err);
      setOpenSnackbar(true);
      setSnackbarVarient('error');
    }
    setLoading(false);
  };

  return (
    <Dialog open={open} scroll='body' fullWidth={true} maxWidth='md'>
      <DialogTitle>
        <IconButton size='small' className={classes.closeButton} onClick={handleOnClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <ProfileForm
          dateOfBirth={dateOfBirth}
          setDateOfBirth={setDateOfBirth}
          placeOfBirth={placeOfBirth}
          setPlaceOfBirth={setPlaceOfBirth}
          height={height}
          setHeight={setHeight}
          weight={weight}
          setWeight={setWeight}
          nationality={nationality}
          setNationality={setNationality}
          contactNumber={contactNumber}
          setContactNumber={setContactNumber}
          residentialAddress={residentialAddress}
          setResidentialAddress={setResidentialAddress}
          nameOfPort={nameOfPort}
          setNameOfPort={setNameOfPort}
          religion={religion}
          setReligion={setReligion}
          educationCollege={educationCollege}
          setEducationCollege={setEducationCollege}
          numberOfSiblings={numberOfSiblings}
          setNumberOfSiblings={setNumberOfSiblings}
          ageOfChildren={ageOfChildren}
          setAgeOfChildren={setAgeOfChildren}
          isSubmitting={isLoading}
          onSubmit={handleOnSubmit}
          onCancel={handleOnClose}
        />
      </DialogContent>
    </Dialog>
  );
};

export default EditProfileModal;
