import React, { FC, useContext, useState, useCallback, useEffect, Fragment, useRef } from 'react';
import axios, { CancelTokenSource } from 'axios';
import useDebounce from 'hooks/useDebounce';
import useCurrentPageTitleUpdater from 'hooks/useCurrentPageTitleUpdater';
import { Button, Container, Grid, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import { CurrentPageContext } from 'contexts/CurrentPageContext';
import { CANDIDATE_BASE_URL } from 'constants/url';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import ActionSnackbar from 'components/ActionSnackbar';
import ContentSection from './components/ContentSection';
import { PaperCustom, Page } from 'components';
import SubMenu from './components/SubMenu';
import CandidatePageContents from 'typings/enum/CandidatePageContents';
import CustomizedTabs from 'components/CustomizedTabs';
import AddIcon from '@material-ui/icons/Add';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import { getCurrentRoleName } from 'selectors';
import ReactDOM from 'react-dom';
import useRouter from 'hooks/useRouter';
import InfiniteScroll from 'react-infinite-scroll-component';
import DownloadIcon from '@material-ui/icons/GetApp';
import { CSVLink } from 'react-csv';
import ArrowRightIcon from '@material-ui/icons/ArrowForwardOutlined';
import ArrowLeftIcon from '@material-ui/icons/ArrowBackOutlined';
import { orange } from '@material-ui/core/colors';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  container: {
    '& > :nth-child(n+2)': {
      marginTop: theme.spacing(2)
    }
  },
  spacing: {
    marginLeft: theme.spacing(1)
  },
  divider: {
    marginBottom: theme.spacing(3)
  },
  pageTitleDivider: {
    backgroundColor: '#0B3469',
    width: 45,
    height: 3,
    marginTop: theme.spacing(0.5)
  },
  subMenuGrid: {
    borderRight: '1px solid #dcdcdc',
    maxWidth: theme.spacing(15)
  },
  headerSubMenuTitleContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(0)
  },
  headerPageTitleContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2)
  },
  contentContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(0),
    paddingBottom: theme.spacing(2)
  },
  buttonAddCandidate: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  gridList: {
    maxWidth: '89%'
  },
  exportToCsvButton: {
    textDecoration: 'none'
  },
  arrowIcon: {
    fontSize: 40,
    color: orange[500]
  },
  rightHeader: {
    paddingRight: theme.spacing(1.5)
  },
  arrowLeft: {
    textDecoration: 'none',
    position: 'fixed',
    top: 0,
    transform: 'translate(-90%, 650%)',
    backgroundColor: '#faebd7',
    opacity: 0.6
  },
  arrowRight: {
    textDecoration: 'none',
    position: 'fixed',
    top: 0,
    transform: 'translate(1469%, 650%)',
    backgroundColor: '#faebd7',
    opacity: 0.6
  }
}));

const CandidatesPageList: FC = () => {
  useCurrentPageTitleUpdater('Helpers (List View)');
  const classes = useStyles();
  const { currentPageTitle } = useContext(CurrentPageContext);
  const { currentUser } = useContext(CurrentUserContext);
  const { history } = useRouter();

  const [selectedContent, setSelectedContent] = useState<CandidatePageContents>(CandidatePageContents.All);
  const [skillsQuery, setSkillsQuery] = useState<string[]>(['ALL']);
  const [experienceInSGQuery, setExperienceInSGQuery] = useState<string>('');

  const selectedRenderContent = (selectedContent: CandidatePageContents): React.MouseEventHandler => () => {
    setCurrentPage(0);
    setHasMore(true);
    setSkillsQuery(selectedContent.toUpperCase().split('+'));
    setSelectedContent(selectedContent);
  };

  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarVarient, setSnackbarVarient] = useState<'success' | 'error'>('success');
  const [messageSuccess, setMessageSuccess] = useState<string>('');
  const [messageError, setMessageError] = useState<string>('');
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(20);
  const [isSearchingCandidate, setSearchingCandidate] = useState<boolean>(false);
  const [isSearchCandidateError, setSearchCandidateError] = useState<boolean>(false);
  const [candidates, setCandidates] = useState<CandidateModel[]>([]);
  const [count, setCount] = useState<number>(0);
  const [headers, setHeaders] = useState<CsvHeaderModel[]>([]);
  const [isDelete, setDelete] = useState<boolean>(false);
  const [orderColumn, setOrderColumn] = useState<string>('');
  const [orderBy, setOrderBy] = useState<'asc' | 'desc'>('asc');
  const [roles, setRoles] = useState<string>('');
  const [partnerCode, setPartnerCode] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [maritalStatus, setMaritalStatus] = useState<string>('');
  const [preferenceRestDay, setPreferenceForRestDay] = useState<string>('');
  const [status, setStatus] = useState<string>('');
  const [passportStatus, setPassportStatus] = useState<string>('');
  const [experience, setExperience] = useState<string>('');
  const [age, setAge] = useState<string>('');
  const [bioFee, setBioFee] = useState<number | null>(null);
  const [loan, setLoan] = useState<number | null>(null);
  const [pocketMoney, setPocketMoney] = useState<number | null>(null);
  const [minSalary, setMinSalary] = useState<number | null>(null);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const divRef = useRef<HTMLDivElement>(null);

  const performActionAndRevertPage = (action: React.Dispatch<React.SetStateAction<any>>, actionParam: any) => {
    switch (actionParam) {
      case 0:
        setCurrentPage(0);
        setHasMore(true);
        setExperienceInSGQuery('ALL');
        return;
      case 1:
        setCurrentPage(0);
        setHasMore(true);
        setExperienceInSGQuery('TRANSFER');
        return;
      case 2:
        setCurrentPage(0);
        setHasMore(true);
        setExperienceInSGQuery('EX-SG');
        return;
      case 3:
        setCurrentPage(0);
        setHasMore(true);
        setExperienceInSGQuery('FRESH');
        return;
    }

    action(actionParam);
  };

  const handleSwipeRight = () => {
    let node = ReactDOM.findDOMNode(divRef.current) as Element;
    node.scrollLeft += 200;
  };

  const handleSwipeLeft = () => {
    let node = ReactDOM.findDOMNode(divRef.current) as Element;
    node.scrollLeft -= 200;
  };

  // Search Candidate whenever skill, flag, employment status filter changes
  const fetchData = useCallback(
    (
      partnerCodeQuery?: string,
      nameQuery?: string,
      ageQuery?: string,
      maritalStatusQuery?: string,
      preferenceRestDayQuery?: string,
      statusQuery?: string,
      passportStatusQuery?: string,
      experienceQuery?: string,
      bioFeeQuery?: string,
      loanQuery?: string,
      pocketMoneyQuery?: string,
      minSalaryQuery?: string
    ) => {
      if (!currentUser) {
        return;
      }

      const roles = currentUser ? getCurrentRoleName(currentUser) : '';
      setRoles(roles);

      const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

      const getParams = () => {
        const params = new URLSearchParams();

        const searchData: SearchByColumnModel[] = [];

        if (partnerCodeQuery) {
          searchData.push({ name: 'code', value: partnerCodeQuery });
        }

        if (nameQuery) {
          searchData.push({ name: 'name', value: nameQuery });
        }
        
        if (ageQuery) {
          searchData.push({ name: 'age', value: String(ageQuery) });
        }

        if (maritalStatusQuery) {
          searchData.push({ name: 'maritalStatus', value: String(maritalStatusQuery) });
        }

        if (preferenceRestDayQuery) {
          searchData.push({ name: 'preferenceForRestDay', value: String(preferenceRestDayQuery) });
        }

        if (statusQuery) {
          searchData.push({ name: 'status', value: String(statusQuery) });
        }

        if (passportStatusQuery) {
          searchData.push({ name: 'passportStatus', value: String(passportStatusQuery) });
        }

        if (experienceQuery) {
          searchData.push({ name: 'experienceInSG', value: String(experienceQuery) });
        }

        if (Number(bioFeeQuery)) {
          searchData.push({ name: 'bioFee', value: String(bioFeeQuery) });
        }

        if (Number(loanQuery)) {
          searchData.push({ name: 'loan', value: String(loanQuery) });
        }

        if (Number(pocketMoneyQuery)) {
          searchData.push({ name: 'pocketMoney', value: String(pocketMoneyQuery) });
        }

        if (Number(minSalaryQuery)) {
          searchData.push({ name: 'minimumSalary', value: String(minSalaryQuery) });
        }

        params.append('columnName[]', JSON.stringify(searchData));

        if (orderBy && orderColumn) {
          params.append('orderBy', orderBy);
          params.append('orderColumn', orderColumn);
        }

        skillsQuery.map((x, index) => {
          if (x !== 'ALL') {
            return params.append(`sf[${index}]`, x.replace(/\s/g, ""));
          }
        });

        if (experienceInSGQuery) {
          params.append('eiSG', experienceInSGQuery.toString());
        }

        if (roles == 'partner_agency') {
          params.append(`status[0]`, 'AVAILABLE');
          params.append(`status[1]`, 'RESERVED');
          params.append(`status[2]`, 'CONFIRMED');
        }

        params.append('s', (0 * 20).toString());
        params.append('l', String(20));

        return params.toString();
      };

      const searchCandidate = async () => {
        setSearchingCandidate(true);
        setSearchCandidateError(false);

        try {
          const url = `${CANDIDATE_BASE_URL}?${getParams()}`;
          const { data } = await axios.get(url, { cancelToken: cancelTokenSource.token });

          setCount(data.count);

          const candidatesData: CandidateModel[] = [...data.candidates];

          candidatesData.map(candidate => {
            const birthDate = new Date(candidate.dateOfBirth);
            const difference = Date.now() - birthDate.getTime();
            const age = new Date(difference);
            candidate.age = Math.abs(age.getUTCFullYear() - 1970);

            return candidate;
          });

          setCandidates(candidatesData);
        } catch (err) {
          setSearchCandidateError(true);
        }

        setSearchingCandidate(false);
        setDelete(false);
      };

      searchCandidate();

      return () => {
        cancelTokenSource.cancel();
      };
    },
    [currentUser, orderBy, orderColumn, skillsQuery, experienceInSGQuery]
  );

  const partnerCodeQuery = useDebounce(partnerCode, 500);
  const nameQuery = useDebounce(name, 500);
  const ageQuery = useDebounce(String(age), 500);
  const maritalStatusQuery = useDebounce(String(maritalStatus), 500);
  const preferenceRestDayQuery = useDebounce(String(preferenceRestDay), 500);
  const statusQuery = useDebounce(String(status), 500);
  const passportStatusQuery = useDebounce(String(passportStatus), 500);
  const experienceQuery = useDebounce(String(experience), 500);
  const bioFeeQuery = useDebounce(String(bioFee), 1500);
  const loanQuery = useDebounce(String(loan), 1500);
  const pocketMoneyQuery = useDebounce(String(pocketMoney), 1500);
  const minSalaryQuery = useDebounce(String(minSalary), 1500);

  useEffect(() => {
    if (
      partnerCodeQuery.length >= 3 ||
      nameQuery.length >= 3 ||
      maritalStatusQuery.length >= 3 ||
      statusQuery.length >= 3 ||
      passportStatusQuery.length >= 3 ||
      experienceQuery.length >= 3 ||
      preferenceRestDayQuery.length >= 3 ||
      ageQuery.length >= 3 ||
      (Number(bioFeeQuery) > 0 && !isNaN(Number(bioFeeQuery))) ||
      (Number(loanQuery) > 0 && !isNaN(Number(loanQuery))) ||
      (Number(pocketMoneyQuery) > 0 && !isNaN(Number(pocketMoneyQuery))) ||
      (Number(minSalaryQuery) > 0 && !isNaN(Number(minSalaryQuery)))
    ) {
      fetchData(
        partnerCodeQuery,
        nameQuery,
        ageQuery,
        maritalStatusQuery,
        preferenceRestDayQuery,
        statusQuery,
        passportStatusQuery,
        experienceQuery,
        bioFeeQuery,
        loanQuery,
        pocketMoneyQuery,
        minSalaryQuery
      );
    }

    if (
      partnerCodeQuery.length === 0 ||
      nameQuery.length === 0 ||
      maritalStatusQuery.length === 0 ||
      statusQuery.length === 0 ||
      passportStatusQuery.length === 0 ||
      experienceQuery.length === 0 ||
      preferenceRestDayQuery.length === 0 ||
      ageQuery.length === 0
    ) {
      fetchData(
        partnerCodeQuery,
        nameQuery,
        ageQuery,
        maritalStatusQuery,
        preferenceRestDayQuery,
        statusQuery,
        passportStatusQuery,
        experienceQuery,
        bioFeeQuery,
        loanQuery,
        pocketMoneyQuery,
        minSalaryQuery
      );
    }
  }, [
    partnerCodeQuery,
    nameQuery,
    ageQuery.length,
    fetchData,
    bioFeeQuery.length,
    loanQuery.length,
    pocketMoneyQuery.length,
    minSalaryQuery.length,
    ageQuery,
    bioFeeQuery,
    loanQuery,
    pocketMoneyQuery,
    minSalaryQuery,
    maritalStatusQuery.length,
    statusQuery.length,
    passportStatusQuery.length,
    experienceQuery.length,
    maritalStatusQuery,
    preferenceRestDayQuery,
    statusQuery,
    passportStatusQuery,
    experienceQuery
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Load candidate data if candidate data has been deleted
  useEffect(() => {
    if (isDelete) {
      fetchData();
    }
  }, [isDelete, fetchData]);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleSetMessageSuccess = (message: string) => {
    setMessageSuccess(message);
  };

  const handleSetMessageError = (message: string) => {
    setMessageError(message);
  };

  const handleOpenCreateCandidate = () => {
    history.push({ pathname: `/candidates-card/create/form` });
  };

  const fetchScrollData = async () => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    const params = new URLSearchParams();

    let result: CandidateModel[] = [];

    try {
      const searchData: SearchByColumnModel[] = [];

      if (partnerCodeQuery) {
        searchData.push({ name: 'code', value: partnerCodeQuery });
      }

      if (nameQuery) {
        searchData.push({ name: 'name', value: nameQuery });
      }

      if (Number(ageQuery)) {
        searchData.push({ name: 'age', value: String(ageQuery) });
      }

      if (maritalStatusQuery) {
        searchData.push({ name: 'maritalStatus', value: String(maritalStatusQuery) });
      }

      if (statusQuery) {
        searchData.push({ name: 'status', value: String(statusQuery) });
      }

      if (passportStatusQuery) {
        searchData.push({ name: 'passportStatus', value: String(passportStatusQuery) });
      }

      if (experienceQuery) {
        searchData.push({ name: 'experienceInSG', value: String(experienceQuery) });
      }

      if (Number(bioFeeQuery)) {
        searchData.push({ name: 'bioFee', value: String(bioFeeQuery) });
      }

      if (Number(loanQuery)) {
        searchData.push({ name: 'loan', value: String(loanQuery) });
      }

      if (Number(pocketMoneyQuery)) {
        searchData.push({ name: 'pocketMoney', value: String(pocketMoneyQuery) });
      }

      if (Number(minSalaryQuery)) {
        searchData.push({ name: 'minimumSalary', value: String(minSalaryQuery) });
      }

      params.append('columnName[]', JSON.stringify(searchData));

      if (orderBy && orderColumn) {
        params.append('orderBy', orderBy);
        params.append('orderColumn', orderColumn);
      }

      skillsQuery.map((x, index) => {
        if (x !== 'ALL') {
          return params.append(`sf[${index}]`, x);
        }
      });

      if (experienceInSGQuery) {
        params.append('eiSG', experienceInSGQuery.toString());
      }

      if (roles == 'partner_agency') {
        params.append(`status[0]`, 'AVAILABLE');
        params.append(`status[1]`, 'RESERVED');
        params.append(`status[2]`, 'CONFIRMED');
      }

      params.append('s', (currentPage === 0 ? 20 : currentPage * rowsPerPage).toString());
      params.append('l', rowsPerPage.toString());

      const url = `${CANDIDATE_BASE_URL}?${params}`;
      const { data } = await axios.get(url, { cancelToken: cancelTokenSource.token });

      result = [...data.candidates];
    } catch (err) {
      setSearchCandidateError(true);
      setDelete(false);
    }

    return result;
  };

  const fetchMoreData = useCallback(() => {
    if (candidates.length === count) {
      setHasMore(false);
      return;
    }

    setCurrentPage(prev => {
      let result = prev + 1 + 1;
      if (prev !== 0) {
        result = prev + 1;
      }
      
      return result;
    });
    setRowsPerPage(20);

    setTimeout(async () => {
      const currentCandidates: CandidateModel[] = [...candidates];
      const newCandidates: CandidateModel[] = await fetchScrollData();

      const mergeArray = currentCandidates.concat(newCandidates);

      if (mergeArray.length === count) {
        setHasMore(false);
      }

      setCandidates(mergeArray);
    }, 1500);
  }, [candidates, count, fetchScrollData]);

  const handleReportCandidate = () => {
    const newCandidate: CandidateModel[] = [...candidates];

    const candidateData: any[] = [];
    newCandidate.map(value => {
      candidateData.push({
        code: value.code,
        name: value.name,
        bioFee: value.bioFee,
        loan: value.loan,
        dateOfBirth: value.dateOfBirth,
        placeOfBirth: value.placeOfBirth,
        residential: value.residential,
        pocketMoney: value.pocketMoney,
        minimumSalary: value.minimumSalary,
        maritalStatus: value.maritalStatus,
        experienceInSG: value.experienceInSG,
        height: value.height,
        weight: value.weight,
        nationality: value.nationality,
        residentialAddress: value.residential,
        educationLevel: value.educationLevel,
        numberOfSibling: value.numberOfSiblings,
        numberOfChildren: value.numberOfChildren,
        nameOfPort: value.nameOfPort,
      });
    });

    return candidateData;
  };

  const renderExportButton = () => {
    if (candidates.length === 0) {
      return (
        <Fragment>
          <Button variant='outlined' color='primary' disabled={true} className={classes.exportToCsvButton}>
            <DownloadIcon /> EXPORT .CSV
          </Button>
          
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <CSVLink data={handleReportCandidate()} separator={','} filename={'candidate list.csv'} className={classes.exportToCsvButton}>
            <Button variant='outlined' color='primary'>
              <DownloadIcon /> EXPORT .CSV
            </Button>
          </CSVLink>
          
        </Fragment>
      );
    }
  };

  const renderContentLoading = () => {
    if (candidates.length === count) {
      return <div></div>;
    } else {
      return (
        <Fragment>
          <Grid container spacing={1} alignItems='center' justify='center'>
            <Typography color='primary' variant='h4'>
              Loading ...
            </Typography>
          </Grid>
        </Fragment>
      );
    }
  };

  return (
    <Page title={currentPageTitle}>
      <Container maxWidth={false}>
        <Grid container direction='row' spacing={1}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant='subtitle1' component='h3'>
                  {currentPageTitle}
                </Typography>
                <Typography variant='h6' component='h6' color='textSecondary'>
                  Displays the data of all helper with card view
                </Typography>
              </Grid>
              {roles !== 'partner_agency' && (
                <Grid container item xs={6} justify='flex-end'>
                  <Button variant='contained' color='primary' className={classes.buttonAddCandidate} onClick={handleOpenCreateCandidate}>
                    <AddIcon fontSize='small' />
                    Add Helper
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>

        <PaperCustom>
          <Grid container spacing={2}>
            <Grid item xs={3} className={classes.subMenuGrid}>
              <Container className={classes.headerSubMenuTitleContainer}>
                <Typography color='textSecondary' variant='h6'>
                  FILTER BY
                </Typography>
              </Container>
              <SubMenu selectedRenderContent={selectedRenderContent} subMenuActive={selectedContent} />
            </Grid>
            <Grid item xs={6} className={classes.gridList}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <CustomizedTabs
                    tabs={[{ id: 0, name: 'ALL' }, { id: 1, name: 'TRANSFER' }, { id: 2, name: 'EX-SG' }, { id: 3, name: 'FRESH' }]}
                    selectedTabId={selectedTab}
                    onSelect={(tabId: number) => performActionAndRevertPage(setSelectedTab, tabId)}
                  />
                </Grid>
                <Grid item xs={6} container direction='row' justify='flex-end' alignItems='center'>
                  {renderExportButton()}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Container className={classes.contentContainer}>
                  <IconButton key='left' aria-label='Left' color='inherit' onClick={() => handleSwipeLeft()} className={classes.arrowLeft}>
                    <ArrowLeftIcon className={classes.arrowIcon} />
                  </IconButton>
                  <IconButton key='right' aria-label='Right' color='inherit' onClick={() => handleSwipeRight()} className={classes.arrowRight}>
                    <ArrowRightIcon className={classes.arrowIcon} />
                  </IconButton>
                  <InfiniteScroll dataLength={candidates.length} next={fetchMoreData} hasMore={hasMore} height={750} loader={renderContentLoading()}>
                    <ContentSection
                      divRef={divRef}
                      candidates={candidates}
                      isLoadingData={isSearchingCandidate}
                      headers={headers}
                      setDelete={setDelete}
                      setOrderColumn={setOrderColumn}
                      orderBy={orderBy}
                      setOrderBy={setOrderBy}
                      setOpenSnackbar={setOpenSnackbar}
                      partnerCode={partnerCode}
                      setPartnerCode={setPartnerCode}
                      name={name}
                      setName={setName}
                      age={age}
                      setAge={setAge}
                      maritalStatus={maritalStatus}
                      setMaritalStatus={setMaritalStatus}
                      status={status}
                      setStatus={setStatus}
                      preferenceRestDay={preferenceRestDay}
                      setPreferenceForRestDay={setPreferenceForRestDay}
                      passportStatus={passportStatus}
                      setPassportStatus={setPassportStatus}
                      experience={experience}
                      setExperience={setExperience}
                      bioFee={bioFee}
                      setBioFee={setBioFee}
                      loan={loan}
                      setLoan={setLoan}
                      pocketMoney={pocketMoney}
                      setPocketMoney={setPocketMoney}
                      minSalary={minSalary}
                      setMinSalary={setMinSalary}
                      setSnackbarVarient={setSnackbarVarient}
                      handleSetMessageSuccess={handleSetMessageSuccess}
                      handleSetMessageError={handleSetMessageError}
                    />
                  </InfiniteScroll>
                </Container>
              </Grid>
            </Grid>
          </Grid>
          <ActionSnackbar
            variant={snackbarVarient}
            message={snackbarVarient === 'success' ? messageSuccess : messageError}
            open={openSnackbar}
            handleClose={handleCloseSnackbar}
            Icon={snackbarVarient === 'success' ? CheckCircleIcon : ErrorIcon}
          />
        </PaperCustom>
      </Container>
    </Page>
  );
};

export default CandidatesPageList;
