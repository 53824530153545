import React, { FC, Fragment, useState, useEffect, RefObject } from 'react';
import { TableCellHead, TableCustom } from 'components/Table';
import axios from 'axios';
import { GET_DELETE_CANDIDATES } from 'constants/url';
import { dummyCandidate } from 'utils/dummy';
import { StandardConfirmationDialog } from 'components/AppDialog';
import { Grid, makeStyles, MenuItem, Paper, Table, TableBody, TableHead, TableRow, TextField, Theme, Typography } from '@material-ui/core';
import HeaderRow from 'components/HeaderRow';
import BodyRow from './components/BodyRow';
import BodyCell from 'components/BodyCell';

interface Props {
  isLoadingData: boolean;
  candidates: CandidateModel[];
  headers: CsvHeaderModel[];
  setOrderColumn: React.Dispatch<React.SetStateAction<string>>;
  orderBy: 'asc' | 'desc';
  setOrderBy: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>;
  setDelete: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarVarient: React.Dispatch<React.SetStateAction<'success' | 'error'>>;
  partnerCode: string;
  setPartnerCode: React.Dispatch<React.SetStateAction<string>>;
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  age: string;
  setAge: React.Dispatch<React.SetStateAction<string>>;
  maritalStatus: string;
  setMaritalStatus: React.Dispatch<React.SetStateAction<string>>;
  status: string;
  setStatus: React.Dispatch<React.SetStateAction<string>>;
  preferenceRestDay: string;
  setPreferenceForRestDay: React.Dispatch<React.SetStateAction<string>>;
  passportStatus: string;
  setPassportStatus: React.Dispatch<React.SetStateAction<string>>;
  experience: string;
  setExperience: React.Dispatch<React.SetStateAction<string>>;
  bioFee: number | null;
  setBioFee: React.Dispatch<React.SetStateAction<number | null>>;
  loan: number | null;
  setLoan: React.Dispatch<React.SetStateAction<number | null>>;
  pocketMoney: number | null;
  setPocketMoney: React.Dispatch<React.SetStateAction<number | null>>;
  minSalary: number | null;
  setMinSalary: React.Dispatch<React.SetStateAction<number | null>>;
  handleSetMessageSuccess: (message: string) => void;
  handleSetMessageError: (message: string) => void;
  divRef: RefObject<HTMLDivElement>;
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    width: '100%',
    overflowX: 'auto',
    color: theme.palette.text.secondary,
    overflowY: 'scroll',
    height: '750px'
  },
  exportToCsvButton: {
    textDecoration: 'none'
  },
  table: {
    width: '160%'
  },
  head: {
    background: 'white',
    position: 'sticky',
    top: 0,
    zIndex: 100
  },
  gridTes: {
    width: '0%'
  },
  tableWrapper: {
    overflowX: 'auto'
  }
}));

const ContentSection: FC<Props> = props => {
  const classes = useStyles();

  const { partnerCode, setPartnerCode } = props;
  const { name, setName } = props;
  const { age, setAge } = props;
  const { maritalStatus, setMaritalStatus } = props;
  const { preferenceRestDay, setPreferenceForRestDay } = props;
  const { status, setStatus } = props;
  const { passportStatus, setPassportStatus } = props;
  const { experience, setExperience } = props;
  const { bioFee, setBioFee } = props;
  const { loan, setLoan } = props;
  const { pocketMoney, setPocketMoney } = props;
  const { minSalary, setMinSalary } = props;

  const {
    isLoadingData,
    candidates,
    headers,
    setOrderColumn,
    orderBy,
    setOrderBy,
    setDelete,
    setOpenSnackbar,
    setSnackbarVarient,
    handleSetMessageSuccess,
    handleSetMessageError,
    divRef
  } = props;

  // The below logic introduces a 500ms delay for showing the skeleton
  const [showSkeleton, setShowSkeleton] = useState<boolean>(false);

  const [message, setMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number>();

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (isLoadingData) {
      timeout = setTimeout(() => {
        setShowSkeleton(true);
      }, 500);
    }

    setShowSkeleton(false);

    return () => {
      clearTimeout(timeout);
    };
  }, [isLoadingData]);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const actionWrapper = async (action: () => Promise<void>) => {
    try {
      await action();
      handleCloseDialog();
      setDelete(true);
      handleSetMessageSuccess(`Successfully delete a candidate`);
      setSnackbarVarient('success');
      setOpenSnackbar(true);
    } catch (err) {
      handleCloseDialog();
      handleSetMessageError(`Failed to delete a candidate`);
      setSnackbarVarient('error');
      setOpenSnackbar(true);
    }
  };

  const deleteContract = async (selectedId: number) => {
    await actionWrapper(async () => {
      await axios.delete(GET_DELETE_CANDIDATES(selectedId));
    });
  };

  const handleRequestSort = (value: string, event: React.MouseEvent<unknown>) => {
    setOrderColumn(value);
    setOrderBy!(orderBy === 'desc' ? 'asc' : 'desc');
  };

  const renderContent = () => {
    return (
      <Paper className={classes.paper} ref={divRef}>
        <Table className={classes.table}>
          <TableHead className={classes.head}>
            <HeaderRow
              onRequestSort={handleRequestSort}
              headers={[
                { label: 'ID', verticalAlign: 'middle', bB: true, mW: '120px' },
                { label: 'NAME', verticalAlign: 'middle', bB: true, mW: '150px', isSort: true, orderBy: orderBy, value: 'name' },
                { label: 'AGE', verticalAlign: 'middle', mW: '100px', bB: true, isSort: true, orderBy: orderBy, value: 'age' },
                { label: 'REST DAY', verticalAlign: 'middle', mW: '100px', bB: true, orderBy: orderBy, value: 'preferenceForRestDay' },
                { label: 'MARITAL STATUS', verticalAlign: 'top', bB: true, mW: '120px', isSort: true, orderBy: orderBy, value: 'maritalStatus' },
                { label: 'STATUS', mW: '150px', verticalAlign: 'middle', bB: true, isSort: true, orderBy: orderBy, value: 'status' },
                { label: 'PASSPORT STATUS', verticalAlign: 'middle', bB: true, mW: '100px' },
                { label: 'EXPERIENCE', verticalAlign: 'middle', bB: true, mW: '120px' },
                { label: 'BIO FEE', verticalAlign: 'middle', bB: true, mW: '120px', isSort: true, orderBy: orderBy, value: 'bioFee' },
                { label: 'LOAN', verticalAlign: 'middle', bB: true, mW: '120px', isSort: true, orderBy: orderBy, value: 'loan' },
                { label: 'POCKET MONEY', verticalAlign: 'top', bB: true, mW: '100px', isSort: true, orderBy: orderBy, value: 'pocketMoney' },
                { label: 'MIN. SALARY', verticalAlign: 'middle', bB: true, mW: '180px', isSort: true, orderBy: orderBy, value: 'minimumSalary' },
                { label: 'REMARKS', pR: '50px', verticalAlign: 'middle', bB: true },
                { label: 'ACTION', pR: '50px', verticalAlign: 'middle', bB: true }
              ]}
            />
            <TableRow>
              <TableCellHead variant='head'>
                <TextField id='partnerId' variant='outlined' value={partnerCode} onChange={event => setPartnerCode(event.target.value)} />
              </TableCellHead>
              <TableCellHead variant='head'>
                <TextField id='name' variant='outlined' value={name} onChange={event => setName(event.target.value)} />
              </TableCellHead>
              <TableCellHead variant='head'>
                <TextField
                  select
                  id='age'
                  variant='outlined'
                  type='number'
                  fullWidth
                  value={age}
                  onChange={event => setAge(event.target.value)}
                >
                  <MenuItem key={'None'} value=''>
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value='< 30' selected={'< 30' === age}>
                    <em>{'< 30'}</em>
                  </MenuItem>
                  <MenuItem value='30 - 40' selected={'30 - 40' === age}>
                    <em>30 - 40</em>
                  </MenuItem>
                  <MenuItem value='> 40' selected={'> 40' === age}>
                    <em>{'> 40'}</em>
                  </MenuItem>
                </TextField>
              </TableCellHead>
              <TableCellHead variant='head'>
                <TextField id='preferenceForRestDay' variant='outlined' value={preferenceRestDay} onChange={event => setPreferenceForRestDay(event.target.value)} />
              </TableCellHead>
              <TableCellHead variant='head'>
                <TextField id='maritalStatus' variant='outlined' value={maritalStatus} onChange={event => setMaritalStatus(event.target.value)} />
              </TableCellHead>
              <TableCellHead variant='head'>
                <TextField id='status' variant='outlined' value={status} onChange={event => setStatus(event.target.value)} />
              </TableCellHead>
              <TableCellHead variant='head'>
                <TextField id='passportStatus' variant='outlined' value={passportStatus} onChange={event => setPassportStatus(event.target.value)} />
              </TableCellHead>
              <TableCellHead variant='head'>
                <TextField id='experienceInSG' variant='outlined' value={experience} onChange={event => setExperience(event.target.value)} />
              </TableCellHead>
              <TableCellHead variant='head'>
                <TextField
                  id='bioFee'
                  variant='outlined'
                  type='number'
                  value={bioFee}
                  onChange={event => setBioFee(event.target.value ? +event.target.value : null)}
                />
              </TableCellHead>
              <TableCellHead variant='head'>
                <TextField
                  id='loan'
                  variant='outlined'
                  type='number'
                  value={loan}
                  onChange={event => setLoan(event.target.value ? +event.target.value : null)}
                />
              </TableCellHead>
              <TableCellHead variant='head'>
                <TextField
                  id='pocketMoney'
                  type='number'
                  variant='outlined'
                  value={pocketMoney}
                  onChange={event => setPocketMoney(event.target.value ? +event.target.value : null)}
                />
              </TableCellHead>
              <TableCellHead variant='head'>
                <TextField
                  id='minSalary'
                  type='number'
                  variant='outlined'
                  value={minSalary}
                  onChange={event => setMinSalary(event.target.value ? +event.target.value : null)}
                />
              </TableCellHead>
              <TableCellHead variant='head'>
                <TextField id='remarks' variant='outlined' />
              </TableCellHead>
            </TableRow>
          </TableHead>

          <TableBody>
            {showSkeleton ? (
              [1, 2, 3, 4, 5].map(index => <BodyRow key={index} candidate={dummyCandidate} isLoadingData={isLoadingData} />)
            ) : (
              <Fragment>
                {candidates.length ? (
                  candidates.map((candidate, index) => <BodyRow key={index} candidate={candidate} isLoadingData={isLoadingData} />)
                ) : (
                  <TableRow>
                    <BodyCell colSpan={13} align='center'>
                      <Typography variant='body1'>No Content</Typography>
                    </BodyCell>
                  </TableRow>
                )}
              </Fragment>
            )}
          </TableBody>
        </Table>
      </Paper>
    );
  };

  return (
    <Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {renderContent()}
        </Grid>
      </Grid>
      <StandardConfirmationDialog
        variant={'warning'}
        message={message}
        open={openDialog}
        handleClose={handleCloseDialog}
        onConfirm={() => selectedId && deleteContract(selectedId)}
      />
    </Fragment>
  );
};

export default ContentSection;
