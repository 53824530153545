import React, { FC } from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import { Page, PaperCustom } from 'components';
import UsersPage from './pages/UsersPage';

const SettingsPage: FC = () => {

  return (
    <Page title='Settings'>
      <Container>
        <Grid container direction='row' spacing={1}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant='subtitle1' component='h3'>
              Settings
            </Typography>
          </Grid>
        </Grid>

        <PaperCustom>
          <UsersPage />
        </PaperCustom>
      </Container>
    </Page>
  );
};

export default SettingsPage;
