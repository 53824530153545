import React, { FC, Fragment, useState, useEffect } from 'react';

import axios from 'axios';
import { GET_DELETE_CANDIDATES } from 'constants/url';
import { dummyCandidate } from 'utils/dummy';
import { StandardConfirmationDialog } from 'components/AppDialog';
import { Grid, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import Content from './components/Content';

interface Props {
  isLoadingData: boolean;
  candidates: CandidateModel[];
  headers: CsvHeaderModel[];
  setDelete: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarVarient: React.Dispatch<React.SetStateAction<'success' | 'error'>>;
  handleSetMessageSuccess: (message: string) => void;
  handleSetMessageError: (message: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: theme.palette.text.secondary
  },
  divSpan: {
    height: '700px',
    overflow: 'auto'
  }
}));

const ContentSection: FC<Props> = props => {
  const classes = useStyles();
  const {
    isLoadingData,
    candidates,
    headers,
    setDelete,
    setOpenSnackbar,
    setSnackbarVarient,
    handleSetMessageSuccess,
    handleSetMessageError
  } = props;

  // The below logic introduces a 500ms delay for showing the skeleton
  const [showSkeleton, setShowSkeleton] = useState<boolean>(false);

  const [message, setMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number>();

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (isLoadingData) {
      timeout = setTimeout(() => {
        setShowSkeleton(true);
      }, 500);
    }

    setShowSkeleton(false);

    return () => {
      clearTimeout(timeout);
    };
  }, [isLoadingData]);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const actionWrapper = async (action: () => Promise<void>) => {
    try {
      await action();
      handleCloseDialog();
      setDelete(true);
      handleSetMessageSuccess(`Successfully delete a candidate`);
      setSnackbarVarient('success');
      setOpenSnackbar(true);
    } catch (err) {
      handleCloseDialog();
      handleSetMessageError(`Failed to delete a candidate`);
      setSnackbarVarient('error');
      setOpenSnackbar(true);
    }
  };

  const deleteContract = async (selectedId: number) => {
    await actionWrapper(async () => {
      await axios.delete(GET_DELETE_CANDIDATES(selectedId));
    });
  };

  const renderContent = () => {
    if (showSkeleton) {
      return [1, 2, 3, 4, 5, 6, 7, 8].map(index => {
        return (
          <Content
            candidate={dummyCandidate}
            index={index}
            key={index}
            isLoadingData={isLoadingData}
            setSelectedId={setSelectedId}
            setOpenDialog={setOpenDialog}
            setMessage={setMessage}
          />
        );
      });
    } else {
      if (candidates.length === 0) {
        return (
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Typography variant='body1' color='textSecondary' align='left'>
                No candidate yet
              </Typography>
            </Paper>
          </Grid>
        );
      } else {
        return candidates.map((candidate, index) => {
          return (
            <Content
              candidate={candidate}
              index={index}
              key={index}
              isLoadingData={isLoadingData}
              setSelectedId={setSelectedId}
              setOpenDialog={setOpenDialog}
              setMessage={setMessage}
            />
          );
        });
      }
    }
  };

  return (
    <Fragment>
      <Grid container spacing={1}>
        {renderContent()}
      </Grid>

      <StandardConfirmationDialog
        variant={'warning'}
        message={message}
        open={openDialog}
        handleClose={handleCloseDialog}
        onConfirm={event => selectedId && deleteContract(selectedId)}
      />
    </Fragment>
  );
};

export default ContentSection;
