import React, { FC } from 'react';
import {
  TableRow,
  makeStyles,
  Theme,
  Typography,
  Switch,
  withStyles,
  Link
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import BodyCell from 'components/BodyCell';
import Skeleton from 'react-loading-skeleton';
import { format } from 'date-fns';

interface Props {
  isLoadingData: boolean;
  document: DocumentModel;
}

const useStyles = makeStyles((theme: Theme) => ({
  tableRow: {
    height: 64
  },
  tableCellInner: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    display: 'inline-flex',
    fontSize: '14px',
    fontWeight: 500,
    height: '36px',
    width: '36px'
  },
  nameTextCell: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  circle: {
    height: theme.spacing(3),
    width: theme.spacing(3),
    borderRadius: '50%',
    display: 'inline-block',
    backgroundColor: green[500],
    marginRight: theme.spacing(1),
    content: "''"
  },
  wrapper: {
    position: 'relative'
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    zIndex: 1,
    top: -4,
    left: -4
  },
  newIcon: {
    color: green[500]
  },
  actionIcon: {
    fontSize: 20
  },
  menuAvatar: {
    backgroundColor: '#ffffff',
    display: 'inline-flex',
    fontSize: '14px',
    fontWeight: 500,
    height: '36px',
    width: '36px',
    color: '#707070'
  },
  menuList: {
    minHeight: 30
  }
}));

const BodyRow: FC<Props> = props => {
  const classes = useStyles();
  const { isLoadingData, document } = props;
  const { id } = document;

  const handleViewDocument = () => {
    window.open(`/candidates-document/detail/${id}`, '_blank');
  };

  return (
    <TableRow className={classes.tableRow}>
      <BodyCell cellWidth='20%' isComponent={true}>
        {isLoadingData ? <Skeleton width={100} /> : <Typography variant='body1'>{document.Candidate.code}</Typography>}
      </BodyCell>
      <BodyCell cellWidth='25%' isComponent={true}>
        {isLoadingData ? <Skeleton width={100} /> : <Typography variant='body1'>{document.User.displayName}</Typography>}
      </BodyCell>
      <BodyCell cellWidth='30%' isComponent={true}>
        {isLoadingData ? <Skeleton width={100} /> : <Typography variant='body1'>{document.nameOfFDW}</Typography>}
      </BodyCell>
      <BodyCell cellWidth='30%' isComponent={true}>
        {isLoadingData ? (
          <Skeleton width={100} />
        ) : (
          <Typography variant='body1'>{document.createdAt ? format(new Date(document.createdAt), 'dd/MM/YYYYY HH:mm') : '-'}</Typography>
        )}
      </BodyCell>
      <BodyCell cellWidth='30%' isComponent={true}>
        {isLoadingData ? (
          <Skeleton width={100} />
        ) : (
          <Typography color='primary' variant='body1'>
            <Link href='#' onClick={handleViewDocument} color='primary'>
              View
            </Link>
          </Typography>
        )}
      </BodyCell>
    </TableRow>
  );
};

export default BodyRow;
