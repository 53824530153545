import { Grid } from '@material-ui/core';
import React, { FC, useState } from 'react';
import CandidateForm from './components/CandidateForm';

interface Props {
  linkVideo: string;
  setLinkVideo: React.Dispatch<React.SetStateAction<string>>;
  fullName: string;
  setFullName: React.Dispatch<React.SetStateAction<string>>;
  fullNameError: string;
  code: string;
  setCode: React.Dispatch<React.SetStateAction<string>>;
  codeError: string;
  passportStatus: string;
  setPassportStatus: React.Dispatch<React.SetStateAction<string>>;
  passportStatusError: string;
  bioFee: number | null;
  setBioFee: React.Dispatch<React.SetStateAction<number | null>>;
  bioFeeError: string;
  loan: number | null;
  setLoan: React.Dispatch<React.SetStateAction<number | null>>;
  loanError: string;
  pocketMoney: number | null;
  setPocketMoney: React.Dispatch<React.SetStateAction<number | null>>;
  pocketMoneyError: string;
  minimumSalary: number | null;
  setMinimumSalary: React.Dispatch<React.SetStateAction<number | null>>;
  minimumSalaryError: string;
  maritalStatus: string;
  setMaritalStatus: React.Dispatch<React.SetStateAction<string>>;
  maritalStatusError: string;
  uploadPhoto: any;
  setUploadPhoto: React.Dispatch<React.SetStateAction<any>>;
  uploadPhotoError: string;
  experienceSG: string;
  setExperinceSG: React.Dispatch<React.SetStateAction<string>>;
  skills: SkillsModel[];
  setSkills: React.Dispatch<React.SetStateAction<SkillsModel[]>>;
  skillsError: string;
  remarks: string;
  setRemarks: React.Dispatch<React.SetStateAction<string>>;
  dob: Date | null;
  setDOB: React.Dispatch<React.SetStateAction<Date | null>>;
  dobError: string;
  placeOfBirth: string;
  setPlaceofBirth: React.Dispatch<React.SetStateAction<string>>;
  placeOfBirthError: string;
  height: number | null;
  setHeight: React.Dispatch<React.SetStateAction<number | null>>;
  heightError: string;
  weight: number | null;
  setWeight: React.Dispatch<React.SetStateAction<number | null>>;
  weightError: string;
  nationality: string;
  setNationality: React.Dispatch<React.SetStateAction<string>>;
  nationalityError: string;
  phoneNumber: string;
  setPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
  phoneNumberError: string;
  residentialAddress: string;
  setResidentialAddress: React.Dispatch<React.SetStateAction<string>>;
  residentialAddressError: string;
  nameOfPort: string;
  setNameOfPort: React.Dispatch<React.SetStateAction<string>>;
  nameOfPortError: string;
  religion: string;
  setReligion: React.Dispatch<React.SetStateAction<string>>;
  religionError: string;
  educationCollege: string;
  setEducationCollege: React.Dispatch<React.SetStateAction<string>>;
  educationCollegeError: string;
  numberOfSiblings: string;
  setNumberOfSiblings: React.Dispatch<React.SetStateAction<string>>;
  numberOfSiblingsError: string;
  addAgeOfChildren: string;
  setAddAgeOfChildren: React.Dispatch<React.SetStateAction<string>>;
  addAgeOfChildrensError: string;
  skillsStatuses: Select[];
  experinceInSGStatuses: Select[];
  maritalStatuses: Select[];
  isSubmitting: boolean;
}

const CandidateProfile: FC<Props> = props => {
  const { isSubmitting } = props;

  const { uploadPhoto, setUploadPhoto, uploadPhotoError } = props;
  const { linkVideo, setLinkVideo } = props;
  const { fullName, setFullName, fullNameError } = props;
  const { code, setCode, codeError } = props;
  const { passportStatus, setPassportStatus, passportStatusError } = props;
  const { bioFee, setBioFee, bioFeeError } = props;
  const { loan, setLoan, loanError } = props;
  const { pocketMoney, setPocketMoney, pocketMoneyError } = props;
  const { minimumSalary, setMinimumSalary, minimumSalaryError } = props;
  const { maritalStatus, setMaritalStatus, maritalStatusError } = props;
  const { experienceSG, setExperinceSG } = props;
  const { skills, setSkills, skillsError } = props;
  const { remarks, setRemarks } = props;

  const { dob, setDOB, dobError } = props;
  const { placeOfBirth, setPlaceofBirth, placeOfBirthError } = props;
  const { height, setHeight, heightError } = props;
  const { weight, setWeight, weightError } = props;
  const { nationality, setNationality, nationalityError } = props;
  const { phoneNumber, setPhoneNumber, phoneNumberError } = props;
  const { residentialAddress, setResidentialAddress, residentialAddressError } = props;
  const { nameOfPort, setNameOfPort, nameOfPortError } = props;
  const { religion, setReligion, religionError } = props;
  const { educationCollege, setEducationCollege, educationCollegeError } = props;
  const { numberOfSiblings, setNumberOfSiblings, numberOfSiblingsError } = props;
  const { addAgeOfChildren, setAddAgeOfChildren, addAgeOfChildrensError } = props;

  const { maritalStatuses } = props;
  const { experinceInSGStatuses } = props;
  const { skillsStatuses } = props;

  return (
    <Grid>
      <Grid container spacing={3} direction='row' justify='center' alignItems='stretch'>
        <CandidateForm
          linkVideo={linkVideo}
          setLinkVideo={setLinkVideo}
          fullName={fullName}
          setFullName={setFullName}
          fullNameError={fullNameError}
          code={code}
          setCode={setCode}
          codeError={codeError}
          passportStatus={passportStatus}
          setPassportStatus={setPassportStatus}
          passportStatusError={passportStatusError}
          bioFee={bioFee}
          setBioFee={setBioFee}
          bioFeeError={bioFeeError}
          loan={loan}
          setLoan={setLoan}
          loanError={loanError}
          pocketMoney={pocketMoney}
          setPocketMoney={setPocketMoney}
          pocketMoneyError={pocketMoneyError}
          minimumSalary={minimumSalary}
          setMinimumSalary={setMinimumSalary}
          minimumSalaryError={minimumSalaryError}
          maritalStatus={maritalStatus}
          setMaritalStatus={setMaritalStatus}
          maritalStatusError={maritalStatusError}
          uploadPhoto={uploadPhoto}
          setUploadPhoto={setUploadPhoto}
          uploadPhotoError={uploadPhotoError}
          experienceSG={experienceSG}
          setExperinceSG={setExperinceSG}
          skills={skills}
          setSkills={setSkills}
          skillsError={skillsError}
          remarks={remarks}
          setRemarks={setRemarks}
          skillsStatuses={skillsStatuses}
          experinceInSGStatuses={experinceInSGStatuses}
          dob={dob}
          setDOB={setDOB}
          dobError={dobError}
          placeOfBirth={placeOfBirth}
          setPlaceofBirth={setPlaceofBirth}
          placeOfBirthError={placeOfBirthError}
          height={height}
          setHeight={setHeight}
          heightError={heightError}
          weight={weight}
          setWeight={setWeight}
          weightError={weightError}
          nationality={nationality}
          setNationality={setNationality}
          nationalityError={nationalityError}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          phoneNumberError={phoneNumberError}
          residentialAddress={residentialAddress}
          setResidentialAddress={setResidentialAddress}
          residentialAddressError={residentialAddressError}
          nameOfPort={nameOfPort}
          setNameOfPort={setNameOfPort}
          nameOfPortError={nameOfPortError}
          religion={religion}
          setReligion={setReligion}
          religionError={religionError}
          educationCollege={educationCollege}
          setEducationCollege={setEducationCollege}
          educationCollegeError={educationCollegeError}
          numberOfSiblings={numberOfSiblings}
          setNumberOfSiblings={setNumberOfSiblings}
          numberOfSiblingsError={numberOfSiblingsError}
          maritalStatuses={maritalStatuses}
          addAgeOfChildren={addAgeOfChildren}
          setAddAgeOfChildren={setAddAgeOfChildren}
          addAgeOfChildrensError={addAgeOfChildrensError}
          isSubmitting={isSubmitting}
        />
      </Grid>
    </Grid>
  );
};

export default CandidateProfile;
