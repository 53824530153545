import React, { FC } from 'react';
import { Grid } from '@material-ui/core';
import SkillForm from './components/SkillForm';

interface Props {
  namesSINGEA: string[];
  setNamesSINGEA: React.Dispatch<React.SetStateAction<string[]>>;
  areasWorksSINGEA: string[];
  setAreasWorksSINGEA: React.Dispatch<React.SetStateAction<string[]>>;
  subSkillsFDWIdSINGEA: SubSkillsModel[];
  setSubSkillsFDWIdSINGEA: React.Dispatch<React.SetStateAction<SubSkillsModel[]>>;
  namesTCEA: string[];
  setNamesTCEA: React.Dispatch<React.SetStateAction<string[]>>;
  areasWorksTCEA: string[];
  setAreasWorksTCEA: React.Dispatch<React.SetStateAction<string[]>>;
  subSkillsFDWIdTCEA: SubSkillsModel[];
  setSubSkillsFDWIdTCEA: React.Dispatch<React.SetStateAction<SubSkillsModel[]>>;
  isSubmitting: boolean;
}

const CandidateSkill: FC<Props> = props => {
  const { namesSINGEA, setNamesSINGEA } = props;
  const { areasWorksSINGEA, setAreasWorksSINGEA } = props;
  const { subSkillsFDWIdSINGEA, setSubSkillsFDWIdSINGEA } = props;

  const { namesTCEA, setNamesTCEA } = props;
  const { areasWorksTCEA, setAreasWorksTCEA } = props;
  const { subSkillsFDWIdTCEA, setSubSkillsFDWIdTCEA } = props;

  const { isSubmitting } = props;

  return (
    <Grid>
      <SkillForm
        namesSINGEA={namesSINGEA}
        setNamesSINGEA={setNamesSINGEA}
        areasWorksSINGEA={areasWorksSINGEA}
        setAreasWorksSINGEA={setAreasWorksSINGEA}
        subSkillsFDWIdSINGEA={subSkillsFDWIdSINGEA}
        setSubSkillsFDWIdSINGEA={setSubSkillsFDWIdSINGEA}
        namesTCEA={namesTCEA}
        setNamesTCEA={setNamesTCEA}
        areasWorksTCEA={areasWorksTCEA}
        setAreasWorksTCEA={setAreasWorksTCEA}
        subSkillsFDWIdTCEA={subSkillsFDWIdTCEA}
        setSubSkillsFDWIdTCEA={setSubSkillsFDWIdTCEA}
        isSubmitting={isSubmitting}
      />
    </Grid>
  );
};

export default CandidateSkill;
