import { Backdrop, Button, ButtonBase, Container, Fade, Grid, makeStyles, Modal, Theme, Typography } from '@material-ui/core';
import axios, { CancelTokenSource } from 'axios';
import { Breadcrumb, Page, PaperCustom } from 'components';
import { CurrentPageContext } from 'contexts/CurrentPageContext';
import useCurrentPageTitleUpdater from 'hooks/useCurrentPageTitleUpdater';
import useRouter from 'hooks/useRouter';
import imageLoader from 'images/imageLoader.gif';

import React, { useContext, useEffect, useState } from 'react';
import { GET_DOCUMENT_BY_ID_URL, GET_DOCUMENT_EXPORT_PDF_BY_ID_URL } from 'constants/url';
import { dummyExtendFieldDocument, dummyDocument } from 'utils/dummy';
import DocumentDetail from './components/DocumentDetail';
import IconBackButton from '../../images/backButton.svg';

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  loader: {
    width: '50%',
    marginLeft: theme.spacing(-1.5)
  },
}));

const DocumentDetailPage: React.FC = () => {
  useCurrentPageTitleUpdater('Documents Detail');
  const { currentPageTitle } = useContext(CurrentPageContext);
  const { history, location, match } = useRouter();
  const params = match.params.id;

  const classes = useStyles();

  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [isLoadingPage, setLoadingPage] = useState<boolean>(false);
  const [document, setDocument] = useState<DocumentModel>(dummyDocument);
  const [clothesWashing, setClothesWashing] = useState<ExtendFieldDocumentModel>(dummyExtendFieldDocument);
  const [takeCarePets, setTakeCarePets] = useState<ExtendFieldDocumentModel>(dummyExtendFieldDocument);
  const [gardening, setGardening] = useState<ExtendFieldDocumentModel>(dummyExtendFieldDocument);

  useEffect(() => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

    const loadData = async () => {
      setIsLoadingData(true);
      try {
        const { data } = await axios.get(`${GET_DOCUMENT_BY_ID_URL(params)}`, { cancelToken: cancelTokenSource.token });

        const documentData: DocumentModel = data.document;
        const extendFieldDocumentData: ExtendFieldDocumentModel[] = [...data.document.ExtendFieldDocuments];

        const clothesWashingData = extendFieldDocumentData.filter(value => value.name === 'Clothes Washing by');
        const takeCarePetsData = extendFieldDocumentData.filter(value => value.name === 'Take Care of Pet(s)');
        const gardeningData = extendFieldDocumentData.filter(value => value.name === 'Gardening');

        setDocument(documentData);

        if (clothesWashingData.length) {
          setClothesWashing(clothesWashingData[0]);
        }

        if (takeCarePetsData.length) {
          setTakeCarePets(takeCarePetsData[0]);
        }

        if (gardeningData.length) {
          setGardening(gardeningData[0]);
        }

        setIsLoadingData(false);
      } catch (err) {
        console.log(err);
        setIsLoadingData(true);
      }
    };
    loadData();
    return () => {
      cancelTokenSource.cancel();
    };
  }, [params]);

  const handleBack = () => {
    const path = location.pathname.split('/').filter(x => x);
    history.push(`/${path.slice(0, 0 + 1).join('/')}`);
  };

  const handleExport = async () => {
    setLoadingPage(true);
    const response = await axios({
      url: GET_DOCUMENT_EXPORT_PDF_BY_ID_URL(params),
      method: 'GET',
      responseType: 'blob'
    });
    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
    const newwindow = window.open(url, 'name', 'height=700,width=750');
    if (newwindow && window.focus) {
      newwindow.focus();
    }
    setLoadingPage(false);
  };

  return (
    <Page title={currentPageTitle}>
      <Container>
        <Grid container direction='row' spacing={1}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant='subtitle1' component='h3'>
              {currentPageTitle}
            </Typography>
            <Typography variant='h6' component='h6' color='textSecondary'>
              <Breadcrumb nameLast={document.employeName} />
            </Typography>
            <ButtonBase focusRipple component='span' disableRipple onClick={handleBack}>
              <img src={IconBackButton} />
            </ButtonBase>
            <Grid container item xs={12} justify='flex-end'>
              <Button variant='outlined' color='primary' onClick={handleExport}>
                EXPORT .PDF
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <PaperCustom>
          <DocumentDetail
            isLoadingData={isLoadingData}
            clothesWashing={clothesWashing}
            takeCarePets={takeCarePets}
            gardening={gardening}
            document={document}
          />
        </PaperCustom>
      </Container>
      <Modal
        aria-labelledby='spring-modal-title'
        aria-describedby='spring-modal-description'
        className={classes.modal}
        open={isLoadingPage}
        disableBackdropClick
        disableAutoFocus
        disableEnforceFocus
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={isLoadingPage}>
          <img src={imageLoader} alt='imageLoader' className={classes.loader} />
        </Fade>
      </Modal>
    </Page>
  );
};

export default DocumentDetailPage;
