import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Dialog, DialogContent, DialogTitle, IconButton, Theme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MedicalHistoryForm from './MedicalHistoryForm';
import axios, { CancelTokenSource } from 'axios';
import { GET_EDIT_MEDICAL_HISTORY_URL } from 'constants/url';

interface Props {
  open: boolean;
  medicalHistory: MedicalHistoryModel;
  setMedicalHistory: React.Dispatch<React.SetStateAction<MedicalHistoryModel>>;
  handleCancel(): void;
  setOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarVarient: React.Dispatch<React.SetStateAction<'success' | 'error'>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  contentGrid: {
    borderRadius: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#0B3469',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0)
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  saveButton: {
    color: '#FFFFFF'
  },
  cancelButton: {
    marginRight: theme.spacing(3)
  },
  controlDiv: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2)
  }
}));

const MedicalHistoryModal: React.FC<Props> = props => {
  const classes = useStyles();

  let cancelTokenSource: CancelTokenSource;
  const { open, medicalHistory, handleCancel, setOpenSnackbar, setSnackbarVarient, setMedicalHistory } = props;

  const [isLoading, setLoading] = useState<boolean>(false);

  const [medicalHistoryId, setMedicalHistoryId] = useState<number>(0);
  const [allergies, setAllergies] = useState<string>('');
  const [dietaryRestrictions, setDietaryrestrictions] = useState<string>('');
  const [mentalIllness, setMentalIllness] = useState<boolean>(false);
  const [tuberculosis, setTuberculosis] = useState<boolean>(false);
  const [physicalDisabilities, setPhysicaldisabilities] = useState<string>('');
  const [heartDisease, setHeartdisease] = useState<boolean>(false);
  const [othersPastExisting, setOtherspastexisting] = useState<string>('');
  const [othersFood, setOthersfood] = useState<string>('');
  const [malaria, setMalaria] = useState<boolean>(false);
  const [operations, setOperations] = useState<boolean>(false);
  const [epilepsy, setEpilepsy] = useState<boolean>(false);
  const [asthma, setAsthma] = useState<boolean>(false);
  const [diabetes, setDiabetes] = useState<boolean>(false);
  const [hypertension, setHypertension] = useState<boolean>(false);
  const [pork, setPork] = useState<boolean>(false);
  const [beef, setBeef] = useState<boolean>(false);
  const [preferenceForRestDay, setPreferenceforrestday] = useState<string | null>(null);
  const [remarks, setRemarks] = useState<string>('');

  // This is to ensure that the form vale and erors are reset/cleared when user canceled the editing
  const resetFormValues = useCallback(() => {

    if (medicalHistory) {
      const {
        id,
        allergies,
        dietaryRestrictions,
        mentalIllness,
        tuberculosis,
        physicalDisabilities,
        heartDisease,
        othersPastExisting,
        othersFood,
        malaria,
        operations,
        epilepsy,
        asthma,
        diabetes,
        hypertension,
        pork,
        beef,
        preferenceForRestDay,
        remarks
      } = medicalHistory!;
  
      setMedicalHistoryId(id);
      setAllergies(allergies);
      setDietaryrestrictions(dietaryRestrictions);
      setMentalIllness(mentalIllness);
      setTuberculosis(tuberculosis);
      setPhysicaldisabilities(physicalDisabilities);
      setHeartdisease(heartDisease);
      setOtherspastexisting(othersPastExisting);
      setOthersfood(othersFood);
      setMalaria(malaria);
      setOperations(operations);
      setEpilepsy(epilepsy);
      setAsthma(asthma);
      setDiabetes(diabetes);
      setHypertension(hypertension);
      setPork(pork);
      setBeef(beef);
      setPreferenceforrestday(preferenceForRestDay);
      setRemarks(remarks);
    }
    
  }, [medicalHistory]);

  useEffect(() => {
    resetFormValues();
  }, [resetFormValues]);

  const handleOnClose = () => {
    resetFormValues();
    handleCancel();
  };

  const handleOnSubmit: React.FormEventHandler = async event => {
    event.preventDefault();
    setLoading(true);
    try {
      cancelTokenSource = axios.CancelToken.source();

      const { data } = await axios.put(
        `${GET_EDIT_MEDICAL_HISTORY_URL(medicalHistoryId)}`,
        {
          allergies,
          dietaryRestrictions,
          mentalIllness,
          tuberculosis,
          physicalDisabilities,
          heartDisease,
          othersPastExisting,
          othersFood,
          malaria,
          operations,
          epilepsy,
          asthma,
          diabetes,
          hypertension,
          pork,
          beef,
          preferenceForRestDay,
          remarks
        },
        { cancelToken: cancelTokenSource.token }
      );

      setMedicalHistory(data);
      setOpenSnackbar(true);
      setSnackbarVarient('success');
      handleCancel();
    } catch (err) {
      console.log(err);
      setOpenSnackbar(true);
      setSnackbarVarient('error');
    }
    setLoading(false);
  };

  return (
    <Dialog open={open} scroll='body' fullWidth={true} maxWidth='md'>
      <DialogTitle>
        <IconButton size='small' className={classes.closeButton} onClick={handleOnClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <MedicalHistoryForm
          allergies={allergies}
          setAllergies={setAllergies}
          dietaryRestrictions={dietaryRestrictions}
          setDietaryrestrictions={setDietaryrestrictions}
          mentalIllness={mentalIllness}
          setMentalIllness={setMentalIllness}
          tuberculosis={tuberculosis}
          setTuberculosis={setTuberculosis}
          physicalDisabilities={physicalDisabilities}
          setPhysicaldisabilities={setPhysicaldisabilities}
          heartDisease={heartDisease}
          setHeartdisease={setHeartdisease}
          othersPastExisting={othersPastExisting}
          setOtherspastexisting={setOtherspastexisting}
          othersFood={othersFood}
          setOthersfood={setOthersfood}
          malaria={malaria}
          setMalaria={setMalaria}
          operations={operations}
          setOperations={setOperations}
          epilepsy={epilepsy}
          setEpilepsy={setEpilepsy}
          asthma={asthma}
          setAsthma={setAsthma}
          diabetes={diabetes}
          setDiabetes={setDiabetes}
          hypertension={hypertension}
          setHypertension={setHypertension}
          pork={pork}
          setPork={setPork}
          beef={beef}
          setBeef={setBeef}
          preferenceForRestDay={preferenceForRestDay}
          setPreferenceforrestday={setPreferenceforrestday}
          remarks={remarks}
          setRemarks={setRemarks}
          isSubmitting={isLoading}
          onSubmit={handleOnSubmit}
          onCancel={handleOnClose}
        />
      </DialogContent>
    </Dialog>
  );
};

export default MedicalHistoryModal;
