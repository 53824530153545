import { Box, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import React, { FC } from 'react';
import Skeleton from 'react-loading-skeleton';

interface Props {
  isLoadingData: boolean;
  document: DocumentModel;
  clothesWashing: ExtendFieldDocumentModel;
  takeCarePets: ExtendFieldDocumentModel;
  gardening: ExtendFieldDocumentModel;
}

const useStyles = makeStyles((theme: Theme) => ({
  dottedBorderBox: {
    width: '246px',
    height: '115px',
    border: '1px dashed grey',
    borderRadius: 10
  },
  gridSignature: {
    paddingTop: theme.spacing(2)
  },
  signatureImage: {
    width: '246px'
  }
}));

const DocumentDetail: FC<Props> = props => {
  const classes = useStyles();
  const { isLoadingData, document, clothesWashing, takeCarePets, gardening } = props;

  return (
    <Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant='body1'>ID</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body1' color='primary'>
                {isLoadingData ? <Skeleton width={20} /> : document.Candidate.code}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant='body1'>Date</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body1' color='primary'>
                {isLoadingData ? <Skeleton width={20} /> : document.createdAt && format(new Date(document.createdAt), 'dd/MM/yyyy')}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant='body1'>Agency Name</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body1' color='primary'>
                {isLoadingData ? <Skeleton width={20} /> : document.User.displayName}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant='body1'>Name of Employer</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body1' color='primary'>
                {isLoadingData ? <Skeleton width={20} /> : document.nameOfEmployer}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant='body1'>Name of FDW</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body1' color='primary'>
                {isLoadingData ? <Skeleton width={20} /> : document.nameOfFDW}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant='body1'>Race</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body1' color='primary'>
                {isLoadingData ? <Skeleton width={20} /> : document.race}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant='body1'>Salary</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body1' color='primary'>
                {isLoadingData ? <Skeleton width={20} /> : document.salary}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant='body1'>Day(s) off</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body1' color='primary'>
                {isLoadingData ? <Skeleton width={20} /> : document.dayOff}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant='body1'>Probation</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body1' color='primary'>
                {isLoadingData ? <Skeleton width={20} /> : document.probation}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant='body1'>Type of house</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body1' color='primary'>
                {isLoadingData ? <Skeleton width={20} /> : document.typeOfHouse}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant='body1'>No of bedroom</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body1' color='primary'>
                {isLoadingData ? <Skeleton width={20} /> : document.numberOfBedroom}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant='body1'>No of toilets</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body1' color='primary'>
                {isLoadingData ? <Skeleton width={20} /> : document.numberOfToilet}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant='body1'>Total no of family</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body1' color='primary'>
                {isLoadingData ? <Skeleton width={20} /> : document.ageOfFirstChildBelowTwelve ? String(document.ageOfFirstChildBelowTwelve).split(',').length : 0}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant='body1'>No of children below 12</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body1' color='primary'>
                {isLoadingData ? <Skeleton width={20} /> : (document.ageOfFirstChildBelowTwelve ? document.ageOfFirstChildBelowTwelve : '-')}
              </Typography>
            </Grid>
          </Grid>
          {isLoadingData ? (
            <Skeleton width={20} />
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant='body2' color='textSecondary'>
                  {document.ageOfFirstChildBelowTwelve}
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant='body1'>No of children below 5</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body1' color='primary'>
                {isLoadingData ? <Skeleton width={20} /> : (document.ageOfFirstChildBelowFive ? document.ageOfFirstChildBelowFive : '-')}
              </Typography>
            </Grid>
          </Grid>
          {isLoadingData ? (
            <Skeleton width={20} />
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant='body2' color='textSecondary'>
                  {document.ageOfFirstChildBelowFive}
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant='body1'>Baby (if any)</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body1' color='primary'>
                {isLoadingData ? <Skeleton width={20} /> : document.numberOfBabies}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant='body1'>Fetch children to & back from school</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body1' color='primary'>
                {isLoadingData ? <Skeleton width={20} /> : document.fetchChildrenToBackFromSchool ? 'Yes' : 'No'}
              </Typography>
            </Grid>
          </Grid>
          {isLoadingData ? (
            <Skeleton width={20} />
          ) : (
            document.fetchChildrenToBackFromSchool &&
            document.waitDownStairs && (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant='body2' color='textSecondary'>
                    Wait Downstairs
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant='body1' color='primary'>
                    {document.waitDownStairs}
                  </Typography>
                </Grid>
              </Grid>
            )
          )}
          {isLoadingData ? (
            <Skeleton width={20} />
          ) : (
            document.fetchChildrenToBackFromSchool &&
            document.busOnly && (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant='body2' color='textSecondary'>
                    Bus Only
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant='body1' color='primary'>
                    {document.busOnly}
                  </Typography>
                </Grid>
              </Grid>
            )
          )}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant='body1'>Look after elderly</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body1' color='primary'>
                {isLoadingData ? <Skeleton width={20} /> : document.lookAfterElderly ? 'Yes' : 'No'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant='body1'>Bedridden patient</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body1' color='primary'>
                {isLoadingData ? <Skeleton width={20} /> : document.bedriddenPatient ? 'Yes' : 'No'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant='body1'>General house work</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body1' color='primary'>
                {isLoadingData ? <Skeleton width={20} /> : document.generalHouseWork ? 'Yes' : 'No'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant='body1'>Ironing of clothes</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body1' color='primary'>
                {isLoadingData ? <Skeleton width={20} /> : document.ironingOfClothes ? 'Yes' : 'No'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant='body1'>Cooking</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body1' color='primary'>
                {isLoadingData ? <Skeleton width={20} /> : document.cooking ? 'Yes' : 'No'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant='body1'>Car washing</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body1' color='primary'>
                {isLoadingData ? <Skeleton width={20} /> : document.carWashing ? 'Yes' : 'No'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant='body1'>Clothes washing by</Typography>
            </Grid>
            <Grid item xs={6}>
              {console.log(clothesWashing)}
              {isLoadingData ? (
                <Skeleton width={20} />
              ) : clothesWashing.hand ? (
                <Typography variant='body1' color='primary'>
                  Hand
                </Typography>
              ) : (
                <Typography variant='body1' color='primary'>
                  -
                </Typography>
              )}
              {isLoadingData ? (
                <Skeleton width={20} />
              ) : clothesWashing.machine ? (
                <Typography variant='body1' color='primary'>
                  Machine
                </Typography>
              ) : (
                <Typography variant='body1' color='primary'>
                  -
                </Typography>
              )}
            </Grid>
          </Grid>
          
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant='body1'>Take care of (pets)</Typography>
            </Grid>
            <Grid item xs={6}>
              {isLoadingData ? (
                <Skeleton width={20} />
              ) : (
                takeCarePets.hand ? (
                  <Typography variant='body1' color='primary'>
                    Hand
                  </Typography>
                ) : (
                  <Typography variant='body1' color='primary'>
                    -
                  </Typography>
                )
              )}
              {isLoadingData ? (
                <Skeleton width={20} />
              ) : (
                takeCarePets.machine ? (
                  <Typography variant='body1' color='primary'>
                    Machine
                  </Typography>
                ) : (
                  <Typography variant='body1' color='primary'>
                    -
                  </Typography>
                )
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant='body1'>Gardening</Typography>
            </Grid>
            <Grid item xs={6}>
              {isLoadingData ? (
                <Skeleton width={20} />
              ) : (
                gardening.hand ? (
                  <Typography variant='body1' color='primary'>
                    Hand
                  </Typography>
                ) : (
                  <Typography variant='body1' color='primary'>
                    -
                  </Typography>
                )
              )}
              {isLoadingData ? (
                <Skeleton width={20} />
              ) : (
                gardening.machine ? (
                  <Typography variant='body1' color='primary'>
                    Machine
                  </Typography>
                ) : (
                  <Typography variant='body1' color='primary'>
                    -
                  </Typography>
                )
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.gridSignature}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box className={classes.dottedBorderBox} />
            </Grid>
            <Grid item xs={6}>
              <Box className={classes.dottedBorderBox}>
                {document.signature && <img src={document.signature} alt='Signature' className={classes.signatureImage} />}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant='body1'>Helper’s Signature</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body1'>Employer’s Signature</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DocumentDetail;
