const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const LOGIN_URL = `${BASE_URL}/login`;
export const LOGOUT_URL = `${BASE_URL}/logout`;
export const FORGOT_PASSWORD_URL = `${BASE_URL}/forgotpassword`;
export const RESET_PASSWORD_URL = `${BASE_URL}/resetpassword`;
export const CHANGE_PASSWORD_URL = `${BASE_URL}/changepassword`;

export const USER_BASE_URL = `${BASE_URL}/users`;
export const GET_CURRENT_USER_URL = `${USER_BASE_URL}/current`;
export const GET_EDIT_USER_URL = (userId: number) => `${USER_BASE_URL}/${userId}`;
export const GET_DEACTIVATE_USER_URL = (userId: number) => `${USER_BASE_URL}/${userId}`;
export const GET_ACTIVATE_USER_URL = (userId: number) => `${USER_BASE_URL}/${userId}/activate`;

export const CANDIDATE_BASE_URL = `${BASE_URL}/candidates`;
export const CANDIDATE_GLOBAL_SEARCH_URL = `${CANDIDATE_BASE_URL}/globalsearch`;
export const GET_CANDIDATE_BY_ID_URL = (candidateId?: string) => `${CANDIDATE_BASE_URL}/${candidateId}`;
export const GET_EDIT_CANDIDATE_URL = (candidateId: number) => `${CANDIDATE_BASE_URL}/${candidateId}`;
export const GET_DOWNLOAD_FILE_URL = (fileKey: string) => `${CANDIDATE_BASE_URL}/downloadDocument/${fileKey}`
export const GET_DOWNLOAD_PICTURE_URL = (fileKey: string) => `${CANDIDATE_BASE_URL}/downloadPicture/${fileKey}`;
export const GET_DOWNLOAD_PDF_URL = (fileKey: string) => `${CANDIDATE_BASE_URL}/downloadPdf/${fileKey}`;
export const GET_CANDIDATE_PHOTO_URL = (photo: string) => `${CANDIDATE_BASE_URL}/getimage/${photo}`;
export const GET_EXPORT_PDF_URL = (candidateId: number) => `${CANDIDATE_BASE_URL}/exportPDF/${candidateId}`;
export const GET_DELETE_CANDIDATES = (candidateId: number) => `${CANDIDATE_BASE_URL}/${candidateId}`;

export const DOCUMENT_UPLOAD_FILE_BASE_URL = `${BASE_URL}/document-upload-files`;
export const GET_DELETE_DOCUMENT_UPLOAD_FILE_BASE_URL = (documentId: number[]) => `${DOCUMENT_UPLOAD_FILE_BASE_URL}/${documentId}`;

export const MEDICAL_HISTORY_BASE_URL = `${BASE_URL}/medicalhistory`;
export const GET_EDIT_MEDICAL_HISTORY_URL = (medicalHistoryId: number) => `${MEDICAL_HISTORY_BASE_URL}/${medicalHistoryId}`;

export const SKILL_BASE_URL = `${BASE_URL}/skills`;

export const SKILL_FDW_BASE_URL = `${BASE_URL}/sub-skill-fdw`;
export const GET_EDIT_BULK_SKILL_FDW_URL = `${SKILL_FDW_BASE_URL}/bulk-edit`;

export const JOB_EXPERIENCE_BASE_URL = `${BASE_URL}/jobexperiences`;
export const GET_EDIT_JOB_EXPERIENCE_URL = (jobExperienceId: number) => `${JOB_EXPERIENCE_BASE_URL}/${jobExperienceId}`;
export const GET_DELETE_JOB_EXPERIENCE = (jobExperienceId: number) => `${JOB_EXPERIENCE_BASE_URL}/${jobExperienceId}`;

export const RESUME_BASE_URL = `${BASE_URL}/resume`;

export const YOUTUBE_DOWNLOADER_BASE_URL = `${BASE_URL}/youtube-downloader`;
export const YOUTUBE_DOWNLOADER_SUGGESTIONS_URL = `${YOUTUBE_DOWNLOADER_BASE_URL}/suggestions`;
export const YOUTUBE_DOWNLOADER_METAINFO_URL = `${YOUTUBE_DOWNLOADER_BASE_URL}/metainfo`;
export const YOUTUBE_DOWNLOADER_WATCH_URL = `${YOUTUBE_DOWNLOADER_BASE_URL}/watch-yt`;

export const FEEDBACK_EMPLOYER_BASE_URL = `${BASE_URL}/feedback-employer`;
export const GET_EDIT_FEEDBACK_EMPLOYER_BASE_URL = (feedbackEmployerId: number) => `${FEEDBACK_EMPLOYER_BASE_URL}/${feedbackEmployerId}`;

export const DOCUMENT_BASE_URL = `${BASE_URL}/documents`;
export const GET_DOCUMENT_BY_ID_URL = (documentId?: string) => `${DOCUMENT_BASE_URL}/${documentId}`;
export const GET_DOCUMENT_EXPORT_PDF_BY_ID_URL = (documentId?: string) => `${DOCUMENT_BASE_URL}/exportPdf/${documentId}`;
