import React, { FC, useCallback, useState } from 'react';
import { Grid, makeStyles, Theme } from '@material-ui/core';
import CandidateHistoryForm from './components/CandidateHistoryForm';
import EmploymentHistoryOverseasModal from './components/EmploymentHistoryOverseasModal';
import { format } from 'date-fns';

interface Props {
  jobExperiences: JobExperienceModel[];
  feedbackEmployer: FeedbackEmployerModel;
  setJobExperiences: React.Dispatch<React.SetStateAction<JobExperienceModel[]>>;
  setFeedbackEmployer: React.Dispatch<React.SetStateAction<FeedbackEmployerModel>>;
}

const CandidateHistoryFDW: FC<Props> = props => {
  const { jobExperiences, setFeedbackEmployer, setJobExperiences, feedbackEmployer } = props;

  const [dateFrom, setDatefrom] = useState<string>(format(new Date(), 'yyyy'));
  const [dateTo, setDateto] = useState<string>(format(new Date(), 'yyyy'));
  const [country, setCountry] = useState<string>('');
  const [employer, setEmployer] = useState<string>('');
  const [workDuties, setWorkduties] = useState<string>('');
  const [remarksOverseas, setRemarksOverseas] = useState<string>('');

  const [openAddEmploymentOverseasModal, setOpenEditEmploymentOverseasModal] = useState<boolean>(false);

  const handleCancelHistory = () => {
    setOpenEditEmploymentOverseasModal(false);
  };

  const handleOnAddHistory = () => {
    if (jobExperiences.length >= 4) {
      return;
    }

    setOpenEditEmploymentOverseasModal(true);
  };

  const resetFormValues = useCallback(() => {
    setDatefrom(format(new Date(), 'yyyy'));
    setDateto(format(new Date(), 'yyyy'));
    setCountry('');
    setEmployer('');
    setWorkduties('');
    setRemarksOverseas('');
  }, []);

  const handleOnAdd = () => {
    const currentCandidateHistorysFDW = [...jobExperiences];

    const newDateFrom = dateFrom ? dateFrom : format(new Date(), 'yyyy');
    const newDateTo = dateTo ? dateTo : format(new Date(), 'yyyy');

    currentCandidateHistorysFDW.push({
      dateFrom: newDateFrom,
      dateTo: newDateTo,
      country,
      employer,
      CandidateId: 0,
      workDuties,
      remarks: remarksOverseas
    });

    setJobExperiences(currentCandidateHistorysFDW);
    setOpenEditEmploymentOverseasModal(false);
    resetFormValues();
  };

  return (
    <Grid>
      <CandidateHistoryForm
        candidateHistorysFDW={jobExperiences}
        setCandidateHistorysFDW={setJobExperiences}
        feedbackEmployer={feedbackEmployer}
        setFeedbackEmployer={setFeedbackEmployer}
        onAddHistory={handleOnAddHistory}
      />
      <EmploymentHistoryOverseasModal
        open={openAddEmploymentOverseasModal}
        dateFrom={dateFrom}
        setDatefrom={setDatefrom}
        dateTo={dateTo}
        setDateto={setDateto}
        country={country}
        setCountry={setCountry}
        employer={employer}
        setEmployer={setEmployer}
        workDuties={workDuties}
        setWorkduties={setWorkduties}
        remarks={remarksOverseas}
        setRemarks={setRemarksOverseas}
        onAdd={handleOnAdd}
        onCancel={handleCancelHistory}
      />
    </Grid>
  );
};

export default CandidateHistoryFDW;
