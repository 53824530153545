import React from 'react';
import { Button, Grid, TextField, Theme, Typography, withStyles, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { orange } from '@material-ui/core/colors';
import { PRIMARY_COLOR } from 'constants/colors';

interface Props {
  allergies: string;
  setAllergies: React.Dispatch<React.SetStateAction<string>>;
  dietaryRestrictions: string;
  setDietaryrestrictions: React.Dispatch<React.SetStateAction<string>>;
  mentalIllness: boolean;
  setMentalIllness: React.Dispatch<React.SetStateAction<boolean>>;
  tuberculosis: boolean;
  setTuberculosis: React.Dispatch<React.SetStateAction<boolean>>;
  physicalDisabilities: string;
  setPhysicaldisabilities: React.Dispatch<React.SetStateAction<string>>;
  heartDisease: boolean;
  setHeartdisease: React.Dispatch<React.SetStateAction<boolean>>;
  othersPastExisting: string;
  setOtherspastexisting: React.Dispatch<React.SetStateAction<string>>;
  othersFood: string;
  setOthersfood: React.Dispatch<React.SetStateAction<string>>;
  malaria: boolean;
  setMalaria: React.Dispatch<React.SetStateAction<boolean>>;
  operations: boolean;
  setOperations: React.Dispatch<React.SetStateAction<boolean>>;
  epilepsy: boolean;
  setEpilepsy: React.Dispatch<React.SetStateAction<boolean>>;
  asthma: boolean;
  setAsthma: React.Dispatch<React.SetStateAction<boolean>>;
  diabetes: boolean;
  setDiabetes: React.Dispatch<React.SetStateAction<boolean>>;
  hypertension: boolean;
  setHypertension: React.Dispatch<React.SetStateAction<boolean>>;
  pork: boolean;
  setPork: React.Dispatch<React.SetStateAction<boolean>>;
  beef: boolean;
  setBeef: React.Dispatch<React.SetStateAction<boolean>>;
  preferenceForRestDay: string | null;
  setPreferenceforrestday: React.Dispatch<React.SetStateAction<string | null>>;
  remarks: string;
  setRemarks: React.Dispatch<React.SetStateAction<string>>;
  isSubmitting: boolean;
  onSubmit: React.FormEventHandler;
  onCancel: React.MouseEventHandler;
}

const useStyles = makeStyles((theme: Theme) => ({
  textPasExisting: {
    position: 'relative',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)'
  },
  contentGrid: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0)
  },
  controlDiv: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2)
  },
  cancelButton: {
    marginRight: theme.spacing(3)
  }
}));

const SaveButton = withStyles(theme => ({
  root: {
    color: '#FFFFFF',
    backgroundColor: PRIMARY_COLOR,
    '&:hover': {
      backgroundColor: orange[700]
    }
  }
}))(Button);

const MedicalHistoryForm: React.FC<Props> = props => {
  const classes = useStyles(props);

  const { allergies, setAllergies } = props;
  const { dietaryRestrictions, setDietaryrestrictions } = props;
  const { mentalIllness, setMentalIllness } = props;
  const { tuberculosis, setTuberculosis } = props;
  const { physicalDisabilities, setPhysicaldisabilities } = props;
  const { heartDisease, setHeartdisease } = props;
  const { othersPastExisting, setOtherspastexisting } = props;
  const { othersFood, setOthersfood } = props;
  const { malaria, setMalaria } = props;
  const { operations, setOperations } = props;
  const { epilepsy, setEpilepsy } = props;
  const { asthma, setAsthma } = props;
  const { diabetes, setDiabetes } = props;
  const { hypertension, setHypertension } = props;
  const { pork, setPork } = props;
  const { beef, setBeef } = props;
  const { preferenceForRestDay, setPreferenceforrestday } = props;
  const { remarks, setRemarks } = props;

  const { isSubmitting, onCancel, onSubmit } = props;

  return (
    <form noValidate onSubmit={onSubmit}>
      <Grid className={classes.contentGrid}>
        <Typography variant='h4' id='modal-title' color='primary'>
          EDIT REST DAY
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              variant='outlined'
              margin='normal'
              fullWidth
              id='preferenceForRestDay'
              label='Preference for Rest Day per month'
              value={preferenceForRestDay}
              onChange={event => setPreferenceforrestday(event.target.value)}
              autoComplete='off'
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant='outlined'
              margin='normal'
              fullWidth
              id='remarks'
              label='Remark'
              value={remarks}
              onChange={event => setRemarks(event.target.value)}
              autoComplete='off'
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container item justify='center' xs={12} sm={12} md={12} lg={12} xl={12} className={classes.controlDiv}>
        <Button variant='contained' className={classes.cancelButton} disabled={isSubmitting} onClick={onCancel}>
          Cancel
        </Button>
        <SaveButton type='submit' variant='contained' disabled={isSubmitting}>
          Save
        </SaveButton>
      </Grid>
    </form>
  );
};

export default MedicalHistoryForm;
