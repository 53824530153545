import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Dialog, DialogContent, DialogTitle, IconButton, Theme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import axios, { CancelTokenSource } from 'axios';
import { GET_EDIT_BULK_SKILL_FDW_URL } from 'constants/url';
import { dummySubSkillFDW } from 'utils/dummy';
import SkillForm from './SkillForm';

interface Props {
  open: boolean;
  candidateId: number;
  candidateSkillsFDW: SkillsFDWModel[];
  setCandidateSkillsFDW: React.Dispatch<React.SetStateAction<SkillsFDWModel[]>>;
  handleCancel(): void;
  setOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarVarient: React.Dispatch<React.SetStateAction<'success' | 'error'>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  contentGrid: {
    borderRadius: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#0B3469',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0)
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  saveButton: {
    color: '#FFFFFF'
  },
  cancelButton: {
    marginRight: theme.spacing(3)
  },
  controlDiv: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2)
  }
}));

const EditSkillModal: React.FC<Props> = props => {
  const classes = useStyles();

  let cancelTokenSource: CancelTokenSource;
  const {
    open,
    candidateId,
    candidateSkillsFDW,
    handleCancel,
    setOpenSnackbar,
    setSnackbarVarient,
    setCandidateSkillsFDW
  } = props;

  const [isLoading, setLoading] = useState<boolean>(false);

  const [namesSINGEA, setNamesSINGEA] = useState<string[]>([]);
  const [areasWorksSINGEA, setAreasWorksSINGEA] = useState<string[]>([]);
  const [subSkillsFDWIdSINGEA, setSubSkillsFDWIdSINGEA] = useState<SubSkillsModel[]>([dummySubSkillFDW]);

  const [namesTCEA, setNamesTCEA] = useState<string[]>([]);
  const [areasWorksTCEA, setAreasWorksTCEA] = useState<string[]>([]);
  const [subSkillsFDWIdTCEA, setSubSkillsFDWIdTCEA] = useState<SubSkillsModel[]>([dummySubSkillFDW]);

  // This is to ensure that the form vale and erors are reset/cleared when user canceled the editing
  const resetFormValues = useCallback(() => {

    const skillsSINGEA = candidateSkillsFDW!.filter(skill => skill.areasWork === 'SINGEA');
    const skillsTCEA = candidateSkillsFDW!.filter(skill => skill.areasWork === 'TCEA');

    setNamesSINGEA(skillsSINGEA.map(value => value.name));
    setAreasWorksSINGEA(skillsSINGEA.map(value => value.areasWork));
    setSubSkillsFDWIdSINGEA(skillsSINGEA.map(value => value.SubSkillFDW));

    setNamesTCEA(skillsTCEA.map(value => value.name));
    setAreasWorksTCEA(skillsTCEA.map(value => value.areasWork));
    setSubSkillsFDWIdTCEA(skillsTCEA.map(value => value.SubSkillFDW));

  }, [candidateSkillsFDW]);

  useEffect(() => {
    resetFormValues();
  }, [resetFormValues]);

  const handleOnClose = () => {
    resetFormValues();
    handleCancel();
  };

  const handleOnSubmit: React.FormEventHandler = async event => {
    event.preventDefault();
    setLoading(true);
    try {
      cancelTokenSource = axios.CancelToken.source();

      const currentCandidateSkillsFDW = [...candidateSkillsFDW];
      currentCandidateSkillsFDW.map((value, index) => {
        if (value.areasWork === 'SINGEA') {
          if (subSkillsFDWIdSINGEA.filter(subSkill => subSkill.id === value.SubSkillFDW.id).length > 0) {
            value.SubSkillFDW = subSkillsFDWIdSINGEA.filter(subSkill => subSkill.id === value.SubSkillFDW.id)[0];
          }
        }

        if (value.areasWork === 'TCEA') {
          if (subSkillsFDWIdSINGEA.filter(subSkill => subSkill.id === value.SubSkillFDW.id).length > 0) {
            value.SubSkillFDW = subSkillsFDWIdTCEA.filter(subSkill => subSkill.id === value.SubSkillFDW.id)[0];
          }
        }

        return value
      });

      await axios.put(
        `${GET_EDIT_BULK_SKILL_FDW_URL}`,
        {
          skillFDWs: currentCandidateSkillsFDW
        },
        { cancelToken: cancelTokenSource.token }
      );

      setCandidateSkillsFDW(currentCandidateSkillsFDW);
      setOpenSnackbar(true);
      setSnackbarVarient('success');
      handleCancel();
    } catch (err) {
      console.log(err);
      setOpenSnackbar(true);
      setSnackbarVarient('error');
    }
    setLoading(false);
  };

  return (
    <Dialog open={open} scroll='body' fullWidth={true} maxWidth='md'>
      <DialogTitle>
        <IconButton size='small' className={classes.closeButton} onClick={handleOnClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <SkillForm
          namesSINGEA={namesSINGEA}
          setNamesSINGEA={setNamesSINGEA}
          areasWorksSINGEA={areasWorksSINGEA}
          setAreasWorksSINGEA={setAreasWorksSINGEA}
          subSkillsFDWIdSINGEA={subSkillsFDWIdSINGEA}
          setSubSkillsFDWIdSINGEA={setSubSkillsFDWIdSINGEA}
          namesTCEA={namesTCEA}
          setNamesTCEA={setNamesTCEA}
          areasWorksTCEA={areasWorksTCEA}
          setAreasWorksTCEA={setAreasWorksTCEA}
          subSkillsFDWIdTCEA={subSkillsFDWIdTCEA}
          setSubSkillsFDWIdTCEA={setSubSkillsFDWIdTCEA}
          isSubmitting={isLoading}
          onSubmit={handleOnSubmit}
          onCancel={handleOnClose}
        />
      </DialogContent>
    </Dialog>
  );
};

export default EditSkillModal;
