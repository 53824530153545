import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button, Dialog, DialogContent, DialogTitle, ButtonBase, Grid, IconButton, Theme, Typography, withStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { orange } from '@material-ui/core/colors';
import { PRIMARY_COLOR } from 'constants/colors';
import DeleteIcon from '@material-ui/icons/Delete';
import AntSwitch from 'components/AntSwitch';
import axios, { CancelTokenSource } from 'axios';
import { DOCUMENT_UPLOAD_FILE_BASE_URL, GET_DELETE_DOCUMENT_UPLOAD_FILE_BASE_URL } from 'constants/url';
import AddIcon from '@material-ui/icons/Add';
import { StandardConfirmationDialog } from 'components/AppDialog';
import { dummyDocumentFile } from 'utils/dummy';

interface Props {
  open: boolean;
  candidateId: number;
  documentUploadFiles: CandidateFileDocument[];
  setDocumentUploadFiles: React.Dispatch<React.SetStateAction<CandidateFileDocument[]>>;
  handleCancel(): void;
  setOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarVarient: React.Dispatch<React.SetStateAction<'success' | 'error'>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  inputFileStyle: {
    display: 'none',
    marginRight: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  buttonAddDocument: {
    padding: theme.spacing(3)
  },
  contentGrid: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    marginBottom: theme.spacing(3)
  },
  contentDocument: {
    marginTop: theme.spacing(3)
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  saveButton: {
    color: '#FFFFFF'
  },
  cancelButton: {
    marginRight: theme.spacing(3)
  },
  controlDiv: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2)
  }
}));

const SaveButton = withStyles(theme => ({
  root: {
    color: '#FFFFFF',
    backgroundColor: PRIMARY_COLOR,
    '&:hover': {
      backgroundColor: orange[700]
    }
  }
}))(Button);

const EditNotesModal: React.FC<Props> = props => {
  const classes = useStyles();

  let cancelTokenSource: CancelTokenSource;
  const { open, candidateId, documentUploadFiles, setDocumentUploadFiles, handleCancel, setOpenSnackbar, setSnackbarVarient } = props;

  const [isLoading, setLoading] = useState<boolean>(false);

  const [confirmationShow, setConfirmationShow] = useState<boolean>(false);

  // This is to ensure that the form vale and erors are reset/cleared when user canceled the editing
  const resetFormValues = () => {
    const currentDocumentUploadFiles = [...documentUploadFiles];
    const newDocumentUploadFiles = currentDocumentUploadFiles.map((value, index, object) => {
      value.isDeleted = false;

      return value;
    });

    setDocumentUploadFiles(newDocumentUploadFiles.filter(value => !value.new));
  };

  useEffect(() => {
    if (candidateId) {
      return;
    }

  }, [candidateId]);

  const handleOnClose = () => {
    resetFormValues();
    handleCancel();
  };

  const handleClose = () => {
    setConfirmationShow(false);
  };

  const handleOnSubmit: React.FormEventHandler = async event => {
    event.preventDefault();
    setLoading(true);
    try {
      cancelTokenSource = axios.CancelToken.source();

      const newDocumentFile = documentUploadFiles.map(async value => {
        if (value.new) {
          value.new = false;
          const response = await axios.post(
            `${DOCUMENT_UPLOAD_FILE_BASE_URL}`,
            {
              documentfile: value.documentFile,
              CandidateId: value.CandidateId,
              allowDownloaded: value.allowDownloaded
            },
            { cancelToken: cancelTokenSource.token }
          );

          const { data } = response;

          value.documentFile = data.documentFile;

          const myHeaders = new Headers();
          const newFile: any = value.file;
          myHeaders.append('Content-Type', newFile.type);

          const config = {
            method: 'PUT',
            body: newFile,
            headers: myHeaders
          };

          await fetch(`${data.urlFile}`, config)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
        }

        if (value.isDeleted) {
          await axios.delete(GET_DELETE_DOCUMENT_UPLOAD_FILE_BASE_URL([value.id]));
        }

        return value;
      });

      const currentDoc: CandidateFileDocument[] = await Promise.all([...newDocumentFile]);

      setDocumentUploadFiles(currentDoc.filter(value => value.isDeleted));
      setOpenSnackbar(true);
      setSnackbarVarient('success');
      handleCancel();
    } catch (err) {
      console.log(err);
      setOpenSnackbar(true);
      setSnackbarVarient('error');
    }
    setLoading(false);
  };

  const handleAllowDownloaded = (index: number) => {
    const newDocumentFiles = [...documentUploadFiles];
    newDocumentFiles[index].allowDownloaded = newDocumentFiles[index].allowDownloaded ? false : true;

    setDocumentUploadFiles(newDocumentFiles);
  };

  const handleDeleteFile = (index: number) => {
    const currentDocumentFiles = [...documentUploadFiles];

    if (currentDocumentFiles[index].new) {
      currentDocumentFiles.splice(index, 1);
      setDocumentUploadFiles(currentDocumentFiles);
    } else {
      currentDocumentFiles[index].isDeleted = true;

      setDocumentUploadFiles(currentDocumentFiles);
    }
  };

  const handleChooseFile = (event: any) => {
    let file;

    if (event.target.files[0] === undefined) {
      file = '';
    } else {
      file = event.target.files[0];
    }

    if (Number((file.size / (1024 * 1024)).toFixed(2)) > 100) {
      setConfirmationShow(true);
      return;
    }

    const newDocumentFiles = [...documentUploadFiles];

    const currentDocumentFile: CandidateFileDocument = dummyDocumentFile;
    newDocumentFiles.push({
      ...currentDocumentFile,
      documentFile: file.name,
      CandidateId: candidateId,
      file: file,
      size: (file.size / (1024 * 1024)).toFixed(2)
    });

    setDocumentUploadFiles(newDocumentFiles);
  };

  return (
    <Dialog open={open} scroll='body' fullWidth={true} maxWidth='sm'>
      <DialogTitle>
        <IconButton size='small' className={classes.closeButton} onClick={handleOnClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid className={classes.contentGrid}>
          <Typography variant='h4' id='modal-title' color='primary'>
            Candidate Documents
          </Typography>
          <Grid item className={classes.contentDocument}>
            {documentUploadFiles.map(
              (value, index) =>
                !value.isDeleted && (
                  <Grid container direction='row' spacing={3} key={index} alignItems='center'>
                    <Grid item xs={6}>
                      <Typography variant='h6'>{value.documentFile}</Typography>
                    </Grid>

                    <Grid item xs={3} alignItems='center'>
                      <AntSwitch color='primary' checked={value.allowDownloaded} onClick={() => handleAllowDownloaded(index)} />
                    </Grid>
                    <Grid item xs={3}>
                      <IconButton size='small' onClick={() => handleDeleteFile(index)}>
                        <DeleteIcon color='primary' />
                      </IconButton>
                    </Grid>
                  </Grid>
                )
            )}
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <input
            accept='application/pdf,application/vnd.ms-word,video/mp4,video/quicktime'
            className={classes.inputFileStyle}
            id={`outlined-button-file`}
            type='file'
            onChange={event => handleChooseFile(event)}
          />
          <label htmlFor={`outlined-button-file`}>
            <ButtonBase focusRipple key={`Upload`} component='span' disableRipple>
              <div className={classes.buttonAddDocument}>
                <Grid container item xs={12} justify='center'>
                  <AddIcon fontSize='small' color='primary' />
                  <Typography variant='h5' color='primary'>
                    Add Document
                  </Typography>
                </Grid>
              </div>
            </ButtonBase>
          </label>
        </Grid>
        <StandardConfirmationDialog
          variant={'warning'}
          message='File max 100MB'
          open={confirmationShow}
          noCancelButton={true}
          handleClose={handleClose}
        />
        <Grid container item justify='center' xs={12} sm={12} md={12} lg={12} xl={12} className={classes.controlDiv}>
          <Button variant='contained' className={classes.cancelButton} disabled={isLoading} onClick={handleOnClose}>
            Cancel
          </Button>
          <SaveButton type='submit' variant='contained' disabled={isLoading} onClick={handleOnSubmit}>
            Save
          </SaveButton>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default EditNotesModal;
