import { createMuiTheme } from '@material-ui/core';
import { responsiveFontSizes } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import { PRIMARY_COLOR } from 'constants/colors';
import { orange } from '@material-ui/core/colors';

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    border: {
      primary: string;
    };
  }
  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    border?: {
      primary?: string;
    };
  }
}

let theme = createMuiTheme({
  border: {
    primary: grey[300]
  },
  palette: {
    primary: {
      main: PRIMARY_COLOR,
      contrastText: '#fff'
    },
    secondary: {
      main: orange[700],
      contrastText: '#fff'
    },
    background: {
      default: '#FFFFFF'
    }
  },
  typography: {
    h1: {
      fontSize: '32px',
      fontWeight: 500
    },
    h2: {
      fontSize: '28px',
      fontWeight: 500
    },
    h3: {
      fontSize: '24px',
      fontWeight: 500
    },
    h4: {
      fontSize: '20px',
      fontWeight: 500
    },
    h5: {
      fontSize: '16px',
      fontWeight: 500
    },
    h6: {
      fontSize: '14px',
      fontWeight: 400
    },
    body1: {
      fontSize: '13px'
    },
    body2: {
      fontSize: '12px',
      lineHeight: '12px'
    },
    subtitle1: {
      fontSize: '24px',
      fontWeight: 'bold',
    },
    button: {
      fontSize: '13px'
    },
    overline: {
      fontSize: '13px',
      lineHeight: '13px'
    },
    caption: {
      fontSize: '11px',
      lineHeight: '11px'
    },
  },
  overrides: {
    MuiFab: {
      root: {
        width: '0px',
        height: '0px',
        minHeight: '0px'
      },
      sizeSmall: {
        width: '20px',
        height: '20px',
      },
    },
    MuiInputBase: {
      input: {
        height: '0.256em',
      }
    },
    MuiSelect: {
      selectMenu: {
        height: '0.256em',
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: "translate(14px, 12px) scale(1)"
      },
    },
    MuiOutlinedInput: {
      adornedStart: {
        paddingLeft: 0,
        marginLeft: 0
      },
    },
    MuiToolbar: {
      root: {
        backgroundColor: '#FFFFFF',
      }
    },
    MuiTableCell: {
      head: {
        lineHeight: 'none'
      },
      root: {
        padding: '0px',
        paddingTop: '3px',
        paddingRight: '7px',
        paddingLeft: '7px',
        paddingBottom: '0px',
      }
    },
    MuiAvatar: {
      img: {
        objectPosition: '-20% 0'
      }
    },
    MuiPaper: {
      elevation4: {
        boxShadow: "0px 1px 0px -1px rgb(0 0 0 / 0%), 0px 0px 1px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 3%)"
      }
    }
  }
});

theme = responsiveFontSizes(theme);

export default theme;
